import React from 'react';
import styles from './ProductImageCard.scss';
import PropTypes from 'prop-types';
import formatPrice from 'utils/formatPrice';
import clsx from 'clsx';

const ProductImageCard = ({
  alias_title,
  title,
  price,
  onClick,
  image,
  disabled,
  className,
}) => {
  return (
    <button
      className={clsx(styles.container, className)}
      onClick={onClick}
      disabled={disabled}
    >
      <div className={styles.productCard}>
        <div className={styles.image}>
          {image && <img src={image} alt="" />}
        </div>
        <div className={styles.info}>
          <h2 className={styles.title}>{alias_title || title || ''}</h2>
          <div>
            <span className={styles.price}>${formatPrice(price)}</span>
          </div>
        </div>
      </div>
    </button>
  );
};

ProductImageCard.propTypes = {
  alias_title: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.number,
  onClick: PropTypes.func,
  image: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default ProductImageCard;

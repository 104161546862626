/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import actionTypes from 'utils/actionTypes';

const productsPresetsSlice = createSlice({
  name: 'productsPresets',
  initialState: {
    presets: [],
    presetsLoading: [],
  },
  reducers: {
    fetchPresetsRequest(state) {
      state.presetsLoading = true;
    },
    fetchPresetsSuccess(state, { payload }) {
      state.presetsLoading = false;
      state.presets = payload;
    },
    fetchPresetsFailure(state) {
      state.presetsLoading = false;
    },
  },
});

export const productsPresetsActions = actionTypes(productsPresetsSlice.actions);

export default productsPresetsSlice.reducer;

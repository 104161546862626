import React from 'react';
import PropTypes from 'prop-types';
import styles from './LoginInput.scss';

const LoginInput = React.forwardRef(
  ({ iconSrc, LeftIconComponent, filled, ...restProps }, ref) => {
    return (
      <div className={styles.root}>
        {LeftIconComponent && (
          <div className={`${styles.icon} ${filled ? styles.filledIcon : ''}`}>
            <LeftIconComponent />
          </div>
        )}
        <input ref={ref} className={styles.input} {...restProps} />
      </div>
    );
  }
);

LoginInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  LeftIconComponent: PropTypes.node,
  onChange: PropTypes.func,
  iconSrc: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  filled: PropTypes.bool,
};

LoginInput.defaultProps = {
  type: 'text',
  iconSrc: null,
  placeholder: '',
  filled: false,
};

export default LoginInput;

import React from 'react';
import useSelector from 'hooks/useSelector';
import useAction from 'hooks/useAction';

import { stripeActions } from 'models/stripe/slice';
import { errorSelector } from 'models/stripe/selectors';
import { useHistory } from 'react-router-dom';
import circlesIcon from 'images/icon-circles.png';

import styles from './Error.scss';

const Error = () => {
  const error = useSelector(errorSelector);
  const disconnectReader = useAction(stripeActions.disconnectReader);
  const history = useHistory();

  return (
    <div className={styles.root}>
      {error}
      <button
        className={styles.button}
        onClick={() => {
          disconnectReader();
          history.push('/dashboard/pos');
        }}
      >
        Return to dashboard
      </button>
      <img className={styles.icon} src={circlesIcon} alt="circles icon" />
    </div>
  );
};

export default Error;

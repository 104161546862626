import { pick } from 'lodash';
import { createSelector } from 'reselect';

export const paginatedCustomersSelector = createSelector(
  state => state,
  state => state.paginatedCustomers
);

export const paginatedCustomersEntitiesSelector = createSelector(
  paginatedCustomersSelector,
  ({ customers }) => customers
);

export const customerPendingSelector = createSelector(
  paginatedCustomersSelector,
  ({ isPending }) => isPending
);

export const paginatedCustomersLoadingSelector = createSelector(
  paginatedCustomersEntitiesSelector,
  ({ customersLoading, customersLoaded }) =>
    !customersLoaded && customersLoading
);

export const paginatedCustomersPaginationSelector = createSelector(
  paginatedCustomersSelector,
  ({ pagination }) => pagination
);

export const paginatedCustomersFiltersSelector = createSelector(
  paginatedCustomersSelector,
  model =>
    pick(model, ['pagination', 'sortingField', 'sortingCustomer', 'search'])
);

export const detailedCustomerSelector = createSelector(
  paginatedCustomersSelector,
  ({ detailedCustomer }) => detailedCustomer
);

export const detailedCustomerLoadingSelector = createSelector(
  paginatedCustomersSelector,
  ({ detailedCustomer, detailedCustomerLoading }) =>
    detailedCustomer === null || detailedCustomerLoading
);

export const addingNoteLoadingSelector = createSelector(
  paginatedCustomersSelector,
  ({ addingNoteLoading }) => addingNoteLoading
);

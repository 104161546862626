/* eslint-disable no-lonely-if */
/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import actionTypes from 'utils/actionTypes';

const tiersSlice = createSlice({
  name: 'tiers',
  initialState: {
    tiers: [],
    tiersLoading: false,
    confirmTierPending: false,
    selectedTierId: null,
    tierPending: false,
    activeObligation: null,
    tierActionSuccess: false,
  },
  reducers: {
    fetchTiersRequest(state) {
      state.tiersLoading = true;
    },
    fetchTiersSuccess(state, { payload }) {
      state.tiersLoading = false;
      state.tiers = payload;
    },
    fetchTiersFailure(state) {
      state.tiersLoading = false;
    },

    confirmTier(state) {
      state.tierPending = true;
      state.tierActionSuccess = false;
    },
    confirmTierSuccess(state) {
      state.tierPending = false;
      state.tierActionSuccess = true;
    },
    confirmTierFailure(state) {
      state.tierPending = false;
    },

    cancelTier(state) {
      state.tierPending = true;
      state.tierActionSuccess = false;
    },
    cancelTierSuccess(state) {
      state.tierPending = false;
      state.tierActionSuccess = true;
    },
    cancelTierFailure(state) {
      state.tierPending = false;
    },

    activeTier(state, { payload }) {
      state.activeObligation = payload?.wine_club_member?.active_obligation;
    },

    changeSelectedTierId(state, { payload }) {
      state.selectedTierId = payload.id;
    },
  },
});

export const tiersActions = actionTypes(tiersSlice.actions);

export default tiersSlice.reducer;

import React from 'react';
import PropTypes from 'prop-types';

const CircleShapedIcon = ({ fill, width, height, className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <title>Circle Shaped Icon</title>
      <g style={{ fill: 'none', opacity: 0.9 }}>
        <g stroke={fill}>
          <circle cx="23.5" cy="23.9" r="10.5" />
          <path
            d="M23.5 40C32.6 40 40 32.6 40 23.5 40 14.4 32.6 7 23.5 7 14.4 7 7 14.4 7 23.5 7 32.6 14.4 40 23.5 40Z"
            strokeDasharray="1"
          />
        </g>
      </g>
    </svg>
  );
};

CircleShapedIcon.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CircleShapedIcon.defaultProps = {
  fill: '#EDEAE3',
  width: '47',
  height: '47',
};

export default CircleShapedIcon;

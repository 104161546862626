export const startOfDayMessages = [
  'Good morning! Ready to crush it today? 🍇',
  "Welcome back! Let's bottle some excellence today. 🍾",
  'Rise and Wine! Another day of crafting the finest. 🍷',
  'Hey there! Looking forward to another grape day with you. 🌞',
  "Welcome! Let's turn today's effort into tomorrow's vintage. 🕰️",
  "Good to see you! Time to decant your skills into today's work. 🍶",
  "Rise and shine! Let's create something worth toasting to. 🥂",
  'Morning! Time to pour yourself into today’s tasks. 📋',
  "Good morning! Let's age to perfection today. ⏳",
  'Hello! Ready to ferment some great ideas today? 💡',
  "Welcome back! Let's let no grape go to waste today. ♻️",
  "Morning! The vineyard is our canvas; let's make it a masterpiece. 🎨",
  'Welcome! Another day to blend our efforts into something special. 🤝',
  "Good to see you! Let's keep things on the bright and fruity side. 🌈",
  "Morning! Let's uncork your potential today. 🍾",
  'Hello! Ready for another sip of the good life? 🥂',
  "Good morning! Let's nurture the vine and treasure the wine. 🌱",
  'Hey! Excited for another day of turning grapes into greatness. 🍇',
  'Welcome! Time to add another layer to our bouquet of achievements. 💐',
  'Hello! Another day to let your skills breathe and blossom. 🌸',
];

export const endOfDayMessages = [
  "Great work today! Let's toast to a job well done. 🥂",
  'Cheers to wrapping up another fruitful day! 🍇',
  'Another day well-spent; you crushed it! 🍷',
  'Time to cork things up! Enjoy your evening. 🌆',
  'Another vintage day in the books. Good job! 📚',
  'You decanted your best efforts today. Time to relax! 🛀',
  'And...cut! Time to vine down for the evening. 🎬',
  'Goodbye for now! Your work today was grape-tastic! 🍇',
  'Wrapped up another day like a fine vintage. Cheers! 🍾',
  'Another blend of effort and skill—well done today! 👏',
  "Day's end is here. Hope it was as smooth as a good Merlot. 🍷",
  'As the sun sets over the vineyards, so does our work day. Great job! 🌅',
  "Time to un-wine-d. You've earned it! 🌙",
  'Bottled up another successful day—see you tomorrow! 🍾',
  "And that's a wrap! Let's savor the achievements. 🎉",
  'Time to decant and chill. See you tomorrow! 🌌',
  'Another day, another grape effort. Goodnight! 🌙',
  'Farewell for now! Our work today will age beautifully. 🕰️',
  "A toast to today's accomplishments. Goodbye! 🥂",
  "Night is here; let's cellar-brate another successful day! 🍷",
];

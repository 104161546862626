import React from 'react';
import PropTypes from 'prop-types';

const ChevronRightIcon = ({ fill, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 511.949 511.949"
    >
      <g>
        <g xmlns="http://www.w3.org/2000/svg">
          <g>
            <path
              d="M386.235,248.308L140.902,2.975c-4.267-4.053-10.987-3.947-15.04,0.213c-3.947,4.16-3.947,10.667,0,14.827l237.76,237.76    l-237.76,237.867c-4.267,4.053-4.373,10.88-0.213,15.04c4.053,4.267,10.88,4.373,15.04,0.213c0.107-0.107,0.213-0.213,0.213-0.213    l245.333-245.333C390.395,259.188,390.395,252.468,386.235,248.308z"
              fill={fill}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

ChevronRightIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ChevronRightIcon.defaultProps = {
  fill: '#EDEAE3',
  width: '17',
  height: '17',
};

export default ChevronRightIcon;

export const getTaxesObject = orderItems => {
  const taxes = {};
  orderItems.forEach(item => {
    Object.entries(item?.applied_fees ?? {}).forEach(([key, value]) => {
      if (value.alias in taxes) taxes[value.alias] += value.fee;
      else if (key in taxes) taxes[key] += value.fee;
      else taxes[value.alias || key] = value.fee;
    });
  });
  return taxes;
};

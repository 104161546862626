import React, { useMemo } from 'react';
import styles from './POSDashboard.scss';
import AddCustomer from './AddCustomer';
import Sidebar from './Sidebar';
import Content from './Content';

import useSelector from 'hooks/useSelector';
import {
  orderLoadingSelector,
  userLoadingSelector,
} from 'models/desks/selectors';

import { POSDashboardLayoutSelector } from 'models/interface/selectors';
import { POSDashboardLayoutsEnum } from 'constants/POSLayouts';

const POSDashboard = () => {
  const dashboardLayout = useSelector(POSDashboardLayoutSelector);
  const orderLoading = useSelector(orderLoadingSelector);
  const userLoading = useSelector(userLoadingSelector);

  const renderedLayout = {
    [POSDashboardLayoutsEnum.MAIN]: useMemo(
      () => (
        <>
          <div className={styles.content}>
            <Content />
          </div>
          <div className={styles.sidebar}>
            <Sidebar />
          </div>
        </>
      ),
      [dashboardLayout]
    ),
    [POSDashboardLayoutsEnum.CUSTOMER]: useMemo(() => <AddCustomer />, [
      dashboardLayout,
    ]),
  };

  return (
    <div className={styles.root}>
      {userLoading || orderLoading
        ? 'Loading...'
        : renderedLayout[dashboardLayout]}
    </div>
  );
};

export default POSDashboard;

/* eslint-disable no-lonely-if */
/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import actionTypes from 'utils/actionTypes';
import { tiersActions } from '../tiers/slice';

const paginatedCustomersSlice = createSlice({
  name: 'paginatedCustomers',
  initialState: {
    customers: [],
    customersLoaded: false,
    customersLoading: false,
    pagination: null,

    sorting: [],

    sortingField: null,
    sortingCustomer: null,
    search: null,

    detailedCustomer: null,
    detailedCustomerLoading: false,

    addingNoteLoading: false,
    isPending: false,
  },
  reducers: {
    fetchCustomersRequest(state) {
      state.customersLoading = true;
    },
    fetchCustomersSuccess(state, { payload }) {
      state.customersLoading = false;
      state.customers = payload.results;
      state.pagination = payload.pagination;
      state.customersLoaded = true;
    },
    fetchCustomersFailure(state) {
      state.customersLoading = false;
    },

    changePagination(state, { payload }) {
      state.pagination.current_page = payload.page;
    },
    changeSorting(state, { payload }) {
      if (state.sortingField !== payload.sortingField) {
        state.sortingField = payload.sortingField;
        state.sortingCustomer = 'asc';
      } else {
        if (state.sortingCustomer === 'asc') {
          state.sortingCustomer = 'desc';
        } else {
          state.sortingField = null;
          state.sortingCustomer = null;
        }
      }
    },
    changeSearch(state, { payload }) {
      state.search = payload.search;
      if (state.pagination) {
        state.pagination.current_page = 1;
      }
    },

    fetchDetailedCustomerRequest(state) {
      state.detailedCustomerLoading = true;
    },
    fetchDetailedCustomerSuccess(state, { payload }) {
      state.detailedCustomer = payload;
      state.detailedCustomerLoading = false;
    },
    fetchDetailedCustomerFailure(state) {
      state.detailedCustomerLoading = false;
    },

    addNoteRequest(state) {
      state.addingNoteLoading = true;
    },
    addNoteRequestSuccess(state, { payload }) {
      state.detailedCustomer.notes.unshift(payload);
      state.addingNoteLoading = false;
    },
    addNoteRequestFailure(state) {
      state.addingNoteLoading = false;
    },
    updateCustomer: state => {
      state.isPending = true;
    },
    updateCustomerSuccess: (state, { payload }) => {
      state.isPending = false;
      state.detailedCustomer = payload;
    },
    updateCustomerFailure: state => {
      state.isPending = false;
    },
  },
  extraReducers: {
    [tiersActions.confirmTierSuccess](state, { payload }) {
      if (state.detailedCustomer) {
        state.detailedCustomer = {
          ...state.detailedCustomer,
          wine_club_member: {
            ...state.detailedCustomer.wine_club_member,
            active_obligation: payload,
          },
        };
      }
    },
    [tiersActions.cancelTierSuccess](state) {
      if (state.detailedCustomer) {
        state.detailedCustomer = {
          ...state.detailedCustomer,
          wine_club_member: {
            ...state.detailedCustomer.wine_club_member,
            active_obligation: null,
          },
        };
      }
    },
  },
});

export const paginatedCustomersActions = actionTypes(
  paginatedCustomersSlice.actions
);

export default paginatedCustomersSlice.reducer;

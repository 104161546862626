import React, { useEffect, useRef, useState } from 'react';
import styles from './FoodForm.scss';
import PropTypes from 'prop-types';
import formatPrice from 'utils/formatPrice';
import Chip from '../Chip';
import { useKeyboard } from 'context/KeyboardContext/KeyboardContext';

const chipCLickHandler = (value, chips, setChip) => {
  const { id } = value;
  if (id in chips) {
    const allergiesCopy = { ...chips };
    delete allergiesCopy[id];
    setChip(allergiesCopy);
  } else {
    setChip({ ...chips, [id]: value });
  }
};

const FoodForm = ({
  onSubmit,
  price,
  additions,
  allergies,
  defaultValues,
  disabled,
  dispatchKeyboardState,
}) => {
  const { keyboardShow, setKeyboardValue, setKeyboardShow } = useKeyboard();
  const [total, setTotal] = useState(price);
  const [selectedAllergies, setSelectedAllergies] = useState({});
  const [selectedAdditions, setSelectedAdditions] = useState(
    defaultValues?.additions ?? {}
  );
  const formRef = useRef(null);

  const onFocus = event => {
    setKeyboardShow(true);
    setKeyboardValue(event.target.value);
  };

  const onBlur = () => setKeyboardShow(false);

  const onChange = event => setKeyboardValue(event.target.value);

  const submitHandler = () => {
    const formData = new FormData(formRef.current);
    onSubmit({
      comment: formData.get('comment'),
      additions: selectedAdditions,
      allergies: selectedAllergies,
    });
  };

  useEffect(() => {
    setKeyboardValue(defaultValues?.comment);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    dispatchKeyboardState && dispatchKeyboardState(keyboardShow);
  }, [keyboardShow]);

  useEffect(() => {
    const additionsPriceTotal = Object.values(selectedAdditions)
      .map(item => item.price)
      .reduce((a, b) => a + b, 0);
    setTotal(price + additionsPriceTotal);
  }, [selectedAdditions]);

  return (
    <div className={styles.container}>
      <div className={styles.foodWrapper}>
        <p className={styles.title}>
          <span>TOTAL:</span> ${formatPrice(total)}
        </p>
      </div>
      <form ref={formRef}>
        <button
          disabled={disabled}
          className={styles.button}
          onClick={submitHandler}
          type="button"
        >
          Confirm
        </button>
        {additions && additions.length > 0 && (
          <div className={styles.additions}>
            <h3 className={styles.subtitle}>Additions</h3>
            <div className={styles.chipsWrapper}>
              {additions.map(item => (
                <Chip
                  key={item.id}
                  onClick={() =>
                    chipCLickHandler(
                      item,
                      selectedAdditions,
                      setSelectedAdditions
                    )
                  }
                  title={item.title}
                  active={item.id in selectedAdditions}
                />
              ))}
            </div>
          </div>
        )}
        <label className={styles.notes}>
          <h3 className={styles.subtitle}>Notes</h3>
          <textarea
            name="comment"
            className={styles.textarea}
            defaultValue={defaultValues?.comment}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            inputMode="none"
          />
        </label>
        {allergies && (
          <div className={styles.chips}>
            <h3 className={styles.subtitle}>Food Allergies</h3>
            <div className={styles.chipsWrapper}>
              {allergies.map(item => (
                <Chip
                  key={item.id}
                  onClick={() =>
                    chipCLickHandler(
                      item,
                      selectedAllergies,
                      setSelectedAllergies
                    )
                  }
                  title={item.title}
                  active={[item.id] in selectedAllergies}
                />
              ))}
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

FoodForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  price: PropTypes.number,
  allergies: PropTypes.array,
  additions: PropTypes.array,
  defaultValues: PropTypes.object,
  dispatchKeyboardState: PropTypes.func,
  disabled: PropTypes.bool,
};
export default FoodForm;

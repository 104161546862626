import React from 'react';
import PropTypes from 'prop-types';

const UserIcon = ({ filled, width, height }) => {
  const fill = filled ? '#333' : '#EDEAE3';
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 21C4.76837e-07 16.5817 3.58172 13 8 13C12.4183 13 16 16.5817 16 21H0V21ZM9 15.083V19H13.659C12.9311 16.9462 11.1483 15.4474 9 15.083L9 15.083ZM7 19V15.083C4.85168 15.4474 3.06893 16.9462 2.341 19H7ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM8 10C10.21 10 12 8.21 12 6C12 3.79 10.21 2 8 2C5.79 2 4 3.79 4 6C4 8.21 5.79 10 8 10Z"
      />
    </svg>
  );
};

UserIcon.propTypes = {
  filled: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

UserIcon.defaultProps = {
  filled: false,
  width: '16',
  height: '21',
};

export default UserIcon;

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { calculateSubtotal } from 'utils/calculateSubtotal';
import formatPrice from 'utils/formatPrice';
import OrderProducts from '../OrderProducts';
import OrderItem from '../OrderItem';
import styles from './OrderProductList.scss';

const FeeItem = ({ title, value }) => (
  <div className={styles.fee}>
    <span>{title}</span>
    <span>{value}</span>
  </div>
);

const OrderProductList = ({ order }) => {
  return (
    <div className={styles.blockContainer}>
      <div className={clsx(styles.block, styles.blockProductList)}>
        <div className={styles.orderItemsWrapper}>
          <h3 className={styles.orderItemsTitle}>Product List</h3>
          <OrderProducts items={order?.order_items} />
        </div>
      </div>
      <div className={clsx(styles.block, styles.blockTotal)}>
        <OrderItem
          title="Subtotal"
          value={`$${formatPrice(calculateSubtotal(order?.order_items))}`}
        />
        <div className={styles.taxes}>
          {order &&
            Object.keys(order.applied_fees).map(tax => (
              <FeeItem
                key={tax}
                title={tax}
                value={`$${formatPrice(order.applied_fees[tax])}`}
              />
            ))}
        </div>
        <OrderItem title="Total Tax" value={`$${formatPrice(order?.fee)}`} />
        {order?.total_order_discount > 0 && (
          <OrderItem
            title="Discount"
            value={`-$${formatPrice(order?.total_order_discount)}`}
          />
        )}
        {order?.tips_amount > 0 && (
          <OrderItem
            title="Tips"
            value={`$${formatPrice(order?.tips_amount)}`}
          />
        )}
        {order?.refund_amount > 0 && (
          <>
            <OrderItem
              title="Refund Amount"
              value={`$${formatPrice(order?.refund_amount)}`}
            />
            <OrderItem
              title="Total before Refund"
              value={`$${formatPrice(order?.total_price)}`}
            />
          </>
        )}
        <OrderItem
          title="Total Price"
          value={`$${formatPrice(order?.total_price)}`}
        />
      </div>
    </div>
  );
};

OrderProductList.propTypes = {
  order: PropTypes.object,
};

FeeItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default OrderProductList;

import React from 'react';
import PropTypes from 'prop-types';

const CircleIcon = ({ fill, width, height }) => {
  return (
    <svg height={height} width={width}>
      <circle cx={width / 2} cy={width / 2} r={height / 2} fill={fill} />
    </svg>
  );
};

CircleIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CircleIcon.defaultProps = {
  fill: '#EDEAE3',
  width: '10',
  height: '10',
};

export default CircleIcon;

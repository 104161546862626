import clsx from 'clsx';
import React from 'react';

import { ToastContainer } from 'react-toastify';
import styles from './ToastContainer.scss';

const CustomToastContainer = ({ ...props }) => (
  <ToastContainer
    draggable
    newestOnTop
    closeOnClick
    pauseOnHover
    toastClassName={clsx(styles.root, styles.black)}
    closeButton={false}
    autoClose={6000}
    position="bottom-right"
    {...props}
  />
);

export default CustomToastContainer;

import React, { useEffect } from 'react';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { paginatedCustomersActions } from 'models/paginatedCustomers/slice';
import { paginatedCustomersLoadingSelector } from 'models/paginatedCustomers/selectors';

import Customers from './Customers';

const CustomersContainer = () => {
  const loading = useSelector(paginatedCustomersLoadingSelector);
  const fetchCustomers = useAction(
    paginatedCustomersActions.fetchCustomersRequest
  );

  useEffect(() => {
    fetchCustomers({ withFilterCriteria: true });
  }, []);

  return loading ? <div>Loading...</div> : <Customers />;
};

export default CustomersContainer;

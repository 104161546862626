import React from 'react';
import styles from './RefundSuccess.scss';
import SimplePageLayout from '../../components/SimplePageLayout';
import DoubleCircleIcon from '../../components/Icons/DoubleCircleIcon';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

const RefundSuccess = () => {
  return (
    <SimplePageLayout>
      <div className={styles.container}>
        <h1 className={styles.title}>Your return has been processed!</h1>
        <DoubleCircleIcon />
        <Link
          className={clsx(styles.link, styles.ordersLink)}
          to="/dashboard/orders"
        >
          Back to orders
        </Link>
      </div>
    </SimplePageLayout>
  );
};

export default RefundSuccess;

import React from 'react';
import styles from './ProductCard.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import formatPrice from 'utils/formatPrice';

const ProductCard = ({ title, onClick, className, price }) => {
  return (
    <button onClick={onClick} className={clsx(styles.container, className)}>
      <div className={styles.info}>
        <p className={styles.title}>{title}</p>
        {price && <p className={styles.price}>${formatPrice(price)}</p>}
      </div>
    </button>
  );
};

ProductCard.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  price: PropTypes.number,
};
export default ProductCard;

import { createSelector } from 'reselect';

export const interfaceSelector = createSelector(
  state => state,
  state => state.interface
);

export const tastingLayoutSelector = createSelector(
  interfaceSelector,
  ({ tastingLayout }) => tastingLayout
);

export const POSDashboardLayoutSelector = createSelector(
  interfaceSelector,
  ({ POSDashboardLayout }) => POSDashboardLayout
);

export const POSSidebarLayoutSelector = createSelector(
  interfaceSelector,
  ({ POSSidebarLayout }) => POSSidebarLayout
);

export const POSContentLayoutSelector = createSelector(
  interfaceSelector,
  ({ POSContentLayout }) => POSContentLayout
);

export const orderItemEditingModalOpenSelector = createSelector(
  interfaceSelector,
  ({ orderItemEditingModalOpen }) => orderItemEditingModalOpen
);

export const guestAddingModalOpenSelector = createSelector(
  interfaceSelector,
  ({ guestAddingModalOpen }) => guestAddingModalOpen
);

export const guestEmailModalOpenSelector = createSelector(
  interfaceSelector,
  ({ guestEmailModalOpen }) => guestEmailModalOpen
);

export const tastingMenuModalOpenSelector = createSelector(
  interfaceSelector,
  ({ tastingMenuModalOpen }) => tastingMenuModalOpen
);

export const menuBreadcrumbsSelector = createSelector(
  interfaceSelector,
  ({ breadcrumbsMenu }) => breadcrumbsMenu
);

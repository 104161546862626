import { createSelector } from 'reselect';

export const sessionSelector = createSelector(
  state => state,
  state => state.session
);

export const userSelector = createSelector(sessionSelector, ({ user }) => user);

export const startedTimeEntrySelector = createSelector(
  sessionSelector,
  ({ user }) => user.started_time_entry
);

export const minCustomerSearchQueryLengthValueSelector = createSelector(
  userSelector,
  ({ min_customer_search_query_length_value }) =>
    min_customer_search_query_length_value
);

export const sessionLoadingSelector = createSelector(
  sessionSelector,
  ({ sessionLoading }) => sessionLoading
);

export const tokenSelector = createSelector(
  sessionSelector,
  ({ user }) => user?.token
);

export const loginPendingSelector = createSelector(
  sessionSelector,
  ({ loginPending }) => loginPending
);

export const isAuthorizedSelector = createSelector(
  [userSelector],
  user => user.token != null
);

export const warehouseIdSelector = createSelector(
  userSelector,
  ({ current_warehouse_id }) => current_warehouse_id
);

export const brandsSelector = createSelector(
  userSelector,
  ({ brands }) => brands
);

export const hostBrandsSelector = createSelector(
  userSelector,
  ({ host_brands }) => host_brands
);

export const terminalsSelector = createSelector(
  userSelector,
  ({ current_terminals }) => current_terminals
);

export const printersSelector = createSelector(
  userSelector,
  ({ current_printers }) => current_printers
);

export const warehousesEntitiesSelector = createSelector(
  userSelector,
  ({ warehouses }) => warehouses
);

export const acquirerSelector = createSelector(
  userSelector,
  ({
    acquirer,
    acquirer_id,
    acquirer_public_key,
    stripe_connect_account_id,
    stripe_location_id,
  }) => ({
    acquirer,
    acquirer_id,
    acquirer_public_key,
    stripe_connect_account_id,
    stripe_location_id,
  })
);

export const hostsSelector = createSelector(
  sessionSelector,
  ({ hosts }) => hosts
);

export const hostsLoadingSelector = createSelector(
  sessionSelector,
  ({ hostsLoading }) => hostsLoading
);

export const roleSelector = createSelector(userSelector, ({ role }) => role);

export const isManagerAuthorizedSelector = createSelector(
  [isAuthorizedSelector, roleSelector],
  (isAuthorized, role) => isAuthorized && role === 'manager'
);

export const isHostAuthorizedSelector = createSelector(
  [isAuthorizedSelector, roleSelector],
  (isAuthorized, role) =>
    isAuthorized && (role === 'host' || role === 'manager')
);

export const currentBrandSelector = createSelector(
  userSelector,
  ({ selectedBrand }) => selectedBrand
);

export const currentWarehouseSelector = createSelector(
  userSelector,
  ({ selectedWarehouse }) => selectedWarehouse
);

export const hostsHistorySelector = createSelector(
  userSelector,
  ({ selectedWarehouse, selectedBrand, brands }) =>
    brands
      ?.find(brand => Number(selectedBrand) === brand.id)
      ?.warehouses?.find(
        warehouse => warehouse.id === Number(selectedWarehouse)
      )?.warehouse_logged_hosts
);

export const userSwitchModalSelector = createSelector(
  sessionSelector,
  ({ userSwitchModal }) => userSwitchModal
);

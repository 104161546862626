import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import styles from './Badge.scss';

const Badge = ({ children, className, style }) => {
  return (
    <div style={style} className={clsx(styles.root, className && className)}>
      {children}
    </div>
  );
};

Badge.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Badge.defaultProps = {
  style: null,
  className: null,
};

export default Badge;

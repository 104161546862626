import { createSelector } from 'reselect';

const selectSelf = state => state.tasters;

export const tastersSelector = createSelector(
  selectSelf,
  tasters => tasters?.tasters
);

export const tastersLoadingSelector = createSelector(
  tastersSelector,
  ({ tastersLoading }) => tastersLoading
);

import React from 'react';
import { Redirect } from 'react-router-dom';
import styles from './LoginManager.scss';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import cellarLogo from 'assets/images/cellar-logo.svg';
import LockIcon from 'components/Icons/LockIcon';
import UserIcon from 'components/Icons/UserIcon';
import { sessionActions } from 'models/session/slice';
import { commonActions } from 'models/common/slice';
import { PinAction } from 'constants/PinActions';

import {
  loginPendingSelector,
  isManagerAuthorizedSelector,
} from 'models/session/selectors';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ErrorLabel from './ErrorLabel';
import LoginInput from 'components/LoginInput/LoginInput';
import * as yup from 'yup';
import clsx from 'clsx';
import config from 'config';
import { brandKey, warehouseKey } from '../../constants/localStorage';

const { coreUrl } = config;

const managerFormSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email field must contain a valid email address')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
});

const LoginManager = () => {
  const loginPending = useSelector(loginPendingSelector);
  const login = useAction(sessionActions.loginRequest);
  const showLoading = useAction(commonActions.showLoading);

  const isManagerAuthorized = useSelector(isManagerAuthorizedSelector);
  const isLocationSet =
    !!localStorage.getItem(brandKey) && !!localStorage.getItem(warehouseKey);

  const isIPad =
    /iPad/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  const adminUrl = isIPad ? 'logout/' : '';

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({ resolver: yupResolver(managerFormSchema) });

  const onLogin = data => {
    showLoading('Logging in');
    login({ email: data.email, password: data.password, role: 'manager' });
  };

  if (isManagerAuthorized && !isLocationSet) {
    return <Redirect to="/space-setup" />;
  } else if (isLocationSet) {
    return (
      <Redirect
        to={{
          pathname: '/pin',
          state: { pinAction: PinAction.LOGIN },
        }}
      />
    );
  }
  return (
    <div className={styles.root}>
      <img src={cellarLogo} height={27} alt="cellar" />
      <form onSubmit={handleSubmit(onLogin)} noValidate className={styles.form}>
        <div className={styles.group}>
          <label htmlFor="email">Email</label>
          {errors.email && <ErrorLabel>{errors.email.message}</ErrorLabel>}
          <LoginInput
            name="email"
            type="email"
            placeholder="Enter Email"
            {...register('email', { required: true })}
            autoComplete="off"
            LeftIconComponent={UserIcon}
            filled={watch('email') !== ''}
            disabled={loginPending}
            onChange={event => {
              const { value } = event.target;
              event.target.value = value.toLowerCase();
            }}
          />
        </div>
        <div className={styles.group}>
          <label htmlFor="email">Password</label>
          {errors.password && (
            <ErrorLabel>{errors.password.message}</ErrorLabel>
          )}
          <LoginInput
            name="password"
            type="password"
            placeholder="Enter Password"
            {...register('password', { required: true })}
            autoComplete="off"
            LeftIconComponent={LockIcon}
            disabled={loginPending}
            filled={watch('password') !== ''}
          />
        </div>
        <button
          className={clsx(styles.btn, styles.buttonPrimary)}
          disabled={loginPending}
        >
          Login
        </button>
        <hr className={styles.hr} />
        <a href={`${coreUrl}/${adminUrl}`} className={styles.link}>
          Go to <span>Admin Portal</span>
        </a>
      </form>

      <div className={styles.copyright}>
        © {new Date().getFullYear()} Cellar
      </div>
    </div>
  );
};

export default LoginManager;

/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import actionTypes from 'utils/actionTypes';

const stripeSlice = createSlice({
  name: 'stripe',
  initialState: {
    id: null,
    paymentIntentId: null,
    error: null,
    step: 'Connecting reader...',
    terminal: null,
    action: null,
    isLoading: false,
    isUpdating: false,
  },
  reducers: {
    setError: (state, { payload }) => {
      state.error = payload;
      state.isLoading = false;
    },
    setIsUpdating: (state, { payload }) => {
      state.isUpdating = payload;
    },
    cancelLoading: state => {
      state.isLoading = false;
    },
    setStep: (state, { payload }) => {
      state.step = payload;
    },
    connectReader: state => state,
    createPaymentIntent: state => state,
    confirmCardPayment: (state, { payload }) => {
      state.isLoading = true;
      state.card = payload;
    },
    setPaymentIntent: (state, { payload }) => {
      state.paymentIntentId = payload;
    },
    capturePayment: state => state,
    capturePaymentSuccess: state => {
      state.isLoading = false;
    },

    makeRefund: state => state,
    captureRefund: state => state,
    sendReceipt: state => state,

    disconnectReader: state => state,

    selectTerminal: (state, { payload }) => {
      state.terminal = payload.terminal;
      state.action = payload.type;
    },
    logRequest: state => state,
  },
});

export const stripeActions = actionTypes(stripeSlice.actions);

export default stripeSlice.reducer;

/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable no-return-assign */
/* eslint-disable react/sort-comp */
/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import ArrowRightIcon from 'components/Icons/ArrowRightIcon';
import AnimatedLoadingIcon from 'components/Icons/AnimatedLoadingIcon';
import styles from './KeyboardInput.scss';
import { isEmpty } from 'lodash';

class KeyboardInput extends React.PureComponent {
  state = {
    value: '',
  };

  componentDidMount() {
    this.setState(prevState => ({
      ...prevState,
      value: this.props.value || '',
    }));
    if (this.props.autoOpenOnMount) {
      this.input.focus();
    }
  }

  handleInputChange = e => {
    const { value } = e.target;
    const { pattern } = this.props;
    const isMatch = value.match(pattern);
    if (!pattern || (pattern && isMatch) || !value) {
      this.setState(
        state => ({ ...state, value }),
        () => {
          this.props.onChange(value);
        }
      );
    }
  };

  handleChange = value => {
    this.setState(
      state => ({ ...state, value }),
      () => {
        this.props.onChange(value);
      }
    );
  };

  render() {
    const { value } = this.state;
    const { onInputFocus, handleInputChange } = this;
    const {
      className,
      name,
      type,
      placeholder,
      loading,
      disabled,
      caption,
      textCentered,
      buttonOptions,
      maxLength,
    } = this.props;

    return (
      <div className={clsx(styles.root, className)}>
        <div className={styles.caption}>{caption}</div>
        <div className={styles.input}>
          <input
            name={name}
            ref={n => (this.input = n)}
            type={type}
            value={value}
            onFocus={onInputFocus}
            onChange={handleInputChange}
            placeholder={placeholder}
            autoComplete="off"
            maxLength={maxLength}
            // inputMode="none"
            disabled={disabled}
            className={clsx({ [styles.inputCentered]: textCentered })}
          />
        </div>
        {isEmpty(buttonOptions) ? (
          <div className={styles.proceed}>
            <button type="submit" className={styles.proceedBtn}>
              {loading ? (
                <AnimatedLoadingIcon width="75" height="75" />
              ) : (
                <ArrowRightIcon />
              )}
            </button>
          </div>
        ) : (
          <div className={buttonOptions.containerClassName}>
            {buttonOptions.options.map(option => {
              if (option.type === 'submit') {
                return (
                  <button type={option.type} className={option.className}>
                    {option.name}
                  </button>
                );
              }
              return (
                <button
                  type={option.type}
                  className={option.className}
                  onClick={evt => {
                    evt.preventDefault();
                    option.action();
                  }}
                >
                  {option.name}
                </button>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

KeyboardInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  caption: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  autoOpenOnMount: PropTypes.bool,
  buttonOptions: PropTypes.array,
  textCentered: PropTypes.bool,
  maxLength: PropTypes.number,
  pattern: PropTypes.instanceOf(RegExp),
};

KeyboardInput.defaultProps = {
  disabled: false,
  value: '',
  textCentered: false,
  maxLength: null,
  pattern: '',
  autoOpenOnMount: false,
  caption: '',
  loading: false,
};

export default KeyboardInput;

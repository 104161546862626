import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './Report.scss';
import { getTimeSince } from 'utils/formatCurrentDate';
import formatPrice from 'utils/formatPrice';

const Report = ({ customer }) => {
  const report = [
    {
      title: 'Last Order',
      value: getTimeSince(customer.last_order_at, true),
    },
    {
      title: 'Lifetime Value',
      value: `$ ${formatPrice(customer.total_sum_of_paid_orders)}`,
    },
    {
      title: 'Average Bill',
      value: `$ ${formatPrice(customer.average_check)}`,
    },
  ];

  return (
    <div className={styles.report}>
      {report.map(item => (
        <div className={styles.item}>
          <p className={styles.itemTitle}>{item.title}</p>
          <p className={styles.itemValue}>{item.value}</p>
        </div>
      ))}
    </div>
  );
};

Report.propTypes = {
  customer: PropTypes.object,
};

export default memo(Report);

/*eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import TimesIcon from 'components/Icons/TimesIcon';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import formatPrice from 'utils/formatPrice';
import { calculateSubtotal } from 'utils/calculateSubtotal';
import { getTaxesObject } from 'utils/getTaxesObject';
import styles from './Bill.scss';
import InputLabelled from 'components/InputLabelled';
import useSelector from 'hooks/useSelector';
import { currentOrderSelector } from 'models/desks/selectors';
import RippleSpinner from '../../RippleSpinner';
import { billBusySelector } from 'models/desks/selectors';

const BillBlock = ({ title, value, className, button, billBusy }) => (
  <div
    className={clsx(styles.billBlock, className)}
    style={billBusy ? { opacity: 0.5 } : {}}
  >
    {!!button && button}
    <p className={styles.billText}>{title}</p>
    <p className={styles.billText}>{value || '-'}</p>
  </div>
);

const Bill = ({
  order,
  orderItems,
  isCheckoutCart,
  updateItem,
  updateDiscountCode,
  discountCode,
  wineDirectSetting,
}) => {
  const history = useHistory();
  const orderSubtotal = calculateSubtotal(orderItems);
  const [couponCode, setCouponCode] = useState(couponCode);
  const [numChildren, setNumChildren] = useState(0);
  const taxesRef = useRef(null);
  const billBusy = useSelector(billBusySelector);

  useEffect(() => {
    try {
      const taxes = taxesRef?.current;
      if (!taxes) {
        return;
      }
      const taxesChildren = taxes?.children.length || 0;
      if (taxesChildren <= 3) {
        taxes.style.maxHeight = `${taxes.scrollHeight}px`;
      } else {
        taxes.style.maxHeight = '90px';
      }
      setNumChildren(taxesChildren);
    } catch (error) {
      console.error(error);
    }
  }, [taxesRef]);

  const currentOrder = useSelector(currentOrderSelector);

  const redirectToPayment = () => {
    history.push(
      `/dashboard/payment?order_id=${order?.id}&type=${order?.initiator || ''}`
    );
  };

  const totalNumberOfItems = orderItems.reduce(
    (total, item) => total + item.number,
    0
  );

  const discountName = order => {
    if (order.discount_code) {
      return `${order.discount_code.title} discount (${
        order.discount_code.percentage_amount
          ? `${order.discount_code.percentage_amount}%`
          : `$${order.discount_code.money_amount}`
      })`;
    }
    if (
      order?.customer?.wine_club_member?.active_obligation?.status === 'ACTIVE'
    ) {
      if (wineDirectSetting) {
        return `Wine Club discount (${order?.customer?.wine_club_member?.active_obligation?.tier?.discount_for_visiting_tasting_room}%)`;
      }
      else {
        return `SignUp discount (${order?.customer?.wine_club_member?.active_obligation?.tier?.discount_for_visiting_tasting_room}%)`;
      }
    }
  };

  return (
    <div className={styles.bill}>
      <BillBlock
        title="Total items"
        value={totalNumberOfItems}
        billBusy={billBusy}
      />
      <BillBlock
        title="Subtotal"
        value={`$ ${formatPrice(orderSubtotal)}`}
        billBusy={billBusy}
      />
      {isCheckoutCart && (
        <div className={styles.taxes}>
          {order &&
            Object.entries(getTaxesObject(orderItems))
              .filter(([_, value]) => value)
              .map(([key, value]) => (
                <BillBlock
                  key={key}
                  className={styles.taxBlock}
                  title={key}
                  value={`$ ${formatPrice(value)}`}
                />
              ))}
        </div>
      )}
      {(order?.total_order_discount > 0 || order?.discount_code?.title) && (
        <BillBlock
          title={discountName(order)}
          // value is 0 unless there is a discount and it is not 0
          value={
            order?.total_order_discount > 0
              ? `-$${formatPrice(order.total_order_discount)}`
              : ''
          }
          button={
            !isCheckoutCart && (
              <button className={styles.billButton} onClick={updateItem}>
                <TimesIcon />
              </button>
            )
          }
          billBusy={billBusy}
        />
      )}
      <BillBlock
        title="Total Tax"
        value={`$${formatPrice(Math.abs(order?.fee))}`}
        billBusy={billBusy}
      />
      <div className={styles.couponContainer}>
        <InputLabelled
          value={couponCode}
          placeholder="Coupon Code"
          onChange={e => setCouponCode(e.target.value)}
        />
        <button
          onClick={() => {
            updateDiscountCode(couponCode);
          }}
        >
          APPLY
        </button>
      </div>
      <div
        className={styles.totalWrapper}
        style={billBusy ? { opacity: 0.5 } : {}}
      >
        <div className={styles.total}>
          <p className={styles.billText}>Total</p>
          <p className={styles.billTotal}>
            $ {formatPrice(Math.abs(order?.total_price || 0))}
          </p>
        </div>
        {!isCheckoutCart && (
          <button
            onClick={redirectToPayment}
            className={styles.billCheckout}
            disabled={billBusy || !orderItems.length}
          >
            <div className={styles.checkoutContainer}>
              CHECKOUT{' '}
              {billBusy && <RippleSpinner className={styles.spinner} />}
            </div>
          </button>
        )}
      </div>
    </div>
  );
};

Bill.propTypes = {
  order: PropTypes.object,
  orderItems: PropTypes.array,
  isCheckoutCart: PropTypes.bool,
  updateItem: PropTypes.func,
  updateDiscountCode: PropTypes.func,
  discountCode: PropTypes.string,
};

BillBlock.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  button: PropTypes.element,
};

export default Bill;

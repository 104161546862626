import React from 'react';
import PropTypes from 'prop-types';

const MastercardIcon = ({ fill, width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 17 11"
      fill="none"
      fillOpacity={0.5}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.34896 1.65677C8.28258 1.70747 8.21763 1.75969 8.15411 1.81344C7.06882 2.7301 6.38183 4.07969 6.38183 5.58594C6.38183 7.09219 7.06882 8.44163 8.15411 9.35844C8.21763 9.41219 8.28258 9.46427 8.34896 9.51497C8.41534 9.46427 8.48029 9.41219 8.54381 9.35844C9.62924 8.44163 10.3161 7.09219 10.3161 5.58594C10.3161 4.07969 9.62924 2.7301 8.54381 1.81344C8.48029 1.75969 8.41534 1.70747 8.34896 1.65677ZM11.532 0.585938C10.4776 0.585938 9.49733 0.893715 8.68087 1.42163C8.72264 1.45483 8.76499 1.48747 8.80533 1.52163C10.0188 2.54649 10.7145 4.02788 10.7145 5.58594C10.7145 7.14399 10.0188 8.62538 8.80548 9.6501C8.76528 9.68413 8.72264 9.71705 8.68087 9.75024C9.49719 10.278 10.4776 10.5859 11.532 10.5859C14.3764 10.5859 16.6823 8.34733 16.6823 5.58594C16.6823 2.82441 14.3764 0.585938 11.532 0.585938ZM7.89258 9.6501C7.93279 9.68413 7.97528 9.71691 8.01705 9.75024C7.20073 10.2782 6.22045 10.5859 5.16593 10.5859C2.32139 10.5859 0.015625 8.34733 0.015625 5.58594C0.015625 2.82455 2.32139 0.585938 5.16593 0.585938C6.22031 0.585938 7.20073 0.893715 8.01705 1.42163C7.97528 1.45483 7.93307 1.48747 7.89258 1.52163C6.67926 2.54649 5.9834 4.02788 5.9834 5.58594C5.9834 7.14413 6.67926 8.62538 7.89258 9.6501ZM16.5209 8.68427V8.4901H16.4688L16.4088 8.62372L16.3487 8.4901H16.2964V8.68427H16.3334V8.53788L16.3896 8.66399H16.4278L16.4841 8.5376V8.68427H16.5209ZM16.1904 8.68427V8.5233H16.2572V8.49052H16.087V8.5233H16.1538V8.68427H16.1904Z"
        fill={fill}
      />
    </svg>
  );
};

MastercardIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MastercardIcon.defaultProps = {
  fill: '#EDEAE3',
  width: '48',
  height: '30',
};

export default MastercardIcon;

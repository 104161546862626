import React, { memo } from 'react';
import styles from './SpinnerModal.scss';
import FullscreenModal from '../FullscreenModal';
import PropTypes from 'prop-types';
import RippleSpinner from '../../RippleSpinner';

const SpinnerModal = ({ isOpen }) => {
  return (
    <FullscreenModal id="SpinnerModal" isOpen={isOpen}>
      <div className={styles.container}>
        <RippleSpinner className={styles.spinner} />
      </div>
    </FullscreenModal>
  );
};

SpinnerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};
export default memo(SpinnerModal);

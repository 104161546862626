import axios from 'axios';
import config from 'config';

import { get } from 'lodash';

import { call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { tokenKey } from 'constants/localStorage';
import 'interceptors/axios';

const { baseUrl } = config;

axios.defaults.baseURL = baseUrl;

export function request({ ...options }) {
  return axios(options);
}

function hasInternalServerError(response) {
  if (!get(response, 'status', false)) {
    console.error(
      `hasInternalServerError: response object has no 'status' property`
    );
    return false;
  }

  switch (response.status) {
    case 500:
    case 502:
    case 503:
      return true;
    default:
      return false;
  }
}

export function* authenticatedRequest(options) {
  try {
    const token = localStorage.getItem(tokenKey);
    const response = yield call(request, {
      ...options,
      headers: {
        ...options.headers,
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    if (hasInternalServerError(err.response)) {
      yield call(
        [toast, 'error'],
        `The backend server returned internal ${err.response.status} error`
      );
    }
    throw err;
  }
}

export function* guestAuthenticatedRequest(options) {
  const token = 'DBBB2018-3206-46A9-AB05-71B5D666A077';
  const response = yield call(request, {
    ...options,
    headers: {
      ...options.headers,
      authorization: token,
    },
  });
  return response;
}

export default authenticatedRequest;

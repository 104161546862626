import React, { useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './PaymentAlternativeCard.scss';
import formatPrice from 'utils/formatPrice';
import useSelector from 'hooks/useSelector';
import {
  currentOrderSelector,
  currentOrderIdSelector,
  paidPendingSelector,
  orderTipsChangingSelector,
} from 'models/desks/selectors';
import CheckoutContext from '../Checkout/context';
import useAction from 'hooks/useAction';
import { desksActions } from 'models/desks/slice';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import strings from 'constants/strings';
import SpinnerModal from 'components/Modal/SpinnerModal';

const PaymentAlternativeCard = () => {
  const { handleCancelCheckout } = useContext(CheckoutContext);
  const order = useSelector(currentOrderSelector);
  const paidByCard = useAction(desksActions.paidByCard);
  const currentOrderId = useSelector(currentOrderIdSelector);
  const paidPending = useSelector(paidPendingSelector);
  const orderTipsChanging = useSelector(orderTipsChangingSelector);
  const loading = paidPending || orderTipsChanging;

  const paidByCardHandler = () => {
    paidByCard({
      orderId: order?.id,
      isPaidByAlternativeCard: true,
      orderData: order,
    });
  };

  if (!currentOrderId) {
    toast.error(strings.orderNotSelected, {
      progressClassName: 'customProgressBarError',
    });
    return <Redirect to="/dashboard/tasting" />;
  }

  return (
    <CSSTransition in appear timeout={200} classNames="fade" unmountOnExit>
      <div className={styles.root}>
        <SpinnerModal isOpen={loading} />
        <div className={styles.price}>
          ${formatPrice(order?.total_price + order?.tips_amount)}
        </div>
        <div className={styles.title}>{strings.continueCardPayment}</div>
        <div className={styles.actions}>
          <button className={styles.button} onClick={handleCancelCheckout}>
            Cancel
          </button>
          <button
            className={styles.button}
            onClick={paidByCardHandler}
            disabled={loading}
          >
            Proceed
          </button>
        </div>
      </div>
    </CSSTransition>
  );
};

export default PaymentAlternativeCard;

export const getOrderedProductCategories = categoriesObject => {
  const orderedCategories = {
    Wine: [],
    Food: [],
    Merchandise: [],
    Discounts: [],
    'Tasting fees': [],
  };
  Object.values(categoriesObject ?? {}).forEach(category => {
    const categoryKey = category?.parent_title ?? category.title;
    orderedCategories[categoryKey].push(category);
  });
  return [
    ...orderedCategories.Wine,
    ...orderedCategories['Tasting fees'],
    ...orderedCategories.Food,
    ...orderedCategories.Merchandise,
    ...orderedCategories.Discounts,
  ];
};

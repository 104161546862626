/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import actionTypes from 'utils/actionTypes';
import { getPreparedProductCategories } from '../../utils/getPreparedProductCategories';
import { getPreparedInventory } from '../../utils/getPreparedInventory';
import { desksActions } from '../desks/slice';
import { OrderItemsResourceTypes } from '../../constants/orderItems';

const initialState = {
  categories: null,
  inventory: null,
  productCounts: {},
  isPending: false,
  productCountsPending: false,
};

const productCategoriesSlice = createSlice({
  name: 'productCategories',
  initialState,
  reducers: {
    fetchProductCategoriesRequest: state => {
      state.isPending = true;
    },
    fetchProductCategoriesSuccess: (state, { payload }) => {
      state.isPending = false;
      state.categories = getPreparedProductCategories(payload);
    },
    fetchProductCategoriesFailure: state => {
      state.isPending = false;
    },
    fetchCategoryInventoriesRequest: state => {
      state.isPending = true;
    },
    fetchCategoryInventoriesSuccess: (state, { payload }) => {
      const { inventory, productCounts } = getPreparedInventory(
        payload?.inventories ?? []
      );
      state.inventory = {
        ...state.inventory,
        [payload.id]: {
          data: inventory,
          timestamp: Date.now(),
        },
      };
      state.productCounts = productCounts;
      state.isPending = false;
    },
    fetchCategoryInventoriesFailure: state => {
      state.isPending = false;
    },
    changeInventory: (state, { payload }) => {
      const { id } = payload;
      payload?.categories?.forEach(category => {
        const categoryId = category.id;
        if (state.inventory?.[categoryId]) {
          state.inventory[categoryId][id] = payload;
        }
      });
    },
    clearInventory: state => {
      if (state.inventory) {
        delete state.inventory;
      }
    },
    fetchProductCountRequest: state => {
      state.productCountsPending = true;
    },
    fetchProductCountSuccess: (state, { payload }) => {
      const { product_id, count } = payload;
      state.productCounts[product_id] = count;
      state.productCountsPending = false;
    },
    fetchProductCountFailure: state => {
      state.productCountsPending = false;
    },
  },
  extraReducers: {
    [desksActions.createOrderItemSuccess](state, { payload }) {
      const { product } = payload.orderItem;
      if (
        payload.orderItem.resourcetype ===
        OrderItemsResourceTypes.PRODUCT_ORDER_ITEM
      ) {
        state.productCounts[product.id] -= 1;
      }
    },
    [desksActions.updateOrderItemSuccess](state, { payload }) {
      const { product } = payload.orderItem;
      if (
        payload.orderItem.resourcetype ===
        OrderItemsResourceTypes.PRODUCT_ORDER_ITEM
      ) {
        state.productCounts[product.id] -= payload.addedNumber;
      }
    },
  },
});

export const productCategoriesActions = actionTypes(
  productCategoriesSlice.actions
);

export default productCategoriesSlice.reducer;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './OrderRefundList.scss';
import formatPrice from 'utils/formatPrice';
import clsx from 'clsx';
import OrderProducts from '../OrderProducts';
import OrderItem from '../OrderItem';

const OrderRefundList = ({ order, productList }) => {
  return (
    <div className={clsx(styles.blockContainer, styles.blockContainerRefunds)}>
      <div className={clsx(styles.block, styles.blockProductList)}>
        <div className={styles.orderItemsWrapper}>
          <h3 className={styles.orderItemsTitle}>Refund List</h3>
          <OrderProducts
            items={productList}
            tips={order?.is_tips_refunded ? order.tips_amount : 0}
          />
        </div>
      </div>
      <div className={clsx(styles.block, styles.blockTotal)}>
        <OrderItem
          title="Refund Amount"
          value={`$${formatPrice(order?.refund_amount)}`}
        />
      </div>
    </div>
  );
};

OrderRefundList.propTypes = {
  order: PropTypes.object,
  productList: PropTypes.array,
};

export default OrderRefundList;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Badge from 'components/Badge';
import formatCurrentDate from 'utils/formatCurrentDate';
import { orderStatusHumanName } from 'constants/orderStatus';
import { ORDER_TYPES } from 'constants/orderTypes';

import InfoItem from '../InfoItem';

import styles from './OrderDetails.scss';

const filteredComments = comments =>
  comments.filter(comment => !!comment.trim());

const OrderDetails = ({ order }) => {
  const formattedDate = formatCurrentDate(new Date(order?.created_at));
  const formattedPaidDate = formatCurrentDate(new Date(order?.paid_at));

  return (
    <div className={styles.blockContainer}>
      <h2 className={styles.orderId}>
        Order Details (Invoice #{order?.invoice_number} -{' '}
        {ORDER_TYPES[order?.resourcetype]})
      </h2>
      <InfoItem title="Customer name" value={order?.customer_name} />
      <InfoItem
        title="Creation date"
        value={`${formattedDate.monthAlias} ${formattedDate.day} ${formattedDate.year}`}
      />
      {order.paid_at && (
        <InfoItem
          title="Paid date"
          value={`${formattedPaidDate.monthAlias} ${formattedPaidDate.day} ${formattedPaidDate.year}`}
        />
      )}
      <InfoItem
        title="Status"
        value={
          <Badge className={clsx({ [styles.badgeDraft]: true })}>
            {orderStatusHumanName[order?.status]}
          </Badge>
        }
      />
      {filteredComments(order?.refund_comments ?? []).length > 0 && (
        <InfoItem
          title="Refund comment"
          value={
            <div className={styles.commentWrapper}>
              {order.refund_comments.map(comment => (
                <p key={comment} className={styles.comment}>
                  {comment}
                </p>
              ))}
            </div>
          }
        />
      )}
    </div>
  );
};

OrderDetails.propTypes = {
  order: PropTypes.object,
};

InfoItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default OrderDetails;

import React, { memo, useEffect } from 'react';
import styles from './ProductSubCategories.scss';
import PropTypes from 'prop-types';
import ProductCard from 'components/ProductCard';
import { productCategoriesActions } from 'models/productCategories/slice';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import {
  productCategoriesPendingSelector,
  productInventorySelector,
} from 'models/productCategories/selectors';
import ProductSkeletonCard from 'components/ProductSkeletonCard';
import ProductImageCard from 'components/ProductImageCard';
import { isCacheValid } from 'models/helpers';

const ProductSubCategories = ({
  categoryId,
  categories,
  onCategoryClick,
  onProductClick,
}) => {
  // Selectors
  const inventory = useSelector(productInventorySelector);
  const fetching = useSelector(productCategoriesPendingSelector);

  // Actions
  const fetchCategoryInventories = useAction(
    productCategoriesActions.fetchCategoryInventoriesRequest
  );

  // Effects
  useEffect(() => {
    if (!isCacheValid(inventory, categoryId)) {
      fetchCategoryInventories({ id: categoryId });
    }
  }, [fetchCategoryInventories, categoryId]);

  return (
    <div className={styles.container}>
      {fetching ? (
        <>
          <ProductSkeletonCard className={styles.card} />
          <ProductSkeletonCard className={styles.card} />
          <ProductSkeletonCard className={styles.card} />
          <ProductSkeletonCard className={styles.card} />
        </>
      ) : (
        <>
          {inventory?.[categoryId] &&
            Object.values(inventory[categoryId].data)?.map(item => (
              <ProductImageCard
                className={styles.card}
                key={item.id.toString()}
                image={item.main_image_url}
                {...item}
                onClick={() => onProductClick && onProductClick(item)}
              />
            ))}
          {categories.map(category => (
            <ProductCard
              className={styles.card}
              key={category.id.toString()}
              onClick={() => onCategoryClick?.(category)}
              {...category}
            />
          ))}
        </>
      )}
    </div>
  );
};

ProductSubCategories.propTypes = {
  categoryId: PropTypes.number.isRequired,
  categories: PropTypes.array.isRequired,
  onCategoryClick: PropTypes.func,
  onProductClick: PropTypes.func,
};
export default memo(ProductSubCategories);

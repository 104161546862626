import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { getTimeSince } from 'utils/formatCurrentDate';
import styles from './CustomerInfo.scss';
import clsx from 'clsx';

const CustomerInfo = ({ customer }) => {
  return (
    <div className={styles.block}>
      <div className={styles.info}>
        <h4 className={styles.customerName}>
          {customer.first_name} {customer.last_name}
        </h4>
        <p className={styles.customerTime}>
          Customer for {getTimeSince(customer.created_at)}
        </p>
      </div>
      <div className={clsx(styles.headerBlock, styles.headerBlockRight)}>
        <p>Customer #</p>
        <p>{customer.id}</p>
      </div>
    </div>
  );
};

CustomerInfo.propTypes = {
  customer: PropTypes.object,
};

export default memo(CustomerInfo);

import React from 'react';
import CalendarIcon from 'components/Icons/CalendarIcon';
import CircleIcon from 'components/Icons/CircleIcon';
import { formatCurrentDate } from 'utils/formatCurrentDate';
import styles from './CalendarDate.scss';

const date = new Date();

const CalendarDate = () => {
  const formattedDate = formatCurrentDate(date);

  return (
    <div className={styles.root}>
      <div className={styles.calendar}>
        <div className={styles.calendarIcon}>
          <CalendarIcon width={22} height={22} />
          <div className={styles.calendarCircle}>
            <CircleIcon width="4" height="4" fill="#1CBB8C" />
          </div>
        </div>
      </div>
      <div className={styles.date}>
        {formattedDate.dayAlias} {formattedDate.monthAlias}. {formattedDate.day}
      </div>
    </div>
  );
};

export default CalendarDate;

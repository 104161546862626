import { createSelector } from 'reselect';

const selectSelf = state => state.timeEntry;

export const timeEntriesSelector = createSelector(
  selectSelf,
  ({ timeEntry }) => timeEntry
);

export const timeEntriesLoadingSelector = createSelector(
  timeEntriesSelector,
  ({ timeEntriesLoading }) => timeEntriesLoading
);

import React from 'react';
import PropTypes from 'prop-types';

const PlusIcon = ({ fill, width, height, className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 11 10"
    >
      <title> Group 29</title>
      <g style={{ fill: 'none', opacity: 0.9 }}>
        <g stroke={fill}>
          <g transform="translate(-1142 -356)translate(1142 356)translate(5.5 5)rotate(-270)translate(-5.5 -5)translate(1 0)">
            <polyline points="4.5 0.2 4.5 2 4.5 9.2" />
            <line x1="9" y1="4.7" y2="4.7" />
          </g>
        </g>
      </g>
    </svg>
  );
};

PlusIcon.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PlusIcon.defaultProps = {
  fill: '#EDEAE3',
  width: '11',
  height: '10',
};

export default PlusIcon;

import React, { useMemo, useCallback } from 'react';
import clsx from 'clsx';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { getTimeSince } from 'utils/formatCurrentDate';
import formatPrice from 'utils/formatPrice';

import { paginatedCustomersActions } from 'models/paginatedCustomers/slice';
import {
  paginatedCustomersEntitiesSelector,
  paginatedCustomersFiltersSelector,
} from 'models/paginatedCustomers/selectors';

import Pagination from 'components/Pagination';

import CustomersTableHeadCol from './CustomersTableHeadCol';
import ArrowRightIcon from 'components/Icons/ArrowRightIcon';
import { customersTableHeadCols } from './CustomersTableHeadCol/constants';
import styles from './CustomersTable.scss';
import LinkedCell from 'components/LinkedCell/LinkedCell';

const CustomersTable = () => {
  const changeSorting = useAction(paginatedCustomersActions.changeSorting);
  const changePagination = useAction(
    paginatedCustomersActions.changePagination
  );
  const onPageChange = useCallback(page => changePagination({ page }), [
    changePagination,
  ]);
  const onSortingChange = useCallback(
    sortingField => changeSorting({ sortingField }),
    [changeSorting]
  );

  const customers = useSelector(paginatedCustomersEntitiesSelector);
  const { pagination, sortingField, sortingCustomer } = useSelector(
    paginatedCustomersFiltersSelector
  );

  const headerCols = useMemo(
    () => (
      <>
        {customersTableHeadCols.map(col => (
          <CustomersTableHeadCol
            key={col.id}
            onSort={onSortingChange}
            activeSortingOrder={sortingCustomer}
            activeSorting={col.sortable && sortingField === col.sortingField}
            {...col}
          />
        ))}
      </>
    ),
    [sortingCustomer, sortingField, onSortingChange]
  );

  const getCustomerStatus = customer => {
    if (customer?.wine_club_member?.active_obligation) {
      return 'Wine Club Member';
    }
    return '-';
  };

  return (
    <div className={styles.root}>
      <table className={styles.table}>
        <thead>
          <tr>{headerCols}</tr>
        </thead>
        <tbody>
          {customers.map(customer => {
            return (
              <tr key={customer.id} className={styles.bodyRow}>
                <LinkedCell
                  to={`/dashboard/customers/${customer.id}`}
                  className={styles.translucentBodyCell}
                >
                  {customer.id}
                </LinkedCell>
                <LinkedCell to={`/dashboard/customers/${customer.id}`}>
                  {customer.first_name} {customer.last_name}
                </LinkedCell>
                <LinkedCell
                  to={`/dashboard/customers/${customer.id}`}
                  className={clsx({
                    [styles.translucentBodyCell]: !customer.wine_club_member,
                  })}
                >
                  {getCustomerStatus(customer)}
                </LinkedCell>
                <LinkedCell to={`/dashboard/customers/${customer.id}`}>
                  ${formatPrice(customer.total_sum_of_paid_orders)}
                </LinkedCell>
                <LinkedCell
                  to={`/dashboard/customers/${customer.id}`}
                  className={styles.translucentBodyCell}
                >
                  {getTimeSince(customer.last_order_at, true)}
                </LinkedCell>
                <LinkedCell to={`/dashboard/customers/${customer.id}`}>
                  <div className={styles.bodyCellRow}>
                    <div>
                      <button className={styles.btnInfo}>
                        <ArrowRightIcon width={23} height={24} />
                      </button>
                    </div>
                  </div>
                </LinkedCell>
              </tr>
            );
          })}
        </tbody>
      </table>

      {pagination && (
        <Pagination pagination={pagination} setPage={onPageChange} />
      )}
    </div>
  );
};

export default CustomersTable;

import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import getPagingRange from 'utils/getPagingRange';

import ChevronLeftIcon from 'components/Icons/ChevronLeftIcon';
import ChevronRightIcon from 'components/Icons/ChevronRightIcon';

import styles from './Pagination.scss';

const Pagination = ({ pagination, setPage }) => {
  const entriesFrom = Math.max(
    pagination.per_page * (pagination.current_page - 1),
    1
  );
  const pagesRange = getPagingRange(pagination.current_page, {
    total: pagination.total_pages,
  });

  const prev = () =>
    pagination.current_page !== 1
      ? setPage(Math.max(pagination.current_page - 1), 1)
      : null;
  const next = () =>
    pagination.current_page !== pagination.total_pages
      ? setPage(Math.min(pagination.current_page + 1), pagination.total_pages)
      : null;

  const showingEntries =
    // eslint-disable-next-line no-restricted-globals
    isNaN(entriesFrom) || isNaN(pagination.total_count)
      ? 'Showing 0 of 0 entries' // Default value when the numbers are NaN
      : `Showing ${entriesFrom} of ${pagination.total_count} entries`;

  return (
    <div className={styles.root}>
      <div className={styles.entries}>{showingEntries}</div>

      <div className={styles.pages}>
        <div className={styles.control}>
          <button
            onClick={prev}
            className={styles.action}
            disabled={pagination.current_page === 1}
          >
            <ChevronLeftIcon />
          </button>
        </div>

        {pagesRange.map((page, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={clsx(styles.page, {
              [styles.pageActive]: page === pagination.current_page,
            })}
          >
            <button
              onClick={() => setPage(page)}
              disabled={page === pagination.current_page}
            >
              {String(page)}
            </button>
          </div>
        ))}

        <div className={clsx(styles.control, styles.controlRight)}>
          <button
            onClick={next}
            className={styles.action}
            disabled={pagination.current_page === pagination.total_pages}
          >
            <ChevronRightIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  pagination: PropTypes.object.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default Pagination;

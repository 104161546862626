import { takeLatest, all, put } from 'redux-saga/effects';
import { tiersUrl } from 'api/urls';
import { authenticatedRequest } from 'api';
import { tiersActions } from './slice';
import { desksActions } from 'models/desks/slice';
import { toast } from 'react-toastify';

function* fetchTiersRequestSaga() {
  try {
    const { data } = yield authenticatedRequest({ url: tiersUrl });
    yield put({
      type: tiersActions.fetchTiersSuccess,
      payload: data,
    });
  } catch (err) {
    yield put({ type: tiersActions.fetchTiersFailure });
  }
}

function* confirmTierSaga(action) {
  try {
    const { payload } = action;
    const { data } = yield authenticatedRequest({
      url: '/tasting-room-master-api/v1/obligations',
      data: payload,
      method: 'POST',
    });
    toast.success('Subscription successfully activated!', {
      progressClassName: 'customProgressBarSuccess',
    });
    yield put({
      type: tiersActions.confirmTierSuccess,
      payload: data,
    });
    if (payload?.isOrderUpdating) {
      yield put({
        type: desksActions.fetchOrderRequest,
        payload: { id: payload?.orderId },
      });
    }
  } catch (error) {
    yield put({ type: tiersActions.confirmTierFailure });
    toast.error(error?.message, {
      progressClassName: 'customProgressBarError',
    });
  }
}

function* cancelTierSaga(action) {
  try {
    const { payload } = action;
    const { data } = yield authenticatedRequest({
      url: `/tasting-room-master-api/v1/obligations/${payload?.id}/cancel`,
      method: 'POST',
    });
    toast.success('Subscription canceled successfully!', {
      progressClassName: 'customProgressBarSuccess',
    });
    yield put({
      type: tiersActions.cancelTierSuccess,
      payload: data,
    });
  } catch (error) {
    yield put({ type: tiersActions.cancelTierFailure });
    toast.error(error?.message, {
      progressClassName: 'customProgressBarError',
    });
  }
}

export default function*() {
  yield all([
    takeLatest(tiersActions.fetchTiersRequest, fetchTiersRequestSaga),
    takeLatest(tiersActions.confirmTier, confirmTierSaga),
    takeLatest(tiersActions.cancelTier, cancelTierSaga),
  ]);
}

import React from 'react';
import PropTypes from 'prop-types';
import useSelector from 'hooks/useSelector';
import useAction from 'hooks/useAction';
import { guestEmailModalOpenSelector } from 'models/interface/selectors';
import { interfaceActions } from 'models/interface/slice';
import { tiersActions } from 'models/tiers/slice';
import {
  currentOrderSelector,
  currentOrderIdSelector,
} from 'models/desks/selectors';
import { toast } from 'react-toastify';
import strings from 'constants/strings';
import GuestEmailModal from 'components/Modal/GuestEmailModal';
import styles from './BlockAddEmail.scss';

const BlockAddEmail = ({ selectedTierId, nextAction }) => {
  const guest = useSelector(currentOrderSelector);
  const guestId = useSelector(currentOrderIdSelector);
  const modalOpen = useSelector(guestEmailModalOpenSelector);
  const setModalOpen = useAction(interfaceActions.changeGuestEmailModalOpen);
  const changeSelectedTierId = useAction(tiersActions.changeSelectedTierId);

  const closeModal = () => setModalOpen(false);

  const openModal = () => {
    if (!guestId) {
      toast.error(strings.orderNotSelected, {
        progressClassName: 'customProgressBarError',
      });
      return;
    }
    if (selectedTierId) {
      changeSelectedTierId({ id: selectedTierId });
    }
    setModalOpen(true);
  };

  const isVerified = guest && guest.customer?.id;

  return (
    <>
      <div className={styles.root}>
        {isVerified ? (
          <div className={styles.inner}>
            <div className={styles.caption}>{guest.customer.email}</div>
            {nextAction && (
              <div className={styles.apply} onClick={nextAction}>
                CONFIRM EMAIL
              </div>
            )}
          </div>
        ) : (
          <div className={styles.inner} onClick={openModal}>
            <div className={styles.caption}>Email Address</div>
            <div className={styles.apply}>APPLY</div>
          </div>
        )}
      </div>
      <GuestEmailModal isOpen={modalOpen} close={closeModal} />
    </>
  );
};

BlockAddEmail.propTypes = {
  selectedTierId: PropTypes.number,
  nextAction: PropTypes.func,
};

export default BlockAddEmail;

import { takeLatest, all, put } from 'redux-saga/effects';
import { authenticatedRequest } from 'api';
import { roomsUrl } from 'api/urls';
import { roomsActions } from './slice';
import { desksActions } from '../desks/slice';

function* fetchRoomsRequestSaga() {
  try {
    const { data } = yield authenticatedRequest({ url: roomsUrl });
    yield put({
      type: roomsActions.fetchRoomsSuccess,
      payload: data,
    });
    yield put({
      type: roomsActions.selectRoom,
      payload: { id: data.results[0].id },
    });
  } catch (err) {
    yield put({ type: roomsActions.fetchRoomsFailure });
  }
}

function* selectRoomSaga() {
  try {
    yield put({ type: desksActions.fetchDesksRequest });
  } catch (err) {
    console.error(err);
  }
}

export default function*() {
  yield all([
    takeLatest(roomsActions.selectRoom, selectRoomSaga),
    takeLatest(roomsActions.fetchRoomsRequest, fetchRoomsRequestSaga),
  ]);
}

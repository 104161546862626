import React, { useRef, useState, useEffect } from 'react';
import styles from './CustomerForm.scss';
import { useForm } from 'react-hook-form';
import TextField from 'components/TextField';
import SelectLabelled from 'components/SelectLabelled';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import AddressField from 'components/AddressField';
import PhoneField from 'components/PhoneField';
import useSelector from 'hooks/useSelector';
import useAction from 'hooks/useAction';
import { tiersSelector } from 'models/tiers/selectors';
import { paginatedCustomersActions } from 'models/paginatedCustomers/slice';
import { detailedCustomerSelector } from 'models/paginatedCustomers/selectors';

const prepareAddress = value => {
  const countryValue = value?.country ? `${value?.country}` : '';
  const stateValue = value?.state ? `, ${value?.state}` : '';
  const cityValue = value?.city ? `, ${value?.city}` : '';
  const postalCodeValue = value?.postalCode ? `, ${value?.postalCode}` : '';
  const line1Value = value?.line1 ? `, ${value?.line1}` : '';
  return `${countryValue}${stateValue}${cityValue}${postalCodeValue}${line1Value}`;
};

const CustomerForm = ({
  isLoading,
  isEdit,
  title,
  onSubmit,
  defaultValues,
  customerID,
}) => {
  const fetchCustomer = useAction(
    paginatedCustomersActions.fetchDetailedCustomerRequest
  );
  useEffect(() => {
    if (customerID) fetchCustomer({ id: customerID });
  }, [customerID]);

  const customer = useSelector(detailedCustomerSelector);

  const { control, handleSubmit, setValue, reset } = useForm({
    mode: 'onTouched',
    defaultValues,
  });

  useEffect(() => {
    // Check if customer data is available and has the necessary fields
    if (customer && customerID) {
      const newDefaultValues = {
        first_name: customer.first_name,
        last_name: customer.last_name,
        phone: customer.phone,
        email: customer.email,
        line2: customer.customer_profile?.line2,
        address: prepareAddress(customer.customer_profile),
        customer_id: customer.id,
      };

      reset(newDefaultValues); // Reset the form with the new default values
    }
  }, [customer, reset, customerID]);

  const submitRef = useRef(null);

  const [address, setAddress] = useState();

  const tiers = useSelector(tiersSelector);
  const [tier, setTier] = useState({});

  const submitHandler = data => {
    onSubmit({
      ...data,
      ...address,
      postal_code: address?.postalCode,
      tier_id: tier?.value,
    });
  };

  const handleOnFocus = e => {
    if (e.target.name === 'address') {
      window.scroll(window.scrollX, 180);
    }
  };

  return (
    <>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(submitHandler)}
        className={clsx(styles.container)}
      >
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.wrapper}>
          <TextField
            className={styles.field}
            placeholder="First Name"
            name="first_name"
            control={control}
          />
          <TextField
            className={styles.field}
            placeholder="Last Name"
            name="last_name"
            control={control}
          />
          <PhoneField
            className={styles.field}
            placeholder="Phone"
            name="phone"
            type="number"
            control={control}
            rules={{
              validate: {
                checkLength: v => {
                  return (
                    !v ||
                    v?.replace(/-|_/g, '')?.length === 11 ||
                    'Phone number should be 11 digit number'
                  );
                },
              },
            }}
            setValue={setValue}
            isPrefix
            prefixVal="1"
          />
          <AddressField
            className={styles.longField}
            onSelect={value => setAddress(value)}
            inputValue={prepareAddress(defaultValues)}
            name="address"
            onFocus={handleOnFocus}
            model={customer?.customer_profile || {}}
          />
          <TextField
            className={styles.field}
            placeholder="Unit (or other comment)"
            name="line2"
            control={control}
          />
          <TextField
            className={styles.longField}
            placeholder="Email*"
            name="email"
            control={control}
            disabled={customer && customerID}
            rules={{
              required: { value: true, message: 'Enter email' },
              pattern: {
                value: /^([A-Za-z0-9_+\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,7})$/,
                message: 'Incorrect email value',
              },
            }}
          />
          {false && !isEdit && (
            <SelectLabelled
              name="tier"
              rootClassName={clsx(styles.longField, styles.selectContainer)}
              placeholderClassName={styles.placeholder}
              items={tiers?.map(t => ({
                capacity: t.capacity,
                value: t.id,
                label: `${t.title} (${t.capacity} bottles)`,
              }))}
              value={tier}
              placeholder="Tier"
              onChange={setTier}
            />
          )}
        </div>
        <button
          ref={submitRef}
          className={styles.button}
          type="submit"
          disabled={isLoading}
        >
          {isEdit ? 'Save' : 'Send Email'}
        </button>
      </form>
    </>
  );
};

CustomerForm.propTypes = {
  isLoading: PropTypes.bool,
  isEdit: PropTypes.bool,
  title: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
  customerID: PropTypes.number,
};
export default CustomerForm;

import React, { useMemo } from 'react';
import clsx from 'clsx';
import formatCurrentDate from 'utils/formatCurrentDate';
import formatPrice from 'utils/formatPrice';

import Pagination from 'components/Pagination';

import OrdersTableHeadCol from './OrdersTableHeadCol';
import { ordersTableHeadCols } from './OrdersTableHeadCol/constants';
import styles from './OrdersTable.scss';
import OrdersMenuButton from './OrdersMenuButton';
import { orderStatusHumanName } from 'constants/orderStatus';
import LinkedCell from '../../../components/LinkedCell/LinkedCell';
import PropTypes from 'prop-types';

const getOrderPath = id => `/dashboard/orders/${id}/`;

const getCustomerFullName = customer =>
  `${customer?.first_name ?? ''} ${customer?.last_name ?? ''}`;

const OrdersTable = ({
  orders,
  pagination,
  loading,
  sortingOrder,
  sortingField,
  onSortingChange,
  onPageChange,
}) => {
  const headerCols = useMemo(
    () => (
      <>
        {ordersTableHeadCols.map(col => (
          <OrdersTableHeadCol
            key={col.id}
            onSort={onSortingChange}
            activeSortingOrder={sortingOrder}
            activeSorting={col.sortable && sortingField === col.sortingField}
            {...col}
          />
        ))}
      </>
    ),
    [sortingOrder, sortingField, onSortingChange]
  );

  return (
    <div className={styles.root}>
      <table className={clsx(styles.table, loading && styles.loadingTable)}>
        <thead>
          <tr>{headerCols}</tr>
        </thead>
        <tbody>
          {orders.map(order => {
            const formattedPaidDate =
              order.created_at && order.paid_at
                ? formatCurrentDate(new Date(order.paid_at))
                : formatCurrentDate(new Date(order.created_at));

            return (
              <tr key={order.id} className={styles.bodyRow}>
                <LinkedCell
                  className={clsx(styles.bodyCell, styles.idCell)}
                  to={getOrderPath(order.id)}
                >
                  <p>#{order.invoice_number}</p>
                </LinkedCell>
                <LinkedCell
                  className={clsx(styles.bodyCell, styles.dateCell)}
                  to={getOrderPath(order.id)}
                >
                  {formattedPaidDate
                    ? `${formattedPaidDate.monthAlias} ${formattedPaidDate.day} ${formattedPaidDate.year}, ${formattedPaidDate.time}`
                    : 'Not Paid'}
                </LinkedCell>
                <LinkedCell
                  className={clsx(styles.bodyCell, styles.customerCell)}
                  to={getOrderPath(order.id)}
                >
                  {getCustomerFullName(order.customer).trim() ||
                    order?.customer_name}
                </LinkedCell>
                <LinkedCell
                  className={clsx(styles.bodyCell, styles.totalCell)}
                  to={getOrderPath(order.id)}
                >
                  ${formatPrice(order.total_price)}
                </LinkedCell>
                <LinkedCell
                  className={clsx(styles.bodyCell, styles.statusCell)}
                  to={getOrderPath(order.id)}
                >
                  {orderStatusHumanName[order.status]}
                </LinkedCell>
                <td className={styles.actionCell}>
                  <OrdersMenuButton id={order.id} status={order?.status} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {pagination && (
        <Pagination pagination={pagination} setPage={onPageChange} />
      )}
    </div>
  );
};

OrdersTable.propTypes = {
  orders: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  sortingOrder: PropTypes.string.isRequired,
  sortingField: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onSortingChange: PropTypes.func,
  onPageChange: PropTypes.func,
};
export default OrdersTable;

import { createSelector } from 'reselect';

export const roomsSelector = createSelector(
  state => state,
  state => state.rooms
);

export const roomsEntitiesSelector = createSelector(
  roomsSelector,
  ({ rooms }) => rooms
);

export const roomsLoadingSelector = createSelector(
  roomsSelector,
  ({ roomsLoading }) => roomsLoading
);

export const selectedRoomIdSelector = createSelector(
  roomsSelector,
  ({ selectedRoomId }) => selectedRoomId
);

export const selectedRoomSelector = createSelector(
  [roomsEntitiesSelector, selectedRoomIdSelector],
  (rooms, id) => rooms.find(r => r.id === id)
);

import React from 'react';
import PropTypes from 'prop-types';
import useSelector from 'hooks/useSelector';
import { PinAction } from 'constants/PinActions';
import { useHistory } from 'react-router-dom';
import { currentOrderIdSelector } from 'models/desks/selectors';
import styles from './ProductDiscount.scss';

const ProductDiscount = ({ discounts }) => {
  const history = useHistory();
  const orderId = useSelector(currentOrderIdSelector);

  const handleOnClick = React.useCallback(
    discount_id => {
      history.push('/pin', {
        pinAction: PinAction.DISCOUNT,
        discount_id,
        orderId,
      });
    },
    [history, orderId]
  );

  return (
    <div className={styles.root}>
      {discounts.map((discount, index) => (
        <div
          key={`${index + discount.title}`}
          className={styles.container}
          onClick={() => handleOnClick(discount.id)}
        >
          <div className={styles.info}>
            <h2 className={styles.title}>
              {discount.title || discount.coupon || ''}
            </h2>
            <div className={styles.description}>
              {discount.description || ''}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

ProductDiscount.propTypes = {
  discounts: PropTypes.array,
};

export default ProductDiscount;

import { request } from './index';

export const print = ({ mac, receipt }) =>
  request({
    url: 'https://printer.api.getcellar.co/print.php',
    method: 'GET',
    params: {
      mac,
      receipt,
    },
  });

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import CopyIcon from 'components/Icons/CopyIcon';
import styles from './CustomerOverview.scss';
import { copy } from '../../../utils/copy';

const CustomerOverview = ({ customer, setIsOpen }) => {
  const getCustomerStatus = aCustomer => {
    if (aCustomer?.wine_club_member?.active_obligation) {
      return 'Wine Club Member (Active)';
    }
    if (aCustomer?.wine_club_member) {
      return 'Wine Club Member (Inactive)';
    }
    return 'No Wine Club Membership';
  };

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <h4 className={styles.headerTitle}>Customer Overview</h4>
        <button onClick={() => setIsOpen(true)} className={styles.headerButton}>
          Manage
        </button>
      </div>
      <p className={styles.wineclub}>{getCustomerStatus(customer)}</p>
      <div className={styles.info}>
        <div className={styles.infoEmail}>
          <p>{customer.email}</p>
          <button
            className={styles.infoButton}
            onClick={() => copy(customer.email)}
          >
            <CopyIcon />
          </button>
        </div>
        <p className={styles.infoName}>
          {customer.first_name} {customer.last_name}
        </p>
        <p className={styles.infoAddress}>{customer.address}</p>
        <p className={styles.infoPhone}>{customer.phone}</p>
      </div>
    </div>
  );
};

CustomerOverview.propTypes = {
  customer: PropTypes.object,
  setIsOpen: PropTypes.func,
};

export default memo(CustomerOverview);

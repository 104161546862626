import React from 'react';
import styles from './ProductCategories.scss';
import PropTypes from 'prop-types';
import InventoryCard from '../InventoryCard';

const ProductCategories = ({ categories, onCategoryClick }) => {
  const onClickHandler = category => {
    if (onCategoryClick) {
      if (category?.title === 'Discounts') {
        onCategoryClick({ type: 'discounts', title: 'Discounts' });
      } else {
        onCategoryClick(category);
      }
    }
  };

  return (
    <>
      <div className={styles.container}>
        {categories.map(category => (
          <InventoryCard
            key={category.id.toString()}
            {...category}
            count={category.count || null}
            onClick={() => onClickHandler(category)}
          />
        ))}
      </div>
    </>
  );
};

ProductCategories.propTypes = {
  categories: PropTypes.array.isRequired,
  onCategoryClick: PropTypes.func,
};
export default ProductCategories;

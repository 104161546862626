import { INVENTORY_TYPES } from '../constants/inventory';

const transformTitle = title =>
  title
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');

const prepareInventoryItem = item => {
  return {
    ...item,
    alias_title: transformTitle(item.title),
    color: item.colour,
    products: item.products.reduce((result, current) => {
      const id = current.product ? current.product.id : current.id;
      return {
        ...result,
        [id]: {
          product_count: current?.product_count ?? 100,
          ...current,
          type: item.type,
        },
      };
    }, {}),
  };
};

export const getOrderedInventory = sourceInventory => {
  const inventoryByTypes = {
    [INVENTORY_TYPES.WINE]: [],
    [INVENTORY_TYPES.TASTING_FEES]: [],
    [INVENTORY_TYPES.FOOD]: [],
    [INVENTORY_TYPES.MERCHANDISE]: [],
    [INVENTORY_TYPES.EXTRA]: [],
  };

  sourceInventory.forEach(item => {
    if (item?.type in inventoryByTypes) {
      const type =
        item?.title === 'Tasting fees'
          ? INVENTORY_TYPES.TASTING_FEES
          : item.type;

      inventoryByTypes[type].push(prepareInventoryItem(item));
    }
  });

  const inventoryObject = {
    ...inventoryByTypes,
    [INVENTORY_TYPES.FOOD]: {
      title: 'Food',
      alias_title: 'Food',
      color: '',
      products: inventoryByTypes[INVENTORY_TYPES.FOOD],
      type: 'FoodWrapper',
    },
    [INVENTORY_TYPES.MERCHANDISE]: {
      title: 'Merchandise',
      alias_title: 'Merchandise',
      color: '#404040',
      products: inventoryByTypes[INVENTORY_TYPES.MERCHANDISE],
      type: 'MerchandiseWrapper',
    },
  };

  return [].concat(...Object.values(inventoryObject));
};

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { prepareAddress } from 'utils/prepareAddress';
import { SHIPPING_STATUSES } from 'constants/shippingStatuses';

import InfoItem from '../InfoItem';
import Badge from 'components/Badge';
import BrowserLink from 'components/BrowserLink';

import styles from './OrderShippingInformation.scss';

const OrderShippingInformation = ({ data }) => {
  const orderShipment = [...(data.shipments ?? [])].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  )[0];

  return (
    <div className={styles.blockContainer}>
      <h2 className={styles.orderId}>Shipping Information</h2>
      <InfoItem title="Address" value={prepareAddress(data)} />
      <InfoItem title="Phone" value={data.phone} />
      {orderShipment && (
        <InfoItem
          title="Shipment"
          value={
            !orderShipment.parcels?.length ? (
              <div className="mt-2">
                <InfoItem
                  title="Parcel"
                  value={
                    <div className={styles.blockContent}>
                      <div className={styles.blockContentItem}>
                        Status:{' '}
                        <Badge className={clsx({ [styles.badgeDraft]: true })}>
                          {SHIPPING_STATUSES[orderShipment.status]}
                        </Badge>{' '}
                      </div>
                      <div className={styles.blockContentItem}>
                        {' '}
                        Track number:{' '}
                        <BrowserLink to={orderShipment.public_url}>
                          {orderShipment.tracking_number}
                        </BrowserLink>
                      </div>
                    </div>
                  }
                />
              </div>
            ) : (
              <div className={styles.blockInner}>
                <div className="mt-2">
                  <p className={styles.blockTitle}>Shipment status</p>
                  <p className={styles.blockContent}>
                    {SHIPPING_STATUSES[orderShipment.status]}
                  </p>
                </div>
                {orderShipment.parcels.map((item, index) => (
                  <div className="mt-2">
                    <p className={styles.blockTitle}>Parcel #{index + 1}</p>
                    <div className={styles.blockContent}>
                      <div className={styles.blockContentItem}>
                        Status:
                        <Badge className={clsx({ [styles.badgeDraft]: true })}>
                          {SHIPPING_STATUSES[item.status]}
                        </Badge>
                      </div>
                      <div className={styles.blockContentItem}>
                        {' '}
                        Track number:{' '}
                        <BrowserLink
                          className={styles.blockContentLink}
                          to={item.public_url}
                        >
                          {item.tracking_number}
                        </BrowserLink>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )
          }
        />
      )}
    </div>
  );
};

OrderShippingInformation.propTypes = {
  data: PropTypes.object,
};

export default OrderShippingInformation;

/* eslint-disable prefer-destructuring */
import { takeLatest, all, put, call, select, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { login } from 'api/login';
import { logoutHost } from 'api/logoutHost';
import { print } from 'api/print';
import { authenticatedRequest } from 'api';
import { resetState } from 'models/helpers';
import { roleSelector } from 'models/session/selectors';
import { tiersActions } from 'models/tiers/slice';
import {
  tokenKey,
  refreshKey,
  roleKey,
  brandKey,
  warehouseKey,
} from 'constants/localStorage';
import strings from 'constants/strings';
import { toast } from 'react-toastify';
import { sessionActions } from './slice';
import { desksActions } from '../desks/slice';
import { commonActions } from '../common/slice';
import { shiftStatus } from 'constants/shiftStatus';

function* removeLocalStorageItems() {
  yield call([localStorage, localStorage.removeItem], tokenKey);
  yield call([localStorage, localStorage.removeItem], refreshKey);
  yield call([localStorage, localStorage.removeItem], roleKey);
}

function* loginHostRequestSaga({ payload }) {
  try {
    const { data } = yield call(login, payload);
    yield all([
      put({
        type: sessionActions.loginHostSuccess,
        payload: {
          ...data,
          role: payload.role,
          warehouse_id: payload.warehouse_id,
          brand_id: payload.brand_id,
          notTrigger: payload?.notTrigger,
        },
      }),
      put({
        type: commonActions.hideLoading,
      }),
    ]);

    if (!payload?.notTrigger && payload?.role === 'host') {
      yield put({
        type: sessionActions.getTerminals,
      });
      yield put({
        type: sessionActions.getPrinters,
      });
    }
  } catch (err) {
    console.log(err);
    yield call([toast, 'error'], strings.invalidLogin, {
      progressClassName: 'customProgressBarError',
    });
    yield all([
      put({ type: sessionActions.loginFailure }),
      put({
        type: commonActions.hideLoading,
      }),
    ]);
  }
}

function* loginRequestSaga({ payload }) {
  try {
    const { data } = yield call(login, payload);
    yield put({
      type: sessionActions.loginSuccess,
      payload: {
        ...data,
        role: payload.role,
        warehouse_id: payload.warehouse_id,
        brand_id: payload.brand_id,
        notTrigger: payload?.notTrigger,
      },
    });

    if (!payload?.notTrigger && payload?.role === 'host') {
      yield put({
        type: sessionActions.getTerminals,
      });
      yield put({
        type: sessionActions.getPrinters,
      });
    }
  } catch (err) {
    console.log(err);
    yield call([toast, 'error'], strings.invalidLogin, {
      progressClassName: 'customProgressBarError',
    });
    yield all([
      put({ type: sessionActions.loginFailure }),
      put({
        type: commonActions.hideLoading,
      }),
    ]);
  }
}

function* logoutHostRequestSaga({ payload }) {
  try {
    yield call(logoutHost, payload);
    yield put({
      type: sessionActions.logoutHostSuccess,
    });
  } catch (err) {
    console.error(err);
    yield all([
      put({ type: sessionActions.logoutHostFailure }),
      put({
        type: commonActions.hideLoading,
      }),
    ]);
  }
}

function* logoutHostSuccessSaga() {
  yield removeLocalStorageItems();
}

function* logoutHostFailureSaga() {
  yield removeLocalStorageItems();
}

function* loginHostSuccessSaga({ payload }) {
  const { access, refresh } = payload;

  if (window.webkit) {
    yield window.webkit?.messageHandlers?.mainMessageHandler?.postMessage({
      type: 'initializeAPI',
      access_token: access,
      refresh_token: refresh,
    });
  }

  if (!payload?.notTrigger) {
    if (access) {
      yield call([localStorage, localStorage.setItem], tokenKey, access);
    }
    if (refresh) {
      yield call([localStorage, localStorage.setItem], refreshKey, refresh);
    }
  }

  if (payload.role) {
    yield call([localStorage, localStorage.setItem], roleKey, payload.role);
  }

  if (!payload?.notTrigger && payload.role === 'host') {
    yield put({ type: tiersActions.fetchTiersRequest });
  }

  if (payload.open_orders.length === 0) {
    yield put({ type: desksActions.createOrderRequest });
  }

  yield call([localStorage, localStorage.getItem], tokenKey);
  yield call([localStorage, localStorage.getItem], roleKey);

  if (payload.started_time_entry) {
    if (payload.started_time_entry.started_at) {
      const isStartedOnPreviousDay =
        new Date(payload.started_time_entry.started_at) <
        new Date().setHours(0, 0, 0, 0);
      // If yesterday's shift then force ending it, otherwise go to POS
      if (isStartedOnPreviousDay) {
        yield put(push(`/shift-entry?status=${shiftStatus.END}`));
      } else {
        yield put(push('/dashboard/pos'));
      }
    } else {
      // If there is a shift without a started_at datetime for whatever reason, make them set a start time
      yield put(push(`/shift-entry?status=${shiftStatus.START}`));
    }
  } else {
    // If there is no shift, make them start a shift
    yield put(push(`/shift-entry?status=${shiftStatus.START}`));
  }
}

function* initializeAPI({ payload }) {
  const { access, refresh } = payload;

  if (window.webkit) {
    yield window.webkit?.messageHandlers?.mainMessageHandler?.postMessage({
      type: 'initializeAPI',
      access_token: access,
      refresh_token: refresh,
    });
  }

  if (!payload?.notTrigger) {
    if (access) {
      yield call([localStorage, localStorage.setItem], tokenKey, access);
    }
    if (refresh) {
      yield call([localStorage, localStorage.setItem], refreshKey, refresh);
    }
  }

  if (payload.role) {
    yield call([localStorage, localStorage.setItem], roleKey, payload.role);
  }

  if (!payload?.notTrigger && payload.role === 'host') {
    yield put({ type: tiersActions.fetchTiersRequest });
  }

  if (payload.open_orders.length === 0) {
    yield put({ type: desksActions.createOrderRequest });
  }
}

function* fetchSessionRequestSaga() {
  try {
    const role = yield select(roleSelector);
    const { data } = yield authenticatedRequest({
      url: '/tasting-room-master-api/v1/users/current',
    });

    yield all([
      put({
        type: sessionActions.fetchSessionSuccess,
        payload: { ...data, role },
      }),
      put({
        type: commonActions.hideLoading,
      }),
      yield put({
        type: sessionActions.getTerminals,
      }),
      yield put({
        type: sessionActions.getPrinters,
      }),
    ]);
  } catch (err) {
    if (err.response) {
      removeLocalStorageItems();
    }
    yield all([
      put({ type: sessionActions.fetchSessionFailure }),
      put({
        type: commonActions.hideLoading,
      }),
    ]);
  }
}

function* loginAfterConfirmationSaga({ payload }) {
  try {
    yield call([localStorage, localStorage.setItem], tokenKey, payload.token);
    yield put({ type: sessionActions.fetchSessionRequest });
    yield put(push('/'));
  } catch (err) {
    yield call([localStorage, localStorage.removeItem], tokenKey);
  }
}

function* logoutSaga() {
  yield call([localStorage, localStorage.clear]);
  yield delay(800);
  yield put(resetState());
  yield delay(100);
  yield put(push('/'));
}

function* switchHostSaga() {
  yield call([localStorage, localStorage.setItem], roleKey, 'manager');
  yield delay(800);
}

function* getTerminalsSaga() {
  const params = {
    warehouse_id: yield call(
      [localStorage, localStorage.getItem],
      warehouseKey
    ),
  };

  try {
    const { data } = yield authenticatedRequest({
      url: '/tasting-room-master-api/v1/terminals',
      params,
    });
    yield put({
      type: sessionActions.getTerminalsSuccess,
      payload: data,
    });
  } catch (err) {
    toast.error(strings.gettingTerminalsError, {
      progressClassName: 'customProgressBarError',
    });
  }
}

function* getPrintersSaga() {
  try {
    const params = { type: 'PRINTER', per_page: 10 };

    const { data } = yield authenticatedRequest({
      url: '/tasting-room-master-api/v1/devices/',
      params,
    });
    yield put({
      type: sessionActions.getPrintersSuccess,
      payload: data,
    });
  } catch (err) {
    toast.error(strings.gettingPrintersError, {
      progressClassName: 'customProgressBarError',
    });
  }
}

function* printRequestSaga({ payload }) {
  try {
    yield call(print, payload);
    toast.info(strings.printingInfo, {
      progressClassName: 'customProgressBarInfo',
    });
  } catch (err) {
    toast.error(strings.printingError, {
      progressClassName: 'customProgressBarError',
    });
  }
}

function* fetchHostsSaga({ payload }) {
  try {
    const params = { per_page: 100, ...payload };

    const { data } = yield authenticatedRequest({
      url: '/tasting-room-master-api/v1/hosts',
      params,
    });

    yield put({
      type: sessionActions.fetchHostsSuccess,
      payload: data,
    });
  } catch (err) {
    console.error(err);
    yield put({
      type: sessionActions.fetchHostsFailure,
    });
  }
}

function* saveBrandAndWarehouseSaga({ payload }) {
  try {
    yield call([localStorage, localStorage.setItem], brandKey, payload.brand);
    yield call(
      [localStorage, localStorage.setItem],
      warehouseKey,
      payload.warehouse
    );
  } catch (err) {
    toast.error(err, {
      progressClassName: 'customProgressBarError',
    });
  }
}

export default function*() {
  yield all([
    takeLatest(sessionActions.logout, logoutSaga),
    takeLatest(sessionActions.switchHost, switchHostSaga),
    takeLatest(sessionActions.loginRequest, loginRequestSaga),
    takeLatest(sessionActions.loginSuccess, initializeAPI),
    takeLatest(sessionActions.loginHostRequest, loginHostRequestSaga),
    takeLatest(sessionActions.loginHostSuccess, loginHostSuccessSaga),
    takeLatest(sessionActions.fetchSessionSuccess, initializeAPI),
    takeLatest(sessionActions.fetchSessionRequest, fetchSessionRequestSaga),
    takeLatest(sessionActions.getTerminals, getTerminalsSaga),
    takeLatest(sessionActions.getPrinters, getPrintersSaga),
    takeLatest(sessionActions.printRequest, printRequestSaga),
    takeLatest(sessionActions.logoutHostRequest, logoutHostRequestSaga),
    takeLatest(sessionActions.logoutHostSuccess, logoutHostSuccessSaga),
    takeLatest(sessionActions.logoutHostFailure, logoutHostFailureSaga),
    takeLatest(
      sessionActions.loginAfterConfirmation,
      loginAfterConfirmationSaga
    ),
    takeLatest(sessionActions.fetchHosts, fetchHostsSaga),
    takeLatest(sessionActions.saveBrandAndWarehouse, saveBrandAndWarehouseSaga),
  ]);
}

import React, { useEffect } from 'react';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import ChevronLeftIcon from 'components/Icons/ChevronLeftIcon';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { paginatedDeskActions } from 'models/paginatedOrders/slice';
import { detailedOrderLoadingSelector } from 'models/paginatedOrders/selectors';
import Order from './Order';
import styles from './Order.scss';

const OrderContainer = () => {
  const { id } = useParams();

  const fetchOrder = useAction(paginatedDeskActions.fetchDetailedOrderRequest);
  const isOrderLoading = useSelector(detailedOrderLoadingSelector);
  const history = useHistory();

  useEffect(() => {
    fetchOrder({ id });
  }, [id]);

  const location = useLocation();

  const handleClick = () => {
    if (location.prevPath === '/dashboard/orders') {
      history.push({
        pathname: '/dashboard/orders',
        state: { ...location.state, prevPageOrder: true },
      });
    } else {
      history.goBack();
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.back}>
        <button onClick={handleClick} className={styles.backLink}>
          <ChevronLeftIcon width="18" height="18" /> <span>Back</span>
        </button>
      </div>

      {isOrderLoading ? <h2>Loading...</h2> : <Order />}
    </div>
  );
};

export default OrderContainer;

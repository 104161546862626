import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import styles from './ConfirmLogoutModal.scss';

const ConfirmLogoutModal = ({ setIsOpen, isOpen, onSubmit, brandTitle }) => {
  return (
    <Modal id="logoutModal" isOpen={isOpen}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.body}>
            <p className={styles.text}>
              Are you sure you want to logout of {brandTitle} POS?
            </p>
          </div>
          <div className={styles.buttons}>
            <button className={styles.cancel} onClick={() => setIsOpen(false)}>
              CANCEL
            </button>
            <button className={styles.confirm} onClick={onSubmit}>
              CONFIRM
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ConfirmLogoutModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  brandTitle: PropTypes.string,
};

export default memo(ConfirmLogoutModal);

import React from 'react';
import PropTypes from 'prop-types';

const LockIcon = ({ filled, width, height }) => {
  const fill = filled ? '#333' : '#EDEAE3';
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7V6C3 2.68629 5.68629 0 9 0C12.3137 0 15 2.68629 15 6V7H17C17.5523 7 18 7.44772 18 8V20C18 20.5523 17.5523 21 17 21H1C0.447715 21 0 20.5523 0 20V8C0 7.44771 0.447715 7 1 7H3ZM16 9H2V19H16V9ZM8 14.732C7.21606 14.2794 6.83386 13.3567 7.06815 12.4823C7.30243 11.6079 8.09479 10.9999 9 10.9999C9.90521 10.9999 10.6976 11.6079 10.9318 12.4823C11.1661 13.3567 10.7839 14.2794 10 14.732V17H8V14.732H8ZM5 7H13V6C13 3.79086 11.2091 2 9 2C6.79086 2 5 3.79086 5 6V7Z"
      />
    </svg>
  );
};

LockIcon.propTypes = {
  filled: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

LockIcon.defaultProps = {
  filled: false,
  width: '18',
  height: '21',
};

export default LockIcon;

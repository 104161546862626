import React from 'react';
import PropTypes from 'prop-types';

const AmericanExpressIcon = ({ fill, width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 33 11"
      fill="none"
      fillOpacity={0.5}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.1062 1.20473L24.7179 2.65715H23.4981L24.1062 1.20473ZM20.0615 2.43539C20.0615 3.09792 20.2232 3.51693 20.5338 3.82627C20.8418 4.13561 21.3857 4.22923 21.8153 4.22923H22.8495L23.1833 3.4166H25.0192L25.3631 4.22923H27.163V1.49473L28.8383 4.22923H30.0906V0.586047H29.1854V3.12341L27.6318 0.586047H26.2863V4.03209L24.7899 0.586047H23.4656L22.2199 3.45121H21.823C21.5903 3.45121 21.3432 3.40748 21.2071 3.2633C21.0427 3.07945 20.964 2.79803 20.964 2.4077C20.964 2.02583 21.0674 1.73583 21.2182 1.58242C21.3936 1.41385 21.5757 1.3666 21.8981 1.3666H22.7359V0.585938H21.8802C21.2711 0.585938 20.8237 0.720003 20.5381 1.01077C20.1582 1.4011 20.0615 1.89506 20.0615 2.43539Z"
        fill={fill}
      />
      <mask
        id="mask0"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="33"
        height="11"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.908203 10.5009H32.5462V0.585938H0.908203V10.5009Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.7271 4.22923H19.6289V0.585938H18.7271V4.22923Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0586 2.07879C16.9372 2.15143 16.7935 2.15385 16.6215 2.15385H15.5478V1.34462H16.6361C16.7901 1.34462 16.9508 1.35143 17.0552 1.41033C17.1698 1.46341 17.2408 1.57638 17.2408 1.73242C17.2408 1.89165 17.1732 2.01978 17.0586 2.07879ZM18.2143 1.56715C18.2143 1.16077 18.0467 0.92319 17.7672 0.760443C17.4814 0.597696 17.1486 0.585938 16.697 0.585938H14.6641V4.22923H15.5479V2.90407H16.4967C16.8147 2.90407 17.0117 2.93187 17.1407 3.06C17.3017 3.23528 17.2802 3.55055 17.2802 3.76978L17.2837 4.22923H18.1751V3.51352C18.1751 3.18814 18.1535 3.02627 18.0288 2.84506C17.9502 2.7389 17.785 2.61077 17.5915 2.53825C17.8209 2.44814 18.2143 2.14803 18.2143 1.56715Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0517 1.34462V0.585938H11.0991V4.22923H14.0517V3.47572H11.9831V2.74814H14.002V2.00132H11.9831V1.34462H14.0517Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.12735 1.20473L3.73227 2.65715H2.52578L3.12735 1.20473ZM1.86811 4.22923L2.21116 3.4166H4.05135L4.39095 4.22923H6.18853V1.3733L7.47342 4.22923H8.24942L9.53097 1.37583L9.53432 4.22923H10.4402V0.585938H8.96877L7.90626 3.05748L6.74962 0.585938H5.30714V4.03539L3.80387 0.585938H2.49087L0.908203 4.22923H1.86811Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5289 8.53918H15.3868V7.61127H16.5392C16.8582 7.61127 17.0796 7.7394 17.0796 8.05797C17.0796 8.37325 16.8685 8.53918 16.5289 8.53918ZM14.5065 10.1702L13.1495 8.68577L14.5065 7.24863V10.1702ZM11.0022 9.74215H8.82923V9.0172H10.7695V8.27369H8.82923V7.61116H11.045L12.0117 8.67314L11.0022 9.74215ZM16.5392 6.85742H13.7151L12.5908 8.05193L11.5034 6.85742H7.94873V10.5016H11.4497L12.5806 9.29533L13.6688 10.5016H15.3868V9.27929H16.4894C17.2621 9.27929 18.0286 9.07039 18.0286 8.05797C18.0286 7.04874 17.2405 6.85742 16.5392 6.85742Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.4419 7.61105V6.85742H22.4922V10.5016H25.4419V9.74544L23.3722 9.74215V9.01709H25.3914V8.27358H23.3722V7.61105H25.4419Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.8419 8.35456C20.7236 8.42369 20.5775 8.42973 20.4055 8.42973H19.3318V7.61116H20.4201C20.5775 7.61116 20.7349 7.61456 20.8419 7.67698C20.9564 7.73599 21.0249 7.84885 21.0249 8.00467C21.0249 8.16072 20.9564 8.28632 20.8419 8.35456ZM22.0021 7.83863C22.0021 7.42281 21.8267 7.19544 21.5477 7.0294C21.2577 6.87687 20.9318 6.85742 20.4843 6.85742H18.4438V10.5016H19.3319V9.17061H20.2764C20.5989 9.17061 20.7957 9.20182 20.9249 9.33237C21.0891 9.50171 21.0677 9.82654 21.0677 10.0457V10.5016H21.9549V9.77929C21.9515 9.45797 21.9334 9.29193 21.8086 9.11402C21.7332 9.00786 21.5724 8.87962 21.3757 8.80808C21.6083 8.71709 22.002 8.42028 22.0021 7.83863Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.1509 9.72017H29.4364V10.5015H31.1578C32.0491 10.5015 32.5462 10.1356 32.5462 9.3516C32.5462 8.97654 32.454 8.755 32.2819 8.57962C32.0817 8.37984 31.7524 8.29808 31.2726 8.28643L30.7968 8.2738C30.6463 8.2738 30.5461 8.26456 30.4529 8.23259C30.3417 8.19204 30.2595 8.09863 30.2595 7.96105C30.2595 7.84544 30.2954 7.75797 30.3921 7.69885C30.4811 7.63907 30.5856 7.63314 30.7463 7.63314H32.3676V6.85742H30.5956C29.6692 6.85742 29.332 7.4138 29.332 7.94237C29.332 9.09555 30.3672 9.04259 31.1937 9.07028C31.3476 9.07028 31.4409 9.09555 31.5085 9.15116C31.5691 9.20171 31.6163 9.28599 31.6163 9.39226C31.6163 9.4916 31.5726 9.57358 31.5118 9.62984C31.4373 9.6983 31.3185 9.72017 31.1509 9.72017Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.6856 9.72017H25.9711V10.5015H27.6925C28.5838 10.5015 29.0809 10.1356 29.0809 9.3516C29.0809 8.97654 28.9887 8.755 28.8166 8.57962C28.6164 8.37984 28.287 8.29808 27.8073 8.28643L27.3315 8.2738C27.1809 8.2738 27.0808 8.26456 26.9875 8.23259C26.8763 8.19204 26.7942 8.09863 26.7942 7.96105C26.7942 7.84544 26.8301 7.75797 26.9268 7.69885C27.0158 7.63907 27.1203 7.63314 27.281 7.63314H28.9022V6.85742H27.1303C26.2038 6.85742 25.8667 7.4138 25.8667 7.94237C25.8667 9.09555 26.9019 9.04259 27.7284 9.07028C27.8823 9.07028 27.9755 9.09555 28.0431 9.15116C28.1038 9.20171 28.151 9.28599 28.151 9.39226C28.151 9.4916 28.1073 9.57358 28.0465 9.62984C27.972 9.6983 27.8532 9.72017 27.6856 9.72017Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

AmericanExpressIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AmericanExpressIcon.defaultProps = {
  fill: '#EDEAE3',
  width: '33',
  height: '11',
};

export default AmericanExpressIcon;

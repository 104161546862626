import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SimpleKeyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { layouts, display, btnAttrs } from './constants';
import './Keyboard.css';

const Keyboard = React.forwardRef(
  ({ onChange, layoutName, onKeyPress, inputName, ...props }, ref) => {
    const [layout, setLayout] = useState(layoutName || 'default');

    const setDefaultOrLayout = nextLayout =>
      setLayout(layout === 'default' ? nextLayout : 'default');

    const handleKeyPress = button => {
      switch (button) {
        case '{default}':
          setLayout('default');
          break;
        case '{shiftleft}':
        case '{shiftright}':
          setDefaultOrLayout('shift');
          break;
        case '{controls}':
          setLayout('controls');
          break;
        default:
          break;
      }

      if (onKeyPress) onKeyPress(button);
    };

    const renderedLayout = props.layout || layouts;
    const renderedLayoutName = layoutName || layout;

    return (
      <SimpleKeyboard
        useButtonTag
        mergeDisplay
        theme="hg-theme-default hg-layout-default hg-theme-therapy"
        keyboardRef={ref}
        onChange={onChange}
        onKeyPress={handleKeyPress}
        display={display}
        buttonAttributes={btnAttrs}
        {...props}
        inputName={inputName}
        layoutName={renderedLayoutName}
        layout={renderedLayout}
      />
    );
  }
);

Keyboard.propTypes = {
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  layoutName: PropTypes.string,
  layout: PropTypes.object,
  type: PropTypes.string,
  inputName: PropTypes.string,
};

Keyboard.defaultProps = {
  layout: null,
  layoutName: null,
  onKeyPress: null,
  type: 'text',
};

export default Keyboard;

import clsx from 'clsx';
import React, { useState } from 'react';
import formatPrice from 'utils/formatPrice';
import EditIcon from '../../../../components/Icons/EditIcon';
import CommentModal from '../../../../components/Modal/CommentModal';
import styles from './ProductItem.scss';
import PropTypes from 'prop-types';

import { getProductTitleWithVintage } from 'utils/getPreparedInventory';

const ProductItem = ({
  caption,
  title,
  number,
  vintage,
  price,
  isTasting,
  additions,
  comment,
  refunded,
}) => {
  const [commentModal, setCommentModal] = useState(false);

  return (
    <div className={styles.root}>
      <CommentModal
        comment={comment}
        onClose={() => setCommentModal(false)}
        isOpen={commentModal}
      />
      <div className={clsx(styles.product, refunded && styles.refundedProduct)}>
        <div className={styles.productWrapper}>
          <div className={styles.productTitle}>
            <h3
              className={clsx(
                styles.productTitleContent,
                isTasting && styles.titleContentItalic
              )}
            >
              {getProductTitleWithVintage(title, vintage)}
            </h3>
            {caption && (
              <span className={styles.productCaption}>{caption}</span>
            )}
          </div>
          {comment && (
            <button
              onClick={() => setCommentModal(true)}
              className={styles.productButton}
            >
              <EditIcon />
            </button>
          )}
        </div>
        <div className={styles.productInfo}>
          {!!number && <span className={styles.productAmount}>({number})</span>}
          <span className={styles.productPrice}>${formatPrice(price)}</span>
        </div>
      </div>
      {(additions ?? []).map(item => (
        <div className={styles.subProduct} key={item.id}>
          <span className={styles.subProductTitle}>{item?.title}</span>
          <div className={styles.subProductPriceWrapper}>
            <span className={styles.subProductPrice}>
              ${formatPrice(item?.product_price)}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

ProductItem.propTypes = {
  title: PropTypes.string,
  vintage: PropTypes.string,
  number: PropTypes.number,
  price: PropTypes.number,
  caption: PropTypes.string,
  isTasting: PropTypes.bool,
  additions: PropTypes.array,
  comment: PropTypes.string,
  refunded: PropTypes.bool,
};

ProductItem.defaultProps = {
  title: '',
  number: 0,
  price: 0,
  isTasting: false,
  caption: null,
};

export default ProductItem;

import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import useAction from 'hooks/useAction';
import { desksActions } from 'models/desks/slice';

import usePortal from 'hooks/usePortal';
import { usePopper } from 'react-popper';

import TimesIcon from 'components/Icons/TimesIcon';
import ChevronDownIcon from 'components/Icons/ChevronDownIcon';
import { CSSTransition } from 'react-transition-group';

import styles from './Select.scss';

const Select = ({
  value,
  items,
  onChange,
  className,
  listClassName,
  disabled,
  onDelete,
  addNewButton,
}) => {
  const [active, setActive] = useState(false);
  const [refEl, setRefEl] = useState(null);
  const [popperEl, setPopperEl] = useState(null);
  const { styles: pStyles, attributes: pAttrs } = usePopper(refEl, popperEl);
  const portalElement = usePortal('SelectPortalled');

  const createOrder = useAction(desksActions.createOrderRequest);

  const handleChange = item => () => {
    setActive(false);
    if (item.value !== value.value) {
      onChange(item);
    }
  };

  const outsideClick = useCallback(
    e => {
      if (refEl.contains(e.target)) return;
      if (!popperEl.contains(e.target)) setActive(false);
    },
    [popperEl, setActive]
  );

  useEffect(() => {
    if (active) document.addEventListener('mousedown', outsideClick);
    else document.removeEventListener('mousedown', outsideClick);
    return () => {
      document.removeEventListener('mousedown', outsideClick);
    };
  }, [active, outsideClick]);

  return (
    <div className={styles.root}>
      <button
        disabled={disabled}
        ref={setRefEl}
        className={className}
        onClick={() => setActive(!active)}
      >
        <span>{value.label}</span>
        <i className={styles.icon}>
          <ChevronDownIcon />
        </i>
      </button>
      <CSSTransition in={active} timeout={200} classNames="fade" unmountOnExit>
        <>
          {ReactDOM.createPortal(
            <div
              ref={setPopperEl}
              style={pStyles.popper}
              className={clsx(styles.list, listClassName)}
              {...pAttrs.popper}
            >
              {items.map(item => (
                <div className={styles.listItem} key={item.value}>
                  <span
                    className={styles.listLabel}
                    onClick={handleChange(item)}
                  >
                    {item.label}
                  </span>
                  {item.label !== '+' && onDelete && (
                    <button
                      onClick={() => {
                        onDelete(item, items);
                        setActive(true);
                      }}
                    >
                      <TimesIcon className={styles.listDelete} />
                    </button>
                  )}
                </div>
              ))}
              {addNewButton && (
                <div
                  className={styles.buttonContainer}
                  onClick={() => createOrder()}
                >
                  <p>New Order</p>
                </div>
              )}
            </div>,
            portalElement
          )}
        </>
      </CSSTransition>
    </div>
  );
};

Select.defaultProps = {
  disabled: false,
};

Select.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  listClassName: PropTypes.string,
  onDelete: PropTypes.func,
  addNewButton: PropTypes.bool,
};

export default Select;

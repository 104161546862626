import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { categorizeOrderItems } from 'utils/categorizeOrderItems';
import ProductItem from './ProductItem';
import { OrderItemsResourceTypes } from 'constants/orderItems';

const captions = {
  [OrderItemsResourceTypes.TASTING_MENU_ORDER_ITEM]: 'Tasting Menu',
  [OrderItemsResourceTypes.PRODUCT_SAMPLE_ORDER_ITEM]: 'Sample',
  [OrderItemsResourceTypes.FOOD_ORDER_ITEM]: 'FOOD',
};

const getProductItemProps = item => ({
  title: item.title,
  number: item.number,
  vintage: item?.product?.vintage,
  price:
    item?.product_price ??
    Number(item?.tasting_menu_price) ??
    item?.total_price,
});

const getAdditions = (key, food_items) => {
  if (key === OrderItemsResourceTypes.FOOD_ORDER_ITEM) {
    return food_items;
  }

  return null;
};

const OrderProducts = ({ items, tips, refunded }) => {
  const categorizedItems = useMemo(() => categorizeOrderItems(items), [items]);

  const renderProducts = Object.keys(categorizedItems).map(type =>
    categorizedItems[type].map(item => (
      <ProductItem
        key={item.id}
        caption={captions[type]}
        {...getProductItemProps(item)}
        refunded={refunded}
        additions={!refunded && getAdditions(type, item.food_order_items)}
      />
    ))
  );

  return (
    <div>
      {renderProducts}
      {!!tips && <ProductItem key="tips" title="Tips" price={tips} />}
    </div>
  );
};

OrderProducts.propTypes = {
  items: PropTypes.array.isRequired,
  tips: PropTypes.number.isRequired,
  refunded: PropTypes.bool,
};

export default OrderProducts;

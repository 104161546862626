import React from 'react';
import PropTypes from 'prop-types';

const ArrowRightIcon = ({ fill, width, height, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 37 34"
    >
      <title> Arrow Copy</title>
      <g style={{ fill: 'none', opacity: 0.9 }}>
        <g stroke={fill}>
          <g transform="translate(-1086 -341)translate(1104.363961 357.998265)scale(-1 1)rotate(-270)translate(-1104.363961 -357.998265)translate(1088.100505 340.134304)">
            <g transform="translate(16.263456 19.464466)rotate(-315)translate(-16.263456 -19.464466)translate(4.471789 8.256133)">
              <line x1="22.1" y1="0.3" x2="22.5" y2="21.5" />
              <line x1="22.5" y1="21.5" x2="0.2" y2="22.2" />
            </g>
            <line x1="16.8" y1="0.9" x2="16.8" y2="33.5" />
          </g>
        </g>
      </g>
    </svg>
  );
};

ArrowRightIcon.propTypes = {
  fill: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ArrowRightIcon.defaultProps = {
  fill: '#EDEAE3',
  width: '37',
  height: '34',
};

export default ArrowRightIcon;

import { takeLatest, all, put } from 'redux-saga/effects';
import { authenticatedRequest } from 'api';
import { productsPresetsUrl } from 'api/urls';
import { productsPresetsActions } from './slice';

function* fetchPresetsSaga() {
  try {
    const { data } = yield authenticatedRequest({ url: productsPresetsUrl });
    yield put({
      type: productsPresetsActions.fetchPresetsSuccess,
      payload: data,
    });
  } catch (err) {
    yield put({ type: productsPresetsActions.fetchPresetsFailure });
  }
}

export default function*() {
  yield all([
    takeLatest(productsPresetsActions.fetchPresetsRequest, fetchPresetsSaga),
  ]);
}

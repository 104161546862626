import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './POSCheckout.scss';
import Cart from 'components/Cart';
import Checkout from './Checkout';
import { desksActions } from 'models/desks/slice';
import { useQuery } from 'hooks/useQuery';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import {
  guestOrdersPendingSelector,
  isPendingSelector,
  guestOrdersSelector,
  currentOrderSelector,
  orderCheckoutDataSelector,
  posOrdersSelector,
} from 'models/desks/selectors';
import { Redirect, useHistory } from 'react-router-dom';
import AddTipsModal from 'components/Modal/AddTipsModal/AddTipsModal';
import ReturnServerModal from 'components/Modal/ReturnServerModal';
import CheckoutContext from '../Checkout/context';
import ChevronLeftIcon from 'components/Icons/ChevronLeftIcon';

const POSCheckout = () => {
  const { selectedPaymentMethod, setSelectedPaymentMethod } = useContext(
    CheckoutContext
  );
  const query = useQuery();
  const history = useHistory();

  const selectOrder = useAction(desksActions.selectOrder);
  const guestOrdersPending = useSelector(guestOrdersPendingSelector);
  const orderIsPending = useSelector(isPendingSelector);

  const orderID = query.get('order_id') || null;
  const type = query.get('type') || null;
  const orders = useSelector(posOrdersSelector);
  const isOpenOrder = orders[orderID];
  const guestOrders = useSelector(guestOrdersSelector);
  const order = useSelector(currentOrderSelector);
  const isGuestOrder = type === 'guest' && guestOrders[orderID];
  const orderItems = Object.values(order?.order_items ?? {}).reverse();
  const [addTipsModal, setAddTipsModal] = useState(false);
  const [tipsValue, setTipsValue] = useState(0);
  const setOrderTips = useAction(desksActions.changeOrderTipsRequest);
  const [extraScreen, setExtraScreen] = useState(false);
  const [selectedTip, setSelectedTip] = useState(0);
  const checkoutData = useSelector(orderCheckoutDataSelector);
  const fetchOrder = useAction(desksActions.fetchOrderRequest);

  const handleChangeTips = value => {
    setTipsValue(value);
    setExtraScreen(true);
  };

  const handleConfirm = (paymentMethod, tips) => {
    setOrderTips({
      tips: parseFloat(tips),
      orderId: checkoutData.id,
      paymentMethod,
    });
  };

  const onTipsSubmit = value => {
    setSelectedTip(value);
    handleConfirm(selectedPaymentMethod, value);
  };

  const handlePaymentMethodSelect = value => {
    setSelectedPaymentMethod(value);
    setAddTipsModal(true);
  };

  const backToDashboard = e => {
    e.preventDefault();
    history.push('/dashboard/pos');
  };

  useEffect(() => {
    if (orderID && (isOpenOrder || isGuestOrder)) {
      selectOrder({ id: orderID, isGuest: type === 'guest' });
    }
  }, [selectOrder, orderID, type, isGuestOrder]);

  useEffect(() => {
    const { id } = order;
    if (!order?.order_items) {
      fetchOrder({ id });
    }
  }, [fetchOrder, order]);

  if (guestOrdersPending || orderIsPending) return 'LOADING...';

  if (
    !orderID ||
    (!isOpenOrder && type !== 'guest') ||
    (guestOrdersPending && !guestOrders[orderID] && type === 'guest')
  )
    return <Redirect to="/dashboard/pos" />;

  return (
    <div className={styles.root}>
      <div className={styles.cart}>
        <button className={styles.backButton} onClick={backToDashboard}>
          <ChevronLeftIcon width="18" height="18" />
          Back to Dashboard
        </button>
        <Cart order={order} isCheckoutCart orderItems={orderItems} />
      </div>
      <div className={styles.checkout}>
        <Checkout
          checkoutData={checkoutData}
          selectedTip={selectedTip}
          changePaymentMethod={handlePaymentMethodSelect}
        />
      </div>

      <AddTipsModal
        onClose={() => setAddTipsModal(false)}
        isOpen={addTipsModal}
        total={checkoutData.total_price}
        onSubmit={handleChangeTips}
      />

      <ReturnServerModal
        isOpen={extraScreen}
        setIsOpen={setExtraScreen}
        onSubmit={() => onTipsSubmit(tipsValue)}
      />
    </div>
  );
};

ReturnServerModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default POSCheckout;

import React, { useEffect, useMemo, useState, memo, useCallback } from 'react';
import styles from './OrderItemsTable.scss';
import Checkbox from '../Checkbox';
import clsx from 'clsx';
import OrderItemRow from './OrderItemRow';
import PropTypes from 'prop-types';
import { OrderItemsResourceTypes } from 'constants/orderItems';
import { normalize } from 'utils/normalizeById';

const getSelectedAllItems = orderItems => {
  return orderItems.reduce(
    (previous, current) => ({
      ...previous,
      [current.id]: {
        resourcetype: current.resourcetype,
        number: current.number,
        back_to_inventory_number:
          current.resourcetype === OrderItemsResourceTypes.PRODUCT_ORDER_ITEM
            ? current.number
            : 0,
      },
    }),
    {}
  );
};

const getSelectedAdditions = (normalizedOrderItems, additions, isSelected) => {
  return additions
    .filter(addition => addition.id in normalizedOrderItems)
    .reduce(
      (previous, current) => ({
        ...previous,
        [current.id]: {
          number: +isSelected,
          resourcetype: current.resourcetype,
          back_to_inventory_number: 0,
        },
      }),
      {}
    );
};

const isAdditionDisabled = (selectedOrderItems, parentId) => {
  return selectedOrderItems?.[parentId]?.number > 0;
};

const getOrderItemTotalPrice = item => {
  return item?.after_return_number
    ? item?.after_return_total_price
    : item?.total_price;
};

const OrderItemsTable = ({
  orderItems,
  onChange,
  tipsAmount,
  isTipsReturned,
  className,
}) => {
  const [selectedOrderItems, setSelectedOrderItems] = useState({});
  const [shouldReturnTips, setShouldReturnTips] = useState(false);

  const normalizedOrderItems = useMemo(() => normalize(orderItems), [
    orderItems,
  ]);

  useEffect(() => {
    onChange?.({
      orderItems: selectedOrderItems,
      shouldReturnTips,
    });
  }, [selectedOrderItems, shouldReturnTips]);

  const onTipsChange = status => {
    setShouldReturnTips(!!status?.number);
  };

  const onRowChange = (item, data) => {
    const { id, resourcetype } = item;

    let selectedAdditions = {};
    if (
      resourcetype === OrderItemsResourceTypes.FOOD_ORDER_ITEM &&
      !item.addition
    ) {
      selectedAdditions = getSelectedAdditions(
        normalizedOrderItems,
        item.food_order_items,
        data.number > 0
      );
    }

    setSelectedOrderItems({
      ...selectedOrderItems,
      [id]: {
        ...data,
        resourcetype,
        back_to_inventory_number:
          resourcetype === OrderItemsResourceTypes.PRODUCT_ORDER_ITEM
            ? data.back_to_inventory_number
            : 0,
      },
      ...selectedAdditions,
    });
  };

  const onDefaultChange = event => {
    const { checked } = event.target;
    setSelectedOrderItems(checked ? getSelectedAllItems(orderItems) : {});
    if (!isTipsReturned) {
      setShouldReturnTips(checked);
    }
  };

  const hasSelectedItems = useMemo(
    () => Object.values(selectedOrderItems).some(item => item.number > 0),
    [selectedOrderItems]
  );

  const getOrderItemProps = useCallback(
    item => ({
      ...item,
      total_price: getOrderItemTotalPrice(item),
      selectedCount: selectedOrderItems[item.id]?.number ?? 0,
      backToInventoryCount:
        selectedOrderItems[item.id]?.back_to_inventory_number ?? 0,
      onChange: data => onRowChange(item, data),
      disabled: isAdditionDisabled(
        selectedOrderItems,
        item?.food_order_item_id
      ),
    }),
    [selectedOrderItems]
  );

  return (
    <div className={clsx(styles.container, className)}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th colSpan="1" className={clsx(styles.head, styles.checkCell)}>
              <div className={styles.checkbox}>
                <Checkbox
                  onChange={onDefaultChange}
                  checked={hasSelectedItems}
                />
              </div>
            </th>
            <th colSpan="1" className={clsx(styles.head, styles.itemCell)}>
              Item
            </th>
            <th colSpan="1" className={styles.head}>
              Qty
            </th>
            <th colSpan="1" className={clsx(styles.head, styles.priceCell)}>
              Cost
            </th>
          </tr>
        </thead>
        <tbody>
          {orderItems.map(item => (
            <React.Fragment key={item.id}>
              {!item?.addition && (
                <>
                  <OrderItemRow
                    key={item.id.toString()}
                    {...getOrderItemProps(item)}
                  />
                  {item?.food_order_items?.map(addition => (
                    <>
                      {addition.id in normalizedOrderItems && (
                        <OrderItemRow
                          key={addition.id.toString()}
                          {...getOrderItemProps(addition)}
                        />
                      )}
                    </>
                  ))}
                </>
              )}
            </React.Fragment>
          ))}
          {!!tipsAmount && !isTipsReturned && (
            <OrderItemRow
              title="Tips"
              selectedCount={0}
              number={1}
              total_price={tipsAmount}
              onChange={onTipsChange}
            />
          )}
          <tr className={styles.emptyRow} />
        </tbody>
      </table>
    </div>
  );
};

OrderItemsTable.propTypes = {
  orderItems: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  onTipsStatusChange: PropTypes.func,
  tipsAmount: PropTypes.number,
  isTipsReturned: PropTypes.bool,
  className: PropTypes.string,
};
export default memo(OrderItemsTable);

import { createSlice } from '@reduxjs/toolkit';
import actionTypes from 'utils/actionTypes';

const timeEntrySlice = createSlice({
  name: 'timeEntry',
  initialState: {
    timeEntry: {},
    timeEntryLoading: false,
    timeEntryError: null,
  },
  reducers: {
    fetchTimeEntry(state) {
      state.timeEntryLoading = true;
    },
    fetchTimeEntrySuccess(state, { payload }) {
      state.timeEntryLoading = false;
      state.timeEntry = payload;
    },
    fetchTimeEntryFailure(state) {
      state.timeEntryLoading = false;
    },
    createTimeEntry(state) {
      state.timeEntryLoading = true;
    },
    createTimeEntrySuccess(state, { payload }) {
      state.timeEntryLoading = false;
      state.timeEntry = payload;
    },
    createTimeEntryFailure(state, { payload }) {
      state.timeEntryLoading = false;
      state.timeEntryError = payload;
    },
    updateTimeEntry(state) {
      state.timeEntryLoading = true;
    },
    updateTimeEntrySuccess(state, { payload }) {
      state.timeEntryLoading = false;
      state.timeEntry = payload;
    },
    updateTimeEntryFailure(state, { payload }) {
      state.timeEntryLoading = false;
      state.timeEntryError = payload;
    },
  },
});

export const timeEntryActions = actionTypes(timeEntrySlice.actions);

export default timeEntrySlice.reducer;

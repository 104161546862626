import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';

import useSelector from 'hooks/useSelector';
import { formatCurrentDate } from 'utils/formatCurrentDate';

import styles from './Tasters.scss';
import useAction from 'hooks/useAction';
import { menuBreadcrumbsSelector } from 'models/interface/selectors';
import { interfaceActions } from 'models/interface/slice';
import { tastersActions } from 'models/tasters/slice';
import { productCategoriesSelector } from 'models/productCategories/selectors';
import {
  orderItemPending,
  orderItemUpdatePending,
} from 'models/desks/selectors';
import { tastersSelector } from 'models/tasters/selectors';
import ProductCategories from 'components/ProductCategories';
import ProductSubCategories from 'components/ProductSubCategories';
import ProductInventory from 'components/ProductInventory';
import { warehouseKey } from 'constants/localStorage';
import AnimateOnChange from 'react-animate-on-change';

const Tasters = () => {
  // Selectors
  const breadcrumbs = useSelector(menuBreadcrumbsSelector);
  const productCategories = useSelector(productCategoriesSelector);
  const createItemPending = useSelector(orderItemPending);
  const updateItemPending = useSelector(orderItemUpdatePending);
  const tastersCount = useSelector(tastersSelector);

  // Actions
  const setBreadcrumbs = useAction(interfaceActions.changeMenuBreadcrumbs);
  const fetchTasters = useAction(tastersActions.fetchTasters);
  const createTaster = useAction(tastersActions.createTaster);

  // State
  const [lastCreatedTasterId, setLastCreatedTasterId] = useState(0);

  // Constants
  const element = breadcrumbs[breadcrumbs.length - 1];
  const wineCategories = productCategories
    ? Object.values(productCategories).filter(
        category => category.parent_title === 'Wine'
      )
    : [];
  const isPending = updateItemPending || createItemPending;
  const selectedWarehouseID = JSON.parse(localStorage.getItem(warehouseKey));
  const totalTasterCount = tastersCount?.inventory_results?.reduce(
    (total, taster) => total + taster.product_count,
    0
  );

  // Effects
  useEffect(() => {
    fetchTasters({ warehouse_id: selectedWarehouseID, count_type: 'Taster' });
  }, []);

  // Functions
  const handleCreateTaster = product => {
    if (!selectedWarehouseID) return;

    createTaster({
      product_id: product?.id,
      warehouse_id: selectedWarehouseID,
      vintage: product?.vintage,
    });

    setLastCreatedTasterId(product?.id);
  };
  const selectCategory = useCallback(
    category => {
      setBreadcrumbs([...breadcrumbs, category]);
    },
    [breadcrumbs, setBreadcrumbs]
  );
  const onProductCategoryClick = item => {
    const isNotMainCategory = item?.parent_id;
    if (!selectCategory) return;
    selectCategory({
      type: isNotMainCategory ? 'inventory' : 'subCategories',
      ...item,
    });
  };
  const handleBreadcrumbClick = useCallback(
    breadcrumbIndex => {
      setBreadcrumbs([...breadcrumbs.slice(0, breadcrumbIndex + 1)]);
    },
    [breadcrumbs, setBreadcrumbs]
  );

  const ComponentTypes = {
    categories: (
      <ProductCategories
        categories={wineCategories}
        onCategoryClick={onProductCategoryClick}
      />
    ),
    subCategories: (
      <ProductSubCategories
        categoryId={element?.id}
        categories={Object.values(element?.sub_categories ?? {})}
        onCategoryClick={item =>
          selectCategory?.({ ...item, type: 'inventory' })
        }
        onProductClick={onProductCategoryClick}
      />
    ),
    inventory: (
      <ProductInventory
        disabled={isPending}
        onProductClick={handleCreateTaster}
        categoryId={element?.id}
      />
    ),
  };

  const RenderedComponent = () => ComponentTypes[element.type];
  const formattedDate = formatCurrentDate(new Date(tastersCount?.created_at));

  return (
    <div className={clsx(styles.root)}>
      <div className={clsx(styles.categories)}>
        <div className={styles.breadcrumbs}>
          {breadcrumbs.map((breadcrumb, index) => (
            <div className={styles.breadcrumb} key={breadcrumb.title}>
              <span
                className={styles.breadcrumbTitle}
                onClick={() => handleBreadcrumbClick(index)}
              >
                {breadcrumb.title}
              </span>
              {index < breadcrumbs.length - 1 && (
                <span className={styles.breadcrumbSpacer}>/</span>
              )}
            </div>
          ))}
        </div>
        <div className={styles.inner}>
          <RenderedComponent />
        </div>
      </div>
      {tastersCount?.created_at && (
        <div className={clsx(styles.sidebar)}>
          <div className={styles.sidebarHeading}>
            Taster Count (Since{' '}
            {`${formattedDate.monthAlias} ${formattedDate.day} ${formattedDate.year}`}
            )
          </div>
          <div className={styles.sidebarContent}>
            {tastersCount?.inventory_results?.map(taster => (
              <div className={styles.sidebarContentItem}>
                <h3 className={styles.sidebarContentItemTitle}>
                  {taster.product_title || 'Product not found'}
                  {taster.vintage ? ` (${taster.vintage})` : ''}
                </h3>
                <AnimateOnChange
                  baseClassName={styles.sidebarContentItemValue}
                  animationClassName={styles.sidebarContentItemValueAnimation}
                  animate={taster.product_id === lastCreatedTasterId}
                >
                  {taster.product_count}
                </AnimateOnChange>
              </div>
            ))}
          </div>
          <div className={styles.sidebarContentItemTotalContainer}>
            <div className={styles.sidebarContentItemTotal}>
              <h3 className={styles.sidebarContentItemTitle}>Total:</h3>
              <AnimateOnChange
                baseClassName={styles.sidebarContentItemValue}
                animationClassName={styles.sidebarContentItemValueAnimation}
                animate={lastCreatedTasterId === 0}
              >
                {totalTasterCount || 0}
              </AnimateOnChange>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tasters;

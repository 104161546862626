import React from 'react';
import styles from './LinkedCell.scss';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const LinkedCell = ({ to, className, children }) => {
  const location = useLocation();
  return (
    <td className={className}>
      <Link
        className={styles.link}
        to={{
          pathname: to,
          prevPath: location.pathname,
        }}
      >
        {children}
      </Link>
    </td>
  );
};

LinkedCell.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.any,
};
export default LinkedCell;

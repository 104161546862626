import React, { memo, useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import useSelector from 'hooks/useSelector';
import { sliderSettings } from './utils';
import styles from './Slider.scss';
import TierCard from '../TierCard';
import {
  tierActionSuccessSelector,
  tiersGrouppedSelector,
} from '../../../models/tiers/selectors';

import isPhoneInvalid from 'utils/isPhoneInvalid';
import isAddressInvalid from 'utils/isAddressInvalid';

const getSortedTiers = (tiers, currentTier) => {
  const tierID = currentTier?.tier?.id;
  return tiers.sort((a, b) =>
    // eslint-disable-next-line no-nested-ternary
    tierID === a?.variations[0]?.id
      ? -1
      : tierID === b?.variations[0]?.id
      ? 1
      : 0
  );
};

const SliderTier = ({
  isPending,
  currentTier,
  phone,
  address,
  onToggleTierClick,
  wineclub,
}) => {
  const slideRef = useRef(null);
  const tiers = useSelector(tiersGrouppedSelector);
  const actionSuccess = useSelector(tierActionSuccessSelector);
  const [inactiveText, setInactiveText] = useState(null);
  const sortedTiers = useMemo(() => getSortedTiers(tiers, currentTier), [
    tiers,
    currentTier,
  ]);

  useEffect(() => {
    if (actionSuccess) {
      slideRef?.current?.slickGoTo(0);
    }
  }, [actionSuccess]);

  useEffect(() => {
    if (isAddressInvalid(address) && isPhoneInvalid(phone)) {
      setInactiveText('Please enter a valid address and phone number');
    } else if (isAddressInvalid(address)) {
      setInactiveText('Please enter a valid address');
    } else if (isPhoneInvalid(phone)) {
      setInactiveText('Please enter a valid phone number');
    } else {
      setInactiveText(null);
    }
  }, [address, phone, setInactiveText]);

  return (
    <div className={styles.slider}>
      {false && (
        <Slider ref={slideRef} {...sliderSettings}>
          {sortedTiers.map(tier => (
            <TierCard
              isActive={
                currentTier?.tier?.id === tier?.variations[0]?.id &&
                currentTier?.status !== 'CANCELLED'
              }
              isPending={isPending}
              onToggleTierClick={onToggleTierClick}
              wineclub_title={wineclub?.wineclub_title}
              inactiveText={inactiveText}
              tier={tier}
            />
          ))}
        </Slider>
      )}
    </div>
  );
};

SliderTier.propTypes = {
  currentTier: PropTypes.object,
  address: PropTypes.object,
  phone: PropTypes.string,
  onToggleTierClick: PropTypes.func,
  wineclub: PropTypes.object,
  isPending: PropTypes.bool,
};

export default memo(SliderTier);

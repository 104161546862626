import React from 'react';
import styles from './DatePickerHeader.scss';
import ChevronLeftIcon from '../../Icons/ChevronLeftIcon';
import ChevronRightIcon from '../../Icons/ChevronRightIcon';
import PropTypes from 'prop-types';

const DatePickerHeader = ({ onPreviousClick, onNextClick }) => {
  return (
    <div className={styles.container}>
      <button onClick={() => onPreviousClick()} className={styles.button}>
        <ChevronLeftIcon />
      </button>
      <button onClick={() => onNextClick()} className={styles.button}>
        <ChevronRightIcon />
      </button>
    </div>
  );
};

DatePickerHeader.propTypes = {
  onPreviousClick: PropTypes.func,
  onNextClick: PropTypes.func,
};
export default DatePickerHeader;

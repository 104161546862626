import React from 'react';
// import iconSearch
import styles from './SearchInput.scss';

const SearchInput = React.forwardRef((props, ref) => {
  return (
    <div className={styles.root}>
      <img src="" alt="" />
      <input
        ref={ref}
        className={styles.input}
        placeholder="Search"
        {...props}
      />
    </div>
  );
});

export default SearchInput;

import React from 'react';
import styles from './RippleSpinner.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const RippleSpinner = ({ className }) => {
  return (
    <div className={clsx(styles.container, className)}>
      <div />
      <div />
    </div>
  );
};

RippleSpinner.propTypes = {
  className: PropTypes.string,
};
export default RippleSpinner;

import React, { memo } from 'react';
import styles from './RefundTotalCard.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const RefundTotalCard = ({ title, onClick, disabled, className }) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        styles.container,
        disabled && styles.disabledContainer,
        className
      )}
      disabled={disabled}
    >
      <p className={styles.title}>{title}</p>
    </button>
  );
};

RefundTotalCard.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};
export default memo(RefundTotalCard);

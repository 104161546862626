import React, { useMemo, useState, useEffect } from 'react';
import useSelector from 'hooks/useSelector';
import useAction from 'hooks/useAction';
import { detailedOrderSelector } from 'models/paginatedOrders/selectors';
import { printersSelector } from 'models/session/selectors';
import { sessionActions } from 'models/session/slice';

import OrderDetails from './OrderDetails';
import OrderShippingInformation from './OrderShippingInformation';
import styles from './Order.scss';
import { getAvailableRefundedOrderItems } from '../../utils/getAvailableRefundedOrderItems';
import OrderProductList from './OrderProductList';
import OrderRefundList from './OrderRefundList';
import {
  generateURLPrintReceipt,
  generateURLPrintKitchen,
} from 'utils/generateURLReceipt';

import ReceiptModal from 'components/Modal/ReceiptPrintModal';

const Order = () => {
  const order = useSelector(detailedOrderSelector);
  const printers = useSelector(printersSelector);
  const getPrinters = useAction(sessionActions.getPrinters);
  const getTerminals = useAction(sessionActions.getTerminals);
  useEffect(() => {
    getPrinters();
    getTerminals();
  }, [order]);

  const { refundedOrderItems } = useMemo(
    () => getAvailableRefundedOrderItems(order?.order_items ?? []),
    [order]
  );

  const [receiptModal, setReceiptModal] = useState(false);
  const [designReceipt, setDesignReceipt] = useState('');
  const closeReceiptModal = () => setReceiptModal(false);

  const onReceiptClick = design => () => {
    const receipt =
      design === 'kitchen'
        ? generateURLPrintKitchen(order)
        : generateURLPrintReceipt(order);
    setReceiptModal(true);
    setDesignReceipt(receipt);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <OrderDetails order={order} />
        <OrderShippingInformation data={order?.shipping_info ?? {}} />
        <div className={styles.print}>
          {printers && (
            <>
              <button
                className={styles.printButton}
                onClick={onReceiptClick('receipt')}
              >
                Print receipt
              </button>
              <button
                className={styles.printButton}
                onClick={onReceiptClick('kitchen')}
              >
                Print kitchen
              </button>
              <ReceiptModal
                printers={printers}
                isOpen={receiptModal}
                printUrl={designReceipt}
                onClose={closeReceiptModal}
              />
            </>
          )}
        </div>
      </div>

      {/* TODO: fix layout structure and styles */}
      <div className={styles.inner}>
        {order?.order_items?.length > 0 && (
          <>
            <OrderProductList order={order} />
            {(refundedOrderItems.length > 0 || order?.is_tips_refunded) && (
              <OrderRefundList order={order} productList={refundedOrderItems} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Order;

import React, { useEffect, useState } from 'react';
import styles from './EditFoodModal.scss';
import PropTypes from 'prop-types';
import FullscreenModal from '../FullscreenModal';
import TimesIcon from '../../Icons/TimesIcon';
import FoodForm from '../../FoodForm/FoodForm';
import useSelector from 'hooks/useSelector';
import useAction from 'hooks/useAction';
import { foodActions } from 'models/food/slice';
import clsx from 'clsx';
import {
  foodsLoadingSelector,
  foodAdditionsSelector,
  foodAdditionsLoadingSelector,
} from 'models/food/selectors';
import RippleSpinner from '../../RippleSpinner';

const EditFoodModal = ({ isOpen, onClose, food }) => {
  const fetchAdditions = useAction(foodActions.fetchFoodAdditions);
  const updateFood = useAction(foodActions.updateFoodWithAdditions);
  const loading = useSelector(foodsLoadingSelector);
  const foodAdditionsLoading = useSelector(foodAdditionsLoadingSelector);
  const foodAdditions = useSelector(foodAdditionsSelector);
  const [isKeyboardActive, setKeyboardActive] = useState(false);

  const currentFoodAdditions =
    foodAdditions?.[food?.product_id ?? food?.product?.id];

  const selectedFoodAdditions = food?.food_order_items.reduce(
    (prev, current) => {
      return {
        ...prev,
        [current.product_id]: {
          ...current,
          price: current?.product_price,
        },
      };
    },
    {}
  );

  useEffect(() => {
    if (food && !currentFoodAdditions) {
      fetchAdditions({ id: food.product_id ?? food?.product?.id });
    }
  }, [food]);

  const onSubmit = data => {
    updateFood({
      id: food.id,
      orderId: food.order,
      newAdditions: data.additions,
      oldAdditions: selectedFoodAdditions,
      data: {
        ...food,
        ...data,
      },
    });
  };

  return (
    <FullscreenModal
      id="EditFoodModal"
      close={onClose}
      isOpen={isOpen}
      translucent
    >
      <div className={styles.container}>
        {foodAdditionsLoading ? (
          <RippleSpinner />
        ) : (
          <div
            className={clsx(
              styles.wrapper,
              isKeyboardActive && styles.activeWrapper
            )}
          >
            <div className={styles.header}>
              <h1 className={styles.title}>{food?.title}</h1>
              <button type="button" onClick={onClose}>
                <TimesIcon width="18" height="18" />
              </button>
            </div>
            <div className={styles.content}>
              <FoodForm
                onSubmit={onSubmit}
                price={food?.product_price}
                additions={currentFoodAdditions ?? []}
                defaultValues={{
                  comment: food?.comment,
                  additions: selectedFoodAdditions,
                }}
                disabled={loading || foodAdditionsLoading}
                dispatchKeyboardState={value => setKeyboardActive(value)}
              />
            </div>
          </div>
        )}
      </div>
    </FullscreenModal>
  );
};

EditFoodModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  food: PropTypes.object,
};
export default EditFoodModal;

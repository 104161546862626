import Dashboard from 'pages/Dashboard';
import Orders from 'pages/Orders';
import Order from 'pages/Order';
import Refund from 'pages/Refund';
import RefundTerminal from 'pages/RefundTerminal';
import RefundFinal from 'pages/RefundFinal';

import Customers from 'pages/Customers';
import Customer from 'pages/Customer';
import Tasters from 'pages/Tasters';

import Process from 'pages/Process';
import PaymentTerminal from 'pages/PaymentTerminal';
import PaymentCard from 'pages/PaymentCard';
import ErrorPage from 'pages/Error';
import HostConfirmation from 'pages/HostConfirmation';
import POSDashboard from './pages/POSDashboard';
import POSCheckout from 'pages/POSCheckout';
import Checkout from 'pages/Checkout';
import PaymentReceipt from 'pages/PaymentReceipt';
import POSCardPayment from './pages/POSPayment/POSCardPayment';
import PaymentCash from './pages/PaymentCash';
import LoginManager from './pages/LoginManager';
import ThermalPrintReceipt from './pages/ThermalPrintReceipt';
import ThermalPrintKitchen from './pages/ThermalPrintKitchen';
import RefundSuccess from './pages/RefundSuccess';
import { REFUND_STEPS } from './constants/refundOrders';
import PaymentAlternativeCard from './pages/PaymentAlternativeCard/PaymentAlternativeCard';
import PinLogin from './pages/PinLogin/PinLogin';
import SpaceSetup from './pages/SpaceSetup';
import ShiftEntry from './pages/ShiftEntry';
import Timesheet from './pages/Timesheet';
import Support from './pages/Support';

export default [
  {
    path: '/',
    component: LoginManager,
    exact: true,
  },
  {
    path: '/space-setup',
    component: SpaceSetup,
    exact: true,
  },
  {
    path: '/shift-entry',
    component: ShiftEntry,
    exact: true,
  },

  {
    path: '/email_confirmation/:hash',
    component: HostConfirmation,
  },
  {
    path: '/pin',
    component: PinLogin,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    private: true,
    routes: [
      {
        path: '/pos',
        exact: true,
        component: POSDashboard,
        private: true,
      },
      {
        path: '/orders',
        exact: true,
        component: Orders,
        private: true,
      },
      {
        path: '/orders/:id',
        exact: true,
        component: Order,
        private: true,
      },
      {
        path: '/orders/:id/refund',
        exact: true,
        component: Refund,
        private: true,
        refund: true,
        refundStep: REFUND_STEPS.MAIN,
      },
      {
        path: '/orders/:id/refund/success',
        exact: true,
        component: RefundSuccess,
        private: true,
        refund: true,
        refundStep: REFUND_STEPS.SUCCESS,
      },
      {
        path: '/orders/:id/refund/reader/select',
        exact: true,
        component: RefundTerminal,
        private: true,
      },
      {
        // reader/discovering
        path: '/orders/:id/refund/reader/processing',
        exact: true,
        component: Process,
        private: true,
      },
      {
        // refund/processing
        path: '/orders/:id/refund/final',
        exact: true,
        component: RefundFinal,
        private: true,
      },
      {
        path: '/customers',
        exact: true,
        component: Customers,
        private: true,
      },
      {
        path: '/customers/:id',
        exact: true,
        component: Customer,
        private: true,
      },
      {
        path: '/tasters',
        exact: true,
        component: Tasters,
        private: true,
      },
      {
        path: '/payment',
        exact: false,
        component: Checkout,
        private: true,
        routes: [
          {
            path: '',
            exact: true,
            component: POSCheckout,
            private: true,
          },
          {
            path: '/reader/select',
            exact: true,
            component: PaymentTerminal,
            private: true,
          },
          {
            path: '/reader/processing',
            exact: true,
            component: Process,
            private: true,
          },
          {
            path: '/card',
            exact: true,
            component: PaymentCard,
            private: true,
          },
          {
            path: '/receipt',
            exact: true,
            component: PaymentReceipt,
            private: true,
          },
          {
            path: '/cash',
            exact: true,
            component: PaymentCash,
            private: true,
          },
          {
            path: '/alternative-card',
            exact: true,
            component: PaymentAlternativeCard,
            private: true,
          },
          {
            path: '/total',
            exact: true,
            component: POSCardPayment,
            private: true,
          },
          {
            path: '/error',
            exact: true,
            component: ErrorPage,
            private: true,
          },
        ],
      },
      {
        path: '/timesheet',
        exact: true,
        component: Timesheet,
        private: true,
      },
      {
        path: '/support',
        exact: true,
        component: Support,
        private: true,
      },
    ],
  },
  {
    path: '/thermal/kitchen/:base64',
    component: ThermalPrintKitchen,
    exact: true,
  },
  {
    path: '/thermal/receipt/:base64',
    component: ThermalPrintReceipt,
    exact: true,
  },
];

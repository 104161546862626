import { toast } from 'react-toastify';

export const copy = async copyString => {
  try {
    await navigator.clipboard.writeText(copyString);
    toast.success('Copying to clipboard was successful!', {
      progressClassName: 'customProgressBarSuccess',
    });
  } catch (error) {
    toast.error(`Could not copy text: ${error.message}`, {
      progressClassName: 'customProgressBarError',
    });
  }
};

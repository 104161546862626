import { createSelector } from 'reselect';

export const paginatedOrdersSelector = createSelector(
  state => state,
  state => state.paginatedOrders
);

export const paginatedOrdersEntitiesSelector = createSelector(
  paginatedOrdersSelector,
  ({ orders }) => orders
);

export const paginatedOrdersLoadingSelector = createSelector(
  paginatedOrdersEntitiesSelector,
  ({ ordersLoading, ordersLoaded }) => !ordersLoaded && ordersLoading
);

export const paginatedOrdersOnlyLoadingSelector = createSelector(
  paginatedOrdersSelector,
  ({ ordersLoading }) => ordersLoading
);

export const paginatedOrdersPaginationSelector = createSelector(
  paginatedOrdersSelector,
  ({ pagination }) => pagination
);

export const paginatedOrdersFiltersSelector = createSelector(
  paginatedOrdersSelector,
  ({ filters }) => filters
);

export const detailedOrderSelector = createSelector(
  paginatedOrdersSelector,
  ({ detailedOrder }) => detailedOrder
);

export const detailedOrderLoadingSelector = createSelector(
  paginatedOrdersSelector,
  ({ detailedOrder, detailedOrderLoading }) =>
    detailedOrder === null || detailedOrderLoading
);

export const orderRefundPendingSelector = createSelector(
  paginatedOrdersSelector,
  ({ orderRefundPending }) => orderRefundPending
);

export const orderRefundStepSelector = createSelector(
  paginatedOrdersSelector,
  ({ orderRefundStep }) => orderRefundStep
);

export const timesheetTableHeadCols = [
  {
    id: 1,
    sortable: false,
    title: '',
    width: 4,
  },
  {
    id: 2,
    sortable: false,
    title: 'Date',
    minimal: true,
    width: 8,
  },
  {
    id: 3,
    sortable: false,
    title: 'Space',
    width: 15,
  },
  {
    id: 4,
    sortable: false,
    title: 'Start of Shift',
    width: 28,
  },
  {
    id: 5,
    sortable: false,
    title: 'End of Shift',
    width: 28,
  },
  {
    id: 6,
    sortable: false,
    title: 'Duration',
    width: 8,
  },
];

import { createSlice } from '@reduxjs/toolkit';
import actionTypes from 'utils/actionTypes';

const discountsSlice = createSlice({
  name: 'discounts',
  initialState: {
    discounts: {},
    loading: false,
    error: null,
  },
  reducers: {
    fetchDiscounts: state => {
      state.loading = true;
    },
    fetchDiscountsSuccess: (state, { payload }) => {
      state.loading = false;
      state.discounts = payload;
    },
    fetchDiscountsFailure: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const discountsActions = actionTypes(discountsSlice.actions);

export default discountsSlice.reducer;

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const monthsSelect = months.map((m, i) => ({ label: m, value: i }));
export const currentYear = new Date().getFullYear();
export const currentMonth = new Date().getMonth();
export const nextMonth = {
  label: months[currentMonth + 1],
  value: currentMonth + 1,
};
export const yearsSelect = [...new Array(13).keys()].map(i => ({
  label: currentYear + i,
  value: currentYear + i,
}));

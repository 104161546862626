import React, { useState, useEffect } from 'react';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { orderCreatingSelector } from 'models/desks/selectors';
import { desksActions } from 'models/desks/slice';

import { strings } from 'constants/strings';

import KeyboardInput from 'components/KeyboardInput';

import styles from './CreateOrderLayout.scss';

const CreateOrderLayout = () => {
  const orderCreating = useSelector(orderCreatingSelector);
  const addOrder = useAction(desksActions.createOrderRequest);

  const [value, setValue] = useState('');
  const [error, setError] = useState(null);

  const onChange = val => {
    if (!val) setError(strings.guestNotEmpty);
    if (val) setError(null);
    setValue(val);
  };

  useEffect(() => {
    setValue('');
    setError(null);
  }, []);

  const handleSubmit = evt => {
    evt.preventDefault();
    if (orderCreating) return;
    if (!value) {
      setError(strings.guestNameEmpty);
      return;
    }
    addOrder({ customer_name: value });
  };

  return (
    <div className={styles.root}>
      <form onSubmit={handleSubmit}>
        <KeyboardInput
          type="text"
          name="customer_name"
          value={value}
          caption="1"
          placeholder="Name"
          onChange={onChange}
          loading={orderCreating}
          autoOpenOnMount
        />
      </form>
      <div className={styles.error}>{error}</div>
    </div>
  );
};

export default CreateOrderLayout;

import React from 'react';
import styles from './SimplePageLayout.scss';
import PropTypes from 'prop-types';
import DenseHeader from '../DenseHeader';
import { userSelector } from '../../models/session/selectors';
import useSelector from '../../hooks/useSelector';

const SimplePageLayout = ({ children }) => {
  const user = useSelector(userSelector);

  return (
    <div className={styles.container}>
      <DenseHeader logo={user?.wine_club?.logo_url} />
      {children}
    </div>
  );
};

SimplePageLayout.propTypes = {
  children: PropTypes.any,
};
export default SimplePageLayout;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './ErrorLabel.scss';

const ErrorLabel = ({ children }) => {
  return <div className={styles.root}>{children}</div>;
};

ErrorLabel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorLabel;

import { push } from 'connected-react-router';
import { takeLatest, all, put, select } from 'redux-saga/effects';

import { ordersUrl, constructOrderUrl } from 'api/urls';
import { authenticatedRequest } from 'api';

import { paginatedDeskActions } from './slice';
import { warehouseIdSelector } from 'models/session/selectors';
import {
  getRefundBasePath,
  REFUND_PREFIX,
  REFUND_STEPS,
} from '../../constants/refundOrders';
import { toast } from 'react-toastify';

export function* fetchPaginatedOrdersSaga({ payload }) {
  try {
    const warehouse_id = yield select(warehouseIdSelector);
    const params = { ...payload, warehouse_id, per_page: 8 };

    const { data } = yield authenticatedRequest({
      url: ordersUrl,
      params,
    });
    yield put({
      type: paginatedDeskActions.fetchOrdersSuccess,
      payload: data,
    });
  } catch (err) {
    console.error(err);
    yield put({ type: paginatedDeskActions.fetchOrdersFailure });
  }
}

function* fetchDetailedOrderRequestSaga({ payload: { id } }) {
  try {
    const { data } = yield authenticatedRequest({
      url: constructOrderUrl({ id }),
    });

    yield put({
      type: paginatedDeskActions.fetchDetailedOrderSuccess,
      payload: data,
    });
  } catch (err) {
    console.error(err);
    yield put({ type: paginatedDeskActions.fetchDetailedOrderFailure });
    yield put(push('/dashboard/orders'));
  }
}

function* refundOrderSaga({ payload }) {
  try {
    const { orderId, data } = payload;
    const response = yield authenticatedRequest({
      url: `/tasting-room-master-api/v1/orders/${orderId}/refund`,
      method: 'POST',
      data,
    });
    yield put({
      type: paginatedDeskActions.refundOrderSuccess,
      payload: response.data,
    });
    const currentPrefix = REFUND_PREFIX[REFUND_STEPS.SUCCESS];
    yield put(push(`${getRefundBasePath(orderId)}${currentPrefix}`));
  } catch (error) {
    console.error(error);
    toast.error('Something went wrong while refunding', {
      progressClassName: 'customProgressBarError',
    });
    yield put({
      type: paginatedDeskActions.refundOrderFailure,
    });
    toast.error(error?.message ?? 'Error', {
      progressClassName: 'customProgressBarError',
    });
  }
}

export default function*() {
  yield all([
    takeLatest(
      paginatedDeskActions.fetchOrdersRequest,
      fetchPaginatedOrdersSaga
    ),
    takeLatest(
      paginatedDeskActions.fetchDetailedOrderRequest,
      fetchDetailedOrderRequestSaga
    ),
    takeLatest(paginatedDeskActions.changeFilters, fetchPaginatedOrdersSaga),
    takeLatest(paginatedDeskActions.refundOrder, refundOrderSaga),
  ]);
}

import React from 'react';
import PropTypes from 'prop-types';
import KeyboardInput from 'components/KeyboardInput';
import styles from './EmailStep.scss';

const EmailStep = ({ value, error, onChange, loading, buttonOptions }) => {
  return (
    <div className={styles.root}>
      <div className={styles.caption}>
        What email should we <br /> send your receipt to?
      </div>
      <div className={styles.content}>
        <KeyboardInput
          name="email"
          value={value}
          buttonOptions={buttonOptions}
          placeholder="Email"
          onChange={onChange}
          loading={loading}
          autoOpenOnMount
        />
      </div>
      <div className={styles.error}>{error}</div>
    </div>
  );
};

EmailStep.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  buttonOptions: PropTypes.object,
  error: PropTypes.string,
};

EmailStep.defaultProps = {
  error: null,
};

export default EmailStep;

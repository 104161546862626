import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

import useAction from 'hooks/useAction';
import { paginatedDeskActions } from 'models/paginatedOrders/slice';
import {
  detailedOrderSelector,
  orderRefundPendingSelector,
} from 'models/paginatedOrders/selectors';

import OrderInfoCard from 'components/OrderInfoCard';
import CustomerInfoCard from 'components/CustomerInfoCard';
import OrderItemsTable from 'components/OrderItemsTable';
import RefundTotalCard from 'components/RefundTotalCard';
import RippleSpinner from 'components/RippleSpinner';
import ChevronLeftIcon from 'components/Icons/ChevronLeftIcon';
import Textarea from 'components/Textarea';

import { getAvailableRefundedOrderItems } from 'utils/getAvailableRefundedOrderItems';
import formatPrice from 'utils/formatPrice';
import { PinAction } from 'constants/PinActions';

import styles from './Refund.scss';

const notificationForAlternativeRefund =
  'Please be careful. ' +
  "The money won't be automatically returned to the card, " +
  'you will need to return it through an alternative payment processor ' +
  'that was used for this order.';

const getFormattedDate = date => {
  return format(new Date(date), 'MMMM dd, yyyy');
};

const getSelectedTotal = (orderItems, tips, selectedItems) => {
  return (
    orderItems.reduce((previous, current) => {
      const { id: currentId, number } = current;
      const totalPrice = current?.after_return_number
        ? current?.after_return_total_price
        : current?.total_price;
      const selectedCount = selectedItems[currentId]?.number ?? 0;
      const amount = (totalPrice * selectedCount) / number;
      return previous + amount;
    }, 0) + tips
  );
};

const getPreparedOrderItemsForRefund = orderItems => {
  return orderItems
    .filter(([, value]) => value.number > 0)
    .map(([key, value]) => ({
      ...value,
      back_to_inventory: value?.back_to_inventory_number > 0,
      order_item_id: +key,
      number: value.number,
    }));
};

const Refund = () => {
  const { id } = useParams();
  const fetchOrder = useAction(paginatedDeskActions.fetchDetailedOrderRequest);
  const order = useSelector(detailedOrderSelector);
  const refundPending = useSelector(orderRefundPendingSelector);
  const [comment, setComment] = useState('');
  const [total, setTotal] = useState(0);
  const [shouldReturnTips, setShouldReturnTips] = useState(false);
  const [selectedOrderItems, setSelectedOrderItems] = useState({});

  const history = useHistory();

  const { availableOrderItems } = useMemo(
    () => getAvailableRefundedOrderItems(order?.order_items ?? []),
    [order]
  );

  useEffect(() => {
    fetchOrder({ id });
  }, [fetchOrder]);

  const onTableChange = data => {
    setSelectedOrderItems(data.orderItems);
    setShouldReturnTips(data.shouldReturnTips);
    setTotal(
      getSelectedTotal(
        availableOrderItems,
        data.shouldReturnTips ? order?.tips_amount : 0,
        data.orderItems
      )
    );
  };

  const onRefundClick = type => {
    const refundData = {
      orderId: id,
      data: {
        refund_type: type,
        refund_tips: shouldReturnTips,
        reason: 5,
        comment,
        order_items: getPreparedOrderItemsForRefund(
          Object.entries(selectedOrderItems)
        ),
      },
    };
    history.push('/pin', { pinAction: PinAction.REFUND, refundData });
  };

  const handleBackClick = () => {
    history.goBack();
  };

  const orderRefundType = () => {
    const status = order?.status.replace(/_/g, ' ');
    if (status === 'PAID BY CARD' && order?.is_paid_by_alternative_card) {
      return 'CARD_ALTERNATIVE';
    } else if (
      status === 'PAID BY CARD' &&
      !order?.is_paid_by_alternative_card
    ) {
      return 'CARD';
    }
    return 'CASH';
  };

  return (
    <>
      {refundPending ? (
        <div className={styles.pendingContainer}>
          <RippleSpinner className={styles.spinner} />
        </div>
      ) : (
        <div className={styles.container}>
          {order && (
            <>
              <div className={styles.header}>
                <div className={styles.back}>
                  <button onClick={handleBackClick} className={styles.backLink}>
                    <ChevronLeftIcon width="18" height="18" /> <span>Back</span>
                  </button>
                </div>
                <p className={styles.title}>Invoice #{order?.invoice_number}</p>
              </div>
              <div className={styles.wrapper}>
                <div className={styles.tableWrapper}>
                  <OrderItemsTable
                    orderItems={availableOrderItems}
                    onChange={onTableChange}
                    tipsAmount={order?.tips_amount}
                    isTipsReturned={order?.is_tips_refunded}
                    total={total}
                  />
                </div>
                <div className={styles.infoWrapper}>
                  <p className={styles.date}>
                    Ordered on {getFormattedDate(order?.created_at)}
                  </p>
                  <OrderInfoCard
                    className={styles.orderInfo}
                    textClassName={styles.orderInfoAlternativeText}
                    total={order?.total_price - order?.refund_amount}
                    paymentMethod={order?.status.replace(/_/g, ' ')}
                  />
                  {order.customer && <CustomerInfoCard {...order.customer} />}
                  <Textarea
                    className={styles.comment}
                    name="comment"
                    placeholder="Comment..."
                    value={comment}
                    onChange={event => setComment(event.target.value)}
                    required
                  />
                  <div className={styles.totalRefundWrapper}>
                    <div className={styles.totalRefund}>
                      <span>Total Refund</span>
                      <span>${formatPrice(total)}</span>
                    </div>
                    {order?.is_paid_by_alternative_card && total > 0 && (
                      <span className={styles.alternativeText}>
                        {notificationForAlternativeRefund}
                      </span>
                    )}
                  </div>
                  <div className={styles.totalWrapper}>
                    <RefundTotalCard
                      title="Process Refund"
                      total={total}
                      disabled={total === 0}
                      onClick={() => onRefundClick(orderRefundType())}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          <div className={styles.footer}>
            <div className={styles.breadcrumbs}>
              <Link className={styles.link} to="/dashboard/orders">
                Orders
              </Link>
              <span className={styles.linkSeparator}>/</span>
              <Link
                className={styles.activeLink}
                to={`/dashboard/orders/${id}/refund`}
              >
                {order?.invoice_number}
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Refund;

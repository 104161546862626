import React from 'react';
import PropTypes from 'prop-types';

import useInitialDataFetch from 'hooks/useInitialDataFetch';
import { useRouteMatch, Switch } from 'react-router-dom';
import useWarehouseChannel from 'hooks/ws/useWarehouseChannel';
import PrivateRoute from 'components/PrivateRoute';
import Header from 'components/Header';
import styles from './Dashboard.scss';
import { useSelector } from 'react-redux';
import { loginPendingSelector } from '../../models/session/selectors';
import RippleSpinner from '../../components/RippleSpinner';

const Dashboard = ({ routes }) => {
  const { path } = useRouteMatch();

  const loginPending = useSelector(loginPendingSelector);

  useInitialDataFetch();
  useWarehouseChannel();

  return (
    <>
      {loginPending && (
        <div className={styles.pendingContainer}>
          <RippleSpinner className={styles.spinner} />
        </div>
      )}
      <div className={styles.root}>
        <Header currentPage="Dashboard" />

        <Switch>
          {routes.map(route => (
            <PrivateRoute
              {...route}
              key={`${path}${route.path}`}
              path={`${path}${route.path}`}
              render={routeProps =>
                route.component && (
                  <route.component routes={route.routes} {...routeProps} />
                )
              }
            />
          ))}
        </Switch>
      </div>
    </>
  );
};

Dashboard.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default Dashboard;

import React, { memo } from 'react';
import styles from './OrderItemRow.scss';
import Checkbox from 'components/Checkbox';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import formatPrice from 'utils/formatPrice';
import TimesIcon from 'components/Icons/TimesIcon';
import OrderItemCheckbox from '../OrderItemCheckbox';
import { OrderItemsResourceTypes } from 'constants/orderItems';
import { ITEM_STATE_TYPES } from 'constants/refundOrders';

const OrderItemRow = ({
  title,
  number,
  total_price,
  resourcetype,
  selectedCount,
  backToInventoryCount,
  onChange,
  disabled,
}) => {
  const onChangeHandler = (value, isDefault = false) => {
    if (isDefault) {
      const selectedNumber = +value * number;
      onChange?.({
        number: selectedNumber,
        back_to_inventory_number:
          resourcetype === OrderItemsResourceTypes.PRODUCT_ORDER_ITEM
            ? selectedNumber
            : 0,
      });
      return;
    }
    // eslint-disable-next-line no-nested-ternary
    const addedNumber = value !== ITEM_STATE_TYPES.VOID ? (value ? 1 : -1) : 0;
    const addedInventoryNumber = value === ITEM_STATE_TYPES.VOID ? -1 : value;
    onChange?.({
      number: selectedCount + addedNumber,
      back_to_inventory_number: backToInventoryCount + addedInventoryNumber,
    });
  };

  return (
    <>
      {selectedCount === 0 ? (
        <tr
          className={clsx(
            styles.container,
            disabled && styles.disabledContainer
          )}
        >
          <td className={clsx(styles.cell, styles.checkboxCell)}>
            <div className={styles.checkbox}>
              <Checkbox
                disabled={disabled}
                name="default"
                onChange={event => onChangeHandler(event.target.checked, true)}
              />
            </div>
          </td>
          <td className={styles.cell}>{title}</td>
          <td className={clsx(styles.cell, styles.countCell)}>{number}</td>
          <td className={clsx(styles.cell, styles.priceCell)}>
            ${formatPrice(total_price)}
          </td>
        </tr>
      ) : (
        Array.from(Array(number).keys()).map(index => (
          <tr
            key={index.toString()}
            className={clsx(
              index === number - 1 && styles.lastContainer,
              disabled && styles.disabledContainer
            )}
          >
            <td className={clsx(styles.cell, styles.checkboxCell)}>
              <div className={styles.checkbox}>
                {index === 0 && (
                  <button
                    onClick={() => onChange({ number: 0 })}
                    className={styles.icon}
                    disabled={disabled}
                  >
                    <TimesIcon width="18" height="17" />
                  </button>
                )}
                {resourcetype === OrderItemsResourceTypes.PRODUCT_ORDER_ITEM ? (
                  <OrderItemCheckbox
                    disabled={disabled}
                    onChange={onChangeHandler}
                  />
                ) : (
                  <Checkbox
                    defaultChecked
                    name={`${title}-${index}`}
                    onChange={event => onChangeHandler(event.target.checked)}
                    disabled={disabled}
                  />
                )}
              </div>
            </td>
            <td className={styles.cell}>{title}</td>
            <td className={clsx(styles.cell, styles.countCell)}>1</td>
            <td className={clsx(styles.cell, styles.priceCell)}>
              ${formatPrice(total_price / number)}
            </td>
          </tr>
        ))
      )}
    </>
  );
};

OrderItemRow.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  total_price: PropTypes.number.isRequired,
  resourcetype: PropTypes.string,
  selectedCount: PropTypes.number,
  backToInventoryCount: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
export default memo(OrderItemRow);

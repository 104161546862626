import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import formatPrice from 'utils/formatPrice';
import { OrderItemsResourceTypes } from 'constants/orderItems';
import useAction from 'hooks/useAction';
import { desksActions } from 'models/desks/slice';

import styles from './ProductWine.scss';
import clsx from 'clsx';

const ProductWine = ({ product, disabled, image }) => {
  // Actions
  const createItem = useAction(desksActions.storeOrderItem);

  // Handlers
  const onBottlePurchase = useCallback(
    type => {
      createItem({
        number: 1,
        product_id: product.id,
        type,
      });
    },
    [product]
  );

  return (
    <div className={styles.root}>
      {product.can_be_a_sample && (
        <button
          key={`sample-${product.title}`}
          className={clsx(styles.wine, disabled && styles.disabled)}
          onClick={() =>
            onBottlePurchase(OrderItemsResourceTypes.PRODUCT_SAMPLE_ORDER_ITEM)
          }
          disabled={disabled}
        >
          <div className={styles.wineTitle}>Glass</div>
          <div className={styles.winePrice}>
            $ {formatPrice(product.sample_price)}
          </div>
        </button>
      )}
      <button
        key={product.title}
        className={clsx(styles.wine, disabled && styles.disabled)}
        onClick={() =>
          onBottlePurchase(OrderItemsResourceTypes.PRODUCT_ORDER_ITEM)
        }
        disabled={disabled}
      >
        {image && (
          <div className={styles.image}>
            <img src={image} alt="" height="60" />
          </div>
        )}
        <div className={styles.wineTitle}>Bottle</div>
        <div className={styles.winePrice}>$ {formatPrice(product.price)}</div>
      </button>
    </div>
  );
};

ProductWine.propTypes = {
  product: PropTypes.object,
  disabled: PropTypes.bool,
  image: PropTypes.string,
};

export default ProductWine;

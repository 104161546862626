/*eslint-disable*/

import React, { useEffect } from 'react';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { detailedOrderSelector } from 'models/paginatedOrders/selectors';
import { stripeActions } from 'models/stripe/slice';
import circlesIcon from 'images/icon-circles.png';

import styles from './RefundFinal.scss';
import { toast } from 'react-toastify';

const RefundFinal = () => {
  const makeRefund = useAction(stripeActions.makeRefund);
  const captureRefund = useAction(stripeActions.captureRefund);
  const order = useSelector(detailedOrderSelector);

  useEffect(() => {
    // only terminal
    makeRefund();
    document.addEventListener('Refund', e => {
      const message = e.detail;
      if (message.status === 'success') {
        captureRefund({
          payment_intent_id: order.charges[0].payment_intent,
        });
      } else {
        toast.error(message.message, {
          progressClassName: 'customProgressBarError',
        });
      }
    });
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.line}>
        <p className={styles.text}>Follow the instructions on the terminal</p>
      </div>
      <img className={styles.icon} src={circlesIcon} alt="circles icon" />
    </div>
  );
};

export default RefundFinal;

export const getRedactedValues = (defaultObject, redactedObject) => {
  return Object.keys(redactedObject).reduce((prev, current) => {
    if (defaultObject?.[current] === redactedObject?.[current]) {
      return prev;
    }

    if (!defaultObject?.[current]) {
      prev[current] = redactedObject[current];
      return prev;
    }

    if (current in redactedObject) {
      prev[current] = redactedObject[current];
      return prev;
    }

    return prev;
  }, {});
};

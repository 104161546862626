import { takeLatest, all, put, call } from 'redux-saga/effects';
import authenticatedRequest from 'api';
import {
  categoriesUrl,
  foodsUrl,
  allergiesUrl,
  constructFoodAdditionsUrl,
} from 'api/urls';
import { foodActions } from './slice';
import { createOrderItemSaga, removeOrderItemSaga } from '../desks/sagas';
import { desksActions } from '../desks/slice';

function* fetchCategoriesSaga() {
  try {
    const { data } = yield authenticatedRequest({
      url: categoriesUrl,
      method: 'GET',
    });

    yield put({
      type: foodActions.fetchCategoriesSuccess,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: foodActions.fetchCategoriesFailure,
    });
  }
}

function* fetchFoodsSaga({ payload }) {
  try {
    const params = { per_page: 12, ...payload };

    const { data } = yield authenticatedRequest({
      url: foodsUrl,
      method: 'GET',
      params,
    });

    yield put({ type: foodActions.fetchFoodsSuccess, payload: data });
  } catch (err) {
    yield put({ type: foodActions.fetchFoodsFailure });
  }
}

function* fetchFoodPairingsSaga({ payload }) {
  try {
    const { data } = yield authenticatedRequest({
      url: foodsUrl,
      method: 'GET',
      params: {
        wine_id: payload,
      },
    });
    yield put({ type: foodActions.fetchFoodPairingsSuccess, payload: data });
  } catch (err) {
    yield put({ type: foodActions.fetchFoodPairingsFailure });
  }
}

function* fetchFoodAllergiesSaga() {
  try {
    const { data } = yield authenticatedRequest({
      url: allergiesUrl,
      method: 'GET',
    });

    yield put({ type: foodActions.fetchFoodAllergiesSuccess, payload: data });
  } catch (err) {
    yield put({ type: foodActions.fetchFoodAllergiesFailure });
  }
}

function* createFoodWithAdditions({ payload }) {
  try {
    const id = yield call(createOrderItemSaga, { payload });

    const additionRequests = yield payload.additions.map(addition => {
      const payloadObject = {
        number: 1,
        resourcetype: payload.resourcetype,
        product_id: addition.id,
        parent_food_order_item_id: id,
        order_id: payload.order_id,
      };

      return call(createOrderItemSaga, { payload: payloadObject });
    });

    yield all([...additionRequests]);
    yield put({
      type: foodActions.createFoodWithAdditionsSuccess,
    });
  } catch (err) {
    console.error(err);
  }
}

function* removeFoodWithAdditionsSaga({ payload }) {
  try {
    yield put({
      type: desksActions.removeOrderItemRequest,
      payload: {
        orderId: payload.order,
        orderItemId: payload.id,
      },
    });
    const additionRequests = yield (payload?.food_order_items ?? []).map(
      addition => {
        return put({
          type: desksActions.removeOrderItemRequest,
          payload: {
            orderId: addition.order,
            orderItemId: addition.id,
          },
        });
      }
    );
    yield all([...additionRequests]);
    yield put({
      type: foodActions.removeFoodWithAdditionsSuccess,
    });
  } catch (error) {
    console.error(error);
  }
}

function* updateFoodWithAdditionsSaga({ payload }) {
  try {
    const { id, orderId, data, newAdditions, oldAdditions } = payload;
    yield put({
      type: desksActions.updateOrderItemRequest,
      payload: {
        orderItemId: id,
        order_id: orderId,
        ...data,
      },
    });
    const newAdditionsRequest = Object.entries(newAdditions)
      .filter(([key]) => !(key in oldAdditions))
      .map(([, value]) => {
        return createOrderItemSaga({
          payload: {
            number: 1,
            product_id: value.id,
            parent_food_order_item_id: id,
            order_id: orderId,
            resourcetype: data.resourcetype,
          },
        });
      });

    const removeAdditionsRequest = Object.entries(oldAdditions)
      .filter(([key]) => !(key in newAdditions))
      .map(([, value]) =>
        removeOrderItemSaga({
          payload: {
            orderId,
            orderItemId: value.id,
          },
        })
      );
    const response = yield all([
      ...newAdditionsRequest,
      ...removeAdditionsRequest,
    ]);
    if (response.some(item => !item)) {
      throw new Error('Request failed');
    }
    yield put({
      type: foodActions.updateFoodWithAdditionsSuccess,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: foodActions.updateFoodWithAdditionsFailure,
    });
  }
}

function* fetchFoodAdditionsSaga({ payload }) {
  try {
    const { data } = yield authenticatedRequest({
      url: constructFoodAdditionsUrl(payload.id),
      method: 'GET',
    });
    yield put({
      type: foodActions.fetchFoodAdditionsSuccess,
      payload: {
        id: payload.id,
        additions: data,
      },
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: foodActions.fetchFoodAdditionsFailure,
    });
  }
}

export default function*() {
  yield all([
    takeLatest(foodActions.fetchCategories, fetchCategoriesSaga),
    takeLatest(foodActions.fetchFoods, fetchFoodsSaga),
    takeLatest(foodActions.fetchFoodPairings, fetchFoodPairingsSaga),
    takeLatest(foodActions.fetchFoodAllergies, fetchFoodAllergiesSaga),
    takeLatest(foodActions.createFoodWithAdditions, createFoodWithAdditions),
    takeLatest(
      foodActions.removeFoodWithAdditions,
      removeFoodWithAdditionsSaga
    ),
    takeLatest(
      foodActions.updateFoodWithAdditions,
      updateFoodWithAdditionsSaga
    ),
    takeLatest(foodActions.fetchFoodAdditions, fetchFoodAdditionsSaga),
  ]);
}

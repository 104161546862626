const weekDayAliases = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const monthAliases = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const formatCurrentDate = (date, locale = 'en-US') => ({
  day: date.getDate(),
  month: date.getMonth(),
  year: date.getFullYear(),
  dayAlias: weekDayAliases[date.getDay()],
  monthAlias: monthAliases[date.getMonth()],
  time: date.toLocaleString(locale, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }),
});

export const getTimeSince = (date, withAgo) => {
  if (!date) {
    return '-';
  }
  const seconds = Math.floor(
    new Date().getTime() / 1000 - new Date(date).getTime() / 1000
  );

  let interval = seconds / 31536000;

  if (interval >= 1) {
    return withAgo
      ? `${Math.round(interval)} years ago`
      : `${Math.round(interval)} years`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return withAgo
      ? `${Math.round(interval)} months ago`
      : `${Math.round(interval)} months`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return withAgo
      ? `${Math.round(interval)} days ago`
      : `${Math.round(interval)} days`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return withAgo
      ? `${Math.round(interval)} hours ago`
      : `${Math.round(interval)} hours`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return withAgo
      ? `${Math.round(interval)} minutes ago`
      : `${Math.round(interval)} minutes`;
  }
  return withAgo
    ? `${Math.round(seconds)} seconds ago`
    : `${Math.round(seconds)} seconds`;
};

export default formatCurrentDate;

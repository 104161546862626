/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import actionTypes from 'utils/actionTypes';
import { getOrderedInventory } from 'utils/getOrderedInventory';

const inventorySlice = createSlice({
  name: 'inventory',
  initialState: {
    inventory: [],
    inventoryByProducts: {},

    inventoryLoading: false,
  },
  reducers: {
    fetchInventoryRequest(state) {
      state.inventoryLoading = true;
    },
    fetchInventorySuccess(state, { payload }) {
      state.inventoryLoading = false;
      state.inventory = getOrderedInventory(payload.inventory);
      state.inventoryByProducts = state.inventory.reduce((result, current) => {
        if (['FoodWrapper', 'MerchandiseWrapper'].includes(current.type)) {
          const products = Object.values(current?.products).map(
            item => item?.products
          );
          return {
            ...result,
            ...products.reduce((prev, curr) => ({ ...prev, ...curr }), {}),
          };
        }
        return { ...result, ...current.products };
      }, {});
    },
    fetchInventoryFailure(state) {
      state.inventoryLoading = false;
    },

    updateInventoryProduct(state, { payload }) {
      state.inventoryByProducts[payload.id] = payload.data;
    },
  },
});

export const inventoryActions = actionTypes(inventorySlice.actions);

export default inventorySlice.reducer;

import React from 'react';
import { get } from 'lodash';
import clsx from 'clsx';
import styles from './Slider.scss';

export const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dotsClass: styles.controls,
  appendDots: dots => (
    <div className={styles.controls}>
      <div className={styles.sliderLines}>
        {dots.map(dot => {
          const onClick = get(dot, 'props.children.props.onClick', null);
          const className = clsx(
            styles.dot,
            dot.props.className === 'slick-active' && styles.dotActive
          );
          return (
            <div key={dot.key} onClick={onClick} className={className}>
              <div className={styles.dotInner} />
            </div>
          );
        })}
      </div>
    </div>
  ),
};

export const customersTableHeadCols = [
  {
    id: 1,
    sortable: false,
    sortingField: 'id',
    title: 'Customer #',
    minimal: true,
    width: 10,
  },
  {
    id: 2,
    sortable: true,
    sortingField: 'full_name',
    title: 'Customer Name',
    width: 25,
  },
  {
    id: 3,
    sortable: false,
    sortingField: 'membership',
    title: 'Membership',
    width: 20,
  },
  {
    id: 4,
    sortable: true,
    sortingField: 'total_sum_of_paid_orders',
    title: 'Lifetime Value',
    width: 20,
  },
  {
    id: 5,
    sortable: true,
    sortingField: 'last_order_at',
    title: 'Last Order',
    width: 20,
  },
  { id: 6, sortable: false, title: '' },
];

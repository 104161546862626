import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import RefundProtectedRoute from '../RefundProtectedRoute';
import { refreshKey, roleKey, brandKey } from 'constants/localStorage';

const PrivateRoute = route => {
  const isHostAuthorized =
    localStorage.getItem(roleKey) === 'host' &&
    !!localStorage.getItem(refreshKey) &&
    !!localStorage.getItem(brandKey);

  const isManagerAuthorized =
    localStorage.getItem(roleKey) === 'manager' &&
    !!localStorage.getItem(refreshKey);

  if (!isManagerAuthorized && !isHostAuthorized) {
    return <Redirect to="/" />;
  }

  if (route?.refund) {
    return (
      <RefundProtectedRoute route={route}>
        <route.component />
      </RefundProtectedRoute>
    );
  }

  return (
    <Route
      path={route.path}
      render={routeProps =>
        route.component && (
          <route.component routes={route.routes} {...routeProps} />
        )
      }
    />
  );
};

export default PrivateRoute;

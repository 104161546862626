import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import useSelector from 'hooks/useSelector';
import useAction from 'hooks/useAction';
import { Redirect, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CSSTransition } from 'react-transition-group';
import PrivateRoute from 'components/PrivateRoute';
import {
  currentOrderIdSelector,
  currentDeskIdSelector,
} from 'models/desks/selectors';
import { desksActions } from 'models/desks/slice';
import strings from 'constants/strings';
import CheckoutContext from './context';

const Checkout = ({ routes }) => {
  const { path } = useRouteMatch();
  const { state } = useLocation();

  const currentOrderId = useSelector(currentOrderIdSelector);
  const currentDeskId = useSelector(currentDeskIdSelector);

  const setOrderTips = useAction(desksActions.changeOrderTipsRequest);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [isJoint] = useState(!!state?.isJoint);

  const [paidOrdersList, updatePaidOrdersList] = useState([currentOrderId]);

  const handleCancelCheckout = useCallback(() => {
    if (currentOrderId) {
      setOrderTips({
        tips: 0,
        deskId: currentDeskId,
        orderId: currentOrderId,
      });
    }
  }, [currentDeskId, currentOrderId]);

  if (!currentOrderId) {
    toast.error(strings.orderNotSelected, {
      progressClassName: 'customProgressBarError',
    });
    return <Redirect to="/dashboard/pos" />;
  }

  return (
    <CheckoutContext.Provider
      value={{
        isJoint,
        selectedPaymentMethod,
        paidOrdersList,
        setSelectedPaymentMethod,
        updatePaidOrdersList,
        handleCancelCheckout,
      }}
    >
      <Switch>
        {routes.map(route => (
          <PrivateRoute
            {...route}
            key={`${path}${route.path}`}
            path={`${path}${route.path}`}
            render={routeProps =>
              route.component && (
                <CSSTransition
                  in
                  appear
                  timeout={200}
                  classNames="fade"
                  unmountOnExit
                >
                  <route.component routes={route.routes} {...routeProps} />
                </CSSTransition>
              )
            }
          />
        ))}
      </Switch>
    </CheckoutContext.Provider>
  );
};

Checkout.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default Checkout;

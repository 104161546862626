import React, { useEffect, useState } from 'react';
import styles from './AddressField.scss';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import { getParsedAddress } from 'utils/getParsedAddress';

const AddressField = ({
  model,
  inputMode,
  onSelect,
  onFocus,
  onBlur,
  onChange,
  name,
  inputValue,
  className,
}) => {
  const [isTouched, setIsTouched] = useState(false);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'ca' },
    },
    debounce: 300,
  });

  const parseAddress = addressValue => {
    const parsedAddress = getParsedAddress(addressValue);
    onSelect?.(parsedAddress);
  };

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  const handleInput = e => {
    setIsTouched(true);
    setValue(e.target.value);
    // eslint-disable-next-line no-unused-expressions
    onChange && onChange(e);
  };

  const handleSelect = ({ description }) => async () => {
    setValue(description, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address: description });
      parseAddress(results[0].address_components);
    } catch (error) {
      console.error(error);
    }
  };

  const renderSuggestions = () =>
    data.map(suggestion => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id}>
          <button
            className={styles.dropdownItem}
            onClick={handleSelect(suggestion)}
          >
            {main_text} {secondary_text}
          </button>
        </li>
      );
    });

  return (
    <div ref={ref} className={clsx(styles.container, className)}>
      <label className={styles.inputContainer}>
        <input
          className={styles.input}
          name={name}
          value={
            Object.keys(model).length !== 0 && !isTouched
              ? [
                  model?.line1,
                  model?.city,
                  model?.state,
                  model?.postal_code,
                  model?.country,
                ]
                  .filter(Boolean)
                  .join(', ')
              : value
          }
          inputMode={inputMode}
          disabled={!ready}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={handleInput}
          placeholder="Address (country, state, city, postal code)"
        />
      </label>
      {status === 'OK' && (
        <ul className={styles.dropdown}>{renderSuggestions()}</ul>
      )}
    </div>
  );
};

AddressField.propTypes = {
  inputMode: PropTypes.string,
  model: PropTypes.object,
  onSelect: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  inputValue: PropTypes.string,
  className: PropTypes.string,
};

AddressField.defaultProps = {
  model: {},
};
export default AddressField;

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import useSelector from '../../hooks/useSelector';
import { orderRefundStepSelector } from '../../models/paginatedOrders/selectors';
import { REFUND_PREFIX } from '../../constants/refundOrders';

const RefundProtectedRoute = ({ route, ...props }) => {
  const currentStep = useSelector(orderRefundStepSelector);
  const { id } = route.computedMatch.params;
  const { path } = route;
  const basePath = `/dashboard/orders/${id}/refund`;

  if (route.refundStep > currentStep) {
    const currentPrefix = REFUND_PREFIX[currentStep];
    return <Redirect to={`${basePath}${currentPrefix}`} exact />;
  }

  return <Route path={path} {...props} />;
};

RefundProtectedRoute.propTypes = {
  route: PropTypes.object.isRequired,
};
export default RefundProtectedRoute;

import React from 'react';
import styles from './Switch.scss';
import PropTypes from 'prop-types';

const Switch = ({ onChange, disabled }) => {
  return (
    <label className={styles.container}>
      <input
        onChange={event => onChange?.(event.target.checked)}
        className={styles.input}
        type="checkbox"
        disabled={disabled}
      />
      <div className={styles.box} />
    </label>
  );
};

Switch.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
export default Switch;

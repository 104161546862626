import { SORTING_DIRECTION_TYPES } from '../../../../constants/sorting';

export const ordersTableHeadCols = [
  {
    id: 1,
    sortable: false,
    sortingField: 'invoice_number',
    title: 'Invoice Number',
    minimal: true,
  },
  {
    id: 2,
    sortable: true,
    sortingField: 'paid_at',
    title: 'Paid at',
    initialSortType: SORTING_DIRECTION_TYPES.DESC,
  },
  {
    id: 3,
    sortable: false,
    sortingField: 'name',
    title: 'Billing Name',
  },
  {
    id: 4,
    sortable: true,
    sortingField: 'total_price',
    title: 'Total',
    minimal: true,
  },
  {
    id: 5,
    sortable: false,
    sortingField: 'status',
    title: 'Status',
    minimal: true,
  },
  { id: 6, sortable: false, title: '' },
];

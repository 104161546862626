import { createSelector } from 'reselect';
import { chain } from 'lodash';

const selectSelf = state => state.tiers;

export const tierActionSuccessSelector = createSelector(
  selectSelf,
  ({ tierActionSuccess }) => tierActionSuccess
);

export const tiersStateSelector = createSelector(
  state => state,
  state => state.tiers
);

export const tiersSelector = createSelector(
  tiersStateSelector,
  ({ tiers }) => tiers
);

export const tiersGrouppedSelector = createSelector(
  tiersStateSelector,
  ({ tiers }) =>
    chain(tiers)
      .groupBy('title')
      .map((value, key) => ({ title: key, variations: value }))
      .value()
);

export const selectedTierIdSelector = createSelector(
  tiersStateSelector,
  ({ selectedTierId }) => selectedTierId
);

export const togglePendingSelector = createSelector(
  tiersStateSelector,
  ({ tierPending }) => tierPending
);

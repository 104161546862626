import React from 'react';
// import { useHistory } from 'react-router-dom';
import styles from './ReturnServerModal.scss';
import FullscreenModal from '../FullscreenModal';
import PropTypes from 'prop-types';
// import CheckoutContext from 'pages/Checkout/context';
// import { PAYMENT_METHODS } from 'pages/Checkout/constants';

const ReturnServerModal = ({ isOpen, setIsOpen, onSubmit }) => {
  // const history = useHistory();
  // const { selectedPaymentMethod } = useContext(CheckoutContext);

  const handleCompleteTransaction = () => {
    onSubmit();
    // history.push('/dashboard/payment/alternative-card');
  };

  return (
    <FullscreenModal id="ReturnServerModal" isOpen={isOpen}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h1 className={styles.title}>Return to your server</h1>
          <div className={styles.buttons}>
            <button onClick={() => setIsOpen(false)} className={styles.button}>
              Cancel
            </button>
            {/* // TODO: uncomment when reader is ready */}
            {/* <button onClick={onSubmit} className={styles.button}>
              Continue
            </button> */}
            <button
              onClick={handleCompleteTransaction}
              className={styles.button}
            >
              Continue
              {/* {selectedPaymentMethod === PAYMENT_METHODS.DEBIT
                ? 'Select Terminal'
                : 'Continue'} */}
              {/* {selectedPaymentMethod === PAYMENT_METHODS.DEBIT && (
                <span className={styles.buttonSmall}>
                  with alternative payment processor
                </span>
              )} */}
            </button>
          </div>
        </div>
      </div>
    </FullscreenModal>
  );
};

ReturnServerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ReturnServerModal;

const IDLE_TIMEOUT_MINUTES = 2;
export const IDLE_TIMEOUT_MS = IDLE_TIMEOUT_MINUTES * 60 * 1000;

const IDLE_TIMEOUT_TERMINAL_MINUTES = 10;
export const IDLE_TIMEOUT_TERMINAL_MS =
  IDLE_TIMEOUT_TERMINAL_MINUTES * 60 * 1000;

const FETCH_TASTERS_MINUTES = 1;
export const FETCH_TASTERS_MS = FETCH_TASTERS_MINUTES * 60 * 1000;

const CACHE_VALID_MINUTES = 15;
export const CACHE_VALID_MS = CACHE_VALID_MINUTES * 60 * 1000;

import React from 'react';
import PropTypes from 'prop-types';

const DiscoverIcon = ({ fill, width, height, className }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 61 11"
      fill="none"
      fillOpacity={0.5}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.81334 7.93577C5.23067 8.45345 4.47372 8.67938 3.27554 8.67938H2.77793V2.49323H3.27554C4.47372 2.49323 5.20094 2.70418 5.81334 3.24993C6.4548 3.81168 6.84053 4.68253 6.84053 5.57856C6.84053 6.47664 6.4548 7.37403 5.81334 7.93577ZM3.64709 0.908203H0.924805V10.263H3.63274C5.07247 10.263 6.11211 9.92875 7.02484 9.18294C8.10944 8.29983 8.75089 6.9688 8.75089 5.59217C8.75089 2.83143 6.65449 0.908203 3.64709 0.908203Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.729 10.263H11.6855V0.908203H9.729V10.263Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1454 4.30848C15.0409 3.90561 14.7166 3.63991 14.7166 3.13742C14.7166 2.55173 15.2944 2.10658 16.0878 2.10658C16.6393 2.10658 17.0925 2.32983 17.572 2.85971L18.5351 1.61695C17.7436 0.934682 16.7969 0.585938 15.7622 0.585938C14.0926 0.585938 12.8191 1.72858 12.8191 3.25074C12.8191 4.5319 13.412 5.18778 15.1408 5.80104C15.8612 6.05153 16.228 6.21846 16.4131 6.33076C16.7809 6.56737 16.965 6.90242 16.965 7.29294C16.965 8.04658 16.3572 8.6047 15.5359 8.6047C14.6579 8.6047 13.9509 8.17207 13.527 7.36431L12.3379 8.49291C13.186 9.71961 14.2043 10.2634 15.605 10.2634C17.5178 10.2634 18.8596 9.01011 18.8596 7.20956C18.8596 5.73187 18.2393 5.06313 16.1454 4.30848Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5117 5.43138C19.5117 8.15292 21.6555 10.2634 24.4142 10.2634C25.194 10.2634 25.8619 10.1106 26.6856 9.72383V7.59773C25.9614 8.3203 25.3199 8.61178 24.4985 8.61178C22.6738 8.61178 21.379 7.29294 21.379 5.41791C21.379 3.64038 22.7148 2.23785 24.4142 2.23785C25.2783 2.23785 25.9323 2.54516 26.6856 3.27969V1.15459C25.8903 0.752476 25.2364 0.585938 24.4569 0.585938C21.7124 0.585938 19.5117 2.73898 19.5117 5.43138Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.0248 7.246L38.4922 0.908203H36.4683L40.4989 10.5856H41.4957L45.5987 0.908203H43.5905L41.0248 7.246Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.5771 10.263H51.7945V8.67938H48.4153V6.15409H51.6698V4.56957H48.4153V2.49323H51.7945V0.908203H46.5771V10.263Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.4602 5.21501H54.9258V2.3818H55.4892C56.6285 2.3818 57.2479 2.8578 57.2479 3.76829C57.2479 4.70873 56.6285 5.21501 55.4602 5.21501ZM59.1303 3.66996C59.1303 1.91873 57.9209 0.908203 55.8112 0.908203H53.0986V10.263H54.9258V6.50505H55.1643L57.6966 10.263H59.9465L56.9937 6.322C58.3717 6.04249 59.1303 5.10239 59.1303 3.66996Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.1204 5.5856C37.1204 8.34729 34.8571 10.5859 32.0658 10.5859C29.274 10.5859 27.0117 8.34729 27.0117 5.5856C27.0117 2.82407 29.274 0.585938 32.0658 0.585938C34.8571 0.585938 37.1204 2.82407 37.1204 5.5856Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.9652 1.35327H59.9156V1.09173H59.9685C60.1141 1.09173 60.19 1.13415 60.19 1.22022C60.19 1.30878 60.1131 1.35327 59.9652 1.35327ZM60.4845 1.21671C60.4845 1.01725 60.3149 0.908203 60.017 0.908203H59.6206V1.9082H59.9156V1.52045L60.261 1.9082H60.6206L60.2147 1.49583C60.3887 1.45797 60.4845 1.35554 60.4845 1.21671Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.9479 1.76096C59.6602 1.76096 59.4247 1.52562 59.4247 1.23194C59.4247 0.937189 59.6574 0.702001 59.9479 0.702001C60.2344 0.702001 60.4679 0.942702 60.4679 1.23194C60.4679 1.52317 60.2344 1.76096 59.9479 1.76096ZM59.9503 0.585938C59.5849 0.585938 59.2944 0.872727 59.2944 1.23087C59.2944 1.58916 59.588 1.87626 59.9503 1.87626C60.3069 1.87626 60.5988 1.58595 60.5988 1.23087C60.5988 0.87778 60.3069 0.585938 59.9503 0.585938Z"
        fill={fill}
      />
    </svg>
  );
};

DiscoverIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DiscoverIcon.defaultProps = {
  fill: '#EDEAE3',
  width: '61',
  height: '11',
};

export default DiscoverIcon;

import React from 'react';
import styles from './ProductCounter.scss';
import PropTypes from 'prop-types';
import formatPrice from '../../utils/formatPrice';
import clsx from 'clsx';

const ProductCounter = ({
  title,
  price,
  count,
  onChange,
  isAddedButtonDisabled,
  className,
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.titleWrapper}>
        <span className={styles.title}>{title}</span>
        {price && <span className={styles.price}>$ {formatPrice(price)}</span>}
      </div>

      <div className={styles.counterWrapper}>
        <button
          className={styles.button}
          onClick={() => onChange(count - 1)}
          disabled={count === 0}
        >
          -
        </button>
        <p className={styles.count}>{count}</p>
        <button
          className={styles.button}
          onClick={() => onChange(count + 1)}
          disabled={isAddedButtonDisabled}
        >
          +
        </button>
      </div>
    </div>
  );
};

ProductCounter.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.number,
  count: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  isAddedButtonDisabled: PropTypes.bool,
  className: PropTypes.string,
};
export default ProductCounter;

/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import actionTypes from 'utils/actionTypes';

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    showLoading: true,
    loadingMessage: 'Hello!',
  },
  reducers: {
    openLink: state => state,
    showLoading(state, { payload }) {
      state.showLoading = true;
      state.loadingMessage = payload;
    },
    hideLoading(state) {
      state.showLoading = false;
      state.loadingMessage = '';
    },
  },
});

export const commonActions = actionTypes(commonSlice.actions);

export default commonSlice.reducer;

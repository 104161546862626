import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import config from 'config';
import LoadingOverlay from '@speedy4all/react-loading-overlay';
import { sessionActions } from 'models/session/slice';
import AppRouter from 'components/AppRouter';
import ToastContainer from 'components/ToastContainer';
import { sessionLoadingSelector } from 'models/session/selectors';
import { loadingStateSelector } from 'models/common/selectors';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/normalize.scss';
import 'styles/index.css';
import styles from './App.scss';
import { KeyboardProvider } from 'context/KeyboardContext/KeyboardContext';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const getManifestVersion = () =>
  fetch('/manifest.json', { cache: 'no-cache' })
    .then(response => response.json())
    .then(manifest => manifest?.version);

const App = ({ routes }) => {
  const fetchSession = useAction(sessionActions.fetchSessionRequest);
  const sessionLoading = useSelector(sessionLoadingSelector);
  const loadingState = useSelector(loadingStateSelector);

  const [manifestVersion, setManifestVersion] = useState();
  const [updating, setUpdating] = useState(false);

  // Cache busting
  const checkManifest = () =>
    !updating &&
    getManifestVersion().then(currentManifestVersion => {
      if (!manifestVersion) {
        setManifestVersion(currentManifestVersion);
      } else if (currentManifestVersion !== manifestVersion) {
        toast.error(
          <>
            <p className={styles.updateTitle}>
              <span role="img" aria-label="sparkles">
                ✨
              </span>{' '}
              Update Available
            </p>
            <p>
              A new version of Cellar POS is available. Click the button below
              to update.
            </p>
            <div>
              <button
                onClick={() => window.location.reload()}
                type="submit"
                className={styles.update}
              >
                Update
              </button>
            </div>
          </>,
          {
            progressClassName: 'customProgressBarError',
            autoClose: false,
            closeOnClick: false,
            pauseOnFocusLoss: false,
            pauseOnHover: false,
          }
        );
        setUpdating(true);
      }
    });
  const location = useLocation();
  useEffect(() => {
    checkManifest();
    const interval = setInterval(() => checkManifest(), 30000);
    return () => {
      clearInterval(interval);
    };
  }, [location, manifestVersion, updating]);

  useEffect(() => {
    fetchSession();
  }, [fetchSession]);

  if (sessionLoading) return null;

  return (
    <>
      <ToastContainer />
      <KeyboardProvider>
        <LoadingOverlay
          active={loadingState.showLoading}
          spinner
          text={loadingState.loadingMessage}
          classNamePrefix="loader_"
        >
          <div className={styles.app}>
            <Helmet {...config.app} />
            <AppRouter routes={routes} />
          </div>
        </LoadingOverlay>
      </KeyboardProvider>
    </>
  );
};

App.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default App;

import { format } from 'date-fns';

// round date forward to the nearest 15 minutes
export const startDate = () => {
  const now = new Date();
  const minutes = now.getMinutes();
  const remainder = minutes % 15;
  const roundedDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    now.getHours(),
    now.getMinutes() + (15 - remainder)
  );
  return roundedDate;
};

// round date backward to the previous 15 minutes
export const endDate = () => {
  const now = new Date();
  const minutes = now.getMinutes();
  const remainder = minutes % 15;
  const roundedDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    now.getHours(),
    now.getMinutes() - remainder
  );
  return roundedDate;
};

export const isTimeInPast = (date, time) => {
  const allowedDate = new Date().setMinutes(new Date().getMinutes() - 5);

  let hours = time.amOrPm === 'pm' ? time.hours + 12 : time.hours;
  if (time.hours === 12) {
    hours = time.amOrPm === 'pm' ? 12 : 0;
  }
  const selectedDateTime = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    hours,
    time.minutes
  );
  return selectedDateTime <= allowedDate;
};

export const isTimeInFuture = (date, time) => {
  const allowedDate = new Date().setMinutes(new Date().getMinutes() + 5);

  let hours = time.amOrPm === 'pm' ? time.hours + 12 : time.hours;
  if (time.hours === 12) {
    hours = time.amOrPm === 'pm' ? 12 : 0;
  }
  const selectedDateTime = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    hours,
    time.minutes
  );
  return selectedDateTime >= allowedDate;
};

export const convertTimeObjectToDate = (date, time) => {
  let hours = time.amOrPm === 'pm' ? time.hours + 12 : time.hours;
  if (time.hours === 12) {
    hours = time.amOrPm === 'pm' ? 12 : 0;
  }
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    hours,
    time.minutes
  );
};

export const getYesterday = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
};

export const getDateText = date => {
  return `${format(date, 'MMM dd, yyyy')}`;
};

export const validateComment = comment => {
  // RegExp to check for 2 or more alphabets
  const alphabetPattern = /[a-zA-Z].*[a-zA-Z]/;
  return alphabetPattern.test(comment);
};

export const buildDateTime = (date, time) => {
  // Adjust hours based on amOrPm
  let hours = time.amOrPm === 'pm' ? time.hours + 12 : time.hours;

  // If it's 12 AM, set hours to 0. If it's 12 PM, set hours to 12.
  if (time.hours === 12) {
    hours = time.amOrPm === 'pm' ? 12 : 0;
  }

  // Create a new Date object with the combined date and time values
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    hours,
    time.minutes
  );
};

export const isMoreThanOneHourInFuture = (date, time) => {
  const now = new Date();
  const selectedDateTime = convertTimeObjectToDate(date, time);
  const oneHourInFuture = new Date(now.getTime() + 60 * 60 * 1000);
  return selectedDateTime > oneHourInFuture;
};

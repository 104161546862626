import React, { useEffect } from 'react';
import styles from './ProductInventory.scss';
import PropTypes from 'prop-types';
import useAction from 'hooks/useAction';
import { productCategoriesActions } from 'models/productCategories/slice';
import useSelector from 'hooks/useSelector';
import {
  productCategoriesPendingSelector,
  productInventorySelector,
} from 'models/productCategories/selectors';
import ProductCard from '../ProductCard';
import ProductImageCard from 'components/ProductImageCard';
import ProductSkeletonCard from 'components/ProductSkeletonCard';
import { isCacheValid } from 'models/helpers';

const ProductInventory = ({ categoryId, onProductClick, disabled }) => {
  // Selectors
  const inventory = useSelector(productInventorySelector);
  const fetching = useSelector(productCategoriesPendingSelector);

  // Actions
  const fetchCategoryInventories = useAction(
    productCategoriesActions.fetchCategoryInventoriesRequest
  );

  // Effects
  useEffect(() => {
    if (!isCacheValid(inventory, categoryId)) {
      fetchCategoryInventories({ id: categoryId });
    }
  }, [fetchCategoryInventories, categoryId]);

  return (
    <div className={styles.container}>
      {fetching ? (
        <>
          <ProductSkeletonCard className={styles.card} />
          <ProductSkeletonCard className={styles.card} />
          <ProductSkeletonCard className={styles.card} />
          <ProductSkeletonCard className={styles.card} />
        </>
      ) : (
        <>
          {inventory?.[categoryId] &&
            Object.values(inventory[categoryId].data)?.map(item => {
              if (item.resourcetype === 'Tasting Fees') {
                return (
                  <ProductCard
                    disabled={disabled}
                    key={item.id.toString()}
                    {...item}
                    onClick={() => onProductClick && onProductClick(item)}
                  />
                );
              }
              return (
                <ProductImageCard
                  disabled={disabled}
                  key={item.id.toString()}
                  image={item.main_image_url}
                  {...item}
                  onClick={() => onProductClick && onProductClick(item)}
                />
              );
            })}
        </>
      )}
    </div>
  );
};

ProductInventory.propTypes = {
  categoryId: PropTypes.number,
  onProductClick: PropTypes.func,
  disabled: PropTypes.bool,
};
export default ProductInventory;

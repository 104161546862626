export const TastingLayoutsEnum = Object.freeze({
  OVERVIEW: 0,
  BILL: 1,
  // RESERVATIONS: 2,
  TASTING_MENU: 3,
  // FOOD_MENU: 4,
  GUESTS: 5,
  SUBSCRIPTION: 6,
  TERMINAL: 7,
});

export const tastingLayoutTitles = Object.freeze({
  [TastingLayoutsEnum.OVERVIEW]: 'Overview',
  [TastingLayoutsEnum.BILL]: 'Bill',
  // [TastingLayoutsEnum.RESERVATIONS]: 'Reservations',
  [TastingLayoutsEnum.TASTING_MENU]: 'Tasting menu',
  // [TastingLayoutsEnum.FOOD_MENU]: 'Food menu',
  [TastingLayoutsEnum.GUESTS]: 'Guests',
  [TastingLayoutsEnum.SUBSCRIPTION]: 'Subscription',
  [TastingLayoutsEnum.TERMINAL]: 'Terminal',
});

export const tastingLayoutSelectItems = Object.values(TastingLayoutsEnum).map(
  layout => ({
    value: layout,
    label: tastingLayoutTitles[layout],
  })
);

export const getTastingLayoutSelectItem = layoutKey =>
  tastingLayoutSelectItems.find(i => i.value === layoutKey);

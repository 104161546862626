/* eslint-disable no-param-reassign */
import { OrderItemsResourceTypes } from 'constants/orderItems';

export const categorizeOrderItems = items => {
  const initialObject = {
    [OrderItemsResourceTypes.PRODUCT_ORDER_ITEM]: [],
    [OrderItemsResourceTypes.PRODUCT_SAMPLE_ORDER_ITEM]: [],
    [OrderItemsResourceTypes.TASTING_MENU_ORDER_ITEM]: [],
    [OrderItemsResourceTypes.FOOD_ORDER_ITEM]: [],
  };
  return items.reduce((result, item) => {
    result[item.resourcetype].push(item);
    return result;
  }, initialObject);
};

export default categorizeOrderItems;

import { takeLatest, all, put } from 'redux-saga/effects';
import { authenticatedRequest } from 'api';
import { inventoryUrl } from 'api/urls';
import { inventoryActions } from './slice';
import { productsPresetsActions } from 'models/productsPresets/slice';

function* fetchInventorySaga() {
  try {
    const { data } = yield authenticatedRequest({
      url: inventoryUrl,
    });
    yield put({
      type: inventoryActions.fetchInventorySuccess,
      payload: { inventory: data },
    });

    yield put({
      type: productsPresetsActions.fetchPresetsRequest,
    });
  } catch (err) {
    yield put({ type: inventoryActions.fetchInventoryFailure });
  }
}

export default function*() {
  yield all([
    takeLatest(inventoryActions.fetchInventoryRequest, fetchInventorySaga),
  ]);
}

import React from 'react';
import PropTypes from 'prop-types';
import styles from './InfoItem.scss';

const InfoItem = ({ title, value }) => (
  <div className={styles.info}>
    <h3 className={styles.infoTitle}>{title}</h3>
    <span className={styles.infoContent}>{value}</span>
  </div>
);

InfoItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default InfoItem;

export const tokenKey = 'access_token';
export const refreshKey = 'refresh_token';
export const warehouseKey = 'selectedWarehouse';
export const brandKey = 'selectedBrand';
export const roleKey = 'role';
export const brandObjectKey = 'selectedBrand_object';
export const warehouseObjectKey = 'selectedWarehouse_object';
export const terminalKey = 'selectedTerminal';

export default {
  tokenKey,
  roleKey,
  brandKey,
  warehouseKey,
  brandObjectKey,
  warehouseObjectKey,
  terminalKey,
};

/* eslint-disable no-param-reassign */
import { connectRouter } from 'connected-react-router';
import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import desksReducer from './desks/slice';
import sessionReducer from './session/slice';
import interfaceReducer from './interface/slice';
import paginatedOrdersReducer from './paginatedOrders/slice';
import paginatedCustomersReducer from './paginatedCustomers/slice';
import stripeReducer from './stripe/slice';
import roomsReducer from './rooms/slice';
import inventoryReducer from './inventory/slice';
import productsPresetsReducer from './productsPresets/slice';
import tiersReducer from './tiers/slice';
import foodReducer from './food/slice';
import verificationReducer from './verification/slice';
import productCategoriesReducer from './productCategories/slice';
import wineClubReducer from './wineClub/slice';
import commonReducer from './common/slice';
import tastersReducer from './tasters/slice';
import timeEntryReducer from './timeEntry/slice';
import discountsReducer from './discounts/slice';

import sessionSagas from './session/sagas';
import desksSagas from './desks/sagas';
import paginatedOrdersSagas from './paginatedOrders/sagas';
import paginatedCustomersSagas from './paginatedCustomers/sagas';
import stripeSagas from './stripe/sagas';
import roomsSagas from './rooms/sagas';
import inventorySagas from './inventory/sagas';
import tiersSagas from './tiers/sagas';
import productsPresetsSagas from './productsPresets/sagas';
import foodSagas from './food/sagas';
import verificationSagas from './verification/sagas';
import productCategoriesSagas from './productCategories/sagas';
import wineClubSagas from './wineClub/sagas';
import commonSagas from './common/sagas';
import tastersSagas from './tasters/sagas';
import timeEntrySagas from './timeEntry/sagas';
import discountsSagas from './discounts/sagas';

import { resetState } from './helpers';

export const createCombinedReducer = history =>
  combineReducers({
    rooms: roomsReducer,
    tiers: tiersReducer,
    desks: desksReducer,
    stripe: stripeReducer,
    food: foodReducer,
    session: sessionReducer,
    inventory: inventoryReducer,
    interface: interfaceReducer,
    router: connectRouter(history),
    paginatedOrders: paginatedOrdersReducer,
    paginatedCustomers: paginatedCustomersReducer,
    productsPresets: productsPresetsReducer,
    verification: verificationReducer,
    productCategories: productCategoriesReducer,
    wineClub: wineClubReducer,
    common: commonReducer,
    tasters: tastersReducer,
    timeEntry: timeEntryReducer,
    discounts: discountsReducer,
  });

export const createRootReducer = history => {
  const combinedReducer = createCombinedReducer(history);
  return (state, action) => {
    if (action.type === resetState.toString()) {
      state = {};
    }
    return combinedReducer(state, action);
  };
};

export const rootSaga = function*() {
  yield all([
    foodSagas(),
    roomsSagas(),
    tiersSagas(),
    desksSagas(),
    stripeSagas(),
    sessionSagas(),
    inventorySagas(),
    paginatedOrdersSagas(),
    paginatedCustomersSagas(),
    productsPresetsSagas(),
    verificationSagas(),
    productCategoriesSagas(),
    wineClubSagas(),
    commonSagas(),
    tastersSagas(),
    timeEntrySagas(),
    discountsSagas(),
  ]);
};

import { takeLatest, all, call, put } from 'redux-saga/effects';
import { authenticatedRequest } from 'api';
import { discountCodesUrl } from 'api/urls/discounts';
import { discountsActions } from './slice';

export function* fetchDiscountsSaga({ payload }) {
  try {
    const { data } = yield call(authenticatedRequest, {
      url: discountCodesUrl,
      method: 'get',
      params: payload,
    });
    yield put({
      type: discountsActions.fetchDiscountsSuccess,
      payload: data,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: discountsActions.fetchDiscountsFailure,
    });
  }
}

export default function*() {
  yield all([takeLatest(discountsActions.fetchDiscounts, fetchDiscountsSaga)]);
}

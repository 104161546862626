import React from 'react';
import PropTypes from 'prop-types';

const CopyIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <rect
        x="0.5"
        y="0.5"
        width="10.7895"
        height="11.6316"
        rx="1.5"
        fill="#0D0D0D"
        stroke="#3B3A39"
      />
      <rect
        x="3.86914"
        y="3.86914"
        width="10.7895"
        height="11.6316"
        rx="1.5"
        fill="#0D0D0D"
        stroke="#3B3A39"
      />
    </svg>
  );
};

CopyIcon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CopyIcon.defaultProps = {
  width: '16',
  height: '16',
};

export default CopyIcon;

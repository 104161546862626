import React from 'react';
import styles from './ReceiptPrintModal.scss';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import TimesIcon from 'components/Icons/TimesIcon';
import Printer from './Printer';
import useAction from 'hooks/useAction';
import { sessionActions } from 'models/session/slice';

const ReceiptPrintModal = ({ onClose, isOpen, printers, printUrl }) => {
  const print = useAction(sessionActions.printRequest);

  const onPrint = (mac, receipt) => () => {
    print({ mac, receipt });
  };

  return (
    <Modal close={onClose} id="CommentModal" isOpen={isOpen}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <button className={styles.close} onClick={onClose}>
            <TimesIcon width="20" height="18" />
          </button>
          <h2 className={styles.title}>Print a receipt</h2>
          <div className={styles.body}>
            {printers?.length > 0 &&
              printers.map(item => (
                <>
                  <Printer
                    room={item.tasting_room_title}
                    mac={item.identifier}
                    onClick={onPrint(item.identifier.toLowerCase(), printUrl)}
                  />
                </>
              ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

ReceiptPrintModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  printers: PropTypes.object.isRequired,
  printUrl: PropTypes.string.isRequired,
};
export default ReceiptPrintModal;

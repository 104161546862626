/*eslint-disable*/
import CryptoJs from 'crypto-js';

const Buffer = require('buffer/').Buffer;

const genHex = size =>
  [...Array(size)]
    .map(() => Math.floor(Math.random() * 16).toString(16))
    .join('');

export const encode = (message, secret) => {
  const key = CryptoJs.enc.Hex.parse(secret);
  const ivHex = genHex(32);
  const iv = CryptoJs.enc.Hex.parse(ivHex);
  const result = CryptoJs.AES.encrypt(message, key, { iv });

  return {
    vector_hex: ivHex,
    payment_method_data: Buffer.from(result.toString(), 'base64').toString(
      'hex'
    ),
  };
};

/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import actionTypes from 'utils/actionTypes';
import { STEPS } from 'constants/verification';

const initialState = {
  step: STEPS.EMAIL,
  loading: false,
  codeFailed: undefined,
  isValidated: false,
};

const verificationSlice = createSlice({
  name: 'verification',
  initialState,
  reducers: {
    setStep(state, { payload }) {
      state.step = payload;
    },
    resetState(state) {
      state.step = initialState.step;
      state.loading = initialState.loading;
      state.codeFailed = initialState.undefined;
      state.isValidated = initialState.isValidated;
    },
    fetchVerification(state) {
      state.loading = true;
      state.codeFailed = undefined;
    },
    fetchVerificationSuccess(state) {
      state.loading = false;
      state.codeFailed = undefined;
      state.step = STEPS.CODE;
    },
    fetchVerificationFailure(state) {
      state.loading = false;
    },
    fetchValidateVerification(state) {
      state.loading = true;
      state.codeFailed = undefined;
    },
    fetchValidateVerificationSuccess(state) {
      state.loading = false;
      state.codeFailed = undefined;
      state.step = STEPS.SUCCESS_VERIFIED;
    },
    fetchValidateVerificationFailure(state, { payload }) {
      state.loading = false;
      state.codeFailed = payload;
    },
  },
});

export const verificationActions = actionTypes(verificationSlice.actions);

export default verificationSlice.reducer;

import React from 'react';
import PropTypes from 'prop-types';

import CircleShapedIcon from 'components/Icons/CircleShapedIcon';
import styles from './Button.scss';
import clsx from 'clsx';

const Button = ({ handlePayment, title, header, active, disabled }) => {
  return (
    <button
      className={styles.paymentCard}
      onClick={handlePayment}
      disabled={disabled}
    >
      <div className={styles.paymentCardHeader}>{header}</div>
      <div className={styles.paymentCardInfo}>
        <div className={styles.paymentCardMethod}>
          <p className={styles.paymentCardName}>{title}</p>
        </div>
        <div className={clsx(active && styles.activeMethod)}>
          <CircleShapedIcon />
        </div>
      </div>
    </button>
  );
};

Button.propTypes = {
  handlePayment: PropTypes.func,
  header: PropTypes.element,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
};

export default Button;

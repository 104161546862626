import { layouts } from 'components/Keyboard/constants';

export const TIPS_MODES = Object.freeze({
  CURRENCY: 1,
  PERCENTAGE: 2,
});

export const tipsPercentageStart = 10;
export const tipsPercentageStep = 5;

export const layout = { floating: layouts.floating };

import { takeLatest, all } from 'redux-saga/effects';

import { commonActions } from './slice';

function openLinkSaga(action) {
  if (window.webkit) {
    window.webkit?.messageHandlers?.mainMessageHandler?.postMessage({
      type: 'openInBrowser',
      link: action.payload,
    });
  } else {
    window.open(action.payload, '_blank');
  }
}

export default function*() {
  yield all([takeLatest(commonActions.openLink, openLinkSaga)]);
}

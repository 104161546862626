import React, { useCallback } from 'react';
import FullscreenModal from '../FullscreenModal';
import PropTypes from 'prop-types';
import styles from './ReceiptEmailModal.scss';
import EmailStep from '../GuestEmailModal/EmailStep';

const ReceiptEmailModal = ({ isOpen, close, onSubmit, email }) => {
  const [inputState, setInputState] = React.useState(email);
  const [inputError, setInputError] = React.useState(null);

  const onEmailChange = value => {
    if (inputError) setInputError(null);
    setInputState(value);
  };

  const submitKeyboard = useCallback(
    evt => {
      evt.preventDefault();
      onSubmit(inputState);
    },
    [onSubmit, inputState]
  );

  const buttonOptions = {
    containerClassName: styles.buttonContainer,
    options: [
      {
        name: 'Cancel',
        type: 'button',
        className: styles.buttonCancel,
        action: () => {
          close();
        },
      },
      {
        name: 'Confirm',
        className: styles.buttonSubmit,
        type: 'submit',
      },
    ],
  };

  return (
    <FullscreenModal id="receiptEmail" isOpen={isOpen}>
      <div className={styles.root}>
        <div className={styles.content}>
          <form onSubmit={submitKeyboard}>
            <EmailStep
              buttonOptions={buttonOptions}
              error={inputError}
              value={inputState || ''}
              onChange={onEmailChange}
              loading={false}
            />
          </form>
        </div>
      </div>
    </FullscreenModal>
  );
};

ReceiptEmailModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  onSubmit: PropTypes.func,
  email: PropTypes.string,
};

export default ReceiptEmailModal;

export const getPreparedProductCategories = categories => {
  const normalizedCategories = {};
  categories.forEach(category => {
    const preparedSubCategories = {};
    category?.sub_categories?.forEach(subCategory => {
      if (subCategory?.is_shown_on_main_page) {
        normalizedCategories[subCategory.id] = subCategory;
      }
      preparedSubCategories[subCategory.id] = subCategory;
    });
    if (category?.is_shown_on_main_page) {
      normalizedCategories[category.id] = {
        ...category,
        sub_categories: preparedSubCategories,
      };
    }
  });
  return normalizedCategories;
};

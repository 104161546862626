import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './Block.scss';
import clsx from 'clsx';

const Block = ({ title, number, children, className }) => {
  return (
    <div className={clsx(styles.block, className)}>
      <h4 className={styles.title}>
        {title}
        {Boolean(number) && (
          <span className={styles.titleNumber}>({number})</span>
        )}
      </h4>
      <div className={styles.container}>{children}</div>
    </div>
  );
};

Block.propTypes = {
  title: PropTypes.string,
  number: PropTypes.number,
  children: PropTypes.element,
  className: PropTypes.string,
};

export default memo(Block);

export const SHIPPING_STATUSES = Object.freeze({
  UNKNOWN: 'Unknown',
  PRE_TRANSIT: 'Pre-Transit',
  IN_TRANSIT: 'In Transit',
  OUT_FOR_DELIVERY: 'Out For Delivery',
  DELIVERED: 'Delivered',
  AVAILABLE_FOR_PICKUP: 'Available For Pickup',
  RETURN_TO_SENDER: 'Return To Sender',
  FAILURE: 'Failure',
  CANCELLED: 'Cancelled',
  ERROR: 'Error',
  RETURNED: 'Returned',
});

import React, { useState } from 'react';
import styles from './AddTipsModal.scss';
import FullscreenModal from '../FullscreenModal';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import getPercentOf from 'utils/getPercentOf';
import TipsModal from '../TipsModal';
import { layout, TIPS_MODES } from 'pages/POSCheckout/Checkout/utils';
import formatPrice from '../../../utils/formatPrice';

const getTipsValueByMode = (mode, total, value) => {
  if (mode === TIPS_MODES.PERCENTAGE) {
    return getPercentOf(total, value);
  }
  return value;
};

const TipsCard = ({ value, subtitle, onClick, active }) => {
  return (
    <button
      onClick={onClick}
      className={clsx(styles.card, active && styles.activeCard)}
    >
      <p className={styles.cardSubtitle}>{subtitle}</p>
      <p className={styles.cardValue}>{value}</p>
    </button>
  );
};

const AddTipsModal = ({ total, isOpen, onClose, onSubmit }) => {
  const [mode, setMode] = useState(null);
  const [selectedTips, setSelectedTips] = useState();
  const [tipsModal, setTipsModal] = useState(false);
  const [tipsValue, setTipsValue] = useState(0);

  const onSelectTips = value => {
    setSelectedTips(value);
    setTipsValue(getPercentOf(total, value));
    onSubmit(getPercentOf(total, value));
  };

  const onSetCustomTip = value => {
    setSelectedTips(0);
    const tips = getTipsValueByMode(mode, total, value);
    setTipsValue(tips);
    onSubmit(tips);
  };

  return (
    <FullscreenModal id="AddTipsModal" isOpen={isOpen}>
      <TipsModal
        close={() => setTipsModal(false)}
        isOpen={tipsModal}
        total={total}
        value={tipsValue}
        setValue={onSetCustomTip}
        mode={mode}
        setMode={setMode}
        layout={layout}
      />
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h1 className={styles.title}>Would you like to add a tip?</h1>
          <div className={styles.cardWrapper}>
            <TipsCard
              onClick={() => onSelectTips(15)}
              subtitle="15%"
              value={`$${formatPrice(getPercentOf(total, 15))}`}
              active={selectedTips === 15}
            />
            <TipsCard
              onClick={() => onSelectTips(18)}
              subtitle="18%"
              value={`$${formatPrice(getPercentOf(total, 18))}`}
              active={selectedTips === 18}
            />
            <TipsCard
              onClick={() => onSelectTips(20)}
              subtitle="20%"
              value={`$${formatPrice(getPercentOf(total, 20))}`}
              active={selectedTips === 20}
            />
            <TipsCard
              onClick={() => setTipsModal(true)}
              subtitle={
                tipsValue && selectedTips === 0
                  ? `$${formatPrice(
                      getTipsValueByMode(mode, total, tipsValue)
                    )}`
                  : '$ %'
              }
              value="Other"
              active={selectedTips === 0}
            />
          </div>
          <div className={styles.linkWrapper}>
            <button onClick={onClose} className={styles.link}>
              Cancel
            </button>
            <button onClick={() => onSubmit(0)} className={styles.link}>
              No thanks
            </button>
            <button onClick={() => onSubmit(tipsValue)} className={styles.link}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </FullscreenModal>
  );
};

TipsCard.propTypes = {
  value: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  active: PropTypes.bool,
};

AddTipsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  onSubmit: PropTypes.func,
};

export default AddTipsModal;

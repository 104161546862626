import { all, put, select, takeLatest, call } from 'redux-saga/effects';
import { wineClubActions } from './slice';
import { desksActions } from 'models/desks/slice';
import authenticatedRequest from '../../api';
import { currentOrderIdSelector } from '../desks/selectors';
import { toast } from 'react-toastify';
import { acquirerSelector } from 'models/session/selectors';
import { brandKey } from 'constants/localStorage';

function parseErrorMessage(error, email) {
  const defaultErrorMessage =
    'Something went wrong while joining the wine club';
  const emailErrors = error.response?.data?.email;

  if (
    emailErrors?.length &&
    emailErrors?.some(message =>
      message.includes('duplicate key value violates unique constraint')
    )
  ) {
    return `User with email ${email} already exists. Find them under Customers, or ask them to reset their password online if they've lost access.`;
  }

  return defaultErrorMessage;
}
function* handleJoinWineClubError(error, email) {
  const errorMessage = parseErrorMessage(error, email);
  toast.error(errorMessage, {
    progressClassName: 'customProgressBarError',
  });
  yield put({ type: wineClubActions.joinWineClubFailure });
}

function* joinWineClubSaga({ payload }) {
  const orderId = yield select(currentOrderIdSelector);
  try {
    const { data } = yield authenticatedRequest({
      url: '/tasting-room-master-api/v1/wineclub/join',
      method: 'POST',
      data: { order_id: orderId, ...payload },
    });
    yield put({
      type: wineClubActions.joinWineClubSuccess,
      payload: data,
    });
    toast.success('Successfully registered customer and sent email!', {
      progressClassName: 'customProgressBarSuccess',
    });
    yield put({ type: wineClubActions.closeJoinModal });
  } catch (error) {
    yield handleJoinWineClubError(error, payload.email);
  }
}

function* joinWineClubSuccessSaga({ payload }) {
  const orderId = yield select(currentOrderIdSelector);
  const { customer } = payload;
  yield put({
    type: desksActions.updateOrderRequest,
    payload: {
      id: orderId,
      is_wineclub_signup: true,
      customer_id: customer?.id,
    },
  });
  yield put({
    type: wineClubActions.getSetupIntent,
    payload: { customer_id: customer?.id },
  });
}

function* getSetupIntentSaga({ payload }) {
  const acquirer = yield select(acquirerSelector);
  try {
    const { data } = yield authenticatedRequest({
      url: `/tasting-room-master-api/v1/customers/${payload.customer_id}/payments/setup_intent`,
      method: 'POST',
      data: { acquirer_id: acquirer.acquirer_id, acquirer: acquirer.acquirer },
    });
    yield put({
      type: wineClubActions.getSetupIntentSuccess,
      payload: data,
    });
  } catch (error) {
    console.error(error);
    toast.error('Something went wrong while getting intent', {
      progressClassName: 'customProgressBarError',
    });
    yield put({
      type: wineClubActions.getSetupIntentFailure,
    });
  }
}

function* getWineDirectSettingsSaga() {
  const brand_id = yield call([localStorage, localStorage.getItem], brandKey);

  try {
    const { data } = yield authenticatedRequest({
      url: '/tasting-room-master-api/v1/winedirect/settings',
      method: 'GET',
      params: { brand_id },
    });
    yield put({
      type: wineClubActions.getWineDirectSettingsSuccess,
      payload: data,
    });
  } catch (error) {
    console.error(error);
    toast.error('Something went wrong while getting Wineclub settings', {
      progressClassName: 'customProgressBarError',
    });
    yield put({
      type: wineClubActions.getWineDirectSettingsFailure,
    });
  }
}

export default function*() {
  yield all([
    takeLatest(wineClubActions.joinWineClub, joinWineClubSaga),
    takeLatest(wineClubActions.joinWineClubSuccess, joinWineClubSuccessSaga),
    takeLatest(wineClubActions.getSetupIntent, getSetupIntentSaga),
    takeLatest(
      wineClubActions.getWineDirectSettings,
      getWineDirectSettingsSaga
    ),
  ]);
}

/* eslint-disable no-param-reassign */

export const getProductTitleWithVintage = (title, vintage) => {
  return `${title} ${vintage ?? ''}`;
};

export const getPreparedInventory = inventory => {
  const initialObject = {
    productCounts: {},
    inventory: {},
  };
  return inventory.reduce((result, current) => {
    if ('product_count' in current && 'product' in current) {
      const { id } = current.product;
      result.inventory[id] = {
        ...current.product,
        title: getProductTitleWithVintage(
          current.product?.title,
          current.product?.vintage
        ),
        product_count: current.product_count,
      };
      result.productCounts[id] = current.product_count;
      return result;
    }
    result.inventory[current.id] = current;
    return result;
  }, initialObject);
};

import React, { useState } from 'react';
import styles from './OrdersMenuButton.scss';
import MenuDotsIcon from '../../../../components/Icons/MenuDotsIcon';
import Dropdown from '../../../../components/Dropdown';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { EXACT_SUCCESS_STATUSES } from '../../../../constants/orderStatus';

const OrdersMenuButton = ({ id, status }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className={styles.container}>
      {status && EXACT_SUCCESS_STATUSES.includes(status) && (
        <>
          <button onClick={() => setMenuOpen(true)} className={styles.button}>
            <MenuDotsIcon />
          </button>
          {menuOpen && (
            <Dropdown
              className={styles.dropdown}
              onClose={() => setMenuOpen(false)}
            >
              <ul className={styles.list}>
                <li className={styles.listItem}>
                  <Link to={`/dashboard/orders/${id}/refund`}>
                    Process Refund
                  </Link>
                </li>
              </ul>
            </Dropdown>
          )}
        </>
      )}
    </div>
  );
};

OrdersMenuButton.propTypes = {
  id: PropTypes.number.isRequired,
  status: PropTypes.string,
};
export default OrdersMenuButton;

/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import actionTypes from 'utils/actionTypes';

import { TastingLayoutsEnum } from 'constants/tastingLayouts';

import {
  POSSidebarLayoutsEnum,
  POSContentLayoutsEnum,
  POSDashboardLayoutsEnum,
} from 'constants/POSLayouts';
import { initialBreadcrumbsState } from 'constants/menuBreadcrumbs';

const interfaceSlice = createSlice({
  name: 'interface',
  initialState: {
    tastingLayout: TastingLayoutsEnum.OVERVIEW,
    POSDashboardLayout: POSDashboardLayoutsEnum.MAIN,
    POSSidebarLayout: POSSidebarLayoutsEnum.BILL,
    POSContentLayout: POSContentLayoutsEnum.MENU,

    breadcrumbsMenu: initialBreadcrumbsState,

    orderItemEditingModalOpen: false,
    guestAddingModalOpen: false,
    guestEmailModalOpen: false,
    tastingMenuModalOpen: false,
  },
  reducers: {
    changeTastingLayout(state, { payload }) {
      state.tastingLayout = payload;
    },

    changePOSDashboardLayout(state, { payload }) {
      state.POSDashboardLayout = payload;
    },

    changePOSSidebarLayout(state, { payload }) {
      state.POSSidebarLayout = payload;
    },

    changePOSContentLayout(state, { payload }) {
      state.POSContentLayout = payload;
    },

    changeOrderItemEditingModalOpen(state, { payload }) {
      state.orderItemEditingModalOpen = payload;
    },

    changeGuestAddingModalOpen(state, { payload }) {
      state.guestAddingModalOpen = payload;
    },

    changeGuestEmailModalOpen(state, { payload }) {
      state.guestEmailModalOpen = payload;
    },

    changeTastingMenuModalOpen(state, { payload }) {
      state.tastingMenuModalOpen = payload;
    },

    changeMenuBreadcrumbs(state, { payload }) {
      state.breadcrumbsMenu = payload;
    },
  },
});

export const interfaceActions = actionTypes(interfaceSlice.actions);

export default interfaceSlice.reducer;

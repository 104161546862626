export const getCheckoutData = order => {
  let subtotal = 0;
  let tax = 0;

  Object.values(order?.order_items || []).forEach(item => {
    Object.values(item.applied_fees).forEach(fee => {
      tax += fee.fee;
    });
    subtotal += item.discount_amount
      ? item.product_price * item.number - item.discount_amount
      : item.product_price * item.number || item.total_price;
  });

  const obj = {
    id: order.id,
    status: order.status,
    currency: order.total_price_currency,
    discount_percentage: order.discount_percentage,
    discount_amount: order.discount_amount,
    tips: order.tips_amount ?? 0,
    total_price: order.total_price,
    subtotal_price: subtotal,
    tax,
  };
  return obj;
};

import React, { memo } from 'react';
import styles from './OrderInfoCard.scss';
import PropTypes from 'prop-types';
import formatPrice from '../../utils/formatPrice';
import clsx from 'clsx';

const OrderInfoCard = ({ total, paymentMethod, className, textClassName }) => {
  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.total}>
        <p className={styles.grayText}>Order Total</p>
        <p className={styles.text}>$ {formatPrice(total)}</p>
      </div>
      <div>
        <p className={styles.grayText}>Payment Status</p>
        <p className={clsx(styles.text, textClassName)}>{paymentMethod}</p>
      </div>
    </div>
  );
};

OrderInfoCard.propTypes = {
  total: PropTypes.number.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  className: PropTypes.string,
  textClassName: PropTypes.string,
};
export default memo(OrderInfoCard);

import React, { useMemo } from 'react';
import clsx from 'clsx';
import styles from './ThermalPrintKitchen.scss';

const ThermalPrintKitchen = route => {
  const { base64 } = route.match.params;
  if (!base64) {
    return <></>;
  }
  const order = JSON.parse(
    decodeURIComponent(escape(atob(decodeURIComponent(base64))))
  );
  const renderedGoods = useMemo(
    () =>
      order.foods.map(item => {
        return (
          item && (
            <div className={styles.print_row} key={item.id}>
              <div className={styles.print_item_name}>
                {item.name} &nbsp;
                {item.comment && (
                  <>
                    <br />
                    <small className={styles.sample}>({item.comment})</small>
                  </>
                )}
              </div>
              <div className={styles.print_item_price}>{item.number}</div>
            </div>
          )
        );
      }),
    [order]
  );

  return (
    <div id="printPanel" className={styles.print_panel}>
      <div className={styles.print_center}>Receipt from Therapy Vineyards</div>
      <div className={clsx(styles.print_center, styles.mb)}>
        Order #{order.id}
      </div>
      <div className={styles.print_space} />
      <div className={clsx(styles.print_row, styles.info_font)}>
        <div className={styles.col} />
        <div className={styles.col}>
          <div>
            <b>DATE PAID</b>
            <br />
            {order.date.month}/{order.date.day}/{order.date.year}
          </div>
        </div>
        <div className={styles.col} />
      </div>
      <div className={styles.print_space} />
      <hr />
      <div className={styles.print_space} />
      {renderedGoods}
      <div className={styles.print_space} />
      <div id="productItemTasting" />
      <hr />
      <div className={styles.print_space} />
      <div className={styles.print_row}>
        <div className={styles.print_space} />
        <div className={styles.print_space} />
        <div className={clsx(styles.mb, styles.fr)}>
          <small>POWERED BY CELLAR</small>
        </div>
      </div>
    </div>
  );
};

export default ThermalPrintKitchen;

import React from 'react';
import styles from './DatePicker.scss';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import useOnclickOutside from 'react-cool-onclickoutside';
import DatePickerHeader from './DatePickerHeader';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const DatePicker = ({
  onHide,
  onSelect,
  pickerStyles,
  modifiers,
  className,
  selectedDate,
  disabled,
  ...props
}) => {
  const pickerRef = useOnclickOutside(onHide);

  const modifiersStyles = {
    selected: {
      backgroundColor: '#ff7e03',
      color: '#000',
    },
    disabled: {
      color: '#404040',
      border: 'none',
    },
    today: {
      borderRadius: '50%',
      display: 'block',
    },
    outside: {
      border: 'none',
    },
  };

  return (
    <div ref={pickerRef}>
      <DayPicker
        navbarElement={DatePickerHeader}
        modifiersStyles={{ ...modifiersStyles, ...pickerStyles }}
        className={clsx(styles.container, className)}
        onDayClick={onSelect}
        modifiers={modifiers}
        selected={selectedDate}
        disabledDays={disabled}
        {...props}
      />
    </div>
  );
};

DatePicker.propTypes = {
  onHide: PropTypes.func,
  onSelect: PropTypes.func,
  pickerStyles: PropTypes.object,
  modifiers: PropTypes.object,
  className: PropTypes.string,
  selectedDate: PropTypes.any,
  disabled: PropTypes.object,
};
export default DatePicker;

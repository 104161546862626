import React from 'react';

const VisaIcon = ({ fill, width, height, className }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 31 11"
      fill="none"
      fillOpacity={0.5}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8601 3.78289C15.8426 5.17586 17.0894 5.95324 18.0287 6.4154C18.9938 6.88964 19.3179 7.19371 19.3142 7.61774C19.3069 8.2668 18.5444 8.55321 17.8307 8.56436C16.6193 8.58336 15.9014 8.24407 15.3333 7.97561L15.2864 7.95343L14.8379 10.0726C15.4153 10.3414 16.4844 10.5757 17.5931 10.5859C20.1955 10.5859 21.8982 9.28875 21.9074 7.2774C21.9135 5.75038 20.6609 5.08629 19.6586 4.55488C18.9855 4.198 18.4252 3.90095 18.4348 3.44255C18.4431 3.09663 18.77 2.72747 19.4864 2.63355C19.841 2.58612 20.8199 2.54986 21.9295 3.06595L22.3651 1.01554C21.7683 0.796092 21.0013 0.585937 20.0463 0.585937C17.5968 0.585937 15.8739 1.9008 15.8601 3.78289ZM26.5504 0.762616C26.0752 0.762616 25.6747 1.04251 25.496 1.47212L21.7785 10.4353H24.379L24.8965 8.99118H28.0744L28.3746 10.4353H30.6667L28.6665 0.762616H26.5504ZM27.6646 7.00773L26.9141 3.3756L25.6093 7.00773H27.6646ZM12.707 0.762616L10.6572 10.4353H13.1352L15.1842 0.762616H12.707ZM6.46171 7.34621L9.04106 0.762616H11.6434L7.62845 10.4353L5.00951 10.4353L3.03333 2.71631C2.91362 2.24114 2.80956 2.06632 2.4449 1.86639C1.8491 1.53907 0.865614 1.23314 0 1.04344L0.0589354 0.762616H4.27558C4.81244 0.762616 5.2959 1.12341 5.41837 1.7483L6.46171 7.34621Z"
        fill={fill}
      />
    </svg>
  );
};

import PropTypes from 'prop-types';

VisaIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

VisaIcon.defaultProps = {
  fill: '#EDEAE3',
  width: '48',
  height: '30',
};

export default VisaIcon;

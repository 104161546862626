import React, { forwardRef } from 'react';
import styles from './Textarea.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Textarea = forwardRef(({ name, label, className, ...props }, ref) => {
  return (
    <label className={clsx(styles.container, className)}>
      {label && <span className={styles.label}>{label}</span>}
      <textarea name={name} className={styles.textarea} {...props} ref={ref} />
    </label>
  );
});

Textarea.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
};
export default Textarea;

import React from 'react';

import useSelector from 'hooks/useSelector';

import { paginatedOrdersLoadingSelector } from 'models/paginatedOrders/selectors';

import Orders from './Orders';

const OrdersContainer = () => {
  const loading = useSelector(paginatedOrdersLoadingSelector);

  return loading ? <div>Loading...</div> : <Orders />;
};

export default OrdersContainer;

import React, { memo } from 'react';
import styles from './Chip.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Chip = ({ title, onClick, active }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={clsx(styles.container, active && styles.activeContainer)}
    >
      {title}
    </button>
  );
};

Chip.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
};
export default memo(Chip);

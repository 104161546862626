import React from 'react';
import styles from './DenseHeader.scss';
import PropTypes from 'prop-types';

const DenseHeader = ({ logo }) => {
  return (
    <header className={styles.container}>
      <img className={styles.image} src={logo} alt="logo" />
    </header>
  );
};

DenseHeader.propTypes = {
  logo: PropTypes.string,
};
export default DenseHeader;

export const getRefundBasePath = id => `/dashboard/orders/${id}/refund`;

export const REFUND_STEPS = Object.freeze({
  MAIN: 0,
  SUCCESS: 2,
});

export const REFUND_PREFIX = {
  [REFUND_STEPS.MAIN]: '/',
  [REFUND_STEPS.SUCCESS]: '/success',
};

export const ITEM_STATE_TYPES = Object.freeze({
  NOT_CHOSEN: 0,
  RETURN: 1,
  VOID: 2,
});

import React from 'react';
import PropTypes from 'prop-types';
import { terminalKey } from 'constants/localStorage';
import styles from './TerminalList.scss';

const TerminalList = ({ terminals, action, showTerminalList }) => {
  if (!terminals?.length) return null;

  const getType = model => {
    switch (model) {
      case 'wise_pad_3':
        return 'Bluetooth';
      case 'verifone_p400':
        return 'Internet';
      default:
        return 'Unknown';
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>Title</div>
        <div className={styles.headerTitle}>Serial Number</div>
        <div className={styles.headerTitle}>Type</div>
        <div className={styles.headerTitle}>Action</div>
      </div>
      <div className={styles.list}>
        {!showTerminalList && !!localStorage.getItem(terminalKey) && (
          <div className={styles.listContainer}>
            <div className={styles.listItem}>
              <div className={styles.listItemDescription}>
                {JSON.parse(localStorage.getItem(terminalKey)).title}
              </div>
              <div className={styles.listItemDescription}>
                {JSON.parse(localStorage.getItem(terminalKey)).serial_number}
              </div>
              <div className={styles.listItemDescription}>
                {getType(JSON.parse(localStorage.getItem(terminalKey)).model)}
              </div>
              <div className={styles.listItemDescription}>
                <button
                  onClick={() => {
                    action.function({
                      terminal: JSON.parse(localStorage.getItem(terminalKey)),
                    });
                  }}
                >
                  {action.title}
                </button>
              </div>
            </div>
          </div>
        )}
        {(!localStorage.getItem(terminalKey) || showTerminalList) && (
          <div className={styles.listContainer}>
            {terminals?.map(item => (
              <div className={styles.listItem} key={item.serial_number}>
                <div className={styles.listItemDescription}>{item.title}</div>
                <div className={styles.listItemDescription}>
                  {item.serial_number}
                </div>
                <div className={styles.listItemDescription}>
                  {getType(item.model)}
                </div>
                <div className={styles.listItemDescription}>
                  <button
                    onClick={() => {
                      localStorage.setItem(terminalKey, JSON.stringify(item));
                      action.function({ terminal: item });
                    }}
                  >
                    {action.title}
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className={styles.listBackdrop} />
      </div>
    </div>
  );
};

TerminalList.propTypes = {
  terminals: PropTypes.array,
  action: PropTypes.object,
  showTerminalList: PropTypes.bool,
};

export default TerminalList;

import { createSelector } from 'reselect';

export const stripeSelector = state => state.stripe;

export const isLoadingSelector = createSelector(
  stripeSelector,
  ({ isLoading }) => isLoading
);

export const isUpdatingSelector = createSelector(
  stripeSelector,
  ({ isUpdating }) => isUpdating
);

export const paymentIntentIdSelector = createSelector(
  stripeSelector,
  ({ paymentIntentId }) => paymentIntentId
);

export const cardSelector = createSelector(stripeSelector, ({ card }) => card);

export const stepSelector = createSelector(stripeSelector, ({ step }) => step);
export const terminalSelector = createSelector(
  stripeSelector,
  ({ terminal }) => terminal
);
export const actionSelector = createSelector(
  stripeSelector,
  ({ action }) => action
);

export const errorSelector = createSelector(
  stripeSelector,
  ({ error }) => error
);

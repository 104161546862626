import React, { useEffect, useMemo } from 'react';
import { parseISO, format } from 'date-fns';
import styles from './TimesheetTable.scss';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { timeEntryActions } from 'models/timeEntry/slice';
import { timeEntriesSelector } from 'models/timeEntry/selectors';
import { userSelector } from 'models/session/selectors';

import { timesheetTableHeadCols } from './TimesheetTableHeadCol/constants';
import { shiftStatus } from 'constants/shiftStatus';

import TimesheetTableHeadCol from './TimesheetTableHeadCol';
import Pagination from 'components/Pagination';

import CheckIcon from 'components/Icons/CheckIcon';

const TimesheetTable = () => {
  const fetchTimeEntry = useAction(timeEntryActions.fetchTimeEntry);
  const timesheet = useSelector(timeEntriesSelector);
  const user = useSelector(userSelector);

  useEffect(() => {
    fetchTimeEntry({
      user_id: user?.id,
      per_page: 8,
      page: 1,
    });
  }, [fetchTimeEntry]);

  const onPageChange = newPageNumber => {
    fetchTimeEntry({
      user_id: user?.id,
      per_page: 8,
      page: newPageNumber,
    });
  };

  const headerCols = useMemo(
    () => (
      <>
        {timesheetTableHeadCols.map(col => (
          <TimesheetTableHeadCol key={col.id} {...col} />
        ))}
      </>
    ),
    []
  );

  const timeDifferenceInHours = (startTimeString, endTimeString) => {
    const startTime = parseISO(startTimeString);
    const endTime = parseISO(endTimeString);

    const diffInMilliseconds = endTime - startTime;
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

    return diffInHours.toFixed(2);
  };

  return (
    <div className={styles.root}>
      <table className={styles.table}>
        <thead>
          <tr>{headerCols}</tr>
        </thead>
        <tbody>
          {timesheet?.results?.map(timeEntry => (
            <tr key={timeEntry.id} className={styles.bodyRow}>
              <td className={styles.bodyCell}>
                {timeEntry.status === shiftStatus.APPROVED ? (
                  <CheckIcon className={styles.icon} />
                ) : (
                  ''
                )}
              </td>
              <td className={styles.bodyCell}>
                {format(new Date(timeEntry.started_at), 'MMMM, dd')}
              </td>
              <td className={styles.bodyCell}>{timeEntry.warehouse.title}</td>
              <td className={styles.bodyCell}>
                <div className={styles.bodyCellRow}>
                  <div
                    className={
                      timeEntry.approved_started_at &&
                      timeEntry.approved_started_at !== timeEntry.started_at
                        ? styles.changedEntry
                        : styles.bodyCellRow
                    }
                  >
                    {format(
                      new Date(
                        timeEntry.approved_started_at || timeEntry.started_at
                      ),
                      'hh:mm a'
                    )}{' '}
                    - {timeEntry.started_comment}
                  </div>
                </div>
              </td>
              <td className={styles.bodyCell}>
                <div
                  className={
                    timeEntry.approved_ended_at &&
                    timeEntry.approved_ended_at !== timeEntry.ended_at
                      ? styles.changedEntry
                      : styles.bodyCellRow
                  }
                >
                  {timeEntry.ended_at
                    ? `${format(
                        new Date(
                          timeEntry.approved_ended_at || timeEntry.ended_at
                        ),
                        'hh:mm a'
                      )} - ${timeEntry.ended_comment || 'No comment'}`
                    : '-'}
                </div>
              </td>
              <td
                className={
                  timeEntry.approved_started_at &&
                  (timeEntry.approved_started_at !== timeEntry.started_at ||
                    timeEntry.approved_ended_at !== timeEntry.ended_at)
                    ? styles.changedEntryRow
                    : styles.bodyCell
                }
              >
                {timeEntry.approved_started_at &&
                  `${timeDifferenceInHours(
                    timeEntry.approved_started_at,
                    timeEntry.approved_ended_at
                  )} hours`}
                {!timeEntry.approved_started_at &&
                  timeEntry.ended_at &&
                  `${timeDifferenceInHours(
                    timeEntry.started_at,
                    timeEntry.ended_at
                  )} hours`}
                {!timeEntry.ended_at && '-'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {timesheet && timesheet.pagination && (
        <Pagination pagination={timesheet?.pagination} setPage={onPageChange} />
      )}
    </div>
  );
};

export default TimesheetTable;

import React from 'react';
import styles from './ProductFood.scss';
import PropTypes from 'prop-types';
import useAction from 'hooks/useAction';
import { foodActions } from 'models/food/slice';
import { OrderItemsResourceTypes } from 'constants/orderItems';
import { useSelector } from 'react-redux';
import { currentOrderIdSelector } from 'models/desks/selectors';
import FoodForm from 'components/FoodForm/FoodForm';
import { foodsLoadingSelector } from 'models/food/selectors';

const prepareComment = (comment, allergies) => {
  const allergiesArray = Object.values(allergies);
  const commentValue = comment ? `${comment}; ` : '';
  const allergiesValue =
    allergiesArray.length > 0
      ? `Allergies: ${allergiesArray.map(({ title }) => title).join(', ')}`
      : '';
  return `${commentValue}${allergiesValue}`;
};

const ProductFood = ({ product, allergies, image }) => {
  const createFoodWithAdditions = useAction(
    foodActions.createFoodWithAdditions
  );
  const currentOrderId = useSelector(currentOrderIdSelector);
  const loading = useSelector(foodsLoadingSelector);

  const submitHandler = data => {
    createFoodWithAdditions({
      number: 1,
      product_id: product.id,
      resourcetype: OrderItemsResourceTypes.FOOD_ORDER_ITEM,
      order_id: currentOrderId,
      comment: prepareComment(data.comment, data.allergies),
      additions: Object.values(data.additions),
    });
  };

  return (
    <div className={styles.container}>
      {image && (
        <div className={styles.image}>
          <img src={image} alt="" height="60" />
        </div>
      )}
      <FoodForm
        onSubmit={submitHandler}
        price={product.price}
        additions={product?.food_additions}
        allergies={allergies}
        disabled={loading}
      />
    </div>
  );
};

ProductFood.propTypes = {
  product: PropTypes.object.isRequired,
  allergies: PropTypes.array.isRequired,
  image: PropTypes.string,
};
export default ProductFood;

import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './Timesheet.scss';
import { shiftStatus } from 'constants/shiftStatus';
import CalendarIcon from 'components/Icons/CalendarIcon';
import TimesheetTable from './TimesheetTable/TimesheetTable';

const Timesheet = () => {
  const history = useHistory();
  const handleAddShift = () => {
    history.push(`/shift-entry?status=${shiftStatus.NEW}`);
  };

  return (
    <div className={styles.root}>
      <div className={styles.topbar}>
        <div className={styles.calendar}>
          <div className={styles.calendarIcon}>
            <CalendarIcon />
          </div>
          <div className={styles.date}>Timesheets</div>
        </div>
        <button onClick={() => handleAddShift()} className={styles.addButton}>
          ADD MISSING SHIFT
        </button>
      </div>
      <div className={styles.content}>
        <TimesheetTable />
      </div>
    </div>
  );
};

export default Timesheet;

import React, { useMemo } from 'react';
import clsx from 'clsx';
import styles from './ThermalPrintReceipt.scss';

const ThermalPrintReceipt = route => {
  const { base64 } = route.match.params;
  const order = JSON.parse(
    decodeURIComponent(escape(atob(decodeURIComponent(base64))))
  );
  const renderedGoods = useMemo(
    () =>
      order.order.map(item => {
        return (
          item && (
            <div className={styles.print_row} key={item.id}>
              <div className={styles.print_item_name}>
                {item.name} &nbsp;
                {(item.type === 'ProductSampleOrderItem' ||
                  item.type === 'TastingMenuOrderItem') && (
                  <>
                    <br />
                    <small className={styles.sample}>(sample)</small>
                  </>
                )}
              </div>
              <div className={styles.print_item_qtn}>{item.number}</div>
              <div className={styles.print_item_price}>
                ${parseFloat(item.price).toFixed(2)}
              </div>
              {item.type === 'TastingMenuOrderItem' && (
                <div className={styles.print_space} />
              )}
            </div>
          )
        );
      }),
    [order]
  );

  return (
    <div id="printPanel" className={styles.print_panel}>
      <div className={styles.print_center}>Receipt from Therapy Vineyards</div>
      <div className={clsx(styles.print_center, styles.mb)}>
        Order #{order.id}
      </div>
      <div className={styles.print_space} />
      <div className={clsx(styles.print_row, styles.info_font)}>
        <div className={styles.col}>
          <div>
            <b>AMOUNT PAID</b>
            <br />${order.total_price}
          </div>
        </div>
        <div className={styles.col}>
          <div>
            <b>DATE PAID</b>
            <br />
            {order.date.month}/{order.date.day}/{order.date.year}
          </div>
        </div>
        <div className={styles.col}>
          <div>
            <b>PAYMENT METHOD</b>
            <br />
            {order.status}
          </div>
        </div>
      </div>
      <div className={styles.print_space} />
      <hr />
      <div className={styles.print_space} />
      {renderedGoods}
      <div className={styles.print_space} />
      <div id="productItemTasting" />
      {order.tastingMenu}
      <hr />
      <div className={styles.print_space} />
      {order.subtotal && (
        <div className={styles.print_row}>
          <div className={styles.print_item_name}>Subtotal</div>
          <div className={styles.print_item_price} id="subtotal">
            ${order.subtotal}
          </div>
        </div>
      )}
      <div className={clsx(styles.print_row, styles.tax_font)}>
        {order.tax.GST && (
          <div>
            <div className={styles.print_item_name}>GST</div>
            <div className={styles.print_item_price}>
              ${order.tax.GST.toFixed(2)}
            </div>
          </div>
        )}
        {order.tax.PST && (
          <div>
            <div className={styles.print_item_name}>PST</div>
            <div className={styles.print_item_price}>
              ${order.tax.PST.toFixed(2)}
            </div>
          </div>
        )}
        {order.tax['BOTTLE DEPOSIT'] && (
          <div>
            <div className={styles.print_item_name}>BOTTLE DEPOSIT</div>
            <div className={styles.print_item_price}>
              ${order.tax['BOTTLE DEPOSIT'].toFixed(2)}
            </div>
          </div>
        )}
      </div>
      <div className={styles.print_space} />
      {order.total_tax && (
        <>
          <div className={styles.print_row}>
            <div className={styles.print_item_name}>Total Tax</div>
            <div className={styles.print_item_price} id="totalTax">
              ${order.total_tax}
            </div>
          </div>
          <div className={styles.print_space} />
        </>
      )}
      {order.discount > 0 && (
        <>
          <div className={styles.print_row}>
            <div className={styles.print_item_name}>DISCOUNT</div>
            <div className={styles.print_item_price}>${order.discount}</div>
          </div>
          <div className={styles.print_space} />
        </>
      )}

      {order.tips > 0 && (
        <>
          <div className={styles.print_row}>
            <div className={styles.print_item_name}>TIPS</div>
            <div className={styles.print_item_price}>${order.tips}</div>
          </div>
          <div className={styles.print_space} />
        </>
      )}

      <hr />
      {order.total_price && (
        <>
          <div className={styles.print_space} />
          <div className={styles.print_row}>
            <div className={clsx(styles.print_item_name, styles.big)}>
              Total
            </div>
            <div className={clsx(styles.fr, styles.big)} id="total">
              ${order.total_price}
            </div>
          </div>
        </>
      )}
      <div className={styles.print_row}>
        <div className={styles.print_space} />
        <div className={styles.print_space} />
        <div className={clsx(styles.mb, styles.fr)}>
          <small>POWERED BY CELLAR</small>
        </div>
      </div>
    </div>
  );
};

export default ThermalPrintReceipt;

import { createSelector } from 'reselect';

export const foodSelector = createSelector(
  state => state,
  state => state.food
);

export const foodCategoriesSelector = createSelector(
  foodSelector,
  ({ categories }) => categories
);

export const metaSelector = createSelector(foodSelector, ({ meta }) => meta);

export const foodsLoadingSelector = createSelector(
  foodSelector,
  ({ foodsLoading }) => foodsLoading
);

export const foodAdditionsLoadingSelector = createSelector(
  foodSelector,
  ({ foodAdditionsLoading }) => foodAdditionsLoading
);

export const foodsSelector = createSelector(foodSelector, ({ foods }) => foods);

export const foodPairingsSelector = createSelector(
  foodSelector,
  ({ foodPairings }) => foodPairings
);

export const foodAllergiesSelector = createSelector(
  foodSelector,
  ({ allergies }) => allergies
);

export const foodAdditionsSelector = createSelector(
  foodSelector,
  ({ additions }) => additions
);

export const changeModalSelector = createSelector(
  foodSelector,
  ({ changeModal }) => changeModal
);

export const allBtns =
  'q w e r t y u i o p {bksp} {empty} a s d f g h j k l " {shiftleft} x z c v b n m , . ? {shiftright} {controls} {space} < > {lang} Q W E R T Y U I O P A S D F G H J K L Z X C V B N M 1 2 3 4 5 6 7 8 9 0 @ # & * ( ) \' % - _ + = / ; : , .';

export const btnAttrs = [
  { buttons: '{enter}', attribute: 'type', value: 'submit' },
  { buttons: allBtns, attribute: 'type', value: 'button' },
];

export const layouts = {
  default: [
    'q w e r t y u i o p {bksp}',
    '{empty} a s d f g h j k l " {enter}',
    '{shiftleft} x z c v b n m , . ? {shiftright}',
    '{controls} {space} < > {lang}',
  ],
  shift: [
    'Q W E R T Y U I O P {bksp}',
    '{empty} A S D F G H J K L " {enter}',
    '{shiftleft} Z X C V B N M , . ? {shiftright}',
    '{controls} {space} < > {lang}',
  ],
  controls: [
    '1 2 3 4 5 6 7 8 9 0 {bksp}',
    '{empty} @ # & * ( ) \' " {enter}',
    '% - _ + = / ; : , .',
    '{default} {space} < > {lang}',
  ],
  numeric: ['1 2 3', '4 5 6', '7 8 9', '{enter} 0 {bksp}'],
  floating: ['1 2 3', '4 5 6', '7 8 9', '{enter} 0 . {bksp}'],
};

export const display = {
  '{shiftleft}': '↑',
  '{shiftright}': '↑',
  '{bksp}': '⌫',
  '{enter}': '→',
  '{controls}': '&123',
  '{default}': 'ABC',
  '{lang}': 'EN',
  '{empty}': ' ',
};

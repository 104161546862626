import React from 'react';
import styles from './SelectOrder.scss';
import PropTypes from 'prop-types';
import Select from '../Select';
import posIcon from 'assets/images/pos.svg';

const SelectOrder = ({ items, disabled, onChange, onDelete }) => {
  return (
    <>
      <i className={styles.icon}>
        <img src={posIcon} className={styles.headerIcon} alt="pos icon" />
      </i>
      <Select
        items={items}
        addNewButton
        disabled={disabled}
        onChange={onChange}
        value={{
          label: 'POS',
          value: 'POS',
        }}
        className={styles.container}
        listClassName={styles.list}
        onDelete={onDelete}
      />
    </>
  );
};

SelectOrder.propTypes = {
  items: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};
export default SelectOrder;

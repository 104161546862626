import { isEmpty, round } from 'lodash';

export const getOrdersPriceSummary = orders => {
  const summary = orders.reduce((result, order) => {
    return result + order.total_price;
  }, 0);

  const rounded = round(summary, 2);
  return rounded;
};

export const mapProductsWithOrderItems = items =>
  items.reduce(
    (result, current) => ({
      ...result,
      [current?.product?.id ?? current.product_id]: current,
    }),
    {}
  );

export const mapTastingMenusWithOrderItems = items =>
  items.reduce(
    (result, current) => ({
      ...result,
      [current.products_preset_id]: current,
    }),
    {}
  );

export const mapFoodWithOrderItems = items => {
  const filteredItems = items.filter(
    item =>
      !item.comment && !item.addition && isEmpty(item.food_addition_order_items)
  );
  return filteredItems.reduce(
    (result, current) => ({
      ...result,
      [current.product_id]: current,
    }),
    {}
  );
};

// export const mapFoodWithOrderItems = food =>
//   food.reduce(
//     (result, current) => ({
//       ...result,
//       [current.product_id]: current,
//     }),
//     {}
//   );

export const getOrderTaxesAmount = items => {
  const taxes = {
    gst_tax_amount: 0,
    pst_tax_amount: 0,
    hst_tax_amount: 0,
    deposit_tax_amount: 0,
    tax: 0,
  };
  return items.reduce((acc, item) => {
    Object.keys(taxes).forEach(key => {
      if (Number(item[key])) {
        taxes[key] += Number(item[key]);
      }
    });
    return acc;
  }, taxes);
};

export const POSDashboardLayoutsEnum = Object.freeze({
  MAIN: 0,
  CUSTOMER: 1,
});

export const POSDashboardLayoutTitles = Object.freeze({
  [POSDashboardLayoutsEnum.MAIN]: 'Main',
  [POSDashboardLayoutsEnum.CUSTOMER]: 'Customer',
});

export const POSDashboardLayoutSelectItems = Object.values(
  POSDashboardLayoutsEnum
).map(layout => ({
  value: layout,
  label: POSDashboardLayoutTitles[layout],
}));

export const getPOSDashboardLayoutSelectItem = layoutKey =>
  POSDashboardLayoutSelectItems.find(i => i.value === layoutKey);

export const POSSidebarLayoutsEnum = Object.freeze({
  BILL: 0,
  CUSTOMER: 1,
});

export const POSSidebarLayoutTitles = Object.freeze({
  [POSSidebarLayoutsEnum.BILL]: 'Bill',
  [POSSidebarLayoutsEnum.CUSTOMER]: 'Customer details',
});

export const POSSidebarLayoutSelectItems = Object.values(
  POSSidebarLayoutsEnum
).map(layout => ({
  value: layout,
  label: POSSidebarLayoutTitles[layout],
}));

export const getPOSSidebarLayoutSelectItem = layoutKey =>
  POSSidebarLayoutSelectItems.find(i => i.value === layoutKey);

export const POSContentLayoutsEnum = Object.freeze({
  MENU: 0,
  ORDER: 1,
});

export const POSContentLayoutTitles = Object.freeze({
  [POSSidebarLayoutsEnum.MENU]: 'Menu',
  [POSSidebarLayoutsEnum.ORDER]: 'Add order',
});

export const POSContentLayoutSelectItems = Object.values(
  POSContentLayoutsEnum
).map(layout => ({
  value: layout,
  label: POSContentLayoutTitles[layout],
}));

export const getPOSContentLayoutSelectItem = layoutKey =>
  POSContentLayoutSelectItems.find(i => i.value === layoutKey);

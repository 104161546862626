/* eslint-disable no-param-reassign */
import clsx from 'clsx';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import ScrollContainer from 'react-indiana-drag-scroll';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import ChevronDownIcon from 'components/Icons/ChevronDownIcon';
import usePortal from 'hooks/usePortal';
import { usePopper } from 'react-popper';
import styles from './SelectLabelled.scss';
import UserIcon from '../Icons/UserIcon';

const SelectLabelled = ({
  items,
  label,
  value,
  placeholder,
  onChange,
  rootClassName,
  selectClassName,
  dropdownClassName,
  optionClassName,
  valueClassName,
  placeholderClassName,
  draggableClassName,
  iconWidth,
  isIcon,
  popperModifierSettings,
  withUserIcon,
}) => {
  const [active, setActive] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const popperModifiers = useMemo(
    () => [
      {
        name: 'sameWidth',
        enabled: true,
        fn: ({ state }) => {
          state.styles.popper.width = `${state.rects.reference.width +
            popperModifierSettings.popperAdditionalWidth}px`;
        },
        effect({ state }) {
          state.elements.popper.style.width = `${state.elements.reference
            .offsetWidth + popperModifierSettings.popperAdditionalWidth}px`;
        },
        phase: 'beforeWrite',
        requires: ['computeStyles'],
      },
      {
        name: 'offset',
        options: {
          offset: () => [
            popperModifierSettings.popperAdditionalWidth
              ? -popperModifierSettings.popperAdditionalWidth + 10
              : 0,
            10,
          ],
        },
      },
    ],
    [popperModifierSettings]
  );

  const { styles: pStyles, attributes: pAttrs } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'bottom-start',
      modifiers: popperModifiers,
    }
  );

  const portalElement = usePortal('SelectPortalledLabelled');

  const handleChange = item => () => {
    setActive(false);
    if (item.value !== value.value) {
      onChange(item);
    }
  };

  const outsideClick = useCallback(
    e => {
      if (referenceElement.contains(e.target)) return;
      if (!popperElement.contains(e.target)) setActive(false);
    },
    [popperElement, setActive]
  );

  useEffect(() => {
    if (active) document.addEventListener('mousedown', outsideClick);
    else document.removeEventListener('mousedown', outsideClick);
    return () => {
      document.removeEventListener('mousedown', outsideClick);
    };
  }, [active, outsideClick]);

  const selectClassList = clsx(
    styles.select,
    selectClassName && selectClassName
  );

  const dropdownClassList = clsx(
    styles.dropdown,
    dropdownClassName && dropdownClassName
  );

  const optionClassList = clsx(
    styles.listItem,
    optionClassName && optionClassName
  );

  const draggableClassList = clsx(
    styles.draggable,
    draggableClassName && draggableClassName
  );

  const valueClassList = clsx(styles.value, valueClassName && valueClassName);

  const placeholderClassList = clsx(
    styles.placeholder,
    placeholderClassName && placeholderClassName
  );
  return (
    <div className={clsx(styles.root, rootClassName)}>
      {label && <div className={styles.label}>{label}</div>}
      <div className={dropdownClassList}>
        <button
          type="button"
          ref={setReferenceElement}
          className={selectClassList}
          onClick={() => setActive(!active)}
        >
          {withUserIcon && isIcon && (
            <span className={styles.icon}>
              <UserIcon />
            </span>
          )}
          {value.label ? (
            <span className={valueClassList}>{value.label}</span>
          ) : (
            <span className={placeholderClassList}>{placeholder}</span>
          )}
          <span className={styles.icon}>
            <ChevronDownIcon width={iconWidth} />
          </span>
        </button>
      </div>
      <CSSTransition in={active} timeout={200} classNames="fade" unmountOnExit>
        <>
          {ReactDOM.createPortal(
            <div
              ref={setPopperElement}
              style={pStyles.popper}
              className={styles.listWrapper}
              {...pAttrs.popper}
            >
              <ScrollContainer className={draggableClassList} vertical>
                <div className={styles.list}>
                  {items.map(item => (
                    <button
                      type="button"
                      key={item.value}
                      className={optionClassList}
                      onClick={handleChange(item)}
                    >
                      {item.label}
                    </button>
                  ))}
                </div>
              </ScrollContainer>
            </div>,
            portalElement
          )}
        </>
      </CSSTransition>
    </div>
  );
};

SelectLabelled.propTypes = {
  label: PropTypes.string,
  value: PropTypes.object,
  items: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  valueClassName: PropTypes.string,
  selectClassName: PropTypes.string,
  optionClassName: PropTypes.string,
  placeholderClassName: PropTypes.string,
  dropdownClassName: PropTypes.string,
  draggableClassName: PropTypes.string,
  iconWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  popperModifierSettings: PropTypes.shape({
    popperAdditionalWidth: PropTypes.number,
    popperParentGapOffset: PropTypes.number,
  }),
  withUserIcon: PropTypes.bool,
  rootClassName: PropTypes.string,
  isIcon: PropTypes.bool,
};

SelectLabelled.defaultProps = {
  popperModifierSettings: {
    popperAdditionalWidth: 0,
    popperParentGapOffset: 0,
  },
  withUserIcon: false,
  isIcon: true,
};

export default SelectLabelled;

import { createSlice } from '@reduxjs/toolkit';
import actionTypes from '../../utils/actionTypes';

const initialState = {
  loading: false,
  joinModal: false,
  customer: {},
  intent: {},
  step: 0,
};

const winClubSlice = createSlice({
  name: 'wineClub',
  initialState,
  reducers: {
    setJoinModal: (state, { payload }) => {
      state.joinModal = payload;
      state.step = 0;
    },
    joinWineClub: state => {
      state.loading = true;
    },
    joinWineClubSuccess: (state, { payload }) => {
      state.loading = false;
      state.customer = payload.customer;
    },
    joinWineClubFailure: state => {
      state.loading = false;
    },
    getSetupIntent: state => {
      state.loading = true;
    },
    getSetupIntentSuccess: (state, { payload }) => {
      state.loading = false;
      state.intent = payload;
      state.step = 1;
    },
    getSetupIntentFailure: state => {
      state.loading = false;
    },
    closeJoinModal: state => {
      state.joinModal = false;
      state.step = 0;
    },
    getWineDirectSettings: state => {
      state.loading = true;
    },
    getWineDirectSettingsSuccess: (state, { payload }) => {
      state.loading = false;
      state.wineDirectSettings = payload;
    },
    getWineDirectSettingsFailure: state => {
      state.loading = false;
    },
  },
});

export const wineClubActions = actionTypes(winClubSlice.actions);

export default winClubSlice.reducer;

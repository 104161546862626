import React from 'react';
import styles from './Dropdown.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useOnclickOutside from 'react-cool-onclickoutside';

const Dropdown = ({ onClose, className, children }) => {
  const containerRef = useOnclickOutside(onClose);

  return (
    <div ref={containerRef} className={clsx(styles.container, className)}>
      <div>{children}</div>
    </div>
  );
};

Dropdown.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.element,
  className: PropTypes.string,
};
export default Dropdown;

import React from 'react';
import styles from './CommentModal.scss';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import TimesIcon from '../../Icons/TimesIcon';

const CommentModal = ({ onClose, isOpen, comment }) => {
  return (
    <Modal close={onClose} id="CommentModal" isOpen={isOpen}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <button className={styles.close} onClick={onClose}>
            <TimesIcon width="20" height="18" />
          </button>
          <h2 className={styles.title}>Comment</h2>
          <p className={styles.text}>{comment}</p>
        </div>
      </div>
    </Modal>
  );
};

CommentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  comment: PropTypes.string.isRequired,
};
export default CommentModal;

import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import SearchInput from 'components/SearchInput';
import OrdersTable from './OrdersTable';

import styles from './Orders.scss';
import useAction from '../../hooks/useAction';
import { paginatedDeskActions } from '../../models/paginatedOrders/slice';
import { useSelector } from 'react-redux';
import {
  paginatedOrdersEntitiesSelector,
  paginatedOrdersFiltersSelector,
  paginatedOrdersOnlyLoadingSelector,
  paginatedOrdersPaginationSelector,
} from '../../models/paginatedOrders/selectors';
import DateRangePicker from '../../components/DateRangePicker';
import { format } from 'date-fns';
import { getEndDate, getStartDate } from 'utils/date';
import TimesIcon from '../../components/Icons/TimesIcon';
import PriceInputRange from '../../components/PriceInputRange';
import { SORTING_DIRECTION_TYPES } from '../../constants/sorting';
import { useLocation, useHistory } from 'react-router-dom';

const Orders = () => {
  const changeFilters = useAction(paginatedDeskActions.changeFilters);
  const {
    sort_field,
    sort_direction,
    customer_info_contains,
    page,
    total_price_range_start,
    total_price_range_end,
    paid_at_date_range_start,
    paid_at_date_range_end,
  } = useSelector(paginatedOrdersFiltersSelector);

  const pagination = useSelector(paginatedOrdersPaginationSelector) ?? {};
  const orders = useSelector(paginatedOrdersEntitiesSelector);
  const loading = useSelector(paginatedOrdersOnlyLoadingSelector);
  const [ordersPage, setOrdersPage] = useState(page ?? 1);
  const [sortingField, setSortingField] = useState(sort_field);
  const [sortingDirection, setSortingDirection] = useState(sort_direction);
  const [search, setSearch] = useState(customer_info_contains ?? '');
  const [paidDateStart, setPaidDateStart] = useState(
    paid_at_date_range_start && new Date(paid_at_date_range_start)
  );
  const [paidDateEnd, setPaidDateEnd] = useState(
    paid_at_date_range_end && new Date(paid_at_date_range_end)
  );

  const [priceStart, setPriceStart] = useState(total_price_range_start ?? 0);
  const [priceEnd, setPriceEnd] = useState(total_price_range_end ?? 1500);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    changeFilters({
      sort_field: sortingField,
      page: ordersPage,
      sort_direction: sortingDirection,
      customer_info_contains: search,
      paid_at_date_range_start: paidDateStart
        ? format(
            getStartDate(new Date(paidDateStart)),
            "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
          )
        : null,
      paid_at_date_range_end: paidDateEnd
        ? format(
            getEndDate(new Date(paidDateEnd)),
            "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
          )
        : null,
      total_price_range_start: priceStart ?? '',
      total_price_range_end: priceEnd ?? '',
    });
  }, [
    ordersPage,
    sortingField,
    sortingDirection,
    search,
    priceStart,
    priceEnd,
    paidDateEnd,
    paidDateStart,
  ]);

  const onSortingChangeHandler = (data, initialSortType) => {
    if (sortingField !== data) {
      setSortingDirection(initialSortType || SORTING_DIRECTION_TYPES.ASC);
    } else {
      setSortingDirection(
        sortingDirection === SORTING_DIRECTION_TYPES.ASC
          ? SORTING_DIRECTION_TYPES.DESC
          : SORTING_DIRECTION_TYPES.ASC
      );
    }
    setSortingField(data);

    if (location.state?.prevPageOrder) {
      history.replace({
        ...history.location,
        state: { ...location.state, prevPageOrder: false },
      });
    } else {
      setOrdersPage(1);
    }
  };

  const onSearchChangeHandler = value => {
    setSearch(value);

    if (location.state?.prevPageOrder) {
      history.replace({
        ...history.location,
        state: { ...location.state, prevPageOrder: false },
      });
    } else {
      setOrdersPage(1);
    }
  };

  const onPriceChangeHandler = ({ min, max }) => {
    setPriceStart(+min);
    setPriceEnd(+max);

    if (location.state?.prevPageOrder) {
      history.replace({
        ...history.location,
        state: { ...location.state, prevPageOrder: false },
      });
    } else {
      setOrdersPage(1);
    }
  };

  const onPaidAtDateRangeChange = range => {
    const { startDate, endDate } = range;
    const currentDate = new Date();

    if (startDate && startDate > currentDate) {
      // Prevent selecting future dates as the start date
      return;
    }

    if (endDate && endDate > currentDate) {
      // Prevent selecting future dates as the end date
      return;
    }
    setPaidDateStart(range?.startDate);
    setPaidDateEnd(range?.endDate);

    if (location.state?.prevPageOrder) {
      history.replace({
        ...history.location,
        state: { ...location.state, prevPageOrder: false },
      });
    } else {
      setOrdersPage(1);
    }
  };

  const onResetPaidDateRangeClick = () => {
    setPaidDateStart(null);
    setPaidDateEnd(null);
  };

  const debouncedSearchChangeHandler = debounce(onSearchChangeHandler, 300);
  const debouncedPriceChangeHandler = debounce(onPriceChangeHandler, 300);

  useEffect(() => {
    const cleanupFunction = () => {
      debouncedSearchChangeHandler.cancel();
      debouncedPriceChangeHandler.cancel();
    };

    return cleanupFunction;
  }, [debouncedSearchChangeHandler, debouncedPriceChangeHandler]);

  return (
    <div className={styles.root}>
      <div className={styles.topbar}>
        <div className={styles.rangeFilters}>
          <div className={styles.filterMargin}>
            <DateRangePicker
              title="Paid date range"
              startDate={paidDateStart}
              endDate={paidDateEnd}
              onRangeChange={onPaidAtDateRangeChange}
            />
          </div>
          {(paidDateStart || paidDateEnd) && (
            <button
              onClick={onResetPaidDateRangeClick}
              className={styles.reset}
            >
              <TimesIcon />
            </button>
          )}
          <div className={styles.priceFilters}>
            <PriceInputRange
              className={styles.inputRange}
              minValue={0}
              maxValue={1500}
              value={{
                min: priceStart,
                max: priceEnd,
              }}
              onChange={debouncedPriceChangeHandler}
            />
          </div>
        </div>

        <SearchInput
          defaultValue={search}
          onChange={event => debouncedSearchChangeHandler(event.target.value)}
        />
      </div>

      <div className={styles.content}>
        <OrdersTable
          orders={orders}
          pagination={pagination}
          sortingField={sort_field}
          sortingOrder={sort_direction}
          loading={loading}
          onPageChange={setOrdersPage}
          onSortingChange={onSortingChangeHandler}
        />
      </div>
    </div>
  );
};

export default Orders;

import React from 'react';

const CheckIcon = props => (
  <svg
    {...props}
    width="11"
    height="9"
    viewBox="0 0 11 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 4.80488L3.4 7L10 1" stroke="#B7B4AF" strokeWidth="1.5" />
  </svg>
);

export default CheckIcon;

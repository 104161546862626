import {
  takeLatest,
  takeEvery,
  all,
  put,
  call,
  select,
} from 'redux-saga/effects';
import { get, omit } from 'lodash';
import { push } from 'connected-react-router';
import { authenticatedRequest } from 'api';
import {
  desksUrl,
  ordersUrl,
  orderItemsUrl,
  constructOrderUrl,
  constructOrderItemsUrl,
  constructDesksDeactivateUrl,
  constructOrderPayInCashUrl,
  constructDesksAttachUrl,
  constructDesksDetachUrl,
  constructOrderTastingMenuChangingUrl,
  customersUrl,
  constructClearCartUrl,
  guestOrdersUrl,
  activeOrdersUrl,
} from 'api/urls';
import { toast } from 'react-toastify';
import strings from 'constants/strings';
import {
  currentOrderIdSelector,
  orderBottleProductsMapSelector,
  orderSampleProductsMapSelector,
  currentDeskIdSelector,
  orderFoodMapSelector,
  orderTastingMenuSelector,
  receiptEmailSelector,
  tastingMenuMapSelector,
  posOrdersSelector,
} from 'models/desks/selectors';
import { selectedRoomIdSelector } from 'models/rooms/selectors';
import { printersSelector } from 'models/session/selectors';
import { desksActions } from 'models/desks/slice';
import { interfaceActions } from 'models/interface/slice';
import { stripeActions } from 'models/stripe/slice';
import { sessionActions } from 'models/session/slice';
import { OrderItemsResourceTypes } from 'constants/orderItems';
import assertHasProperty from 'utils/assertHasProperty';
import { PAYMENT_METHODS } from 'pages/Checkout/constants';
import { initialBreadcrumbsState } from 'constants/menuBreadcrumbs';
import { POSDashboardLayoutsEnum } from 'constants/POSLayouts';
import { menuBreadcrumbsSelector } from '../interface/selectors';
import { getFirstEmptyOrder } from 'utils/getFirstEmptyOrder';
import { generateURLPrintReceipt } from 'utils/generateURLReceipt';

export function* fetchDesksSaga() {
  try {
    const roomId = yield select(selectedRoomIdSelector);
    if (roomId == null) {
      throw new Error('fetchDesksSaga: room id is null');
    }

    const { data } = yield authenticatedRequest({
      url: desksUrl,
      params: {
        tasting_room_id: roomId,
        per_page: 100,
      },
    });

    yield put({
      type: desksActions.fetchDesksSuccess,
      payload: data,
    });
  } catch (err) {
    console.error(err);
    yield put({ type: desksActions.fetchDesksFailure });
  }
}

function* deactivateDeskSaga({ payload }) {
  try {
    if (!Object.getOwnPropertyDescriptor(payload, 'id')) {
      throw new Error('deactivateDeskSaga: id is empty');
    }

    const { data } = yield authenticatedRequest({
      url: constructDesksDeactivateUrl({ id: payload.id }),
      data: omit(payload, 'id'),
      method: 'POST',
    });

    yield put({
      type: desksActions.deactivateDeskSuccess,
      payload: data,
    });

    yield put({ type: desksActions.closeDeskSessionCloseConfirmation });
    yield call([toast, 'success'], 'Table session has been deactivated', {
      progressClassName: 'customProgressBarSuccess',
    });
  } catch (err) {
    console.error(err);
    yield put({ type: desksActions.deactivateDeskFailure });
    yield call(
      [toast, 'error'],
      'Deactivating the table resulted in an internal error'
    );
  }
}

/**
 * Detect whether orderItem exists or not.
 * If so, just update the number, otherwise create an instance
 */
export function* storeOrderItemSaga({ payload }) {
  try {
    const id = payload.product_id || payload.products_preset_id;
    const currentOrderId = yield select(currentOrderIdSelector);
    const bottleProductsMap = yield select(orderBottleProductsMapSelector);
    const sampleProductsMap = yield select(orderSampleProductsMapSelector);
    const foodsMap = yield select(orderFoodMapSelector);
    const tastingMenuMap = yield select(tastingMenuMapSelector);
    const orderItemLocation = type => {
      switch (type) {
        case OrderItemsResourceTypes.PRODUCT_ORDER_ITEM:
          return bottleProductsMap;
        case OrderItemsResourceTypes.PRODUCT_SAMPLE_ORDER_ITEM:
          return sampleProductsMap;
        case OrderItemsResourceTypes.FOOD_ORDER_ITEM:
          return foodsMap;
        case OrderItemsResourceTypes.TASTING_MENU_ORDER_ITEM:
          return tastingMenuMap;
        default:
          return [];
      }
    };

    const genericPayloadData = {
      ...omit(payload, 'type'),
      order_id: currentOrderId,
      resourcetype: payload.type,
    };

    const productOrder = get(orderItemLocation(payload.type), id, null);

    if (productOrder === null) {
      yield put({
        type: desksActions.createOrderItemRequest,
        payload: {
          ...genericPayloadData,
          addedNumber: genericPayloadData.number,
        },
      });
    }
    yield put({
      type: desksActions.fetchOrderRequest,
      payload: { id: currentOrderId },
    });
  } catch (err) {
    console.error(err);
  }
}

export function* updateOrderItemSaga({ payload }) {
  try {
    const { data } = yield authenticatedRequest({
      method: 'PATCH',
      data: payload,
      url: constructOrderItemsUrl({ id: payload.orderItemId }),
    });
    yield put({
      type: desksActions.updateOrderItemSuccess,
      payload: {
        orderItem: data,
        orderId: payload.order_id,
        addedNumber: payload.addedNumber,
      },
    });
    yield put({
      type: desksActions.fetchOrderRequest,
      payload: { id: payload.order_id },
    });
  } catch (err) {
    console.error(err);
    const errorMessages = err.response?.data?.id || [];
    const hasDeadlockError = errorMessages
      .map(message => message === 'Deadlock Exception')
      .includes(true);
    yield put({
      type: desksActions.updateOrderItemFailure,
      payload: {
        hasDeadlockError,
      },
    });
    if (hasDeadlockError) {
      yield put({
        type: desksActions.fetchOrderRequest,
        payload: { id: payload.order_id },
      });
    } else {
      yield call(
        [toast, 'error'],
        'Something went wrong while updating the order item'
      );
    }
  }
}

export function* createOrderItemSaga({ payload }) {
  try {
    const { data } = yield authenticatedRequest({
      data: payload,
      method: 'POST',
      url: orderItemsUrl,
    });

    yield put({
      type: desksActions.createOrderItemSuccess,
      payload: { orderItem: data, orderId: payload.order_id },
    });

    yield put({
      type: desksActions.fetchOrderRequest,
      payload: { id: payload.order_id },
    });

    const isFoodOrderItem =
      data.resourcetype === OrderItemsResourceTypes.FOOD_ORDER_ITEM &&
      !data.addition;
    if (isFoodOrderItem) {
      const breadcrumbs = yield select(menuBreadcrumbsSelector);
      yield put({
        type: interfaceActions.changeMenuBreadcrumbs,
        payload: breadcrumbs.slice(0, -1),
      });
    }
    return data.id;
  } catch (err) {
    console.error(err);
    // Extract error message from server response
    const errorMessage = err.response?.data?.product_id
      ? err.response.data.product_id[0] // Specific error message
      : err.message || 'An unknown error occurred'; // Fallback to generic error message

    yield call([toast, 'error'], errorMessage, {
      progressClassName: 'customProgressBarError',
    });
    yield put({
      type: desksActions.createOrderItemFailure,
    });
  }
  // eslint-disable-next-line no-unreachable
  return undefined;
}

export function* createTastingOrderItemSaga({ payload }) {
  try {
    const tastingMenu = yield select(orderTastingMenuSelector);
    const tastingMenuItem = tastingMenu[0];
    if (!tastingMenuItem) {
      yield put({
        type: desksActions.createOrderItemRequest,
        payload: {
          ...payload,
          number: 1,
          resourcetype: OrderItemsResourceTypes.TASTING_MENU_ORDER_ITEM,
        },
      });
    }
  } catch (err) {
    console.log(err);
  }
}

// eslint-disable-next-line consistent-return
export function* removeOrderItemSaga({ payload: { orderId, orderItemId } }) {
  try {
    yield authenticatedRequest({
      url: constructOrderItemsUrl({ id: orderItemId }),
      method: 'DELETE',
    });
    yield put({
      type: desksActions.removeOrderItemSuccess,
      payload: { orderId, orderItemId },
    });
    yield put({
      type: desksActions.fetchOrderRequest,
      payload: { id: orderId },
    });
    return 'ok';
  } catch (err) {
    console.log(err);
    yield put({ type: desksActions.removeOrderItemFailure });
  }
}

function* createOrderSaga({ payload }) {
  try {
    const currentDeskId = yield select(currentDeskIdSelector);

    const { data } = yield authenticatedRequest({
      url: ordersUrl,
      data: { ...payload, table_id: currentDeskId, tips_amount: 0 },
      method: 'POST',
    });

    yield put({
      type: desksActions.createOrderSuccess,
      payload: { order: data, deskId: currentDeskId },
    });

    yield call([toast, 'success'], `Guest successfully created`, {
      progressClassName: 'customProgressBarSuccess',
    });
  } catch (err) {
    console.error(err);
    yield put({ type: desksActions.createOrderFailure });
  }
}

function* updateOrderSaga({ payload }) {
  try {
    const currentDeskId = yield select(currentDeskIdSelector);

    const { data } = yield authenticatedRequest({
      url: constructOrderUrl({ id: payload.id }),
      data: { ...payload },
      method: 'PUT',
    });

    yield put({
      type: desksActions.replaceOrderData,
      payload: { order: data, deskId: currentDeskId },
    });

    yield put({ type: desksActions.updateOrderSuccess });

    yield put({
      type: interfaceActions.changePOSDashboardLayout,
      payload: POSDashboardLayoutsEnum.MAIN,
    });
    yield put({
      type: desksActions.fetchOrderRequest,
      payload: { id: payload.id, skip_loading: true },
    });
  } catch (err) {
    yield put({ type: desksActions.updateOrderFailure });
    yield put({
      type: interfaceActions.changeMenuBreadcrumbs,
      payload: initialBreadcrumbsState,
    });
    const errors = err?.response?.data;
    let errorMessage = '';
    if (errors) {
      Object.keys(errors).forEach(async key => {
        errors[key].forEach(async error => {
          errorMessage += `${error}\t\n`;
        });
      });
    }
    yield call([toast, 'error'], errorMessage, {
      progressClassName: 'customProgressBarError',
    });
  }
}

function* deleteOrderSaga({ payload }) {
  try {
    // Make the API request to delete the order
    yield authenticatedRequest({
      url: constructOrderUrl({ id: payload }),
      method: 'DELETE',
    });

    // Dispatch the deleteOrderSuccess action with the orderId
    yield put({
      type: desksActions.deleteOrderSuccess,
      payload,
    });
  } catch (error) {
    // Dispatch the deleteOrderFailure action
    console.log(error);
    yield put({ type: desksActions.deleteOrderFailure });
  }
}

function* changeOrderPaymentToCashSaga({ payload }) {
  try {
    assertHasProperty(
      payload,
      'orderId',
      'changeOrderPaymentToCashSaga: property orderId is undefined'
    );

    assertHasProperty(
      payload,
      'deskId',
      'changeOrderPaymentToCashSaga: property deskId is undefined'
    );

    const { orderId, deskId, orderData } = payload;
    const receiptEmail = yield select(receiptEmailSelector);

    const { data } = yield authenticatedRequest({
      method: 'POST',
      url: constructOrderPayInCashUrl({ id: orderId }),
    });

    yield put(push('/dashboard/pos'));

    yield put({
      type: desksActions.changeOrderPaymentToCashSuccess,
      payload: {
        deskId,
        orderId,
        status: data.status,
        orderData,
      },
    });

    yield put({
      type: stripeActions.sendReceipt,
      payload: { email: receiptEmail, orderId },
    });

    yield call([toast, 'success'], strings.successfullyChangeToPayInCash, {
      progressClassName: 'customProgressBarSuccess',
    });
  } catch (err) {
    yield put({ type: desksActions.changeOrderPaymentToCashFailure });
  }
}

function* changeOrderPaymentToCashSuccessSaga({ payload }) {
  try {
    const { orderId, orderData } = payload;
    const orders = yield select(posOrdersSelector);
    const emptyOrder = getFirstEmptyOrder(Object.values(orders));

    // Convert order_items object to an array
    const itemsArray = Object.values(orderData.order_items);

    // Create a new order object with the modified items array
    const modifiedOrderData = {
      ...orderData,
      order_items: itemsArray,
    };

    // Print receipt
    const printers = yield select(printersSelector);
    if (printers?.length > 0) {
      const receiptContent = generateURLPrintReceipt(modifiedOrderData); // Generate receipt content
      const firstPrinterMac = printers[0].identifier.toLowerCase();
      yield put({
        type: sessionActions.printRequest,
        payload: {
          mac: firstPrinterMac,
          receipt: receiptContent,
        },
      });
    }

    yield put({
      type: desksActions.removeOrder,
      payload: orderId,
    });
    if (emptyOrder) {
      yield put({
        type: desksActions.setCurrentOrderId,
        payload: {
          id: emptyOrder.id,
          isGuest: false,
        },
      });
    } else {
      yield put({ type: desksActions.createOrderRequest });
    }
  } catch (error) {
    console.error(error);
  }
}

function* requestWaiterForPaymentSaga({ payload }) {
  try {
    assertHasProperty(
      payload,
      'order_id',
      'requestWaiterForPaymentSaga: property order_id is undefined'
    );
    yield call([toast, 'success'], strings.callWaiter.cash(payload), {
      autoClose: false,
      progressClassName: 'customProgressBarSuccess',
    });
  } catch (err) {
    console.error(err);
  }
}

function* changeOrderTipsSaga({ payload }) {
  try {
    assertHasProperty(
      payload,
      'orderId',
      'fetchOrderTipsSaga: property orderId is undefined'
    );

    const { orderId, deskId, tips, paymentMethod } = payload;

    yield authenticatedRequest({
      method: 'PATCH',
      url: constructOrderUrl({ id: payload.orderId }),
      data: {
        tips_amount: payload.tips,
      },
    });

    yield put({
      type: desksActions.changeOrderTipsSuccess,
      payload: { orderId, deskId, tips },
    });

    switch (paymentMethod) {
      case PAYMENT_METHODS.CASH:
        yield put(push(`/dashboard/payment/cash`));
        break;
      case PAYMENT_METHODS.DEBIT: {
        // TODO: uncomment when reader is ready
        yield put(push(`/dashboard/payment/reader/select`));
        // yield put(push(`/dashboard/payment/alternative-card`));
        break;
      }
      default:
        yield put(push('/dashboard/payment'));
    }
  } catch (err) {
    console.error(err);
    yield put({
      type: desksActions.changeOrderTipsFailure,
    });
  }
}

function* attachTerminalSaga({ payload }) {
  const { terminal } = payload;
  try {
    const orderItemId = yield select(currentDeskIdSelector);
    yield authenticatedRequest({
      url: constructDesksAttachUrl({ id: orderItemId }),
      method: 'POST',
      data: { terminal_id: terminal.id },
    });
    yield put({
      type: desksActions.attachTerminalSuccess,
      payload: terminal,
    });
    yield call([toast, 'success'], `Terminal ${terminal.title} was attached`, {
      progressClassName: 'customProgressBarSuccess',
    });
  } catch (err) {
    yield call([toast, 'error'], `Can't attach terminal ${terminal.title}`, {
      progressClassName: 'customProgressBarError',
    });
  }
}

function* detachTerminalSaga({ payload }) {
  try {
    const orderItemId = yield select(currentDeskIdSelector);
    yield authenticatedRequest({
      url: constructDesksDetachUrl({ id: orderItemId }),
      method: 'POST',
      data: { terminal_id: payload.id },
    });
    yield put({
      type: desksActions.detachTerminalSuccess,
      payload,
    });
    yield call([toast, 'success'], `Terminal ${payload.title} was detached`, {
      progressClassName: 'customProgressBarSuccess',
    });
  } catch (err) {
    yield call([toast, 'error'], `Can't detach terminal ${payload.title}`, {
      progressClassName: 'customProgressBarError',
    });
  }
}

function* changeTastingMenuPriceSaga({ payload }) {
  try {
    const { id, zero_price } = payload;
    const { data } = yield authenticatedRequest({
      url: constructOrderTastingMenuChangingUrl({ id }),
      method: 'POST',
      data: { zero_price },
    });
    yield put({
      type: desksActions.changeTastingMenuPriceSuccess,
      payload: data,
    });
    yield call([toast, 'success'], `Tasting Menu price was changed`, {
      progressClassName: 'customProgressBarSuccess',
    });
  } catch (err) {
    console.log(err);
    yield put({
      type: desksActions.changeTastingMenuPriceFailure,
    });
    yield call([toast, 'error'], `Can't change Tasting Menu price`, {
      progressClassName: 'customProgressBarError',
    });
  }
}

function* fetchCustomersSaga({ payload }) {
  try {
    const { data } = yield authenticatedRequest({
      url: customersUrl,
      method: 'GET',
      params: { per_page: 10, ...payload },
    });
    yield put({
      type: desksActions.fetchCustomersSuccess,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: desksActions.fetchCustomersFailure,
      payload: err,
    });
  }
}

function* clearCartSaga({ payload }) {
  try {
    const { data } = yield authenticatedRequest({
      url: constructClearCartUrl({ id: payload.id }),
      method: 'POST',
    });
    yield put({
      type: desksActions.clearCartSuccess,
      payload: data,
    });
  } catch (err) {
    yield put({ type: desksActions.clearCartFailure });
  }
}

function* fetchOrderSaga({ payload }) {
  const { id } = payload;
  try {
    const { data } = yield authenticatedRequest({
      url: constructOrderUrl({ id }),
    });
    yield put({
      type: desksActions.fetchOrderSuccess,
      payload: data,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: desksActions.fetchOrderFailure,
    });
  }
}

function* fetchGuestOrdersSaga() {
  try {
    const { data } = yield authenticatedRequest({
      url: guestOrdersUrl,
      method: 'GET',
    });
    yield put({
      type: desksActions.fetchGuestOrdersSuccess,
      payload: data,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: desksActions.fetchGuestOrdersFailure,
    });
  }
}

function* fetchActiveOrdersSaga() {
  try {
    const { data } = yield authenticatedRequest({
      url: activeOrdersUrl,
      method: 'GET',
    });
    yield put({
      type: desksActions.fetchActiveOrdersSuccess,
      payload: data,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: desksActions.fetchActiveOrdersSuccessFailure,
    });
  }
}

function* paidByCardSaga({ payload }) {
  try {
    const { orderId, isPaidByAlternativeCard, orderData } = payload;
    const receiptEmail = yield select(receiptEmailSelector);
    const { data } = yield authenticatedRequest({
      url: `/tasting-room-master-api/v1/orders/${orderId}/pay_by_card`,
      method: 'POST',
      data: { isPaidByAlternativeCard },
    });

    yield put(push('/dashboard/pos'));

    yield put({
      type: desksActions.paidByCardSuccess,
      payload: { ...data, orderId, isPaidByAlternativeCard, orderData },
    });

    yield put({
      type: stripeActions.sendReceipt,
      payload: { email: receiptEmail, orderId },
    });

    yield call([toast, 'success'], strings.successfullyChangeToPayByCard, {
      progressClassName: 'customProgressBarSuccess',
    });
  } catch (err) {
    yield put({
      type: desksActions.paidByCardFailure,
      payload: err,
    });
    const errorMessages = err.response?.data?.id || [];
    yield* errorMessages.map(message =>
      call(
        [toast, 'error'],
        `Payment by Alternative Processor error: ${message}`
      )
    );
  }
}

function* paidByCardSuccessSaga({ payload }) {
  try {
    const { orderId, orderData } = payload;
    const orders = yield select(posOrdersSelector);
    const emptyOrder = getFirstEmptyOrder(Object.values(orders));

    // Convert order_items object to an array
    const itemsArray = Object.values(orderData.order_items);

    // Create a new order object with the modified items array
    const modifiedOrderData = {
      ...orderData,
      order_items: itemsArray,
    };

    // Print receipt
    const printers = yield select(printersSelector);
    if (printers?.length > 0) {
      const receiptContent = generateURLPrintReceipt(modifiedOrderData); // Generate receipt content
      const firstPrinterMac = printers[0].identifier.toLowerCase();
      yield put({
        type: sessionActions.printRequest,
        payload: {
          mac: firstPrinterMac,
          receipt: receiptContent,
        },
      });
    }

    yield put({
      type: desksActions.removeOrder,
      payload: orderId,
    });
    if (emptyOrder) {
      yield put({
        type: desksActions.setCurrentOrderId,
        payload: {
          id: emptyOrder.id,
          isGuest: false,
        },
      });
    } else {
      yield put({ type: desksActions.createOrderRequest });
    }
  } catch (error) {
    console.error(error);
  }
}

export default function*() {
  yield all([
    takeEvery(desksActions.createOrderItemRequest, createOrderItemSaga),
    takeEvery(
      desksActions.createTastingOrderItemRequest,
      createTastingOrderItemSaga
    ),
    takeEvery(desksActions.removeOrderItemRequest, removeOrderItemSaga),
    takeEvery(desksActions.updateOrderItemRequest, updateOrderItemSaga),
    takeEvery(desksActions.storeOrderItem, storeOrderItemSaga),
    takeEvery(desksActions.createOrderRequest, createOrderSaga),
    takeEvery(desksActions.updateOrderRequest, updateOrderSaga),
    // 2: The saga listens to this action and executes the deleteOrderSaga generator function
    takeEvery(desksActions.deleteOrderRequest, deleteOrderSaga),
    takeLatest(desksActions.deactivateDeskRequest, deactivateDeskSaga),
    takeLatest(desksActions.fetchDesksRequest, fetchDesksSaga),
    takeLatest(desksActions.changeOrderTipsRequest, changeOrderTipsSaga),
    takeLatest(
      desksActions.changeOrderPaymentToCashRequest,
      changeOrderPaymentToCashSaga
    ),
    takeLatest(
      desksActions.changeOrderPaymentToCashSuccess,
      changeOrderPaymentToCashSuccessSaga
    ),
    takeEvery(
      desksActions.requestWaiterForPayment,
      requestWaiterForPaymentSaga
    ),
    takeLatest(desksActions.attachTerminal, attachTerminalSaga),
    takeLatest(desksActions.detachTerminal, detachTerminalSaga),
    takeLatest(
      desksActions.changeTastingMenuPriceRequest,
      changeTastingMenuPriceSaga
    ),
    takeLatest(desksActions.fetchCustomersRequest, fetchCustomersSaga),
    takeLatest(desksActions.clearCart, clearCartSaga),
    takeLatest(desksActions.fetchGuestOrdersRequest, fetchGuestOrdersSaga),
    takeLatest(desksActions.fetchOrderRequest, fetchOrderSaga),
    takeLatest(desksActions.fetchActiveOrders, fetchActiveOrdersSaga),
    takeLatest(desksActions.paidByCard, paidByCardSaga),
    takeLatest(desksActions.paidByCardSuccess, paidByCardSuccessSaga),
  ]);
}

import React from 'react';
import styles from './CustomerInfoCard.scss';
import CopyIcon from '../Icons/CopyIcon';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PhoneIcon from '../Icons/PhoneIcon';
import EmailIcon from '../Icons/EmailIcon';
import { copy } from '../../utils/copy';

const CustomerInfoCard = ({ id, email, first_name, last_name, phone }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <p className={styles.name}>
            {first_name} {last_name}
          </p>
          <small className={styles.small}>#{id}</small>
        </div>
        <Link to={`/dashboard/customers/${id}`} className={styles.link}>
          View
        </Link>
      </div>
      {phone && (
        <div className={styles.wrapper}>
          <PhoneIcon />
          <a href={`tel:${phone}`} className={styles.text}>
            {phone}
          </a>
        </div>
      )}
      <div className={styles.wrapper}>
        <EmailIcon />
        <a href={`mailto:${email}`} className={styles.text}>
          {email}
        </a>
        <button className={styles.copy} onClick={() => copy(email)}>
          <CopyIcon />
        </button>
      </div>
    </div>
  );
};

CustomerInfoCard.propTypes = {
  id: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  phone: PropTypes.string,
};
export default CustomerInfoCard;

import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import PrivateRoute from 'components/PrivateRoute';
import PublicRoute from 'components/PublicRoute';

import NotFound from 'pages/NotFound';

const Router = ({ routes }) => (
  <Switch>
    {routes.map(route =>
      route.private ? (
        <PrivateRoute key={route.path} {...route} />
      ) : (
        <PublicRoute key={route.path} {...route} exact />
      )
    )}
    {/* <Route path="/" exact>
      <Redirect to="/dashboard/tasting" />
    </Route> */}
    <Route path="*" exact component={NotFound} />
  </Switch>
);

Router.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default Router;

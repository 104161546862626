import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './Notes.scss';
import Block from '../Block';
import { format } from 'date-fns';

const Notes = ({ notes }) => {
  return (
    <Block title="Notes" number={notes?.length} className={styles.block}>
      <ul>
        {notes?.map(item => (
          <li className={styles.item}>
            <p className={styles.itemDate}>
              {format(new Date(item.created_at), 'dd.MM.yyyy')}
            </p>
            <p className={styles.itemNote}>{item.text}</p>
          </li>
        ))}
      </ul>
    </Block>
  );
};

Notes.propTypes = {
  notes: PropTypes.array,
};

export default memo(Notes);

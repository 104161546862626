import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ProductCategories from 'components/ProductCategories';
import ProductSubCategories from 'components/ProductSubCategories';
import ProductInventory from 'components/ProductInventory';
import { INVENTORY_TYPES } from 'constants/inventory';
import ProductWine from 'components/ProductWine';
import ProductFood from 'components/ProductFood';
import ProductDiscount from 'components/ProductDiscount';
import useAction from 'hooks/useAction';
import { desksActions } from 'models/desks/slice';
import { discountsActions } from 'models/discounts/slice';
import useSelector from 'hooks/useSelector';
import {
  currentOrderIdSelector,
  orderItemPending,
  orderItemUpdatePending,
} from 'models/desks/selectors';
import { discountsSelector } from 'models/discounts/selectors';
import { OrderItemsResourceTypes } from 'constants/orderItems';
import { warehouseObjectKey, brandKey } from 'constants/localStorage';
import { formatUTCDate } from 'utils/formatDate';

const MenuLayoutComponent = ({ element, categories, onClick, allergies }) => {
  // Selectors
  const orderId = useSelector(currentOrderIdSelector);
  const createItemPending = useSelector(orderItemPending);
  const updateItemPending = useSelector(orderItemUpdatePending);
  const discounts = useSelector(discountsSelector);

  // Actions
  const createItem = useAction(desksActions.storeOrderItem);
  const fetchDiscounts = useAction(discountsActions.fetchDiscounts);

  // Variables
  const isPending = updateItemPending || createItemPending;
  const currentWarehouse =
    JSON.parse(localStorage.getItem(warehouseObjectKey)) || {};
  const currentBrandID = JSON.parse(localStorage.getItem(brandKey)) || {};

  // Effects
  useEffect(() => {
    const date = new Date();
    fetchDiscounts({
      warehouse_id: currentWarehouse.id,
      page: 1,
      per_page: 100,
      status: 'ACTIVE',
      brand_id: currentBrandID,
      is_coupon: false,
      promotion_start_date_lte: formatUTCDate(date),
      promotion_end_date_gte: formatUTCDate(date),
    });
  }, []);

  // Handlers
  const onProductCategoryClick = item => {
    const isNotMainCategory = item?.title === 'Tasting fees' || item?.parent_id;
    if (!onClick) return;
    onClick({
      type: isNotMainCategory ? 'inventory' : 'subCategories',
      ...item,
    });
  };

  const onProductClick = product => {
    if (!onClick) return;
    const type = product?.resourcetype;
    if (type === INVENTORY_TYPES.MERCHANDISE) {
      createItem({
        number: 1,
        product_id: product.id,
        type: OrderItemsResourceTypes.PRODUCT_ORDER_ITEM,
      });
    } else if (type === INVENTORY_TYPES.TASTING_FEES) {
      createItem({
        number: 1,
        products_preset_id: product.id,
        type: OrderItemsResourceTypes.TASTING_MENU_ORDER_ITEM,
        order_id: orderId,
      });
    } else {
      onClick({
        type,
        ...product,
      });
    }
  };

  const ComponentTypes = {
    categories: (
      <ProductCategories
        categories={categories}
        onCategoryClick={onProductCategoryClick}
      />
    ),
    subCategories: (
      <ProductSubCategories
        categoryId={element?.id}
        categories={Object.values(element?.sub_categories ?? {})}
        onCategoryClick={item => onClick?.({ ...item, type: 'inventory' })}
        onProductClick={onProductClick}
      />
    ),
    inventory: (
      <ProductInventory
        disabled={isPending}
        onProductClick={onProductClick}
        categoryId={element?.id}
      />
    ),
    discounts: <ProductDiscount discounts={discounts.discounts.results} />,
    [INVENTORY_TYPES.WINE]: (
      <ProductWine disabled={isPending} product={element} />
    ),
    [INVENTORY_TYPES.FOOD]: (
      <ProductFood
        disabled={isPending}
        product={element}
        allergies={allergies}
      />
    ),
  };

  const RenderedComponent = () => ComponentTypes[element.type];

  return (
    <>
      <RenderedComponent />
    </>
  );
};

MenuLayoutComponent.propTypes = {
  element: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  allergies: PropTypes.array.isRequired,
  onClick: PropTypes.func,
};
export default MenuLayoutComponent;

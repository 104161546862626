import { createSelector } from 'reselect';

import isPhoneInvalid from 'utils/isPhoneInvalid';
import isAddressInvalid from 'utils/isAddressInvalid';

const selectSelf = state => state.wineClub;

export const joinModalSelector = createSelector(
  selectSelf,
  ({ joinModal }) => joinModal
);

export const wineClubLoadingSelector = createSelector(
  selectSelf,
  ({ loading }) => loading
);

export const wineClubStepSelector = createSelector(
  selectSelf,
  ({ step }) => step
);

export const customerIdSelector = createSelector(
  selectSelf,
  ({ customer }) => customer?.id
);

export const customerSelector = createSelector(
  selectSelf,
  ({ customer }) => customer
);

export const isValidForSubscriptionSelector = createSelector(
  selectSelf,
  ({ customer }) => {
    const address = customer?.customer_profile;
    const phone = customer?.phone;
    return !(
      isAddressInvalid(address) ||
      isPhoneInvalid(phone) ||
      (!customer.last_selected_tier?.id &&
        customer.last_selected_tier?.id !== 0)
    );
  }
);

export const intentSelector = createSelector(
  selectSelf,
  ({ intent }) => intent
);

export const wineDirectSettingsSelector = createSelector(
  selectSelf,
  ({ wineDirectSettings }) => wineDirectSettings
);

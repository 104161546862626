import React from 'react';
import { Route } from 'react-router-dom';

const PublicRoute = route => {
  return (
    <Route
      path={route.path}
      render={routeProps =>
        route.component && (
          <route.component routes={route.routes} {...routeProps} />
        )
      }
    />
  );
};

export default PublicRoute;

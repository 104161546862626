import React from 'react';
import styles from './Checkbox.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Checkbox = React.forwardRef(
  ({ name, label, className, ...props }, ref) => {
    return (
      <label className={clsx(styles.container, className)}>
        <input
          {...props}
          type="checkbox"
          name={name}
          className={styles.input}
          ref={ref}
        />
        <div className={styles.box}>
          <div className={styles.innerBox} />
        </div>
      </label>
    );
  }
);

Checkbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.any,
  className: PropTypes.string,
};
export default Checkbox;

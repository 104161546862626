/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import actionTypes from 'utils/actionTypes';

const foodSlice = createSlice({
  name: 'food',
  initialState: {
    categories: [],
    categoriesLoading: false,
    changeModal: false,

    allergies: [],
    allergiesLoading: false,

    foods: [],
    additions: {},
    foodPairings: [],
    foodsLoading: false,
    foodAdditionsLoading: false,
    meta: {},
  },
  reducers: {
    fetchCategories(state) {
      state.categoriesLoading = true;
    },
    fetchCategoriesSuccess(state, { payload }) {
      state.categoriesLoading = false;
      state.categories = payload;
    },
    fetchCategoriesFailure(state) {
      state.categoriesLoading = false;
    },

    fetchFoods(state) {
      state.foodsLoading = true;
    },
    fetchFoodsSuccess(state, { payload }) {
      state.foodsLoading = false;
      state.foods = [...state.foods, ...payload.results];
      state.meta = payload.pagination;
    },
    fetchFoodsFailure(state) {
      state.foodsLoading = false;
    },
    fetchFoodPairings(state) {
      state.foodsLoading = true;
    },
    fetchFoodPairingsSuccess(state, { payload }) {
      state.foodPairings = payload.results;
      state.foodsLoading = false;
    },
    fetchFoodPairingsFailure(state) {
      state.foodsLoading = false;
    },
    fetchFoodAllergies(state) {
      state.allergiesLoading = true;
    },
    fetchFoodAllergiesSuccess(state, { payload }) {
      state.allergies = payload;
      state.allergiesLoading = false;
    },
    fetchFoodAllergiesFailure(state) {
      state.allergiesLoading = false;
    },
    createFoodWithAdditions(state) {
      state.foodsLoading = true;
    },
    createFoodWithAdditionsSuccess(state) {
      state.foodsLoading = false;
    },
    removeFoodWithAdditions(state) {
      state.foodsLoading = true;
    },
    removeFoodWithAdditionsSuccess(state) {
      state.foodsLoading = false;
    },
    updateFoodWithAdditions(state) {
      state.foodsLoading = true;
    },
    updateFoodWithAdditionsSuccess(state) {
      state.foodsLoading = false;
      state.changeModal = false;
    },
    updateFoodWithAdditionsFailure(state) {
      state.foodsLoading = false;
      state.changeModal = true;
    },
    setChangeModal(state, { payload }) {
      state.changeModal = payload;
    },

    clearFoods(state) {
      state.foods = [];
      state.meta = {};
    },

    fetchFoodAdditions(state) {
      state.foodAdditionsLoading = true;
    },
    fetchFoodAdditionsSuccess(state, { payload }) {
      const { id, additions } = payload;
      state.foodAdditionsLoading = false;
      state.additions[id] = additions;
    },
    fetchFoodAdditionsFailure(state) {
      state.foodAdditionsLoading = false;
    },
  },
});

export const foodActions = actionTypes(foodSlice.actions);

export default foodSlice.reducer;

import React, { useMemo, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';

import useSelector from 'hooks/useSelector';
import {
  startedTimeEntrySelector,
  userSelector,
} from 'models/session/selectors';
import useAction from 'hooks/useAction';

import styles from './ShiftEntry.scss';
import ChevronLeftIcon from 'components/Icons/ChevronLeftIcon';
import clock from 'assets/images/clock.svg';
import DatePicker from 'components/DatePicker/DatePicker';
import Textarea from 'components/Textarea';
import { shiftStatus } from 'constants/shiftStatus';
import {
  refreshKey,
  warehouseKey,
  brandObjectKey,
  brandKey,
} from 'constants/localStorage';
import { PinAction } from 'constants/PinActions';
import { IDLE_TIMEOUT_MS } from 'constants/idleTimer';
import { sessionActions } from 'models/session/slice';
import { timeEntryActions } from 'models/timeEntry/slice';
import SelectLabelled from 'components/SelectLabelled';
import TimeSection from 'components/TimeSection';

import { startOfDayMessages, endOfDayMessages } from './ShiftEntryMessages';
import {
  startDate,
  endDate,
  getYesterday,
  isTimeInFuture,
  isMoreThanOneHourInFuture,
  isTimeInPast,
  convertTimeObjectToDate,
  getDateText,
  validateComment,
  buildDateTime,
} from './utils';

const ShiftEntry = () => {
  // Hooks
  const history = useHistory();
  const location = useLocation();

  // Selectors
  const startedTimeEntry = useSelector(startedTimeEntrySelector);
  const user = useSelector(userSelector);

  // Actions
  const createTimeEntry = useAction(timeEntryActions.createTimeEntry);
  const updateTimeEntry = useAction(timeEntryActions.updateTimeEntry);
  const logoutHost = useAction(sessionActions.logoutHostRequest);

  // Vars
  const now = new Date();
  const currentBrand = JSON.parse(localStorage.getItem(brandObjectKey)) || {};
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get('status');
  const warehouseOptions = useMemo(() => {
    return currentBrand?.warehouses?.map(warehouse => ({
      value: warehouse.id,
      label: warehouse.title,
      brandID: currentBrand?.id,
      warehouseID: warehouse.id,
    }));
  }, [currentBrand]);
  const title = (() => {
    switch (status) {
      case shiftStatus.NEW:
        return 'Shift Entry';
      case shiftStatus.START:
        return 'Start Shift';
      case shiftStatus.END:
        return 'End Shift';
      default:
        return '';
    }
  })();

  const isOldShift =
    startedTimeEntry &&
    new Date(startedTimeEntry?.started_at) < new Date().setHours(0, 0, 0, 0);
  const lockScreen = () => {
    const hasValuesFromLocalStorage =
      !!localStorage.getItem(brandKey) &&
      !!localStorage.getItem(`${brandKey}_object`) &&
      !!localStorage.getItem(warehouseKey) &&
      !!localStorage.getItem(`${warehouseKey}_object`);
    if (!hasValuesFromLocalStorage) {
      localStorage.clear();
      window.location.href = '/';
    }
    logoutHost({ refresh_token: localStorage.getItem(refreshKey) });
    history.push('/pin', { pinAction: PinAction.LOGIN });
  };

  // Local state
  const [currentWarehouse, setCurrentWarehouse] = useState({});
  const [startOfDayMessage] = useState(
    startOfDayMessages[Math.floor(Math.random() * 20)]
  );
  const [endOfDayMessage] = useState(
    endOfDayMessages[Math.floor(Math.random() * 20)]
  );
  const [selectedDate, setSelectedDate] = useState(() => {
    if (status === shiftStatus.NEW) {
      return getYesterday();
    } else if (status === shiftStatus.END && startedTimeEntry?.started_at) {
      return new Date(startedTimeEntry?.started_at);
    }
    return now;
  });
  const [showPicker, setShowPicker] = useState(false);
  const [startedComment, setStartedComment] = useState('');
  const [endedComment, setEndedComment] = useState('');
  const [startCommentError, setStartCommentError] = useState('');
  const [endCommentError, setEndCommentError] = useState('');
  const [startTime, setStartTime] = useState(() => {
    const MAX_HOUR = 15;
    const MAX_MINUTE = 45;

    let defaultHours24Format = startDate()?.getHours();
    let defaultMinutes = startDate()?.getMinutes();

    if (status === shiftStatus.NEW) {
      if (
        defaultHours24Format > MAX_HOUR ||
        (defaultHours24Format === MAX_HOUR && defaultMinutes > MAX_MINUTE)
      ) {
        defaultHours24Format = MAX_HOUR;
        defaultMinutes = MAX_MINUTE;
      }
    }

    const defaultHours12Format = defaultHours24Format % 12 || 12;
    const defaultAmOrPm = defaultHours24Format >= 12 ? 'pm' : 'am';

    return {
      hours: defaultHours12Format,
      minutes: defaultMinutes,
      amOrPm: defaultAmOrPm,
    };
  });

  const timeStateToDate = (time, baseDate) => {
    const date = baseDate || new Date();
    const hours24Format =
      time.amOrPm === 'pm' ? (time.hours % 12) + 12 : time.hours % 12;
    date.setHours(hours24Format, time.minutes, 0, 0);
    return date;
  };
  const [endTime, setEndTime] = useState(() => {
    if (status === shiftStatus.NEW) {
      const startTimeDate = timeStateToDate(startTime);
      const newEnd = new Date(startTimeDate.getTime() + 8 * 60 * 60 * 1000);
      return {
        hours: newEnd.getHours() % 12 || 12,
        minutes: newEnd.getMinutes(),
        amOrPm: newEnd.getHours() >= 12 ? 'pm' : 'am',
      };
    } else if (status === shiftStatus.END) {
      const adjustedEndDate = endDate();
      return {
        hours: adjustedEndDate.getHours() % 12 || 12,
        minutes: adjustedEndDate.getMinutes(),
        amOrPm: adjustedEndDate.getHours() >= 12 ? 'pm' : 'am',
      };
    }
    return {
      hours: endDate()?.getHours() % 12 || 20,
      minutes: endDate()?.getMinutes(),
      amOrPm: endDate()?.getHours() >= 12 ? 'pm' : 'am',
    };
  });
  const [spaceError, setSpaceError] = useState('');
  const [timeError, setTimeError] = useState('');

  const getTimeErrors = (submitting = false) => {
    // return first error
    if (status === shiftStatus.NEW) {
      const startDateTime = convertTimeObjectToDate(selectedDate, startTime);
      const endDateTime = convertTimeObjectToDate(selectedDate, endTime);

      if (endDateTime < startDateTime) return 'End time is before start time.';
      if (endDateTime === startDateTime)
        return 'End time is same as start time.';
    }
    if (status === shiftStatus.START && isTimeInPast(selectedDate, startTime))
      return 'Past time for start shift is not allowed.';
    if (status === shiftStatus.END && isTimeInFuture(selectedDate, endTime))
      return 'Future date for end shift is not allowed.';
    if (
      status === shiftStatus.START &&
      isTimeInFuture(selectedDate, startTime) &&
      isMoreThanOneHourInFuture(selectedDate, startTime)
    )
      return 'Start time cannot be more than 1 hour in the future.';

    // if we are submitting, check for additional errors
    if (submitting) {
      if (status === shiftStatus.NEW && !startTime)
        return 'Start time is required';
      if (status === shiftStatus.NEW && !endTime) return 'End time is required';
      if (status === shiftStatus.START && isTimeInPast(selectedDate, startTime))
        return 'Starting time cannot be in the past';
      if (status === shiftStatus.END && isTimeInFuture(selectedDate, endTime))
        return 'End of shift cannot be in the future';
      if (
        status === shiftStatus.END &&
        new Date(startedTimeEntry?.started_at) >
          buildDateTime(selectedDate, endTime)
      )
        return 'End of shift cannot be before start of shift.';
    }
    // No errors
    return '';
  };
  const validateTime = () => {
    setTimeError(getTimeErrors());
  };

  // Effects
  useEffect(validateTime, [startTime, endTime, selectedDate, status]);

  useEffect(() => {
    if (status === shiftStatus.NEW && currentWarehouse?.warehouseID) {
      setSpaceError('');
    }
  }, [currentWarehouse, status]);

  const handleClick = () => {
    history.goBack();
  };

  useIdleTimer({
    onIdle: () => {
      lockScreen();
    },
    timeout: IDLE_TIMEOUT_MS,
    throttle: 500,
  });

  // Handlers
  const handleDateSelect = (date, { disabled }) => {
    if (date && date > now && status === shiftStatus.END && !disabled) {
      setTimeError('Future time for end shift is not allowed.');
      return;
    }
    if (!disabled) {
      setSelectedDate(date);
    }
  };

  const handleConfirm = () => {
    const errors = getTimeErrors(true);
    if (errors) {
      setTimeError(errors);
      return;
    }

    if (status === shiftStatus.NEW && !currentWarehouse?.warehouseID) {
      setSpaceError('*Space is required');
      return;
    }

    setSpaceError('');

    if (
      (status === shiftStatus.START || status === shiftStatus.NEW) &&
      !validateComment(startedComment)
    ) {
      setStartCommentError('*Starting Comment is required');
    } else {
      setStartCommentError('');
    }

    if (
      (status === shiftStatus.END || status === shiftStatus.NEW) &&
      !validateComment(endedComment)
    ) {
      setEndCommentError('*Ending Comment is required');
    } else {
      setEndCommentError('');
    }

    const started_at = buildDateTime(selectedDate, startTime);
    const ended_at = buildDateTime(selectedDate, endTime);
    if (
      status === shiftStatus.START &&
      !timeError &&
      !startCommentError &&
      validateComment(startedComment)
    ) {
      createTimeEntry({
        status: shiftStatus.START,
        started_at,
        started_comment: startedComment,
        warehouse_id: localStorage.getItem(warehouseKey),
      });
    }
    if (
      status === shiftStatus.END &&
      !timeError &&
      !endCommentError &&
      validateComment(endedComment)
    ) {
      updateTimeEntry({
        status: shiftStatus.END,
        ended_at,
        ended_comment: endedComment,
        warehouse_id: localStorage.getItem(warehouseKey),
        id: startedTimeEntry?.id,
      });
    }

    if (
      status === shiftStatus.NEW &&
      !timeError &&
      !spaceError &&
      !startCommentError &&
      !endCommentError &&
      validateComment(startedComment) &&
      validateComment(endedComment)
    ) {
      createTimeEntry({
        status: shiftStatus.END,
        started_at,
        ended_at,
        started_comment: startedComment,
        ended_comment: endedComment,
        warehouse_id: currentWarehouse?.warehouseID,
      });
    }
  };

  const handleCancel = () => {
    if (status === shiftStatus.NEW || status === shiftStatus.END) {
      history.goBack();
    }
    if (status === shiftStatus.START) {
      history.push(`/dashboard/pos`);
    }
  };

  // Component

  return (
    <div className={styles.root}>
      <div className={styles.back}>
        <button onClick={handleClick} className={styles.backLink}>
          <ChevronLeftIcon width="16" height="16" /> <span>Back</span>
        </button>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.heading}>
          <div className={styles.titleContainer}>
            <i className={styles.icon}>
              <img src={clock} className={styles.clockIcon} alt="clock" />
            </i>
            <h1 className={styles.title}>{`${title}: ${getDateText(
              isOldShift && status === shiftStatus.END
                ? new Date(startedTimeEntry.started_at)
                : selectedDate
            )}`}</h1>
          </div>
        </div>
        {status === shiftStatus.NEW && (
          <div className={styles.changeButtonContainer}>
            <button
              onClick={() => setShowPicker(true)}
              className={styles.dateButton}
            >
              CHANGE DATE
            </button>
            {showPicker && (
              <DatePicker
                className={styles.picker}
                onHide={() => setShowPicker(false)}
                onSelect={handleDateSelect}
                selectedDate={selectedDate}
                modifiers={{
                  selected: selectedDate,
                  disabled: day => day > now,
                }}
                disabled={[
                  {
                    after: getYesterday(),
                  },
                ]}
              />
            )}
          </div>
        )}
        <div className={styles.content}>
          {status === shiftStatus.START && (
            <div className={styles.helloMessage}>
              <p className={styles.label}>Hi {user.first_name}!</p>
              <p className={styles.label}>{startOfDayMessage}</p>
            </div>
          )}
          {status === shiftStatus.END && !isOldShift && (
            <div className={styles.helloMessage}>
              <p className={styles.label}>Bye {user.first_name}!</p>
              <p className={styles.label}>{endOfDayMessage}</p>
            </div>
          )}
          {status === shiftStatus.END && isOldShift && (
            <div className={styles.helloMessage}>
              <p className={styles.label}>
                Please end your previous days shift.
              </p>
            </div>
          )}
          <div className={styles.Info}>
            {status === shiftStatus.NEW && (
              <SelectLabelled
                value={currentWarehouse}
                onChange={setCurrentWarehouse}
                placeholder="Select Space"
                items={warehouseOptions}
                selectClassName={styles.select}
                dropdownClassName={styles.selectDropdown}
                optionClassName={styles.selectOption}
                draggableClassName={styles.selectDraggable}
                valueClassName={styles.selectValue}
                placeholderClassName={styles.selectPlaceholder}
                iconWidth={12}
              />
            )}
            {status === shiftStatus.NEW && (
              <div className={styles.messageContainer}>
                {spaceError && (
                  <p className={styles.errorMessage}>{spaceError}</p>
                )}
              </div>
            )}
            {status === shiftStatus.NEW && (
              <div className={styles.timeSectionContainer}>
                <TimeSection
                  title="START TIME"
                  setTime={setStartTime}
                  time={startTime}
                />
                <TimeSection
                  title="END TIME"
                  setTime={setEndTime}
                  time={endTime}
                />
              </div>
            )}
            {status === shiftStatus.START && (
              <div className={styles.timeSectionSingleContainer}>
                <TimeSection title="" setTime={setStartTime} time={startTime} />
              </div>
            )}
            {status === shiftStatus.END && (
              <div className={styles.timeSectionSingleContainer}>
                <TimeSection title="" setTime={setEndTime} time={endTime} />
              </div>
            )}
            <div className={styles.messageContainer}>
              {timeError && <p className={styles.errorMessage}>{timeError}</p>}
            </div>
          </div>
        </div>
        <div className={styles.commentSection}>
          {(status === shiftStatus.START || status === shiftStatus.NEW) && (
            <div className={styles.commentContainer}>
              <Textarea
                className={styles.comment}
                name="comment"
                placeholder="Starting Comment..."
                value={startedComment}
                onChange={event => {
                  setStartedComment(event.target.value);
                  setStartCommentError('');
                }}
              />
              {(status === shiftStatus.START || status === shiftStatus.NEW) &&
                startCommentError && (
                  <span className={styles.errorMessage}>
                    {startCommentError}
                  </span>
                )}
            </div>
          )}
          {(status === shiftStatus.END || status === shiftStatus.NEW) && (
            <div className={styles.commentContainer}>
              <Textarea
                className={styles.comment}
                name="comment"
                placeholder="Ending Comment..."
                value={endedComment}
                onChange={event => {
                  setEndedComment(event.target.value);
                  setEndCommentError('');
                }}
              />
              {(status === shiftStatus.END || status === shiftStatus.NEW) &&
                endCommentError && (
                  <span className={styles.errorMessage}>{endCommentError}</span>
                )}
            </div>
          )}
        </div>

        <div className={styles.buttonSection}>
          <button className={styles.submit} onClick={handleConfirm}>
            Confirm
          </button>
          {status === shiftStatus.START && user?.is_manager && (
            <button className={styles.skip} onClick={handleCancel}>
              Skip & Go to POS
            </button>
          )}
          {(status === shiftStatus.NEW || status === shiftStatus.END) && (
            <button className={styles.skip} onClick={handleCancel}>
              Cancel
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShiftEntry;

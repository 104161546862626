/**
 * Return an integer range within [min, min + total) of given length centered
 * around the current page number.
 */
export default function getPagingRange(
  current,
  { min = 1, total = 20, delta = 3 } = {}
) {
  const length = delta > total ? total : delta;

  let start = current - Math.floor(length / 2);
  start = Math.max(start, min);
  start = Math.min(start, Math.max(min + total - length, 1));

  return Array.from({ length }, (_, i) => start + i);
}

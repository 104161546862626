/* eslint-disable */

import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { interfaceActions } from 'models/interface/slice';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { POSDashboardLayoutsEnum } from 'constants/POSLayouts';

import { currentOrderSelector } from 'models/desks/selectors';

import styles from './Sidebar.scss';
import Cart from 'components/Cart';
import EditOrderModal from 'components/Modal/EditOrderModal';
import { OrderItemsResourceTypes } from 'constants/orderItems';
import { foodActions } from 'models/food/slice';
import EditFoodModal from 'components/Modal/EditFoodModal';
import { changeModalSelector } from 'models/food/selectors';
import { desksActions } from 'models/desks/slice';

const Sidebar = () => {
  // Selectors
  const order = useSelector(currentOrderSelector);
  const changeModal = useSelector(changeModalSelector);

  // Actions
  const changePOSDashboardLayout = useAction(
    interfaceActions.changePOSDashboardLayout
  );
  const fetchOrder = useAction(desksActions.fetchOrderRequest);
  const onRemove = useAction(desksActions.removeOrderItemRequest);
  const removeFood = useAction(foodActions.removeFoodWithAdditions);
  const clearCart = useAction(desksActions.clearCart);
  const setChangeModal = useAction(foodActions.setChangeModal);

  // States
  const [isModalOpen, changeModalStatus] = useState(false);
  const [editableItem, changeEditableItem] = useState(null);
  const [foodItem, setFoodItem] = useState();

  // Effects
  useEffect(() => {
    if (!order?.order_items && order?.id) {
      fetchOrder({ id: order?.id });
    }
  }, [fetchOrder, order]);

  // Variables
  const orderItems = Object.values(order?.order_items ?? {}).reverse();

  // Callbacks
  const handleChangeOnCustomerLayout = useCallback(() => {
    changePOSDashboardLayout(POSDashboardLayoutsEnum.CUSTOMER);
  }, [changePOSDashboardLayout]);

  const handleOpenEditOrderModal = useCallback(
    item => {
      if (item.resourcetype === OrderItemsResourceTypes.FOOD_ORDER_ITEM) {
        setFoodItem(item);
        setChangeModal(true);
      } else {
        changeModalStatus(true);
        changeEditableItem(item);
      }
    },
    [changeEditableItem, changeModalStatus]
  );

  // Handlers
  const handleClearClick = () => {
    clearCart({ id: order.id });
  };

  const handleRemove = item => {
    if (item?.resourcetype === OrderItemsResourceTypes.FOOD_ORDER_ITEM) {
      removeFood(item);
    } else {
      onRemove({
        orderId: order.id,
        orderItemId: item.id,
      });
    }
  };

  return (
    <div className={clsx(styles.root)}>
      <div className={styles.header}>
        <div className={styles.title}>
          <p>
            {order?.customer_name
              ? `${order?.customer_name}'s Cart`
              : `Cart #${order?.id}`}
          </p>
          {order?.table_title && (
            <small className={styles.smallText}>{order.table_title}</small>
          )}
        </div>
        <div className={styles.select}>
          <button
            className={styles.button}
            onClick={handleChangeOnCustomerLayout}
          >
            {order?.customer?.id || order?.customer_name
              ? 'Switch customer'
              : 'Add customer'}
          </button>
        </div>
      </div>

      <div className={styles.inner}>
        <Cart
          handleClearCart={handleClearClick}
          order={order}
          handleRemove={handleRemove}
          editOrder={handleOpenEditOrderModal}
          orderItems={orderItems}
        />
      </div>

      {isModalOpen && (
        <EditOrderModal
          orderId={order.id}
          orderItem={editableItem}
          title={editableItem.product?.title || editableItem.title}
          isOpen={isModalOpen}
          onClose={() => changeModalStatus(false)}
        />
      )}
      <EditFoodModal
        onClose={() => {
          setFoodItem(undefined);
          setChangeModal(false);
        }}
        isOpen={!!foodItem && changeModal}
        food={foodItem}
      />
    </div>
  );
};

export default Sidebar;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './OrderItem.scss';
import clsx from 'clsx';

const OrderItem = ({ title, value, number, className }) => (
  <div className={clsx(styles.container, className)}>
    <h3 className={styles.title}>{title}</h3>
    <div className={styles.info}>
      {!!number && <span>{number}</span>}
      {!!value && <span>{value}</span>}
    </div>
  </div>
);

OrderItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  number: PropTypes.string,
  className: PropTypes.string,
};

export default OrderItem;

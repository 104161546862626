import React from 'react';
import PropTypes from 'prop-types';

const TimesIcon = ({ fill, width, height, strokeWidth }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${Math.max(width / 2, 14)} ${Math.max(height / 2, 13)}`}
    >
      <title>Times</title>
      <g fill="none">
        <g stroke={fill} strokeWidth={strokeWidth}>
          <g transform="translate(-1114 -388)translate(775 341)translate(339 47)">
            <line
              x1="15"
              y1="6.5"
              x2="-1"
              y2="6.5"
              transform="translate(7 6.5)rotate(-45)translate(-7 -6.5)"
            />
            <line
              x1="15"
              y1="6.5"
              x2="-1"
              y2="6.5"
              transform="translate(7 6.5)rotate(-135)translate(-7 -6.5)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

TimesIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  strokeWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TimesIcon.defaultProps = {
  fill: '#EDEAE3',
  width: '14',
  height: '13',
  strokeWidth: '2',
};

export default TimesIcon;

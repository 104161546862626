import React from 'react';
import PropTypes from 'prop-types';

const CalendarIcon = ({ fill, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <title>Calendar</title>
      <g fill="none">
        <path d="M0 0L17.6 0 17.6 18 0 18 0 0Z" />
        <path
          d="M12.5 2.3L15.4 2.3C15.8 2.3 16.1 2.6 16.1 3L16.1 15C16.1 15.4 15.8 15.8 15.4 15.8L2.2 15.8C1.8 15.8 1.5 15.4 1.5 15L1.5 3C1.5 2.6 1.8 2.3 2.2 2.3L5.1 2.3 5.1 0.8 6.6 0.8 6.6 2.3 11 2.3 11 0.8 12.5 0.8 12.5 2.3 12.5 2.3ZM14.7 8.3L2.9 8.3 2.9 14.3 14.7 14.3 14.7 8.3 14.7 8.3ZM11 3.8L6.6 3.8 6.6 5.3 5.1 5.3 5.1 3.8 2.9 3.8 2.9 6.8 14.7 6.8 14.7 3.8 12.5 3.8 12.5 5.3 11 5.3 11 3.8 11 3.8ZM4.4 9.8L5.9 9.8 5.9 11.3 4.4 11.3 4.4 9.8 4.4 9.8ZM8.1 9.8L9.5 9.8 9.5 11.3 8.1 11.3 8.1 9.8 8.1 9.8ZM11.7 9.8L13.2 9.8 13.2 11.3 11.7 11.3 11.7 9.8 11.7 9.8Z"
          style={{ fillOpacity: 0.8, fill }}
        />
      </g>
    </svg>
  );
};

CalendarIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CalendarIcon.defaultProps = {
  fill: '#EDEAE3',
  width: '18',
  height: '18',
};

export default CalendarIcon;

import { createSelector } from 'reselect';

export const commonSelector = createSelector(
  state => state,
  state => state.common
);

export const loadingStateSelector = createSelector(
  commonSelector,
  ({ showLoading, loadingMessage }) => ({
    showLoading,
    loadingMessage,
  })
);

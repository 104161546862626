import React from 'react';
import PropTypes from 'prop-types';

const EditIcon = ({ width, height, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.771 2.78906C2.321 2.78906 2.771 2.33906 2.771 1.78906C2.771 1.23906 2.321 0.789062 1.771 0.789062C1.221 0.789062 0.770996 1.23906 0.770996 1.78906C0.770996 2.33906 1.221 2.78906 1.771 2.78906Z"
        fill="#C9C7C1"
      />
      <path
        d="M7.771 2.78906C8.321 2.78906 8.771 2.33906 8.771 1.78906C8.771 1.23906 8.321 0.789062 7.771 0.789062C7.221 0.789062 6.771 1.23906 6.771 1.78906C6.771 2.33906 7.221 2.78906 7.771 2.78906Z"
        fill="#C9C7C1"
      />
      <path
        d="M13.771 2.78906C14.321 2.78906 14.771 2.33906 14.771 1.78906C14.771 1.23906 14.321 0.789062 13.771 0.789062C13.221 0.789062 12.771 1.23906 12.771 1.78906C12.771 2.33906 13.221 2.78906 13.771 2.78906Z"
        fill="#C9C7C1"
      />
    </svg>
  );
};

EditIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

EditIcon.defaultProps = {
  fill: 'none',
  width: '15',
  height: '3',
};

export default EditIcon;

import React from 'react';
import styles from './InputRange.scss';
import ReactInputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const InputRange = ({
  minValue,
  maxValue,
  value,
  step,
  onChange,
  className,
  ...props
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      <ReactInputRange
        {...props}
        step={step}
        minValue={minValue}
        maxValue={maxValue}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

InputRange.propTypes = {
  onChange: PropTypes.func,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  value: PropTypes.any.isRequired,
  step: PropTypes.number,
  className: PropTypes.string,
};
export default InputRange;

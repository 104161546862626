export const OrderStatus = Object.freeze({
  DRAFT: 'DRAFT',
  FULFILLED: 'FULFILLED',
  WAITING_FOR_APPROVAL: 'WAITING_FOR_APPROVAL',
  CUSTOMER_APPROVED: 'CUSTOMER_APPROVED',
  MANAGER_APPROVED: 'MANAGER_APPROVED',
  PAYMENT_ERROR: 'PAYMENT_ERROR',
  PAID_BY_CARD: 'PAID_BY_CARD',
  PAID_IN_CASH: 'PAID_IN_CASH',
  PARTIALLY_RETURNED: 'PARTIALLY_RETURNED',
});

export const orderStatusHumanName = Object.freeze({
  DRAFT: 'ORDER CREATED',
  FULFILLED: 'ORDER CREATED',
  WAITING_FOR_APPROVAL: 'WAITING FOR APPROVAL',
  CUSTOMER_APPROVED: 'CUSTOMER APPROVED',
  MANAGER_APPROVED: 'MANAGER APPROVED',
  PAYMENT_ERROR: 'PAYMENT ERROR',
  PAID_BY_CARD: 'PAID BY CARD',
  PAID_IN_CASH: 'PAID IN CASH',
  PARTIALLY_RETURNED: 'PARTIALLY RETURNED',
  RETURNED: 'RETURNED',
  SHIPPED: 'PAID',
  CANCELLED: 'CANCELLED',
});

export const ACTIVE_STATUSES = [
  OrderStatus.DRAFT,
  OrderStatus.FULFILLED,
  OrderStatus.WAITING_FOR_APPROVAL,
  OrderStatus.CUSTOMER_APPROVED,
  OrderStatus.MANAGER_APPROVED,
  OrderStatus.PAYMENT_ERROR,
];

export const EXACT_SUCCESS_STATUSES = [
  OrderStatus.PAID_BY_CARD,
  OrderStatus.PAID_IN_CASH,
  OrderStatus.PARTIALLY_RETURNED,
];

/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import actionTypes from 'utils/actionTypes';

const tastersSlice = createSlice({
  name: 'tasters',
  initialState: {
    tasters: {},
    tastersLoading: false,
  },
  reducers: {
    fetchTasters(state) {
      state.tastersLoading = true;
    },
    fetchTastersSuccess(state, { payload }) {
      state.tastersLoading = false;
      state.tasters = payload;
    },
    fetchTastersFailure(state) {
      state.tastersLoading = false;
    },
    createTaster(state) {
      state.tastersLoading = true;
    },
    createTasterSuccess(state, { payload }) {
      state.tastersLoading = false;
      state.tasters = payload;
    },
    createTasterFailure(state) {
      state.tastersLoading = false;
    },
  },
});

export const tastersActions = actionTypes(tastersSlice.actions);

export default tastersSlice.reducer;

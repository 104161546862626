/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import actionTypes from 'utils/actionTypes';

const roomsSlice = createSlice({
  name: 'rooms',
  initialState: {
    rooms: [],
    roomsLoading: false,
    selectedRoomId: null,
  },
  reducers: {
    fetchRoomsRequest(state) {
      state.roomsLoading = true;
    },
    fetchRoomsSuccess(state, { payload }) {
      state.rooms = payload.results;
      state.roomsLoading = false;
    },
    fetchRoomsFailure(state) {
      state.roomsLoading = false;
    },

    selectRoom(state, { payload }) {
      state.selectedRoomId = payload.id;
    },
  },
});

export const roomsActions = actionTypes(roomsSlice.actions);

export default roomsSlice.reducer;

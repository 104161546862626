import { takeLatest, all, put } from 'redux-saga/effects';
import { productCategoriesActions } from './slice';
import authenticatedRequest from 'api';
import {
  constructProductCategoriesUrl,
  productCategoriesUrl,
  constructProductCountUrl,
} from 'api/urls/productCategories';

function* fetchProductCategoriesSaga({ payload }) {
  try {
    const { data } = yield authenticatedRequest({
      url: productCategoriesUrl,
      method: 'get',
      params: payload,
    });
    yield put({
      type: productCategoriesActions.fetchProductCategoriesSuccess,
      payload: data,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: productCategoriesActions.fetchProductCategoriesFailure,
    });
  }
}

function* fetchCategoryInventoriesSaga({ payload }) {
  try {
    const { id } = payload;
    const { data } = yield authenticatedRequest({
      url: constructProductCategoriesUrl(id),
      method: 'GET',
    });
    yield put({
      type: productCategoriesActions.fetchCategoryInventoriesSuccess,
      payload: data,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: productCategoriesActions.fetchCategoryInventoriesFailure,
    });
  }
}

function* fetchProductCountSaga({ payload }) {
  try {
    const { id } = payload;
    const { data } = yield authenticatedRequest({
      url: constructProductCountUrl(id),
      method: 'GET',
    });
    const product = data[0] ?? { product_id: id, count: 0 };
    yield put({
      type: productCategoriesActions.fetchProductCountSuccess,
      payload: product,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: productCategoriesActions.fetchProductCountFailure,
    });
  }
}

export default function*() {
  yield all([
    takeLatest(
      productCategoriesActions.fetchProductCategoriesRequest,
      fetchProductCategoriesSaga
    ),
    takeLatest(
      productCategoriesActions.fetchCategoryInventoriesRequest,
      fetchCategoryInventoriesSaga
    ),
    takeLatest(
      productCategoriesActions.fetchProductCountRequest,
      fetchProductCountSaga
    ),
  ]);
}

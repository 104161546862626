import { push } from 'connected-react-router';
import { takeLatest, all, put, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  customersUrl,
  constructCustomerUrl,
  constructCustomerNoteUrl,
} from 'api/urls';
import { authenticatedRequest } from 'api';

import { paginatedCustomersActions } from './slice';
import { paginatedCustomersFiltersSelector } from './selectors';

export function* fetchPaginatedCustomersSaga({
  payload: { withFilterCriteria },
}) {
  try {
    const params = { per_page: 8 };

    if (withFilterCriteria) {
      const filters = yield select(paginatedCustomersFiltersSelector);
      params.sort_field = filters.sortingField;
      params.sort_direction = filters.sortingCustomer;
      params.customer_info_contains = filters.search;
      params.page = filters.pagination?.current_page;
    }

    const { data } = yield authenticatedRequest({
      url: customersUrl,
      params,
    });

    yield put({
      type: paginatedCustomersActions.fetchCustomersSuccess,
      payload: data,
    });
  } catch (err) {
    console.error(err);
    yield put({ type: paginatedCustomersActions.fetchCustomersFailure });
  }
}

function* fetchPaginatedCustomersWithFiltersSaga() {
  yield call(fetchPaginatedCustomersSaga, {
    payload: { withFilterCriteria: true },
  });
}

function* fetchDetailedCustomerRequestSaga({ payload: { id } }) {
  try {
    const { data } = yield authenticatedRequest({
      url: constructCustomerUrl({ id }),
    });

    yield put({
      type: paginatedCustomersActions.fetchDetailedCustomerSuccess,
      payload: data,
    });
  } catch (err) {
    console.error(err);
    yield put({ type: paginatedCustomersActions.fetchDetailedCustomerFailure });
    yield put(push('/dashboard/customers'));
  }
}

function* addNoteSaga({ payload: { id, text } }) {
  try {
    const { data } = yield authenticatedRequest({
      url: constructCustomerNoteUrl({ id }),
      method: 'POST',
      data: { text },
    });

    yield put({
      type: paginatedCustomersActions.addNoteRequestSuccess,
      payload: data,
    });
  } catch (err) {
    console.error(err);
    yield put({ type: paginatedCustomersActions.addNoteRequestFailure });
    yield call([toast, 'error'], 'Something went wrong while adding note', {
      progressClassName: 'customProgressBarError',
    });
  }
}

function* updateCustomerSaga({ payload }) {
  const { id } = payload;
  try {
    const { data } = yield authenticatedRequest({
      url: constructCustomerUrl({ id }),
      method: 'PATCH',
      data: payload,
    });
    toast.success('Customer successfully updated!', {
      progressClassName: 'customProgressBarSuccess',
    });
    yield put({
      type: paginatedCustomersActions.updateCustomerSuccess,
      payload: data,
    });
  } catch (error) {
    console.error(error);
    yield put({ type: paginatedCustomersActions.updateCustomerFailure });
    toast.error(error?.message, {
      progressClassName: 'customProgressBarError',
    });
  }
}

export default function*() {
  yield all([
    takeLatest(
      paginatedCustomersActions.fetchCustomersRequest,
      fetchPaginatedCustomersSaga
    ),
    takeLatest(
      paginatedCustomersActions.fetchDetailedCustomerRequest,
      fetchDetailedCustomerRequestSaga
    ),
    takeLatest(
      [
        paginatedCustomersActions.changePagination,
        paginatedCustomersActions.changeSearch,
        paginatedCustomersActions.changeSorting,
      ],
      fetchPaginatedCustomersWithFiltersSaga
    ),
    takeLatest(paginatedCustomersActions.addNoteRequest, addNoteSaga),
    takeLatest(paginatedCustomersActions.updateCustomer, updateCustomerSaga),
  ]);
}

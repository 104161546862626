import { useCallback, useEffect, useMemo, useRef } from 'react';
import config from 'config';
import useSelector from 'hooks/useSelector';
import useAction from 'hooks/useAction';
import { desksActions } from 'models/desks/slice';
import { productCategoriesActions } from 'models/productCategories/slice';
import { inventoryActions } from 'models/inventory/slice';
import { tokenSelector, warehouseIdSelector } from 'models/session/selectors';

const { wsUrl } = config;

const MESSAGE_TYPES = Object.freeze({
  TASTING_ROOM_ORDER: 'tasting_room_order',
  TASTING_ROOM_DISCOUNT: 'tasting_room_order_discount',
  INVENTORY: 'inventory',
  CALL_WAITER: 'call_waiter',
  TASTING_MENU: 'tasting_menu',
});

const useWarehouseChannel = () => {
  const replaceOrderData = useAction(desksActions.replaceOrderData);
  const updateInventoryProduct = useAction(
    inventoryActions.updateInventoryProduct
  );
  const requestWaiterForPayment = useAction(
    desksActions.requestWaiterForPayment
  );
  const activateDesk = useAction(desksActions.activateDesk);
  const deactivateDesk = useAction(desksActions.deactivateDesk);

  const warehouseId = useSelector(warehouseIdSelector);
  const userAuthToken = useSelector(tokenSelector);

  const changeGuestOrders = useAction(desksActions.changeGuestOrders);
  const changeInventory = useAction(productCategoriesActions.changeInventory);

  const socket = useRef();

  const socketUrl = useMemo(() => {
    return `${wsUrl}/master/${userAuthToken}/${warehouseId}/`;
  }, [warehouseId, userAuthToken]);

  const handleDeskStatusUpdate = useCallback(payload => {
    if (!Object.getOwnPropertyDescriptor(payload, 'tasting_menu')) {
      throw Error(
        'handleDeskStatusUpdate: `tasting_menu` field is not presented in the payload'
      );
    }
    if (payload.tasting_menu.table_attached) {
      activateDesk({
        deskId: payload.tasting_menu.table_id,
        tastingMenu: payload.tasting_menu,
      });
    } else {
      deactivateDesk({ id: payload.tasting_menu.table_id });
    }
  });

  const handleOrderReceive = useCallback(
    payload => {
      if (!Object.getOwnPropertyDescriptor(payload, 'order')) {
        throw Error(
          'handleOrderReceive: `order` field is not presented in the payload'
        );
      }
      changeGuestOrders(payload.order);
    },
    [replaceOrderData]
  );

  const handleInventoryUpdate = useCallback(
    payload => {
      if (!Object.getOwnPropertyDescriptor(payload, 'inventory')) {
        throw Error(
          'handleInventoryUpdate: `inventory` field is not presented in the payload'
        );
      }
      const { product, product_count } = payload.inventory;
      changeInventory({
        ...product,
        product_count,
      });
    },
    [updateInventoryProduct]
  );

  const handleCallWaiter = useCallback(
    payload => {
      if (!Object.getOwnPropertyDescriptor(payload, 'order_id')) {
        throw Error(
          'handleCallWaiter: `order_id` field is not presented in the payload'
        );
      }
      requestWaiterForPayment(payload);
    },
    [requestWaiterForPayment]
  );

  useEffect(() => {
    socket.current = new WebSocket(socketUrl);
    socket.current.onopen = () => {
      console.log('connection to master channel established!');
    };
    socket.current.onmessage = event => {
      const message = JSON.parse(event.data);
      console.log(message);
      switch (message.payload.type) {
        case MESSAGE_TYPES.TASTING_ROOM_ORDER:
        case MESSAGE_TYPES.TASTING_ROOM_DISCOUNT: {
          return handleOrderReceive(message.payload);
        }
        case MESSAGE_TYPES.INVENTORY: {
          return handleInventoryUpdate(message.payload);
        }
        case MESSAGE_TYPES.CALL_WAITER: {
          return handleCallWaiter(message.payload);
        }
        case MESSAGE_TYPES.TASTING_MENU: {
          return handleDeskStatusUpdate(message.payload);
        }
        default:
          return null;
      }
    };
    socket.current.onclose = () => {
      console.log('connection to master channel closed!');
    };
    return () => {
      if (socket.current) socket.current.close();
    };
  }, [socketUrl]);
};

export default useWarehouseChannel;

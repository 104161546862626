/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import actionTypes from 'utils/actionTypes';
import { REFUND_STEPS } from '../../constants/refundOrders';

const paginatedOrdersSlice = createSlice({
  name: 'paginatedOrders',
  initialState: {
    orders: [],
    ordersLoaded: false,
    ordersLoading: false,
    pagination: null,

    sortingField: 'paid_at',
    sortingOrder: 'desc',
    search: '',

    filters: {
      sort_field: 'paid_at',
      sort_direction: 'desc',
      customer_info_contains: null,
      per_page: 8,
      page: 1,
      date_range_start: null,
      date_range_end: null,
      total_price_range_start: null,
      total_price_range_end: null,
      paid_at_date_range_start: null,
      paid_at_date_range_end: null,
    },

    detailedOrder: null,
    detailedOrderLoading: false,

    orderRefundStep: REFUND_STEPS.MAIN,
    orderRefundPending: false,
  },
  reducers: {
    fetchOrdersRequest(state) {
      state.ordersLoading = true;
    },
    fetchOrdersSuccess(state, { payload }) {
      state.ordersLoading = false;
      state.orders = payload.results;
      state.pagination = payload.pagination;
      state.ordersLoaded = true;
    },
    fetchOrdersFailure(state) {
      state.ordersLoading = false;
    },

    changeFilters(state, { payload }) {
      state.filters = payload;
      if (state.pagination) {
        state.pagination.current_page = payload.page;
      }
      state.ordersLoading = true;
    },

    changePagination(state, { payload }) {
      state.pagination.current_page = payload.page;
      state.ordersLoading = true;
    },
    changeSorting(state, { payload }) {
      if (state.sortingField !== payload.sortingField) {
        state.sortingOrder = 'asc';
      } else {
        state.sortingOrder = state.sortingOrder === 'asc' ? 'desc' : 'asc';
      }
      state.sortingField = payload.sortingField;
      state.ordersLoading = true;
    },
    changeSearch(state, { payload }) {
      state.search = payload.search;
      state.ordersLoading = true;
    },

    fetchDetailedOrderRequest(state) {
      state.detailedOrderLoading = true;
    },
    fetchDetailedOrderSuccess(state, { payload }) {
      state.detailedOrder = payload;
      state.detailedOrderLoading = false;
    },
    fetchDetailedOrderFailure(state) {
      state.detailedOrderLoading = false;
    },
    refundOrder(state) {
      state.orderRefundPending = true;
    },
    refundOrderSuccess(state, { payload }) {
      const { id } = payload;
      state.orders = state.orders.map(order =>
        order.id === id ? payload : order
      );
      state.orderRefundStep = REFUND_STEPS.SUCCESS;
      state.orderRefundPending = false;
    },
    refundOrderFailure(state) {
      state.orderRefundPending = false;
    },
  },
});

export const paginatedDeskActions = actionTypes(paginatedOrdersSlice.actions);

export default paginatedOrdersSlice.reducer;

import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Keyboard from 'components/Keyboard';
import styles from './Keyboard.scss';

const KeyboardContext = createContext({
  setKeyboardValue: () => {},
  keyboardValue: null,
  keyboardShow: false,
  setKeyboardShow: () => {},
});

export const KeyboardProvider = ({ children }) => {
  const [keyboardShow, setKeyboardShow] = useState(false);
  const keyboardRef = useRef(null);

  useEffect(() => {
    const focusedElement = document.activeElement;
    document.addEventListener('selectionchange', () => {
      keyboardRef.current.setCaretPosition(
        focusedElement.selectionStart,
        focusedElement.selectionEnd
      );
    });
  });

  const onChange = data => {
    const { caretPosition } = keyboardRef.current;
    const focusedElement = document.activeElement;
    focusedElement.value = data;
    if (caretPosition) {
      focusedElement.focus();
      focusedElement.setSelectionRange(caretPosition, caretPosition);
    }
  };

  const onKeyPress = key => {
    if (key === '{enter}') {
      const focusedElement = document.activeElement;
      const activeForm = focusedElement.form;
      focusedElement.blur();
      setKeyboardShow(false);
      if (activeForm) {
        const formTrigger = activeForm.querySelector('button[type="submit"]');
        if (formTrigger) formTrigger.click();
      }
    }
  };

  const setKeyboardValue = (data, fieldname) => {
    keyboardRef.current.setInput(data, fieldname);
  };

  return (
    <KeyboardContext.Provider
      value={{
        keyboardShow,
        setKeyboardShow,
        setKeyboardValue,
      }}
    >
      {children}
      <div
        className={clsx(styles.keyboard, keyboardShow && styles.keyboardShow)}
      >
        <Keyboard
          ref={element => {
            keyboardRef.current = element;
          }}
          onChange={onChange}
          onKeyPress={onKeyPress}
          preventMouseDownDefault
          stopMouseDownPropagation
          useMouseEvents
          layoutName="default"
        />
      </div>
    </KeyboardContext.Provider>
  );
};

KeyboardProvider.propTypes = {
  children: PropTypes.element,
};

export const useKeyboard = () => useContext(KeyboardContext);

export const constructOrderUrl = ({ id }) =>
  `/tasting-room-master-api/v1/orders/${id}`;

export const constructOrderItemsUrl = ({ id }) =>
  `/tasting-room-master-api/v1/orders/order-items/${id}`;

export const orderItemsUrl = `/tasting-room-master-api/v1/orders/order-items`;

export const ordersUrl = `/tasting-room-master-api/v1/orders/`;

export const constructOrderPayInCashUrl = ({ id }) =>
  `/tasting-room-master-api/v1/orders/${id}/pay_in_cash`;

export const constructOrderTastingMenuChangingUrl = ({ id }) =>
  `/tasting-room-master-api/v1/orders/${id}/tasting_menu_free`;

export const guestOrdersUrl = `/tasting-room-master-api/v1/orders/open?initiator=guest`;
export const activeOrdersUrl = `/tasting-room-master-api/v1/orders/open?initiator=pos`;

import { createSelector } from 'reselect';

const selectSelf = state => state.productCategories;

export const productCategoriesSelector = createSelector(
  selectSelf,
  ({ categories }) => categories
);

export const productInventorySelector = createSelector(
  selectSelf,
  ({ inventory }) => inventory
);

export const productInventoryByIdSelector = id =>
  createSelector(selectSelf, ({ inventory }) => inventory?.[id]);

export const productCategoriesPendingSelector = createSelector(
  selectSelf,
  ({ isPending }) => isPending
);

export const productCountsSelector = createSelector(
  selectSelf,
  ({ productCounts }) => productCounts
);

export const productCountsByIdSelector = id =>
  createSelector(selectSelf, ({ productCounts }) => productCounts?.[id]);

export const productCountsPendingSelector = createSelector(
  selectSelf,
  ({ productCountsPending }) => productCountsPending
);

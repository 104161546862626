import React from 'react';

const DoubleCircleIcon = () => (
  <svg
    width="56"
    height="35"
    viewBox="0 0 56 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.85">
      <circle cx="17.5" cy="17.0957" r="16.5" stroke="#EDEAE3" />
      <circle
        cx="38.2295"
        cy="17.0957"
        r="16.5"
        stroke="#EDEAE3"
        strokeLinecap="round"
        strokeDasharray="1 3"
      />
    </g>
  </svg>
);

export default DoubleCircleIcon;

import React, { useState } from 'react';
import styles from './OrderItemCheckbox.scss';
import Switch from '../../Switch';
import Checkbox from '../../Checkbox';
import PropTypes from 'prop-types';
import { ITEM_STATE_TYPES } from '../../../constants/refundOrders';

const ItemStateValue = {
  [ITEM_STATE_TYPES.RETURN]: 'Return',
  [ITEM_STATE_TYPES.VOID]: 'Void',
};

const OrderItemCheckbox = ({ onChange, disabled }) => {
  const [state, setState] = useState(ITEM_STATE_TYPES.RETURN);

  const onChangeHandler = () => {
    const currentState = (state + 1) % 3;
    setState(currentState);
    onChange?.(currentState);
  };

  return (
    <div className={styles.container}>
      {state === ITEM_STATE_TYPES.NOT_CHOSEN ? (
        <Checkbox onChange={onChangeHandler} disabled={disabled} />
      ) : (
        <>
          <Switch disabled={disabled} onChange={onChangeHandler} />
          <span className={styles.text}>{ItemStateValue[state]}</span>
        </>
      )}
    </div>
  );
};

OrderItemCheckbox.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
export default OrderItemCheckbox;

import React, { useState } from 'react';
import { constructHostsUrl } from 'api/urls';
import useAuthRequest from 'hooks/useAuthRequest';
import useAction from 'hooks/useAction';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { sessionActions } from 'models/session/slice';
import strings from 'constants/strings';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import styles from './HostForm.scss';

const formSchema = yup.object().shape({
  hostFirstName: yup.string().required('First Name is required'),
  hostLastName: yup.string().required('Last name is required'),
  hostPassword: yup.string().required('Password is required'),
  hostConfirmPassword: yup
    .string()
    .required('Password confirmation is required')
    .oneOf([yup.ref('hostPassword'), null], 'Passwords must match'),
});

const HostForm = () => {
  const request = useAuthRequest();
  const { hash } = useParams();

  const [busy, setBusy] = useState(false);
  const loginAfterConfirmation = useAction(
    sessionActions.loginAfterConfirmation
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(formSchema) });

  const onSubmit = data => {
    if (busy) return;
    setBusy(true);
    request({
      url: constructHostsUrl(hash),
      method: 'POST',
      data: {
        first_name: data.hostFirstName,
        last_name: data.hostLastName,
        password: data.hostPassword,
        password_confirmation: data.hostConfirmPassword,
      },
    })
      .then(res => {
        toast.success(strings.hostCreationSuccess, {
          progressClassName: 'customProgressBarSuccess',
        });
        loginAfterConfirmation({ token: res.data.auth_token });
      })
      .catch(() => {
        toast.error(strings.hostCreationErrored, {
          progressClassName: 'customProgressBarError',
        });
      })
      .finally(() => {
        setBusy(false);
      });
  };

  return (
    <div className={styles.form}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoCorrect="off"
        autoComplete="off"
      >
        <div className={styles.formGroup}>
          <div className={styles.formRow}>
            <div className={styles.caption}>1</div>
            <div className={styles.input}>
              <input
                type="text"
                className={styles.input}
                placeholder="First name"
                name="hostFirstName"
                {...register('hostFirstName', { required: true })}
                autoComplete="off"
              />
            </div>
          </div>
          {errors.hostFirstName && (
            <div className={styles.error}>{errors.hostFirstName.message}</div>
          )}
        </div>
        <div className={styles.formGroup}>
          <div className={styles.formRow}>
            <div className={styles.caption}>2</div>

            <div className={styles.input}>
              <input
                type="text"
                className={styles.input}
                placeholder="Last name"
                name="hostLastName"
                {...register('hostLastName', { required: true })}
                autoComplete="off"
              />
            </div>
          </div>

          {errors.hostLastName && (
            <div className={styles.error}>{errors.hostLastName.message}</div>
          )}
        </div>
        <div className={styles.formGroup}>
          <div className={styles.formRow}>
            <div className={styles.caption}>3</div>
            <div className={styles.input}>
              <input
                type="password"
                className={styles.input}
                placeholder="Password"
                name="hostPassword"
                {...register('hostPassword', { required: true })}
                autoComplete="new-password"
              />
            </div>
          </div>
          {errors.hostPassword && (
            <div className={styles.error}>{errors.hostPassword.message}</div>
          )}
        </div>
        <div className={styles.formGroup}>
          <div className={styles.formRow}>
            <div className={styles.caption}>4</div>

            <div className={styles.input}>
              <input
                type="password"
                className={styles.input}
                placeholder="Confirm password"
                name="hostConfirmPassword"
                {...register('hostConfirmPassword', { required: true })}
                autoComplete="new-password"
              />
            </div>
          </div>
          {errors.hostConfirmPassword && (
            <div className={styles.error}>
              {errors.hostConfirmPassword.message}
            </div>
          )}
        </div>
        <div className={styles.formSubmit}>
          <button type="submit" className={styles.submitBtn} disabled={busy}>
            Create host
          </button>
        </div>
      </form>
    </div>
  );
};

export default HostForm;

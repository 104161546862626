import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './OrdersHistory.scss';
import { orderStatusHumanName } from 'constants/orderStatus';
import { ORDER_TYPES } from 'constants/orderTypes';
import { Link } from 'react-router-dom';
import Block from '../Block';
import { format } from 'date-fns';

const OrdersHistory = ({ orders }) => {
  return (
    <Block
      title="Order History"
      number={orders.length}
      className={styles.block}
    >
      <ul>
        {orders?.map(order => (
          <li>
            <Link
              className={styles.item}
              to={order.status !== 'SHIPPED' && `/dashboard/orders/${order.id}`}
            >
              <p className={styles.itemDate}>
                {format(new Date(order.created_at), 'dd LLL, yyyy')}
              </p>
              <p className={styles.itemPrice}>${order.total_price}</p>
              <p className={styles.itemStatus}>
                {orderStatusHumanName[order.status]}
              </p>
              <p className={styles.itemType}>
                {ORDER_TYPES[order.resourcetype]}
              </p>
            </Link>
          </li>
        ))}
      </ul>
    </Block>
  );
};

OrdersHistory.propTypes = {
  orders: PropTypes.array,
};

export default memo(OrdersHistory);

import React, { useState } from 'react';
import styles from './CashProcessingModal.scss';
import PropTypes from 'prop-types';
import KeyboardInput from 'components/KeyboardInput';
import { layouts } from 'components/Keyboard/constants';
import TimesIcon from 'components/Icons/TimesIcon';
import formatPrice from 'utils/formatPrice';
import Modal from '../Modal';

const calculateDifference = (value, total) => formatPrice((value ?? 0) - total);

const CashProcessingModal = ({ onClose, isOpen, total, onSubmit }) => {
  const [inputValue, setValue] = useState(null);

  const handleSubmit = event => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <Modal close={onClose} id="CashProcessingModal" isOpen={isOpen}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <button className={styles.close} onClick={onClose}>
            <TimesIcon width="20" height="18" />
          </button>
          <h2 className={styles.title}>How much cash was&nbsp;given?</h2>

          <div className={styles.content}>
            <form onSubmit={handleSubmit}>
              <KeyboardInput
                className={styles.input}
                name="cashProcessing"
                type="text"
                layout={{ floating: layouts.floating }}
                pattern={/^(?:\d*\.(\d{1,2}|(?![\s\S]))|\d+)$/}
                value={inputValue}
                layoutName="floating"
                onChange={setValue}
                placeholder="Enter cash value"
              />
            </form>
            <div className={styles.changeWrapper}>
              {calculateDifference(inputValue, total) >= 0 && (
                <p className={styles.change}>
                  ${calculateDifference(inputValue, total)}
                </p>
              )}
              <p className={styles.grayText}>
                {calculateDifference(inputValue, total) < 0
                  ? `The entered value is less than $${total}`
                  : 'Change'}
              </p>
            </div>
          </div>

          <div className={styles.actions}>
            <button className={styles.grayLink} onClick={onClose}>
              CANCEL
            </button>
            <button className={styles.link} onClick={handleSubmit}>
              CONFIRM
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

CashProcessingModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default CashProcessingModal;

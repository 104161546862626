import React from 'react';
import styles from './InventoryCard.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const InventoryCard = ({ title, count, colour, onClick, className }) => {
  return (
    <button
      onClick={onClick}
      className={clsx(styles.container, className)}
      style={{ backgroundColor: colour && colour }}
    >
      <span className={styles.count}>{count}</span>
      <p className={styles.title}>{title}</p>
    </button>
  );
};

InventoryCard.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number,
  colour: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};
export default InventoryCard;

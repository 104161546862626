/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import actionTypes from 'utils/actionTypes';
import { resetState } from '../helpers';
import {
  tokenKey,
  roleKey,
  brandKey,
  warehouseKey,
} from 'constants/localStorage';

const initialState = {
  user: {
    id: null,
    email: null,
    roles: null,
    token: localStorage.getItem(tokenKey),
    workspaces: null,
    taxes: {
      pst: null,
      gst: null,
      hst: null,
    },
    current_terminals: [],
    acquirer: null,
    acquirer_id: null,
    acquirer_public_key: null,
    stripe_connect_account_id: null,
    stripe_location_id: null,
    min_customer_search_query_length_value: null,
    brands: null,
    host_brands: null,
    role: localStorage.getItem(roleKey),
    selectedBrand: localStorage.getItem(brandKey),
    selectedWarehouse: localStorage.getItem(warehouseKey),
  },
  loginPending: false,
  sessionLoading: true,
  hostsLoading: false,
  hosts: [],
  userSwitchModal: false,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    loginRequest(state) {
      state.loginPending = true;
    },
    loginSuccess(state, { payload }) {
      if (payload?.notTrigger) {
        state.userSwitchModal = true;
      } else {
        state.user = payload;
        state.user.current_terminals = [];
        state.user.selectedWarehouse = payload.warehouse_id;
        state.user.selectedBrand = payload.brand_id;
        state.userSwitchModal = false;
      }
      state.loginPending = false;
      state.user.token = payload.auth_token || payload.access;
    },
    loginFailure(state) {
      state.loginPending = false;
      state.loginFailed = false;
    },
    logoutHostRequest() {},
    loginHostRequest() {},
    loginHostSuccess(state, { payload }) {
      state.sessionLoading = false;
      state.user = payload;
    },
    logoutHostSuccess() {},
    logoutHostFailure() {},
    fetchSessionRequest(state) {
      state.sessionLoading = true;
    },
    fetchSessionSuccess(state, { payload }) {
      state.sessionLoading = false;
      state.user = payload;
      state.user.token = payload.auth_token;
      state.user.role = localStorage.getItem(roleKey);
      state.user.selectedBrand = localStorage.getItem(brandKey);
      state.user.selectedWarehouse = localStorage.getItem(warehouseKey);
    },
    fetchSessionFailure(state) {
      state.sessionLoading = false;
      state.user.token = null;
      state.user.current_warehouse_id = null;
    },

    loginAfterConfirmation(state, { payload }) {
      state.user.token = payload.token;
    },

    getTerminals() {},
    getTerminalsSuccess(state, { payload }) {
      state.user.current_terminals = payload;
    },

    getPrinters() {},
    getPrintersSuccess(state, { payload }) {
      state.user.current_printers = payload.results;
    },
    printRequest() {},

    logout() {},
    switchHost(state) {
      state.user.role = 'manager';
    },

    fetchHosts(state) {
      state.hostsLoading = true;
    },
    fetchHostsSuccess(state, { payload }) {
      state.hostsLoading = false;
      state.hosts = payload.hosts;
    },
    fetchHostsFailure(state) {
      state.hostsLoading = false;
    },
    saveBrandAndWarehouse(state, { payload }) {
      state.user.selectedWarehouse = payload.warehouse;
      state.user.selectedBrand = payload.brand;
    },
    setUserSwitchModal: (state, { payload }) => {
      state.userSwitchModal = payload;
    },
    setStartedTimeEntry: (state, { payload }) => {
      state.user.started_time_entry = payload;
    },
  },
  extraReducers: {
    [resetState]: state => {
      state.sessionLoading = false;
      state.user = {
        ...initialState.user,
        token: null,
        role: null,
        selectedBrand: null,
        selectedWarehouse: null,
      };
    },
  },
});

export const sessionActions = actionTypes(sessionSlice.actions);
export const logoutAction = sessionActions.logout;

export default sessionSlice.reducer;

/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import TimesIcon from 'components/Icons/TimesIcon';
import styles from './TipsModal.scss';
import KeyboardInput from 'components/KeyboardInput';
import FullscreenModal from '../FullscreenModal';
import formatPrice from 'utils/formatPrice';

const TIPS_MODES = Object.freeze({
  CURRENCY: 1,
  PERCENTAGE: 2,
});

const TipsModal = ({
  isOpen,
  close,
  value,
  total,
  setValue,
  mode,
  setMode,
  layout,
}) => {
  const [tip, setTip] = useState(String(value));

  useEffect(() => {
    setMode(null);
    setTip(null);
  }, [isOpen]);

  const onChange = val => {
    setTip(val);
  };

  const setCurrencyMode = () => {
    setMode(TIPS_MODES.CURRENCY);
    setTip('');
  };

  const setPercentageMode = () => {
    setMode(TIPS_MODES.PERCENTAGE);
    setTip('');
  };

  const handleSubmit = evt => {
    evt.preventDefault();

    setValue(Number(tip || 0));
    close();
  };

  return (
    <FullscreenModal id="TipsModal" isOpen={isOpen}>
      <div className={styles.root}>
        <div className={styles.close}>
          <button type="button" onClick={close}>
            <TimesIcon width="28" height="26" />
          </button>
        </div>

        <div className={styles.total}>
          <div className={styles.totalPriceText}>
            How much would <br /> you like to tip?
          </div>
          <div className={styles.totalPrice}>
            <div className={clsx(styles.totalPriceText, styles.textOpacity)}>
              $ {formatPrice(total)} CAD
            </div>
            <div className={clsx(styles.totalPriceText, styles.textOpacity)}>
              Your Total
            </div>
          </div>
        </div>

        <form className={styles.form} onSubmit={handleSubmit}>
          {Boolean(!mode) && (
            <div className={styles.customTips}>
              <div className={styles.customTipsSelect}>
                <div>
                  <button
                    type="button"
                    className={clsx(styles.btn, {
                      [styles.btnActive]: mode === TIPS_MODES.CURRENCY,
                    })}
                    onClick={setCurrencyMode}
                  >
                    $
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    className={clsx(styles.btn, {
                      [styles.btnActive]: mode === TIPS_MODES.PERCENTAGE,
                    })}
                    onClick={setPercentageMode}
                  >
                    %
                  </button>
                </div>
              </div>
            </div>
          )}
          <KeyboardInput
            name="tip"
            type="text"
            loading={false}
            layout={layout}
            pattern={/^(?:\d*\.(\d{1,2}|(?![\s\S]))|\d+)$/}
            value={tip}
            layoutName="floating"
            placeholder={
              (mode && (mode === TIPS_MODES.PERCENTAGE ? '%' : '$')) || ''
            }
            onChange={onChange}
            disabled={!mode}
            keyboardAlwaysShown={Boolean(mode)}
            textCentered
          />
        </form>
      </div>
    </FullscreenModal>
  );
};

TipsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  value: PropTypes.number,
  setValue: PropTypes.func,
  mode: PropTypes.string,
  setMode: PropTypes.func,
  layout: PropTypes.object,
  total: PropTypes.number,
};

export default TipsModal;

/*eslint-disable*/
import React, { useEffect, useContext } from 'react';
import CheckoutContext from 'pages/Checkout/context';
import clsx from 'clsx';
import paymentPattern from 'assets/images/payment-pattern.svg';
import styles from './POSCardPayment.scss';

import { useHistory } from 'react-router-dom';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { stripeActions } from 'models/stripe/slice';
import { currentOrderSelector } from 'models/desks/selectors';
import formatPrice from 'utils/formatPrice';

import { toast } from 'react-toastify';

const errors = {
  SDK_IS_BUSY_WITH_CONNECT_READER:
    'because the SDK is busy with another command: connectReader',
  SDK_IS_BUSY:
    'Could not execute retrievePaymentIntent because the SDK is busy',
  STRIPE_DECLINED_TRANSACTION: 'The Stripe API declined the transaction',
  STRIPE_DECLINED_6000: 'code = 6000;',
};

const maxAttemptsNumber = {
  RETRIEVE_ERRORS: 7,
  DECLINED_ERRORS: 2,
};

const POSCardPayment = () => {
  const history = useHistory();
  const { paidOrdersList } = useContext(CheckoutContext);
  let retrieveErrors = 1;
  let declinedErrors = 1;

  const logRequest = useAction(stripeActions.logRequest);
  const createPaymentIntent = useAction(stripeActions.createPaymentIntent);
  const capturePayment = useAction(stripeActions.capturePayment);
  const disconnectReader = useAction(stripeActions.disconnectReader);
  const order = useSelector(currentOrderSelector);

  const handleClear = () => {
    retrieveErrors = 1;
    declinedErrors = 1;
    document.removeEventListener('Intent', handleAnalizeMessage);
    logRequest(`clear use`);
  };

  const handleError = message => {
    handleCancel();
    toast.error(message, {
      progressClassName: 'customProgressBarError',
    });
    history.push('/dashboard/payment/reader/select');
  };

  const handleAnalizeMessage = e => {
    const message = e.detail;
    logRequest(`New message: ${JSON.stringify(message)}`);
    if (message.status === 'success') {
      handleClear();
      capturePayment({
        payment_intent_id: message.stripeId,
        interac: message.intent.status === 'succeeded',
        orderId: order,
      });
    } else {
      if (message.message.includes(errors.SDK_IS_BUSY_WITH_CONNECT_READER)) {
        if (retrieveErrors < maxAttemptsNumber.RETRIEVE_ERRORS) {
          logRequest(`Connect retrieve error, step: ${retrieveErrors}`);
          document.removeEventListener('Intent', handleAnalizeMessage);
          createDelayPaymentIntent();
        } else {
          handleError("Can't complete the payment.");
        }
      } else if (message.message.includes(errors.STRIPE_DECLINED_TRANSACTION)) {
        logRequest(`Stripe declined transaction, step: ${declinedErrors}`);
        if (declinedErrors < maxAttemptsNumber.DECLINED_ERRORS) {
          // todo: check code=6000 & capture || check status & capture
          // the first option
          if (message.message.includes(errors.STRIPE_DECLINED_6000)) {
            // find the intent and pull the capture
            const regExpIntent = /paymentIntent = (.*?):/;
            const matchesIntent = regExpIntent.exec(message.message) || [];
            if (matchesIntent.length > 1) {
              const paymentIntentWithError = matchesIntent[1];
              logRequest(`
                It is crutch paymentIntent = ${paymentIntentWithError} CAPTURE`);
              capturePayment({
                payment_intent_id: paymentIntentWithError,
                interac: false,
              });
            } else {
              logRequest(`Not found paymentIntent`);
              retrieveErrors = 1;
              declinedErrors = 2;
              logRequest(`
                Create new intent | retrieveErrors: ${retrieveErrors},
                declinedErrors: ${declinedErrors}`);
              createPaymentIntent({
                paidOrdersList,
                withIntent: true,
              });
            }
          } else {
            retrieveErrors = 1;
            declinedErrors = 2;
            logRequest(`
              Create new intent | retrieveErrors: ${retrieveErrors},
              declinedErrors: ${declinedErrors}`);
            createPaymentIntent({
              paidOrdersList,
              withIntent: true,
            });
          }
        } else {
          handleError("Can't complete the payment.");
          logRequest(`
            finita la commedia — retrieveErrors: ${retrieveErrors},
            declinedErrors: ${declinedErrors}`);
        }
      } else if (!message.message.includes(errors.SDK_IS_BUSY)) {
        handleError(message.message);
        logRequest(
          `Error: ${message.message}, retrieve errors: ${retrieveErrors},
          declined errors: ${declinedErrors}`
        );
      } else {
        logRequest(
          `Skip: ${message.message}, retrieve errors: ${retrieveErrors},
          declined errors: ${declinedErrors}`
        );
      }
    }
  };

  const createDelayPaymentIntent = () => {
    setTimeout(() => {
      logRequest(`setTimeout, step: ${retrieveErrors}`);
      document.addEventListener('Intent', handleAnalizeMessage);
      createPaymentIntent({ paidOrdersList, withIntent: false });
      retrieveErrors += 1;
    }, 1000 * retrieveErrors);
  };

  const handleCancel = () => {
    handleClear();
    disconnectReader();
  };

  useEffect(() => {
    return handleCancel;
  }, []);

  useEffect(() => {
    logRequest(
      `In useEffect, retrieve: ${retrieveErrors}, declined: ${declinedErrors}`
    );
    createPaymentIntent({ paidOrdersList, withIntent: true });
    document.addEventListener('Intent', handleAnalizeMessage);

    return handleClear;
  }, []);
  const total_price = Number(formatPrice(order?.total_price));
  const tips_amount = Number(formatPrice(order?.tips_amount));
  const total = total_price + tips_amount;

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <div className={styles.innerPayment}>
          <div className={styles.innerCaption}>
            Use the terminal to complete your payment
          </div>
          <div className={styles.innerTotal}>
            <div className={styles.innerLabel}>Total</div>
            <div className={styles.innerPrice}>$ {formatPrice(total)}</div>
            <div className={styles.innerLabel}>CAD</div>
          </div>
        </div>
        <div className={styles.innerButtons}>
          <div className={styles.innerButton} onClick={handleCancel}>
            Cancel
          </div>
          <div className={clsx(styles.innerButton, styles.buttonActive)}>
            Manually enter info
          </div>
        </div>
      </div>
      <img src={paymentPattern} alt="pattern" className={styles.pattern} />
    </div>
  );
};

export default POSCardPayment;

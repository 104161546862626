/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';
import actionTypes from 'utils/actionTypes';
import { deepKeyBy } from 'utils/deepKeyBy';

import { sessionActions } from 'models/session/slice';
import { normalize } from '../../utils/normalizeById';
import { wineClubActions } from '../wineClub/slice';

const isOrderItemDuplicated = (order, orderItemId, createdItem) => {
  return (
    order?.order_items[orderItemId] &&
    order?.order_items[orderItemId].total_price === createdItem.total_price
  );
};

const getPreparedOrders = orders => {
  return orders.reduce(
    (result, current) => ({
      ...result,
      [current.id]: {
        ...current,
        total_price: current.total_price - (current?.tips_amount ?? 0),
        order_items: current.order_items.reduce(
          (orderItems, item) => ({ ...orderItems, [item.id]: item }),
          {}
        ),
      },
    }),
    {}
  );
};

const handleLoginSuccess = (state, { payload }) => {
  if (payload.role === 'host' || payload.role === 'manager') {
    const { open_orders } = payload;
    state.orders = getPreparedOrders(open_orders);
    if (open_orders.length) {
      state.currentOrderId = {
        id: open_orders[open_orders.length - 1].id,
      };
    }
    state.userLoading = false;
    state.orderLoading = false;
  }
};

const desksSlice = createSlice({
  name: 'desks',
  initialState: {
    desks: [],
    orders: {},
    guestOrders: {},
    pending: false,
    guestOrdersPending: false,
    managerCode: null,
    receiptEmail: '',
    desksLoading: false,
    userLoading: true,
    orderLoading: true,
    deskActivating: false,
    tastingMenuUpdating: false,

    customers: { pagination: {}, results: [] },
    customersLoading: false,

    deskCloseSessionModalActive: false,
    changeOrderTastingMenuModalActive: false,

    orderPaymentChanging: false,
    orderCreating: false,
    orderDeleting: false,
    orderTipsChanging: false,

    currentDeskId: null,
    currentOrderId: null,

    deskToActivate: null,

    productToPurchase: null,
    purchaseProductModalActive: false,
    paidPending: false,
    hasDeadlockError: false,
  },
  reducers: {
    fetchDesksRequest(state) {
      state.desksLoading = true;
    },
    fetchDesksSuccess(state, { payload }) {
      state.desksLoading = false;
      state.currentDeskId = null;
      state.desks = deepKeyBy(payload.results, 'id');
    },
    fetchDesksFailure(state) {
      state.desksLoading = false;
    },

    selectDesk(state, { payload }) {
      state.currentDeskId = payload.id;
      const { orders } = state.desks[payload.id];
      if (orders) {
        const orderList = Object.values(orders);
        const nextOrder = orderList.length ? orderList[0].id : null;
        state.currentOrderId = nextOrder;
      } else {
        state.currentOrderId = null;
      }
    },

    selectDeskToActivate(state, { payload }) {
      state.deskToActivate = payload.id;
    },

    activateDesk(state, { payload }) {
      state.desks[payload.deskId].tasting_menu = payload.tastingMenu;
    },
    deactivateDesk(state, { payload }) {
      state.desks[payload.id].tasting_menu = null;
      state.desks[payload.id].orders = null;
    },

    deactivateDeskRequest(state) {
      state.deskDeactivating = true;
    },
    deactivateDeskSuccess(state, { payload }) {
      state.deskDeactivating = false;
      state.desks[payload.id].tasting_menu = null;
      state.desks[payload.id].orders = null;
      state.desks[payload.id] = payload;
    },
    deactivateDeskFailure(state) {
      state.deskDeactivating = false;
    },

    clearOrders(state) {
      state.orders = {};
      state.orderLoading = false;
      state.currentOrderId = null;
    },
    clearCart(state) {
      state.desksLoading = false;
    },
    clearCartSuccess(state, { payload }) {
      if (state.currentOrderId.isGuest) {
        state.guestOrders[payload.id] = payload;
      } else {
        state.orders[payload.id] = payload;
      }
      state.desksLoading = false;
    },
    clearCartFailure(state) {
      state.desksLoading = false;
    },

    selectOrder(state, { payload }) {
      state.currentOrderId = payload;
    },

    attachTerminal() {},
    attachTerminalSuccess(state, { payload }) {
      const desk = state.desks[state.currentDeskId];
      desk.terminal = payload;
    },
    detachTerminal() {},
    detachTerminalSuccess(state) {
      const desk = state.desks[state.currentDeskId];
      desk.terminal = null;
    },

    fetchCustomersRequest(state) {
      state.customersLoading = true;
    },
    fetchCustomersSuccess(state, { payload }) {
      state.customers.pagination = payload.pagination;
      if (payload.pagination.current_page === 1) {
        state.customers.results = payload.results;
      } else {
        state.customers.results = state.customers.results.concat(
          payload.results
        );
      }
      state.customersLoading = false;
    },
    fetchCustomersFailure(state) {
      state.customersLoading = false;
    },

    replaceOrderData(state, { payload }) {
      const { order, deskId } = payload;
      const container = deskId ? state.desks[deskId] : state;
      const orderExists = get(container.orders, order.id, false);
      if (!orderExists && deskId) {
        const normalizedOrder = deepKeyBy([payload.order], 'id')[
          payload.order.id
        ];
        if (!container.orders) container.orders = {};
        container.orders[`${payload.order.id}`] = normalizedOrder;
      } else {
        container.orders[order.id] = {
          ...container.orders[order.id],
          ...order,
          order_items: order.order_items.reduce(
            (result, current) => ({
              ...result,
              [current.id]: {
                ...container.orders[order.id]?.order_items[current.id],
                ...current,
              },
            }),
            {}
          ),
        };
      }
    },

    createOrderRequest(state) {
      state.orderCreating = true;
      state.orderLoading = true;
    },
    createOrderSuccess(state, { payload }) {
      state.hasDeadlockError = false;
      const normalizedObj = deepKeyBy([payload.order], 'id');
      const normalizedOrder = normalizedObj[payload.order.id];
      if (state.currentDeskId) {
        const desk = state.desks[state.currentDeskId];
        if (desk.orders.length === 0) {
          desk.orders = {};
          state.currentOrderId = { id: payload.order.id, isGuest: false };
        }
        desk.orders[payload.order.id] = normalizedOrder;
        state.currentOrderId = {
          id: Object.values(desk.orders)[0].id,
          isGuest: false,
        };
      } else {
        state.orders[payload.order.id] = normalizedOrder;
        state.currentOrderId = { id: payload.order.id, isGuest: false };
      }
      state.orderLoading = false;
      state.orderCreating = false;
    },
    createOrderFailure(state) {
      state.orderCreating = false;
    },

    storeOrderItem(state) {
      state.billBusy = true;
    },

    createOrderItemRequest(state) {
      state.billBusy = true;
      state.orderItemCreating = true;
    },
    createOrderItemSuccess(state, { payload: { orderItem, orderId } }) {
      const order = state.currentOrderId.isGuest
        ? state.guestOrders[orderId]
        : state.orders[orderId];
      const orderItemId = orderItem.id;

      if (!isOrderItemDuplicated(order, orderItemId, orderItem)) {
        order.total_price += orderItem.total_price;
        order.fee += orderItem?.fee ?? 0;
        order.order_items[orderItemId] = orderItem;
      }

      state.orderItemCreating = false;
      state.billBusy = false;
      order.discount_amount += +orderItem?.discount_amount;

      if (orderItem?.addition && orderItem?.food_order_item_id) {
        const parentOrderItemId = orderItem?.food_order_item_id;
        // To be refactored: change the food_additions storage structure
        if (
          !order.order_items[parentOrderItemId]?.food_order_items?.find(
            item => item.id === orderItem.id
          )
        ) {
          order.order_items[parentOrderItemId].food_order_items.push(orderItem);
        }
      }
    },
    createOrderItemFailure(state) {
      state.orderItemCreating = false;
      state.billBusy = false;
    },

    updateOrderItemRequest(state) {
      state.orderItemUpdate = true;
      state.billBusy = true;
    },
    updateOrderItemSuccess(state, { payload: { orderItem, orderId } }) {
      const order = state.currentOrderId.isGuest
        ? state.guestOrders[orderId]
        : state.orders[orderId];
      const orderItemId = orderItem.id;

      if (!isOrderItemDuplicated(order, orderItemId, orderItem)) {
        order.total_price -= order.order_items[orderItem.id]?.total_price;
        order.total_price += orderItem.total_price;
        order.fee -= order.order_items[orderItem.id]?.fee;
        order.fee += orderItem.fee;

        order.discount_amount -= +order?.order_items[orderItem.id]
          ?.discount_amount;
        order.discount_amount += +orderItem?.discount_amount;

        order.order_items[orderItem.id] = orderItem;
      }

      state.billBusy = false;
      state.orderItemUpdate = false;
    },
    updateOrderItemFailure(state, { payload: hasDeadlockError }) {
      state.billBusy = false;
      state.orderItemUpdate = false;
      state.hasDeadlockError = hasDeadlockError;
    },

    createTastingOrderItemRequest(state) {
      state.billBusy = true;
      state.hasDeadlockError = false;
    },

    removeOrderItemRequest(state) {
      state.billBusy = true;
    },
    removeOrderItemSuccess(state, { payload: { orderId, orderItemId } }) {
      state.billBusy = false;
      const order = state.currentOrderId.isGuest
        ? state.guestOrders[orderId]
        : state.orders[orderId];
      if (order.order_items[orderItemId]) {
        order.total_price -= order.order_items[orderItemId].total_price;
      }

      const orderItem = order.order_items[orderItemId];

      order.discount_amount -= +orderItem.discount_amount;

      if (orderItem?.addition && orderItem?.food_order_item_id) {
        const parentOrderItemId = orderItem?.food_order_item_id;
        const parentOrderItems =
          order.order_items[parentOrderItemId]?.food_order_items;
        if (parentOrderItems) {
          order.order_items[
            parentOrderItemId
          ].food_order_items = parentOrderItems.filter(
            item => item.id !== orderItemId
          );
        }
      }
      order.fee -= order.order_items?.[orderItemId]?.fee ?? 0;
      delete order.order_items[orderItemId];
    },
    removeOrderItemFailure(state) {
      state.billBusy = false;
    },

    updateOrderRequest(state, { payload }) {
      if (!payload?.skip_loading) {
        state.orderCreating = true;
      }
    },
    updateOrderSuccess(state) {
      state.orderCreating = false;
      state.managerCode = null;
    },
    updateOrderFailure(state) {
      state.orderCreating = false;
    },
    removeOrder(state, { payload }) {
      const selectedOrders = state.currentOrderId.isGuest
        ? state.guestOrders
        : state.orders;
      delete selectedOrders[payload];
    },
    deleteOrderRequest(state) {
      state.orderDeleting = true;
    },
    // this reducer updates the state to handle the successful deletion of an order
    deleteOrderSuccess(state, payload) {
      // Remove the order from the state
      delete state.orders[payload.payload];
      state.orderDeleting = false;
      state.hasDeadlockError = false;
    },
    deleteOrderFailure(state) {
      // update the state to handle the failure scenario if needed
      state.orderDeleting = false;
      state.error = 'Failed to delete the order.';
    },

    acceptOrderEmail(state, { payload }) {
      const { id } = payload;
      state.orders = { ...state.orders, [id]: payload };
    },

    changeOrderStatus(state, { payload }) {
      // TODO: understand the action, not used yet
      const orders = Object.values(state.orders);
      const { length } = orders;
      if (length > 1) {
        state.currentOrderId = {
          id:
            orders[length - 1].id === payload[0].id
              ? orders[length - 2].id
              : orders[length - 1].id,
        };
      } else {
        state.orderLoading = true;
      }
      const selectedOrders = state.currentOrderId.isGuest
        ? state.guestOrders
        : state.orders;
      payload.forEach(item => {
        delete selectedOrders[item.id];
      });
    },

    setCurrentOrderId(state, { payload }) {
      state.currentOrderId = payload;
    },

    changeOrderPaymentToCashRequest(state) {
      state.orderPaymentChanging = true;
    },
    changeOrderPaymentToCashSuccess(state) {
      state.orderPaymentChanging = false;
      state.orderLoading = false;
    },
    changeOrderPaymentToCashFailure(state) {
      state.orderPaymentChanging = false;
    },

    changeOrderTipsRequest(state) {
      state.orderTipsChanging = true;
    },
    changeOrderTipsSuccess(state, { payload }) {
      state.orderTipsChanging = false;
      const { orderId } = payload;
      const order = state.currentOrderId.isGuest
        ? state.guestOrders[orderId]
        : state.orders[orderId];
      order.tips_amount = payload.tips;
    },
    changeOrderTipsFailure(state) {
      state.orderTipsChanging = false;
    },

    requestWaiterForPayment() {},

    openPurchaseProductModal(state, { payload }) {
      state.purchaseProductModalActive = true;
      state.productToPurchase = {
        ...payload.product,
        sample: !!payload.sample,
      };
    },
    closePurchaseProductModal(state) {
      state.purchaseProductModalActive = false;
      state.productToPurchase = null;
    },

    openDeskSessionCloseConfirmation(state) {
      state.deskCloseSessionModalActive = true;
    },
    closeDeskSessionCloseConfirmation(state) {
      state.deskCloseSessionModalActive = false;
    },

    openChangeOrderTastingMenuModal(state) {
      state.changeOrderTastingMenuModalActive = true;
    },
    closeChangeOrderTastingMenuModal(state) {
      state.changeOrderTastingMenuModalActive = false;
    },
    changeTastingMenuPriceRequest(state) {
      state.tastingMenuUpdating = true;
    },
    changeTastingMenuPriceSuccess(state, { payload }) {
      state.tastingMenuUpdating = false;
      const desk = state.desks[state.currentDeskId];
      desk[payload.id] = {
        ...desk[payload.id],
        ...payload,
        order_items: payload.order_items.reduce(
          (result, current) => ({
            ...result,
            [current.id]: {
              ...desk.orders[payload.id]?.order_items[current.id],
              ...current,
            },
          }),
          {}
        ),
      };
      state.changeOrderTastingMenuModalActive = false;
    },
    changeTastingMenuPriceFailure(state) {
      state.tastingMenuUpdating = false;
      state.changeOrderTastingMenuModalActive = false;
    },
    changeManagerCode(state, { payload }) {
      state.managerCode = payload;
    },
    changeReceiptEmail(state, { payload }) {
      state.orders[payload.id].receiptEmail = payload.email;
    },

    fetchOrderRequest(state, { payload }) {
      if (!payload?.skip_loading) {
        state.pending = true;
      }
    },
    fetchOrderSuccess(state, { payload }) {
      state.pending = false;
      const preparedOrder = {
        ...payload,
        order_items: normalize(payload.order_items),
      };
      if (payload.initiator === 'guest') {
        state.guestOrders[payload.id] = preparedOrder;
      } else {
        state.orders[payload.id] = preparedOrder;
      }
    },
    fetchOrderFailure(state) {
      state.pending = false;
    },

    fetchGuestOrdersRequest(state) {
      state.guestOrdersPending = true;
    },
    fetchGuestOrdersSuccess(state, { payload }) {
      state.guestOrders = normalize(payload);
      state.guestOrdersPending = false;
    },
    fetchGuestOrdersFailure(state) {
      state.guestOrdersPending = false;
    },
    fetchActiveOrders() {},
    fetchActiveOrdersSuccess(state, { payload }) {
      state.orders = normalize(payload);
      state.userLoading = false;
      state.orderLoading = false;
      if (payload.length) {
        state.currentOrderId = {
          id: payload[payload.length - 1].id,
        };
      }
    },

    paidByCard(state) {
      state.paidPending = true;
    },
    paidByCardSuccess(state) {
      state.paidPending = false;
    },
    paidByCardFailure(state) {
      state.paidPending = false;
    },

    changeGuestOrders(state, { payload }) {
      const { id, table } = payload;
      state.guestOrders[id] = {
        ...state.guestOrders[id],
        ...payload,
        table_id: table?.id,
        table_title: table?.title,
        order_items: normalize(payload.order_items),
      };
    },
  },
  extraReducers: {
    [sessionActions.loginSuccess]: handleLoginSuccess,
    [sessionActions.loginHostSuccess]: handleLoginSuccess,
    [sessionActions.fetchSessionSuccess](state, { payload }) {
      const { open_orders } = payload;
      state.orders = getPreparedOrders(open_orders);
      if (open_orders.length) {
        state.currentOrderId = {
          id: open_orders[open_orders.length - 1].id,
        };
      }
      state.userLoading = false;
      state.orderLoading = false;
    },
    [wineClubActions.joinWineClubSuccess](state, { payload }) {
      const { order } = payload;
      if (state.currentOrderId.isGuest) {
        state.guestOrders[order.id] = order;
      } else {
        state.orders[order.id] = order;
      }
    },
  },
});

export const desksActions = actionTypes(desksSlice.actions);

export default desksSlice.reducer;

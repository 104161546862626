export const strings = {
  internalServerError: 'The backend server returned internal error',
  guestNameEmpty: 'Guest name should not be empty!',
  createSession: 'Start',
  closeSession: 'Close',
  emailNotEmpty: 'Guest email should not be empty!',
  emailNotVerified: 'Guest email should be verified!',
  fillAllFields: 'Please fill all fields',
  codeNotEmpty: 'Verification code should not be empty!',
  orderNotSelected: 'Customer is not selected',
  tierAdded: 'Tier has been successfully attached to the user',
  expiredConfirmationToken:
    'Confirmation token is expired, new token has been sent',
  hostCreationErrored: 'Error occurred while sending host creation request',
  hostCreationSuccess: 'Host has been successfully created!',
  invalidLogin: 'Invalid credentials provided',
  successfullyChangeToPayInCash:
    'Order payment method has been successfully changed to pay in cash',
  successfullyChangeToPayByCard:
    'Order payment method has been successfully changed to pay by card',
  callWaiter: {
    cash: data =>
      `Waiter requested for cash payment for ${data.name} from ${data.table} from ${data.room}`,
  },
  continueCashPayment: 'Proceed with the cash payment for this order?',
  continueCardPayment: 'Proceed with the card payment for this order?',
  gettingTerminalsError: 'Something is wrong while getting terminals list',
  gettingPrintersError: 'Something is wrong while getting printer list',
  printingError: 'Printing error',
  printingInfo: 'The receipt has been sent to print 👌',
};

export default strings;

import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';
import { desksActions } from 'models/desks/slice';
import {
  currentOrderIdSelector,
  currentDeskIdSelector,
  orderPaymentChangingSelector,
  currentOrderSelector,
  paidPendingSelector,
  orderTipsChangingSelector,
} from 'models/desks/selectors';
import CheckoutContext from 'pages/Checkout/context';
import strings from 'constants/strings';
import formatPrice from 'utils/formatPrice';
import styles from './PaymentCash.scss';
import CashProcessingModal from 'components/Modal/CashProcessingModal/CashProcessingModal';
import SpinnerModal from 'components/Modal/SpinnerModal';

const PaymentCash = () => {
  const { handleCancelCheckout } = useContext(CheckoutContext);
  const [showModal, setModal] = useState(false);

  const changeToPayWithCash = useAction(
    desksActions.changeOrderPaymentToCashRequest
  );

  const paidPending = useSelector(paidPendingSelector);
  const orderTipsChanging = useSelector(orderTipsChangingSelector);
  const orderPaymentChanging = useSelector(orderPaymentChangingSelector);
  const loading = paidPending || orderTipsChanging || orderPaymentChanging;
  const currentOrderId = useSelector(currentOrderIdSelector);
  const currentDeskId = useSelector(currentDeskIdSelector);
  const order = useSelector(currentOrderSelector);

  const payWithCash = () => {
    setModal(true);
  };

  const onModalSubmit = () => {
    setModal(false);
    changeToPayWithCash({
      orderId: currentOrderId,
      deskId: currentDeskId,
      orderData: order,
    });
  };

  if (!currentOrderId) {
    toast.error(strings.orderNotSelected, {
      progressClassName: 'customProgressBarError',
    });
    return <Redirect to="/dashboard/tasting" />;
  }

  const totalAmount = parseFloat(
    (order?.total_price + order?.tips_amount).toFixed(2)
  );

  return (
    <CSSTransition in appear timeout={200} classNames="fade" unmountOnExit>
      <div className={styles.root}>
        <SpinnerModal isOpen={loading} />
        <CashProcessingModal
          isOpen={showModal}
          onClose={() => setModal(false)}
          total={totalAmount}
          onSubmit={onModalSubmit}
        />
        <div className={styles.price}>
          ${formatPrice(order?.total_price + order?.tips_amount)}
        </div>
        <div className={styles.title}>{strings.continueCashPayment}</div>
        <div className={styles.actions}>
          <button
            disabled={loading}
            className={styles.btn}
            onClick={handleCancelCheckout}
          >
            Cancel
          </button>
          <button
            className={styles.btn}
            onClick={payWithCash}
            disabled={loading}
          >
            Proceed
          </button>
        </div>
      </div>
    </CSSTransition>
  );
};

export default PaymentCash;

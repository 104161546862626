import React, { useEffect, useState } from 'react';
import { Dots } from 'react-activity';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { detailedOrderSelector } from 'models/paginatedOrders/selectors';
import { isUpdatingSelector } from 'models/stripe/selectors';
import { stripeActions } from 'models/stripe/slice';
import circlesIcon from 'images/icon-circles.png';

import styles from './Process.scss';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const Process = () => {
  const history = useHistory();
  const [step, setStep] = useState('Discovering reader');
  const [isOpened, setIsOpened] = useState('');
  const connectReader = useAction(stripeActions.connectReader);
  const setIsUpdating = useAction(stripeActions.setIsUpdating);
  const isUpdating = useSelector(isUpdatingSelector);
  const order = useSelector(detailedOrderSelector);

  useEffect(() => {
    // Create a function to handle the connection event that can be referenced to remove the event later
    const handleConnectionEvent = e => {
      const { type, status, message } = e.detail;
      if (type === 'update') {
        switch (status) {
          case 'start':
            setIsUpdating(true);
            setStep(message);
            break;
          case 'continue':
            setStep(`Updating reader..${(Number(message) * 100).toFixed(2)}%`);
            break;
          case 'failed':
            setStep(message);
            toast.error(message, {
              progressClassName: 'customProgressBarError',
            });
            break;
          default:
            setStep('Discovering reader');
            setIsUpdating(false);
        }
      } else if (type === 'readers') {
        setIsOpened(isOpened + message);
      } else if (type === 'final') {
        const isRefund = history.location.pathname.includes('refund');
        if (status === 'success') {
          // Go to "/dashboard/payment/total" to bypass discovering reader
          history.push(
            isRefund
              ? `/dashboard/orders/${order.id}/refund/final`
              : '/dashboard/payment/total'
          );
        } else {
          toast.error(`Reader isn't available. ${message}`, {
            progressClassName: 'customProgressBarError',
          });
          history.push(
            isRefund ? '/dashboard/orders' : '/dashboard/payment/reader/select'
          );
        }
      }
    };

    // Create the event listener
    document.addEventListener('Connection', handleConnectionEvent);

    // Connect to the reader
    connectReader();

    // Remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('Connection', handleConnectionEvent);
    };
  }, [connectReader]);

  return (
    <div className={styles.root}>
      <div className={styles.line}>
        <p className={styles.step}>{step}</p>
        {!isUpdating && <Dots size={8} />}
      </div>
      {isOpened.length > 20 && isOpened}
      <img className={styles.icon} src={circlesIcon} alt="circles icon" />
    </div>
  );
};

export default Process;

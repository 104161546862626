/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback, memo, useMemo } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import useSelector from 'hooks/useSelector';
import useAction from 'hooks/useAction';
import { paginatedCustomersActions } from 'models/paginatedCustomers/slice';
import KeyboardInput from 'components/KeyboardInput';
import styles from './Customer.scss';
import AddCustomerModal from '../../components/Modal/AddCustomerModal/AddCustomerModal';
import CustomerOverview from './CustomerOverview';
import Report from './Report';
import OrdersHistory from './OrdersHistory';
import Notes from './Notes/Notes';
import CustomerInfo from './CustomerInfo';
import SliderTier from './Slider/Slider';
import { tiersActions } from '../../models/tiers/slice';
import { togglePendingSelector } from '../../models/tiers/selectors';
import { getRedactedValues } from '../../utils/getRedactedValues';
import {
  customerPendingSelector,
  detailedCustomerSelector,
  addingNoteLoadingSelector,
  detailedCustomerLoadingSelector,
} from '../../models/paginatedCustomers/selectors';
import ChevronLeftIcon from 'components/Icons/ChevronLeftIcon';

const getCustomerDefaultValues = customer => {
  const copiedCustomer = { ...customer };
  delete copiedCustomer?.customer_profile;
  delete copiedCustomer?.orders;
  delete copiedCustomer?.wine_club_member;
  return {
    ...copiedCustomer,
    ...(customer?.customer_profile ?? {}),
  };
};

const Customer = () => {
  const { id } = useParams();
  const customer = useSelector(detailedCustomerSelector);
  const fetchCustomer = useAction(
    paginatedCustomersActions.fetchDetailedCustomerRequest
  );
  const isCustomerLoading = useSelector(detailedCustomerLoadingSelector);
  const updateCustomer = useAction(paginatedCustomersActions.updateCustomer);
  const addNote = useAction(paginatedCustomersActions.addNoteRequest);
  const addingNoteLoading = useSelector(addingNoteLoadingSelector);
  const isPending = useSelector(togglePendingSelector);
  const customerPending = useSelector(customerPendingSelector);
  const confirmTier = useAction(tiersActions.confirmTier);
  const cancelTier = useAction(tiersActions.cancelTier);

  const [note, setNote] = useState();
  const [customerModal, setCustomerModal] = useState(false);
  const activeObligation = useMemo(
    () => customer?.wine_club_member?.active_obligation,
    [customer]
  );
  const customerAddress = customer?.customer_profile;

  useEffect(() => {
    fetchCustomer({ id });
  }, [id]);

  useEffect(() => {
    setCustomerModal(false);
  }, [customer]);

  const handleSubmitNote = useCallback(
    evt => {
      evt.preventDefault();
      addNote({ id, text: note });
      setNote();
    },
    [note, addNote, id]
  );

  const handleCustomerSubmit = data => {
    const redactedData = getRedactedValues(customer, data);
    updateCustomer({ id, ...redactedData });
  };

  const handleConfirmTier = tierId => {
    if (
      tierId === activeObligation?.tier?.id &&
      activeObligation?.tier?.status !== 'CANCELLED'
    ) {
      cancelTier({
        id: activeObligation?.id,
      });
      return;
    }
    confirmTier({
      tier_id: tierId,
      customer_id: customer?.id,
      phone: customer?.phone,
      ...customer?.customer_profile,
    });
  };

  const customKeyboardButtons = useMemo(
    () => ({
      containerClassName: styles.keyboardButtonContainer,
      options: [
        {
          name: 'Add',
          type: 'submit',
          className: styles.addNoteButton,
        },
      ],
    }),
    [note]
  );

  if (isCustomerLoading) return <h2>Loading...</h2>;

  const history = useHistory();
  const handleClick = () => {
    history.goBack();
  };

  return (
    <div className={styles.root}>
      <div className={styles.back}>
        <button onClick={handleClick} className={styles.backLink}>
          <ChevronLeftIcon width="18" height="18" /> <span>Back</span>
        </button>
      </div>
      <div className={styles.wrapper}>
        <AddCustomerModal
          onClose={() => setCustomerModal(false)}
          isOpen={customerModal}
          defaultValues={getCustomerDefaultValues(customer)}
          onSubmit={handleCustomerSubmit}
          loading={customerPending}
        />
        <div className={styles.leftBlock}>
          <CustomerInfo customer={customer} />
          <form onSubmit={handleSubmitNote}>
            <KeyboardInput
              className={styles.customerNote}
              type="text"
              name="customer_note"
              placeholder="Add a Customer Note"
              buttonOptions={customKeyboardButtons}
              value={note}
              loading={addingNoteLoading}
              onChange={setNote}
            />
          </form>
          <Report customer={customer} />
          <OrdersHistory orders={customer?.orders || []} />
        </div>
        <div className={styles.rightBlock}>
          <CustomerOverview customer={customer} setIsOpen={setCustomerModal} />
          <SliderTier
            isPending={isPending}
            address={customerAddress}
            phone={customer?.phone}
            currentTier={activeObligation}
            onToggleTierClick={handleConfirmTier}
            wineclub={customer?.wine_club_member || {}}
          />
          <Notes notes={customer?.notes || []} />
        </div>
        <div className={styles.breadcrumbs}>
          <Link
            className={styles.breadcrumbsCustomers}
            to="/dashboard/customers"
          >
            Customers
          </Link>
          <p>/</p>
          <p className={styles.breadcrumbsCustomer}>
            {customer.first_name} {customer.last_name}
          </p>
        </div>
      </div>
    </div>
  );
};

export default memo(Customer);

import React, { useMemo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SortCoupleIcon from 'components/Icons/SortCoupleIcon';
import SortDownIcon from 'components/Icons/SortDownIcon';
import SortUpIcon from 'components/Icons/SortUpIcon';
import styles from './TimesheetTableHeadCol.scss';

const TimesheetTableHeadCol = ({
  onSort,
  sortable,
  sortingField,
  title,
  width,
  minimal,
  activeSorting,
  activeSortingOrder,
}) => {
  const sort = () => sortable && onSort(sortingField);
  const sortingIcon = useMemo(() => {
    if (activeSorting) {
      if (activeSortingOrder === 'asc') {
        return <SortUpIcon />;
      }
      return <SortDownIcon />;
    }
    if (sortable) return <SortCoupleIcon />;
    return null;
  }, [activeSorting, sortable, activeSortingOrder]);

  const className = clsx(styles.root, {
    [styles.sortable]: sortable,
    [styles.minimal]: minimal,
  });

  return (
    <th className={className} style={{ width: `${width}%` }} onClick={sort}>
      <div className={styles.rootInner}>
        {sortable && <div className={styles.sortIcon}>{sortingIcon}</div>}
        <div>{title}</div>
      </div>
    </th>
  );
};

TimesheetTableHeadCol.propTypes = {
  title: PropTypes.string,
  width: PropTypes.number,
  onSort: PropTypes.func,
  minimal: PropTypes.bool,
  sortable: PropTypes.bool,
  activeSorting: PropTypes.bool,
  sortingField: PropTypes.string,
  activeSortingOrder: PropTypes.oneOf(['asc', 'desc']),
};

TimesheetTableHeadCol.defaultProps = {
  title: '',
  onSort: null,
  minimal: false,
  sortable: false,
  sortingField: '',
  activeSorting: false,
  activeSortingOrder: null,
};

export default TimesheetTableHeadCol;

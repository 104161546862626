import { takeLatest, all, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { authenticatedRequest } from 'api';
import { timeEntryUrl, timeEntryUrlById, timesheetUrlByUserId } from 'api/urls';
import { timeEntryActions } from 'models/timeEntry/slice';
import { toast } from 'react-toastify';
import { sessionActions } from '../session/slice';
import { refreshKey } from 'constants/localStorage';
import { shiftStatus } from 'constants/shiftStatus';

function* fetchTimeEntrySaga({ payload }) {
  try {
    const { user_id, per_page, page } = payload;
    const { data } = yield authenticatedRequest({
      method: 'GET',
      data: { ...payload },
      url: timesheetUrlByUserId(user_id, per_page, page),
    });

    yield put({
      type: timeEntryActions.fetchTimeEntrySuccess,
      payload: data,
    });
  } catch (error) {
    yield put({ type: timeEntryActions.fetchTimeEntryFailure });
    yield call(
      [toast, 'error'],
      'Something went wrong while getting time entry'
    );
  }
}

function* createTimeEntrySaga({ payload }) {
  try {
    const { data } = yield authenticatedRequest({
      url: timeEntryUrl,
      method: 'POST',
      data: { ...payload },
    });

    yield put({
      type: timeEntryActions.createTimeEntrySuccess,
      payload: data,
    });

    if (payload?.status === shiftStatus.START) {
      yield put({
        type: sessionActions.setStartedTimeEntry,
        payload: data,
      });
    }

    yield put(push('/dashboard/pos'));
  } catch (error) {
    // Extract the specific error message for warehouse conflict
    const errorMessage =
      error.response?.data?.started_at?.[0] ||
      'Something went wrong while creating shift entry';

    yield put({
      type: timeEntryActions.createTimeEntryFailure,
      payload: errorMessage,
    });

    // Display the error message using toast
    yield call([toast, 'error'], errorMessage, {
      progressClassName: 'customProgressBarError',
    });
  }
}

function* updateTimeEntrySaga({ payload }) {
  try {
    const { id } = payload;
    const { data } = yield authenticatedRequest({
      url: timeEntryUrlById(id),
      method: 'PUT',
      data: { ...payload },
    });

    yield all([
      put({
        type: timeEntryActions.updateTimeEntrySuccess,
        payload: data,
      }),
      put({
        type: sessionActions.logoutHostRequest,
        payload: { refresh_token: localStorage.getItem(refreshKey) },
      }),
      put(push('/')),
    ]);

    yield call([toast, 'success'], 'Timesheet successfully updated', {
      progressClassName: 'customProgressBarSuccess',
    });
  } catch (error) {
    const errorMessage =
      error.response?.data?.warehouse_id?.[0] ||
      error.response?.data ||
      'Something went wrong while updating shift entry';

    yield put({
      type: timeEntryActions.updateTimeEntryFailure,
      payload: errorMessage,
    });

    yield call([toast, 'error'], errorMessage, {
      progressClassName: 'customProgressBarError',
    });
  }
}

export default function*() {
  yield all([
    takeLatest(timeEntryActions.fetchTimeEntry, fetchTimeEntrySaga),
    takeLatest(timeEntryActions.createTimeEntry, createTimeEntrySaga),
    takeLatest(timeEntryActions.updateTimeEntry, updateTimeEntrySaga),
  ]);
}

import clsx from 'clsx';
import React, { useMemo } from 'react';
import useSelector from 'hooks/useSelector';

import { POSContentLayoutSelector } from 'models/interface/selectors';
import { POSContentLayoutsEnum } from 'constants/POSLayouts';

import MenuLayout from './MenuLayout';
import CreateOrderLayout from './CreateOrderLayout';

import styles from './Content.scss';

const Content = () => {
  const contentLayout = useSelector(POSContentLayoutSelector);

  const renderedLayout = useMemo(() => {
    switch (contentLayout) {
      case POSContentLayoutsEnum.MENU:
        return <MenuLayout />;
      case POSContentLayoutsEnum.ORDER:
        return <CreateOrderLayout />;
      default:
        return null;
    }
  }, [contentLayout]);

  return (
    <div className={clsx(styles.root)}>
      <div className={styles.inner}>{renderedLayout}</div>
    </div>
  );
};

export default Content;

import React, { useEffect, useState } from 'react';
import styles from './PriceInputRange.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import InputRange from '../InputRange';

const PriceInputRange = ({
  onChange,
  minValue,
  maxValue,
  value,
  className,
}) => {
  const [min, setMin] = useState(value?.min ?? '');
  const [max, setMax] = useState(value?.max ?? '');

  useEffect(() => {
    onChange?.({ min, max });
  }, [min, max]);

  const onRangeChangeHandler = range => {
    setMin(range.min);
    setMax(range.max);
  };

  return (
    <div className={clsx(styles.container, className)}>
      <span className={styles.label}>Select price range</span>
      <div className={styles.inputContainer}>
        <label className={styles.inputWrapper}>
          <input
            placeholder="Minimum price"
            className={styles.input}
            type="number"
            step={0.01}
            value={min}
            onChange={event => setMin(event.target.value)}
          />
        </label>
        <label className={styles.inputWrapper}>
          <input
            placeholder="Maximum price"
            className={styles.input}
            type="number"
            step={0.01}
            value={max}
            onChange={event => setMax(event.target.value)}
          />
        </label>
      </div>
      <InputRange
        step={10}
        minValue={minValue}
        maxValue={maxValue}
        value={{ min, max }}
        onChange={onRangeChangeHandler}
      />
    </div>
  );
};

PriceInputRange.propTypes = {
  onChange: PropTypes.func,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  value: PropTypes.any.isRequired,
  className: PropTypes.string,
};
export default PriceInputRange;

import React from 'react';

import useAction from 'hooks/useAction';

import { paginatedCustomersActions } from 'models/paginatedCustomers/slice';

import CalendarDate from 'components/CalendarDate';
import SearchInput from 'components/SearchInput';
import CustomersTable from './CustomersTable';

import useSelector from 'hooks/useSelector';
import { minCustomerSearchQueryLengthValueSelector } from 'models/session/selectors';

import styles from './Customers.scss';

const Customers = () => {
  const changeSearch = useAction(paginatedCustomersActions.changeSearch);
  const minCustomerSearchQueryLengthValue = useSelector(
    minCustomerSearchQueryLengthValueSelector
  );
  const onSearchChange = search => changeSearch({ search });

  const handleOnChange = evt => {
    if (evt.target.value.trim().length >= minCustomerSearchQueryLengthValue) {
      onSearchChange(evt.target.value);
    } else if (evt.target.value.trim().length === 0) {
      onSearchChange('');
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.topbar}>
        <CalendarDate />
        <SearchInput onChange={handleOnChange} />
      </div>

      <div className={styles.content}>
        <CustomersTable />
      </div>
    </div>
  );
};

export default Customers;

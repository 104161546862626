import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import formatPrice from 'utils/formatPrice';
import TimesIcon from 'components/Icons/TimesIcon';
import EditIcon from 'components/Icons/EditIcon';

import styles from './CartItem.scss';
import { OrderItemsResourceTypes } from 'constants/orderItems';

import { getProductTitleWithVintage } from 'utils/getPreparedInventory';
import { productCountsByIdSelector } from 'models/productCategories/selectors';
import { hasDeadlockErrorSelector } from 'models/desks/selectors';
import { desksActions } from 'models/desks/slice';
import useSelector from 'hooks/useSelector';
import useAction from 'hooks/useAction';
import { debounce } from 'lodash';

const checkSingleTypeOrderItem = item => {
  const resourceType = item?.product?.resourcetype ?? item?.resourcetype;
  return [
    OrderItemsResourceTypes.FOOD_ORDER_ITEM,
    'Food',
    'Merchandise',
  ].includes(resourceType);
};

const CartItem = ({
  onRemove,
  item,
  openEditOrderModal,
  isCheckoutCart,
  orderId,
}) => {
  const getProductType = {
    TastingMenuOrderItem: 'Tasting Menu',
    ProductOrderItem: 'Bottle',
    ProductSampleOrderItem: 'Glass',
  };

  if (item?.addition) {
    return <></>;
  }

  const productId = item?.product?.id;
  const productCount = useSelector(productCountsByIdSelector(productId));
  const deadLock = useSelector(hasDeadlockErrorSelector);
  const [count, setCount] = useState(item?.number);
  useEffect(() => {
    if (deadLock) {
      setCount(item.number);
    }
  }, [item]);
  const isPlusButtonDisabled =
    item?.resourcetype === OrderItemsResourceTypes?.PRODUCT_ORDER_ITEM &&
    productCount <= count - item?.number;
  const createItem = useAction(desksActions.storeOrderItem);
  const updateOrderItem = useAction(desksActions.updateOrderItemRequest);
  const debouncedUpdateOrderItem = useCallback(
    debounce(updateOrderItem, 300),
    []
  );
  const onRemoveItem = useAction(desksActions.removeOrderItemRequest);
  const changeCartItemCount = newCount => {
    if (item?.number === 0) {
      debouncedUpdateOrderItem.cancel();
      createItem({
        number: newCount,
        product_id: item?.product?.id,
        type: item?.resourcetype,
      });
    } else if (newCount === 0) {
      debouncedUpdateOrderItem.cancel();
      onRemoveItem({ orderId, orderItemId: item?.id });
    } else {
      debouncedUpdateOrderItem({
        number: newCount,
        resourcetype: item?.resourcetype,
        orderItemId: item?.id,
        order_id: orderId,
        addedNumber: newCount - item?.number,
      });
    }
    setCount(newCount);
  };
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.infoTitleBlock}>
          {/* Remove button */}
          {!isCheckoutCart && (
            <button className={styles.infoDeleteButton} onClick={onRemove}>
              <TimesIcon />
            </button>
          )}
          <h4 className={styles.infoTitle}>
            {/* Title */}
            {isCheckoutCart
              ? getProductTitleWithVintage(item?.title, item?.product?.vintage)
              : item.alias_title.trim() ||
                getProductTitleWithVintage(item?.title, item?.product?.vintage)}
          </h4>
          {/* Edit button */}
          {!isCheckoutCart && openEditOrderModal && (
            <button
              className={styles.infoEditButton}
              onClick={() => openEditOrderModal(item)}
            >
              <EditIcon />
            </button>
          )}
        </div>
      </div>
      <div className={styles.priceBlock}>
        <p className={styles.price}>
          ${''}
          {formatPrice(
            item?.discount_percentage || item?.discount_amount
              ? item?.product_price * item?.number -
                  (item?.total_order_item_discount || 0)
              : item?.product_price * item?.number || item?.total_price
          )}
        </p>
        {Boolean(item?.discount_percentage || item?.discount_amount) && (
          <p className={styles.oldPrice}>
            ${formatPrice(item?.product_price * item?.number)}
          </p>
        )}
      </div>
      {(item?.food_order_items ?? []).map(orderItem => (
        <div key={orderItem.id} className={styles.addition}>
          <p className={styles.additionTitle}>{orderItem?.title}</p>
          <div className={styles.additionPriceWrapper}>
            <p className={styles.additionPrice}>
              ${''}
              {formatPrice(
                orderItem?.discount_amount
                  ? orderItem?.product_price * orderItem?.number -
                      orderItem?.discount_amount
                  : orderItem?.product_price * orderItem?.number ||
                      orderItem?.total_price
              )}
            </p>
            {Boolean(item.discount_amount) && (
              <p className={styles.additionOldPrice}>
                ${formatPrice(orderItem?.product_price * orderItem?.number)}
              </p>
            )}
          </div>
        </div>
      ))}
      <p className={styles.descriptionItem}>
        {!checkSingleTypeOrderItem(item)
          ? `(${getProductType[item?.resourcetype]})`
          : (item?.comment && `${item?.comment.substring(0, 20)}...`) || ''}
      </p>
      {isCheckoutCart && (
        <div className={styles.descriptionItem}>({count})</div>
      )}
      {!isCheckoutCart && (
        <div
          className={clsx(styles.descriptionItem, styles.cartButtonsWrapper)}
        >
          <button
            className={styles.cartButton}
            onClick={() => changeCartItemCount(count - 1)}
            disabled={count === 0}
          >
            -
          </button>
          <span>{count}</span>
          <button
            className={styles.cartButton}
            onClick={() => changeCartItemCount(count + 1)}
            disabled={isPlusButtonDisabled}
          >
            +
          </button>
        </div>
      )}
    </div>
  );
};

CartItem.propTypes = {
  item: PropTypes.object,
  openEditOrderModal: PropTypes.func,
  isCheckoutCart: PropTypes.bool,
  onRemove: PropTypes.func,
  orderId: PropTypes.number,
};

export default CartItem;

import { createAction } from '@reduxjs/toolkit';
import { CACHE_VALID_MS } from 'constants/idleTimer';

export const resetState = createAction('root/resetState');

export const isCacheValid = (stateVariable, id) => {
  const cachedValue = stateVariable?.[id];
  if (!cachedValue) return false;

  return Date.now() - cachedValue.timestamp < CACHE_VALID_MS;
};

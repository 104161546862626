import React from 'react';
import PropTypes from 'prop-types';
import styles from './InputLabelled.scss';

const InputLabelled = ({ label, value, onChange, placeholder, ...props }) => {
  return (
    <div className={styles.root}>
      <div className={styles.label}>{label}</div>
      <div className={styles.wrapper}>
        <input
          value={value}
          onChange={onChange}
          className={styles.input}
          placeholder={placeholder}
          {...props}
        />
      </div>
    </div>
  );
};

InputLabelled.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  maxLength: PropTypes.string,
};

export default React.memo(InputLabelled);

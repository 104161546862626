import React from 'react';

const MenuDotsIcon = () => (
  <svg
    width="24"
    height="4"
    viewBox="0 0 24 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.69782 3.39565C2.63163 3.39565 3.39565 2.63163 3.39565 1.69782C3.39565 0.764021 2.63163 0 1.69782 0C0.764021 0 0 0.764021 0 1.69782C0 2.63163 0.764021 3.39565 1.69782 3.39565Z"
      fill="#C9C7C1"
    />
    <path
      d="M11.8843 3.39565C12.8182 3.39565 13.5822 2.63163 13.5822 1.69782C13.5822 0.764021 12.8182 0 11.8843 0C10.9505 0 10.1865 0.764021 10.1865 1.69782C10.1865 2.63163 10.9505 3.39565 11.8843 3.39565Z"
      fill="#C9C7C1"
    />
    <path
      d="M22.0718 3.39565C23.0057 3.39565 23.7697 2.63163 23.7697 1.69782C23.7697 0.764021 23.0057 0 22.0718 0C21.138 0 20.374 0.764021 20.374 1.69782C20.374 2.63163 21.138 3.39565 22.0718 3.39565Z"
      fill="#C9C7C1"
    />
  </svg>
);

export default MenuDotsIcon;

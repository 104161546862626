import clsx from 'clsx';
import React, { useCallback } from 'react';

import useSelector from 'hooks/useSelector';

import styles from './MenuLayout.scss';
import useAction from 'hooks/useAction';
import { menuBreadcrumbsSelector } from 'models/interface/selectors';
import { foodAllergiesSelector } from 'models/food/selectors';
import { interfaceActions } from 'models/interface/slice';
import { productCategoriesSelector } from 'models/productCategories/selectors';
import MenuLayoutComponent from './MenuLayoutComponent';
import { getOrderedProductCategories } from 'utils/getOrderedProductCategories';

const MenuLayout = () => {
  // Selectors
  const breadcrumbs = useSelector(menuBreadcrumbsSelector);
  const foodAllergies = useSelector(foodAllergiesSelector);
  const productCategories = useSelector(productCategoriesSelector);

  // Actions
  const setBreadcrumbs = useAction(interfaceActions.changeMenuBreadcrumbs);

  // Callbacks
  const selectCategory = useCallback(
    category => {
      setBreadcrumbs([...breadcrumbs, category]);
    },
    [breadcrumbs, setBreadcrumbs]
  );

  const handleBreadcrumbClick = useCallback(
    breadcrumbIndex => {
      setBreadcrumbs([...breadcrumbs.slice(0, breadcrumbIndex + 1)]);
    },
    [breadcrumbs, setBreadcrumbs]
  );

  return (
    <div className={clsx(styles.root)}>
      <div className={styles.breadcrumbs}>
        {breadcrumbs.map((breadcrumb, index) => (
          <div className={styles.breadcrumb} key={breadcrumb.title}>
            <span
              className={styles.breadcrumbTitle}
              onClick={() => handleBreadcrumbClick(index)}
            >
              {breadcrumb.title}
            </span>
            {index < breadcrumbs.length - 1 && (
              <span className={styles.breadcrumbSpacer}>/</span>
            )}
          </div>
        ))}
      </div>
      <div className={styles.inner}>
        {productCategories && (
          <MenuLayoutComponent
            categories={getOrderedProductCategories(productCategories)}
            element={breadcrumbs[breadcrumbs.length - 1]}
            onClick={selectCategory}
            setBreadcrumbs={setBreadcrumbs}
            allergies={foodAllergies}
          />
        )}
      </div>
    </div>
  );
};

export default MenuLayout;

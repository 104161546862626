import axios from 'axios';
import config from 'config';

import { tokenKey, refreshKey } from 'constants/localStorage';

let isRefreshing = false;
axios.interceptors.response.use(
  resp => resp,
  async error => {
    if (error.response && error.response.status === 401 && !isRefreshing) {
      const originalRequest = error.config;

      isRefreshing = true;

      const refreshResponse = await axios.post(
        `${config.baseUrl}/tasting-room-master-api/v1/auth/token/refresh/`,
        {
          refresh: localStorage.getItem(refreshKey),
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
        { withCredentials: true }
      );
      if (refreshResponse.status === 200) {
        originalRequest.headers.authorization = `Bearer ${refreshResponse.data.access}`;

        localStorage.setItem(tokenKey, refreshResponse.data.access);
        localStorage.setItem(refreshKey, refreshResponse.data.refresh);
        return axios(originalRequest);
      }
    }
    isRefreshing = false;

    return Promise.reject(error);
  }
);

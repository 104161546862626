import { request } from 'api';
import { tokenKey } from 'constants/localStorage';

const useAuthRequest = () => {
  const token = localStorage.getItem(tokenKey);
  return options => {
    let headers = {};
    headers = { ...options.headers };
    if (token) headers.authorization = `Bearer ${token}`;
    return request({
      ...options,
      headers,
    });
  };
};

export default useAuthRequest;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './TierCard.scss';
import clsx from 'clsx';
import activeImg from 'assets/images/active-tier.svg';
import noActiveImg from 'assets/images/no-active-tier.svg';

const periodName = {
  Quarterly: 'quarter',
  Biannually: 'twice a year',
  Monthly: 'month',
};

const TierCard = ({
  isPending,
  inactiveText,
  onToggleTierClick,
  tier,
  wineclub_title,
  isActive,
}) => {
  const capacity = tier?.variations[0]?.capacity;
  const period = periodName[tier?.variations[0]?.period_in_months];
  const discountTastingRoom =
    tier?.variations[0]?.discount_for_visiting_tasting_room;
  const tierId = tier?.variations[0]?.id;

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <h4 className={styles.wineclub}>{wineclub_title}</h4>
        {isActive ? (
          <img src={activeImg} alt="active" className={styles.icon} />
        ) : (
          <img src={noActiveImg} alt="noactive" className={styles.icon} />
        )}
      </div>
      <div className={styles.body}>
        <h3 className={styles.title}>{tier?.title}</h3>
        <p className={styles.text}>
          {capacity} bottles / {period}
          {discountTastingRoom ? ` - ${discountTastingRoom}% Off Wine` : ''} –
          $25 Shipping
        </p>
      </div>
      <div className={styles.footer}>
        <button
          className={clsx(styles.button, isActive && styles.active)}
          onClick={() => onToggleTierClick(tierId)}
          disabled={isPending || (!isActive && inactiveText)}
        >
          {isActive ? 'unsubscribe' : 'subscribe'}
        </button>
        {!!inactiveText && (
          <small className={styles.addressText}>{inactiveText}</small>
        )}
      </div>
    </div>
  );
};

TierCard.propTypes = {
  isActive: PropTypes.bool,
  tier: PropTypes.object,
  wineclub_title: PropTypes.string,
  onToggleTierClick: PropTypes.func,
  inactiveText: PropTypes.string,
  isPending: PropTypes.bool,
};

export default memo(TierCard);

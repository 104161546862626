import { createSelector } from 'reselect';
import { pick, get, floor, keyBy } from 'lodash';
import { ACTIVE_STATUSES } from 'constants/orderStatus';
import { OrderItemsResourceTypes } from 'constants/orderItems';

import {
  mapProductsWithOrderItems,
  getOrdersPriceSummary,
  mapFoodWithOrderItems,
  mapTastingMenusWithOrderItems,
} from 'utils/getDeskInfo';
import { getCheckoutData } from '../../utils/getCheckoutData';

const paramSelector = (_, param) => param;

export const desksSelector = createSelector(
  state => state,
  state => state.desks
);

export const isPendingSelector = createSelector(
  desksSelector,
  ({ pending }) => pending
);

export const paidPendingSelector = createSelector(
  desksSelector,
  ({ paidPending }) => paidPending
);

export const orderTipsChangingSelector = createSelector(
  desksSelector,
  ({ orderTipsChanging }) => orderTipsChanging
);

export const posOrdersSelector = createSelector(
  desksSelector,
  ({ orders }) => orders
);

export const guestOrdersSelector = createSelector(
  desksSelector,
  ({ guestOrders }) => guestOrders
);

export const guestOrdersPendingSelector = createSelector(
  desksSelector,
  ({ guestOrdersPending }) => guestOrdersPending
);

export const desksEntitiesSelector = createSelector(
  desksSelector,
  ({ desks }) => desks
);

export const desksListSelector = createSelector(desksEntitiesSelector, desks =>
  Object.values(desks)
);

export const customersLoadingSelector = createSelector(
  desksSelector,
  ({ customersLoading }) => customersLoading
);

export const desksLoadingSelector = createSelector(
  desksSelector,
  ({ desksLoading }) => desksLoading
);

export const userLoadingSelector = createSelector(
  desksSelector,
  ({ userLoading }) => userLoading
);

export const tastingMenuUpdatingSelector = createSelector(
  desksSelector,
  ({ tastingMenuUpdating }) => tastingMenuUpdating
);

export const isTastingMenuModalActiveSelector = createSelector(
  desksSelector,
  ({ changeOrderTastingMenuModalActive }) => changeOrderTastingMenuModalActive
);

export const currentDeskIdSelector = createSelector(
  desksSelector,
  ({ currentDeskId }) => currentDeskId
);

export const ordersWithoutTableSelector = createSelector(
  desksSelector,
  ({ orders }) => orders
);

export const activeDeskSelector = createSelector(
  [desksEntitiesSelector, currentDeskIdSelector],
  (desks, id) => desks[id]
);

export const currentDeskActivatedSelector = createSelector(
  [activeDeskSelector],
  desk => (desk ? desk.tasting_menu != null : null)
);

export const orderEntitiesSelector = createSelector(
  [activeDeskSelector, ordersWithoutTableSelector],
  (desk, orders) => {
    return desk ? desk.orders : orders;
  }
);

export const orderListSelector = createSelector(orderEntitiesSelector, orders =>
  orders ? Object.values(orders) : []
);

export const orderItemsSelector = createSelector(orderListSelector, orders => {
  return keyBy(Object.values(orders), 'customer_name');
});

export const currentOrderInfoSelector = createSelector(
  desksSelector,
  ({ currentOrderId }) => currentOrderId
);

export const currentOrderIdSelector = createSelector(
  desksSelector,
  ({ currentOrderId }) => currentOrderId.id
);

export const currentOrderSelector = createSelector(
  desksSelector,
  ({ orders, guestOrders, currentOrderId }) => {
    if (currentOrderId) {
      const { id } = currentOrderId;
      return currentOrderId?.isGuest ? guestOrders[id] : orders[id];
    }
    return null;
  }
);

export const currentOrderItemsListSelector = createSelector(
  currentOrderSelector,
  order => {
    return order ? Object.values(order.order_items) : [];
  }
);

export const currentDeskEmptySelector = createSelector(
  orderListSelector,
  list => list.length <= 0
);

export const ordersCustomersSelector = createSelector(
  orderListSelector,
  orders => {
    return orders.map(o => pick(o, ['customer_name', 'id']));
  }
);

export const ordersPriceSummarySelector = createSelector(
  [orderListSelector],
  orders => {
    if (!orders.length) return 0;
    return getOrdersPriceSummary(orders);
  }
);

export const selectedOrdersPriceSummarySelector = createSelector(
  [orderEntitiesSelector, paramSelector],
  (orders, selectedOrdersIds) => {
    const selectedOrders = selectedOrdersIds.map(item => get(orders, item));
    return getOrdersPriceSummary(selectedOrders);
  }
);

export const ordersAveragePriceSummarySelector = createSelector(
  [orderListSelector, ordersPriceSummarySelector],
  (orders, summary) => {
    if (!summary || !orders.length) return 0;
    return floor(summary / orders.length, 2);
  }
);

export const orderCheckoutDataSelector = createSelector(
  [currentOrderSelector],
  order => {
    if (!order) return null;

    return getCheckoutData(order);
  }
);

export const orderSamplesSelector = createSelector(
  currentOrderItemsListSelector,
  orderItems =>
    orderItems.filter(
      o => o.resourcetype === OrderItemsResourceTypes.PRODUCT_SAMPLE_ORDER_ITEM
    )
);

export const orderBottlesSelector = createSelector(
  currentOrderItemsListSelector,
  orderItems =>
    orderItems.filter(
      o => o.resourcetype === OrderItemsResourceTypes.PRODUCT_ORDER_ITEM
    )
);

export const orderFoodSelector = createSelector(
  currentOrderItemsListSelector,
  orderItems =>
    orderItems.filter(
      o => o.resourcetype === OrderItemsResourceTypes.FOOD_ORDER_ITEM
    )
);

export const orderTastingMenuSelector = createSelector(
  currentOrderItemsListSelector,
  orderItems => {
    return orderItems.filter(
      o => o.resourcetype === OrderItemsResourceTypes.TASTING_MENU_ORDER_ITEM
    );
  }
);

export const currentTastingMenuSelector = createSelector(
  currentOrderItemsListSelector,
  orderItems =>
    orderItems.find(
      o => o.resourcetype === OrderItemsResourceTypes.TASTING_MENU_ORDER_ITEM
    )
);

export const orderBottleProductsMapSelector = createSelector(
  orderBottlesSelector,
  mapProductsWithOrderItems
);

export const orderSampleProductsMapSelector = createSelector(
  orderSamplesSelector,
  mapProductsWithOrderItems
);

export const orderFoodMapSelector = createSelector(
  orderFoodSelector,
  mapFoodWithOrderItems
);

export const tastingMenuMapSelector = createSelector(
  orderTastingMenuSelector,
  mapTastingMenusWithOrderItems
);

export const orderDeletingSelector = createSelector(
  desksSelector,
  ({ orderDeleting }) => orderDeleting
);

export const orderLoadingSelector = createSelector(
  desksSelector,
  ({ orderLoading }) => orderLoading
);

export const orderCreatingSelector = createSelector(
  desksSelector,
  ({ orderCreating }) => orderCreating
);

export const deskToActivateSelector = createSelector(
  desksSelector,
  ({ deskToActivate }) => deskToActivate
);

export const billBusySelector = createSelector(
  desksSelector,
  ({ billBusy }) => billBusy
);

export const orderPaymentChangingSelector = createSelector(
  desksSelector,
  ({ orderPaymentChanging }) => orderPaymentChanging
);

export const currentOrderFinalizedSelector = createSelector(
  currentOrderSelector,
  order => {
    if (!order) return true;
    return !ACTIVE_STATUSES.includes(order.status);
  }
);

export const productToPurchaseSelector = createSelector(
  desksSelector,
  ({ productToPurchase }) => productToPurchase
);

export const purchaseProductModalActiveSelector = createSelector(
  desksSelector,
  ({ purchaseProductModalActive }) => purchaseProductModalActive
);

export const deactivateDeskConfirmationModalActiveSelector = createSelector(
  desksSelector,
  ({ deskCloseSessionModalActive }) => deskCloseSessionModalActive
);

export const orderWineOptionsSelector = createSelector(
  [currentOrderSelector, paramSelector],
  (order, wine) => {
    const options = Object.values(order.order_items).reduce(
      (result, current) => {
        if (
          current.product?.id === wine.id ||
          current?.products_preset_id === wine.id
        ) {
          return {
            ...result,
            [current.resourcetype]: {
              id: current.id,
              product: current.product,
              price: current.product_price || current.total_price,
              number: current.number,
              resourcetype: current.resourcetype,
            },
          };
        } else if (
          current.tasting_menu_id === wine.id &&
          current.resourcetype === wine.type
        ) {
          return {
            ...result,
            [current.resourcetype]: {
              id: current.id,
              tasting_menu_id: current.tasting_menu_id,
              price: current.total_price,
              number: current.number,
              resourcetype: current.resourcetype,
            },
          };
        }
        return result;
      },
      {}
    );
    if (
      Object.keys(options).length < 2 &&
      Object.keys(options)[0] !==
        OrderItemsResourceTypes.TASTING_MENU_ORDER_ITEM
    ) {
      const option = options[OrderItemsResourceTypes.PRODUCT_ORDER_ITEM];
      const sampleOption =
        options[OrderItemsResourceTypes.PRODUCT_SAMPLE_ORDER_ITEM];
      if (option?.product.can_be_a_sample) {
        options[OrderItemsResourceTypes.PRODUCT_SAMPLE_ORDER_ITEM] = {
          product: option.product,
          price: option.product.sample_price,
          number: 0,
          resourcetype: OrderItemsResourceTypes.PRODUCT_SAMPLE_ORDER_ITEM,
        };
      } else if (sampleOption && !option) {
        options[OrderItemsResourceTypes.PRODUCT_ORDER_ITEM] = {
          product: sampleOption.product,
          price: sampleOption.product.price,
          number: 0,
          resourcetype: OrderItemsResourceTypes.PRODUCT_ORDER_ITEM,
        };
      }
    }
    return options;
  }
);

export const managerCodeSelector = createSelector(
  desksSelector,
  ({ managerCode }) => managerCode
);

export const receiptEmailSelector = createSelector(
  currentOrderSelector,
  ({ receiptEmail, customer }) => receiptEmail || customer?.email
);

export const customersSelector = createSelector(
  desksSelector,
  ({ customers }) => customers
);

export const orderItemPending = createSelector(
  desksSelector,
  ({ orderItemCreating }) => orderItemCreating
);

export const orderItemUpdatePending = createSelector(
  desksSelector,
  ({ orderItemUpdate }) => orderItemUpdate
);
export const hasDeadlockErrorSelector = createSelector(
  desksSelector,
  ({ hasDeadlockError }) => hasDeadlockError
);

export const numberOfGuestsSelector = createSelector(
  [posOrdersSelector, (_, orderId) => orderId],
  (orders, orderId) => orders[orderId]?.num_of_guests
);

import React, { useEffect, useState } from 'react';
import styles from './DateRangePicker.scss';
import DatePicker from '../DatePicker';
import { format } from 'date-fns';
import { DateUtils } from 'react-day-picker';
import PropTypes from 'prop-types';

const pickerStyles = {
  between: {
    backgroundColor: styles.ligthTransparentColor,
    color: styles.lightColor,
    borderRadius: 0,
  },
  startDate: {
    backgroundColor: styles.lightColor,
    color: styles.blackColor,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  endDate: {
    backgroundColor: styles.lightColor,
    color: styles.blackColor,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
};

const getDateRangeText = (start, end) => {
  const startDate = start ? `${format(start, 'dd.MM.yy')} - ` : 'Select date';
  const endDate = end ? format(end, 'dd.MM.yy') : '';
  return `${startDate}${endDate}`;
};

const DateRangePicker = ({ startDate, endDate, title, onRangeChange }) => {
  const [showPicker, setShowPicker] = useState(false);

  useEffect(() => {
    onRangeChange?.({ startDate, endDate });
  }, [startDate, endDate]);

  const modifiers = {
    startDate,
    endDate,
    between: day =>
      startDate && endDate && DateUtils.isDayBetween(day, startDate, endDate)
        ? day
        : null,
    disabled: day => day > new Date(),
  };

  const onDaySelectHandler = day => {
    if (endDate) {
      onRangeChange?.({
        startDate: day,
        endDate: null,
      });
      return;
    }
    const { from, to } = DateUtils.addDayToRange(day, {
      from: startDate,
      end: endDate,
    });
    onRangeChange?.({
      startDate: from,
      endDate: to,
    });
  };

  return (
    <div className={styles.container}>
      <p className={styles.label}>{title}</p>
      <button onClick={() => setShowPicker(true)} className={styles.button}>
        {getDateRangeText(startDate, endDate)}
      </button>
      {showPicker && (
        <DatePicker
          className={styles.picker}
          onHide={() => setShowPicker(false)}
          onSelect={onDaySelectHandler}
          modifiers={modifiers}
          pickerStyles={pickerStyles}
        />
      )}
    </div>
  );
};

DateRangePicker.propTypes = {
  title: PropTypes.string,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  onRangeChange: PropTypes.func,
};
export default DateRangePicker;

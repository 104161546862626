import React, { useEffect, useState, useMemo } from 'react';
import useAuthRequest from 'hooks/useAuthRequest';
import { Link, useParams } from 'react-router-dom';
import { constructHostsCheckUrl, constructHostsMailUrl } from 'api/urls';
import strings from 'constants/strings';
import AnimatedLoadingIcon from 'components/Icons/AnimatedLoadingIcon';
import { toast } from 'react-toastify';
import HostForm from './HostForm';
import styles from './HostConfirmation.scss';

const HostConfirmation = () => {
  const request = useAuthRequest();
  const { hash } = useParams();

  const [loading, setLoading] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(false);

  const displayTokenExpired = () =>
    toast.error(strings.expiredConfirmationToken, {
      progressClassName: 'customProgressBarError',
    });

  const renewConfirmationRequest = () =>
    request({ url: constructHostsMailUrl(hash), method: 'POST' });

  useEffect(() => {
    setLoading(true);
    request({
      url: constructHostsCheckUrl(hash),
    })
      .then(() => {
        setIsTokenValid(true);
      })
      .catch(() => {
        displayTokenExpired();
        renewConfirmationRequest();
      })
      .finally(() => setLoading(false));
  }, [hash]);

  const renderedLayout = useMemo(() => {
    if (loading) {
      return (
        <div className={styles.loading}>
          <AnimatedLoadingIcon />
        </div>
      );
    } else if (!isTokenValid) {
      return (
        <div className={styles.invalid}>
          <Link to="/dashboard/tasting" className={styles.link}>
            Back to dashboard
          </Link>
        </div>
      );
    }
    return <HostForm />;
  }, [loading, isTokenValid]);

  return <div className={styles.root}>{renderedLayout}</div>;
};

export default HostConfirmation;

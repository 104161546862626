import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import clsx from 'clsx';

import styles from './FullscreenModal.scss';

const FullscreenModal = ({ id, children, isOpen, className, translucent }) => {
  return (
    <Modal id={id} isOpen={isOpen}>
      <div
        className={clsx(
          styles.root,
          translucent && styles.translucent,
          className
        )}
      >
        {children}
      </div>
    </Modal>
  );
};

FullscreenModal.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
  translucent: PropTypes.bool,
};

export default FullscreenModal;

import React, { useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import useSelector from 'hooks/useSelector';
import { terminalsSelector } from 'models/session/selectors';

import useAction from 'hooks/useAction';
import { stripeActions } from 'models/stripe/slice';

import TerminalList from 'components/TerminalList';

import styles from './RefundTerminal.scss';

const RefundTerminal = () => {
  const terminalsList = useSelector(terminalsSelector);
  const selectTerminal = useAction(stripeActions.selectTerminal);

  const handleClick = useCallback(
    params => {
      selectTerminal({ ...params, type: 'refund' });
    },
    [selectTerminal]
  );

  const action = { title: 'Choose', function: handleClick };

  return (
    <CSSTransition in appear timeout={200} className="fade" unmountOnExit>
      <div className={styles.root}>
        <div className={styles.guestList}>
          <TerminalList terminals={terminalsList} action={action} />
        </div>
        <div className={styles.buttons}>
          <Link
            className={clsx(styles.actionLink, styles.actionLinkMuted)}
            to="/dashboard/orders"
          >
            Cancel
          </Link>
        </div>
      </div>
    </CSSTransition>
  );
};

export default RefundTerminal;

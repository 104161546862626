import { useEffect } from 'react';
import useAction from 'hooks/useAction';
import { foodActions } from 'models/food/slice';
import { productCategoriesActions } from 'models/productCategories/slice';
import { desksActions } from 'models/desks/slice';
import useSelector from 'hooks/useSelector';
import {
  currentBrandSelector,
  currentWarehouseSelector,
} from 'models/session/selectors';
import { brandKey, warehouseKey } from 'constants/localStorage';

export default () => {
  const fetchFoodAllergies = useAction(foodActions.fetchFoodAllergies);
  const fetchProductCategories = useAction(
    productCategoriesActions.fetchProductCategoriesRequest
  );
  const fetchGuestOrders = useAction(desksActions.fetchGuestOrdersRequest);

  const currentBrand = useSelector(currentBrandSelector);
  const currentWarehouse = useSelector(currentWarehouseSelector);

  useEffect(() => {
    fetchProductCategories({
      brand_id: currentBrand || localStorage.getItem(brandKey),
      warehouse_id: currentWarehouse || localStorage.getItem(warehouseKey),
    });
    fetchGuestOrders();
    fetchFoodAllergies();
  }, []);
};

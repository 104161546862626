import React from 'react';
import clsx from 'clsx';
import useSelector from 'hooks/useSelector';
import { Link, useHistory } from 'react-router-dom';
import { currentOrderSelector } from 'models/desks/selectors';
import { CSSTransition } from 'react-transition-group';
import ChevronLeftIcon from 'components/Icons/ChevronLeftIcon';
import styles from './PaymentReceipt.scss';

import BlockAddEmail from 'components/BlockAddEmail';

const PaymentTips = () => {
  const history = useHistory();
  const currentOrder = useSelector(currentOrderSelector);

  const redirectToTips = () => history.push('/dashboard/payment/tips');

  return (
    <CSSTransition in appear timeout={200} classNames="fade" unmountOnExit>
      <div className={styles.root}>
        <div className={styles.title}>
          Please leave your email address so that we can send you a receipt.
        </div>

        <div className={styles.email}>
          <BlockAddEmail nextAction={redirectToTips} />
        </div>

        <div className={styles.actions}>
          {!currentOrder.customer?.email && (
            <Link
              to="/dashboard/payment/tips"
              className={clsx(styles.actionLink, styles.actionLinkMuted)}
            >
              Skip
            </Link>
          )}
        </div>

        <Link className={styles.back} to="/dashboard/payment">
          <div className={styles.backButton}>
            <ChevronLeftIcon width="30" height="30" />
          </div>
          <div className={styles.backContent}>Payment Type</div>
        </Link>
      </div>
    </CSSTransition>
  );
};

export default PaymentTips;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TimesIcon from 'components/Icons/TimesIcon';
import FullscreenModal from '../FullscreenModal';
import styles from './EditOrderModal.scss';
import { OrderItemsResourceTypes } from 'constants/orderItems';

import useSelector from 'hooks/useSelector';
import useAction from 'hooks/useAction';

import { desksActions } from 'models/desks/slice';
import { productCountsByIdSelector } from 'models/productCategories/selectors';
import { productCategoriesActions } from 'models/productCategories/slice';
import ProductCounter from '../../ProductCounter';

import { getProductTitleWithVintage } from 'utils/getPreparedInventory';

const getProductTypeName = product => {
  const productTypes = {
    TastingMenuOrderItem: 'Tasting Menu',
    ProductOrderItem: 'Bottle',
    ProductSampleOrderItem: 'Glass',
  };

  if (
    product?.resourcetype === OrderItemsResourceTypes.PRODUCT_ORDER_ITEM &&
    product?.product?.resourcetype !== 'Wine'
  )
    return 'Item';

  return productTypes[product.resourcetype];
};

const EditOrderModal = ({ orderItem, title, onClose, isOpen, orderId }) => {
  const productId = orderItem?.product?.id;
  const onRemove = useAction(desksActions.removeOrderItemRequest);
  const fetchProductCount = useAction(
    productCategoriesActions.fetchProductCountRequest
  );
  const [count, setCount] = useState(orderItem?.number);
  const createItem = useAction(desksActions.storeOrderItem);
  const updateOrderItem = useAction(desksActions.updateOrderItemRequest);
  const productCount = useSelector(productCountsByIdSelector(productId));
  const isPlusButtonDisabled =
    orderItem?.resourcetype === OrderItemsResourceTypes.PRODUCT_ORDER_ITEM &&
    productCount <= count - orderItem?.number;

  useEffect(() => {
    if (productCount == null && productId) {
      fetchProductCount({ id: productId });
    }
  }, [productCount, fetchProductCount, productId]);

  const onContinue = () => {
    if (orderItem.number !== count) {
      if (orderItem.number === 0) {
        createItem({
          number: count,
          product_id: orderItem.product.id,
          type: orderItem.resourcetype,
        });
      } else if (count === 0) {
        onRemove({ orderId, orderItemId: orderItem.id });
      } else {
        updateOrderItem({
          number: count,
          resourcetype: orderItem.resourcetype,
          orderItemId: orderItem.id,
          order_id: orderId,
          addedNumber: count - orderItem?.number,
        });
      }
    }
    onClose();
  };

  return (
    <FullscreenModal id="editOrderModal" isOpen={isOpen} translucent>
      <div className={styles.root}>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.title}>
              {getProductTitleWithVintage(title, orderItem?.product?.vintage)}
            </div>
            <div className={styles.close}>
              <button type="button" onClick={onClose}>
                <TimesIcon width="18" height="18" />
              </button>
            </div>
          </div>
          <div className={styles.options}>
            <ProductCounter
              className={styles.counter}
              title={getProductTypeName(orderItem)}
              count={count}
              price={orderItem?.product_price ?? orderItem?.total_price ?? 0}
              onChange={setCount}
              isAddedButtonDisabled={isPlusButtonDisabled}
            />
          </div>
          <div className={styles.continueButton} onClick={onContinue}>
            Confirm
          </div>
        </div>
      </div>
    </FullscreenModal>
  );
};

EditOrderModal.propTypes = {
  title: PropTypes.string,
  orderId: PropTypes.number,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  orderItem: PropTypes.object,
};

export default EditOrderModal;

import React from 'react';
import PropTypes from 'prop-types';

const ChevronLeftIcon = ({ fill, width, height, strokeWidth }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      x="0"
      y="0"
      viewBox="0 0 511.641 511.641"
    >
      <g>
        <g xmlns="http://www.w3.org/2000/svg" strokeWidth={strokeWidth}>
          <g>
            <path
              d="M148.32,255.76L386.08,18c4.053-4.267,3.947-10.987-0.213-15.04c-4.16-3.947-10.667-3.947-14.827,0L125.707,248.293    c-4.16,4.16-4.16,10.88,0,15.04L371.04,508.667c4.267,4.053,10.987,3.947,15.04-0.213c3.947-4.16,3.947-10.667,0-14.827    L148.32,255.76z"
              fill={fill}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

ChevronLeftIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  strokeWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ChevronLeftIcon.defaultProps = {
  fill: '#EDEAE3',
  width: '17',
  height: '17',
  strokeWidth: '2',
};

export default ChevronLeftIcon;

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import styles from './TimeSection.scss';

const TimeSection = ({ title, setTime, time }) => {
  const formatTime = givenTime => {
    return givenTime.toString().padStart(2, '0');
  };
  const incrementHours = setFunction => {
    setFunction(prev => ({ ...prev, hours: (prev.hours + 1) % 12 || 12 }));
  };

  const decrementHours = setFunction => {
    setFunction(prev => ({ ...prev, hours: (prev.hours - 1) % 12 || 12 }));
  };

  const incrementMinutes = setFunction => {
    setFunction(prev => ({ ...prev, minutes: (prev.minutes + 15) % 60 }));
  };

  const decrementMinutes = setFunction => {
    setFunction(prev => {
      if (prev.minutes - 1 < 0) {
        return { ...prev, minutes: 45 };
      }
      return { ...prev, minutes: prev.minutes - 15 };
    });
  };

  const toggleAmOrPm = setFunction => {
    setFunction(prev => ({
      ...prev,
      amOrPm: prev.amOrPm === 'am' ? 'pm' : 'am',
    }));
  };
  return (
    <div className={styles.timeSection}>
      <p className={styles.label}>{title}</p>
      <div className={styles.customTimeInput}>
        <div className={clsx(styles.timeButtons, styles.increment)}>
          <button onClick={() => incrementHours(setTime)}>+</button>
          <button onClick={() => incrementMinutes(setTime)}>+</button>
        </div>
        <div className={styles.timeDisplay}>
          <span>{formatTime(time?.hours) || '00'}</span>
          <> : </>
          <span>{formatTime(time?.minutes) || '00'}</span>
          <span className={styles.amOrPm} onClick={() => toggleAmOrPm(setTime)}>
            {time?.amOrPm}
          </span>
        </div>
        <div className={clsx(styles.timeButtons, styles.decrement)}>
          <button onClick={() => decrementHours(setTime)}>-</button>
          <button onClick={() => decrementMinutes(setTime)}>-</button>
        </div>
      </div>
    </div>
  );
};

TimeSection.propTypes = {
  title: PropTypes.string,
  setTime: PropTypes.func,
  time: PropTypes.object,
};

export default TimeSection;

import React from 'react';
import styles from './ProductSkeletonCard.scss';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const ProductSkeletonCard = ({ className }) => {
  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.titleWrapper}>
        <div className={styles.subtitle} />
        <div className={styles.title} />
      </div>
    </div>
  );
};

ProductSkeletonCard.propTypes = {
  className: PropTypes.string,
};
export default ProductSkeletonCard;

import { takeLatest, all, put, call } from 'redux-saga/effects';
import { authenticatedRequest } from 'api';
import { fetchTastersUrl, createTasterUrl } from 'api/urls';
import { tastersActions } from './slice';
import { toast } from 'react-toastify';

function* fetchTastersSaga({ payload }) {
  try {
    const { data } = yield authenticatedRequest({
      url: fetchTastersUrl,
      method: 'PUT',
      data: { ...payload },
    });

    yield put({
      type: tastersActions.fetchTastersSuccess,
      payload: data,
    });
  } catch (error) {
    yield put({ type: tastersActions.fetchTastersFailure });
    yield call([toast, 'error'], 'Something went wrong while getting tasters', {
      progressClassName: 'customProgressBarError',
    });
  }
}

function* createTasterSaga({ payload }) {
  try {
    const { data } = yield authenticatedRequest({
      url: createTasterUrl,
      method: 'PUT',
      data: { ...payload },
    });

    yield put({
      type: tastersActions.createTasterSuccess,
      payload: data,
    });
  } catch (error) {
    yield put({ type: tastersActions.createTasterFailure });
    yield call([toast, 'error'], 'Something went wrong while creating taster', {
      progressClassName: 'customProgressBarError',
    });
  }
}

export default function*() {
  yield all([
    takeLatest(tastersActions.fetchTasters, fetchTastersSaga),
    takeLatest(tastersActions.createTaster, createTasterSaga),
  ]);
}

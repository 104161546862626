import React from 'react';
import PropTypes from 'prop-types';

const ChevronDownIcon = ({ fill, width, height, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      className={className}
      height={height}
      viewBox="0 0 14 6"
    >
      <title>Down</title>
      <g style={{ fill: 'none', opacity: 0.9 }}>
        <g stroke={fill}>
          <polyline
            transform="translate(-1148 -122)translate(1067 111)translate(88 11)rotate(-225)translate(-88 -11)"
            points="84 7 92 7 92 15"
          />
        </g>
      </g>
    </svg>
  );
};

ChevronDownIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

ChevronDownIcon.defaultProps = {
  fill: '#EDEAE3',
  width: '14',
  height: '6',
};

export default ChevronDownIcon;

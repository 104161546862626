import React from 'react';
import styles from './AddCustomerModal.scss';
import FullscreenModal from '../FullscreenModal';
import PropTypes from 'prop-types';
import TimesIcon from 'components/Icons/TimesIcon';
import CustomerForm from 'components/CustomerForm/CustomerForm';
import RippleSpinner from '../../RippleSpinner';

const AddCustomerModal = ({
  isOpen,
  onClose,
  defaultValues,
  onSubmit,
  loading,
}) => {
  const onSubmitHandler = data => {
    onSubmit?.(data);
  };

  return (
    <FullscreenModal id="AddCustomerModal" isOpen={isOpen}>
      <div className={styles.container}>
        {loading ? (
          <RippleSpinner className={styles.spinner} />
        ) : (
          <>
            <div className={styles.close}>
              <button type="button" onClick={onClose}>
                <TimesIcon width="28" height="26" />
              </button>
            </div>
            <h1 className={styles.title}>Edit Customer</h1>
            <div className={styles.form}>
              <CustomerForm
                onSubmit={onSubmitHandler}
                defaultValues={defaultValues}
                isEdit
              />
            </div>
          </>
        )}
      </div>
    </FullscreenModal>
  );
};

AddCustomerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  defaultValues: PropTypes.object,
};

export default AddCustomerModal;

import { all, call, put, takeLatest } from 'redux-saga/effects';
import { verificationActions } from './slice';
import { desksActions } from '../desks/slice';
import { authenticatedRequest } from 'api';
import { sendVerificationCodeUrl, validateVerificationUrl } from 'api/urls';
import { toast } from 'react-toastify';

function* fetchVerificationSaga({ payload }) {
  try {
    yield authenticatedRequest({
      url: sendVerificationCodeUrl,
      method: 'POST',
      data: payload,
    });
    yield put({
      type: verificationActions.fetchVerificationSuccess,
    });
  } catch (error) {
    console.error(error);
    yield put({ type: verificationActions.fetchVerificationFailure });
    yield call(
      [toast, 'error'],
      'An error occurred while sending verification code to the server',
      {
        progressClassName: 'customProgressBarError',
      }
    );
  }
}

function* fetchValidateVerificationSaga({ payload }) {
  try {
    const { data } = yield authenticatedRequest({
      url: validateVerificationUrl,
      method: 'POST',
      data: payload,
    });
    yield put({
      type: desksActions.acceptOrderEmail,
      payload: data.order,
    });
    yield call(
      [toast, 'success'],
      'The guest has been successfully verified!',
      {
        progressClassName: 'customProgressBarSuccess',
      }
    );
    yield put({
      type: verificationActions.fetchValidateVerificationSuccess,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: verificationActions.fetchValidateVerificationFailure,
      payload: 'Verification code is invalid',
    });
  }
}

export default function*() {
  yield all([
    takeLatest(verificationActions.fetchVerification, fetchVerificationSaga),
    takeLatest(
      verificationActions.fetchValidateVerification,
      fetchValidateVerificationSaga
    ),
  ]);
}

import React from 'react';
import styles from './TextField.scss';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import clsx from 'clsx';

const TextField = ({
  name,
  placeholder,
  control,
  inputMode,
  type,
  rules,
  onChange,
  onFocus,
  onBlur,
  className,
  disabled,
}) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const handleChange = event => {
    inputProps.onChange(event);
    // eslint-disable-next-line no-unused-expressions
    onChange && onChange(event);
  };

  const handleBlur = event => {
    inputProps.onBlur(event);
    // eslint-disable-next-line no-unused-expressions
    onBlur && onBlur(event);
  };

  return (
    <label
      className={clsx(
        styles.container,
        error && styles.errorContainer,
        className
      )}
    >
      <input
        {...inputProps}
        className={styles.input}
        type={type}
        name={name}
        inputMode={inputMode}
        placeholder={placeholder}
        ref={ref}
        onFocus={onFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={disabled}
      />
      {error && <p className={styles.error}>{error?.message}</p>}
    </label>
  );
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  control: PropTypes.any.isRequired,
  inputMode: PropTypes.string,
  type: PropTypes.string,
  rules: PropTypes.object,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default TextField;

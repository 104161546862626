import React, { memo } from 'react';
import PropTypes from 'prop-types';
import useAction from 'hooks/useAction';
import { commonActions } from 'models/common/slice';

const BrowserLink = ({ children, to, ...props }) => {
  const openLink = useAction(commonActions.openLink);

  const handleOnClick = () => {
    if (to) {
      openLink(to);
    }
  };

  return (
    <button type="button" onClick={handleOnClick} {...props}>
      {children}
    </button>
  );
};

BrowserLink.propTypes = {
  children: PropTypes.any,
  to: PropTypes.string,
};

export default memo(BrowserLink);

export const getParsedAddress = addressValue => {
  const address = {};
  const addressTypes = {
    country: 'country',
    administrative_area_level_1: 'state',
    locality: 'city',
    route: 'line1',
    street_number: 'line1',
    postal_code: 'postalCode',
  };
  addressValue.forEach(item => {
    const type = addressTypes[item.types[0]];
    if (type) {
      address[type] = address[type]
        ? `${address[type]} ${item.long_name}`
        : item.long_name;
    }
  });
  return address;
};

/* eslint-disable no-undef */
import { request } from 'api';
import { store } from '../client';

export const terminal = StripeTerminal.create({
  onFetchConnectionToken: async () => {
    const {
      session: {
        user: { token, acquirer, acquirer_id },
      },
    } = store.getState();
    return request({
      url: `/tasting-room-master-api/v1/payments/${acquirer}/${acquirer_id}/connection-token`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    })
      .then(({ data }) => {
        return data.secret;
      })
      .catch(err => console.err(err));
  },
  onUnexpectedReaderDisconnect: e => console.log(e),
});
